<template>
    <Modal ref="baseModal" :title="`장바구니`" :width="`600px`" :isDrag="false" @close="close">
        <template #body>
            <div>
				<p>해당 상품을 장바구니에 담았습니다.</p>
				<p>장바구니를 확인하시겠습니까?</p>
            </div>
        </template>
        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">계속 쇼핑하기</button>
			<button type="submit" class="btn btn-primary-1" :disabled="submitButtonDisabled" @click="onClickSave">장바구니 확인하기</button>
        </template>
	</Modal>
</template>

<script>
import { defineComponent, ref } from 'vue';
import Modal from '@/modals/Modal'

export default defineComponent({
    name       : 'modalProductCartPage',
    components: {
		Modal
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){

        const baseModal = ref(null)
		const resolvePromise = ref(null)

        const submitButtonDisabled  = ref(true)
        const closeModalData        = ref({})
        
        const initialize = async () => {
        }

		const open = async () => {
            submitButtonDisabled.value = false

            closeModalData.value = {
                isUpdate : false
            }

            //Open Modal
			baseModal.value.openModal()
            await initialize()
			return new Promise((resolve) => {
				resolvePromise.value = resolve;
			});
		};

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			baseModal.value.closeModal()
			resolvePromise.value(closeModalData.value)
		};

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onClickSave = () => {
            closeModalData.value = {
                isUpdate : true
            }
            close()
        }

        return {
			baseModal, 
			open,
			close,
            submitButtonDisabled,
            onClickSave
		}
    }
});
</script>