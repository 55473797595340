<template>
    <Modal ref="baseModal" :title="`상품 ${mode === 'create' ? '추가' : '수정'}`" :width="`95%`">
        <template #body>
			<form ref="productFormRef" @submit.prevent="onClickSave">
                <div class="row">
                    <div class="col-sm-4 border-right">
                        <div class="form-group row form-required mb-1">
                            <label for="inputProductDistributor" class="col-sm-2 col-form-label">상품 판매 구분</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-list-check"></i></span>
                                    </div>
                                    <select 
                                    v-model="productFormData.productDistributor"
                                    required
                                    pattern-hint="상품 판매 구분은 필수로 선택해야 합니다."
                                    class="form-control" 
                                    autocomplete="off"
                                    @change="onInputCheckEvent" 
                                    >
                                        <option value="">-- 상품 판매 구분 --</option>
                                        <option value="ddokdok">똑독</option>
                                        <option value="rentalez">렌탈이지</option>
                                    </select>
                                </div>
                                <small class="input-hint"></small>
                            </div>
                        </div>
                        <div class="form-group row form-required">
                            <label for="inputProductName" class="col-sm-2 col-form-label">상품명</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <input type="text" class="form-control" id="inputProductName" placeholder="상품명을 입력해주세요." autocomplete="off" tabindex="1"
                                    required 
                                    maxlength="150" 
                                    pattern-hint="상품명은 필수로 입력해야 합니다."
                                    v-model="productFormData.productName"
                                    @input="onInputCheckEvent" 
                                    />
                                </div>
                                <small class="input-hint"></small>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputProductDescription" class="col-sm-2 col-form-label">간략 소개글</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <input type="text" class="form-control" id="inputProductDescription" placeholder="간략 소개글을 입력해주세요." autocomplete="off" tabindex="2"
                                    v-model="productFormData.productDescription" 
                                    @input="onInputCheckEvent" 
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="form-group row form-required">
                            <label for="inputProductState" class="col-sm-2 col-form-label">사용여부</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-check-circle"></i></span>
                                    </div>
                                    <div class="form-control">
                                        <label v-for="(item, key) in storeState" :key="key" class="input-label mr-3" :for="`productState_${key}`">
                                            <input type="radio"
                                                :id="`productState_${key}`"
                                                name="productState"
                                                :value="key"
                                                v-model="productFormData.productState"
                                                @input="onInputCheckEvent" 
                                                required
                                            />
                                            <span class="radio-icon"></span>
                                            <span class="label-text">{{ item }}</span>
                                        </label>
                                    </div>
                                </div>
                                <small class="input-hint">사용여부는 필수로 입력해야 합니다.</small>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="selectProductState" class="col-sm-2 col-form-label">상품이미지</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <component-form-file-upload 
                                        ref="productImagesRef"
                                        :maxSize="10240"
                                        accept="png"
                                        :initialFiles = productFormData.productImages
                                        :mode=mode
                                        @on-click-attach-file="onClickProductImageFile"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="selectProductState" class="col-sm-2 col-form-label">상세정보</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <component-form-file-upload 
                                        ref="productDescriptionImagesRef"
                                        :maxSize="10240"
                                        accept="png"
                                        :initialFiles = productFormData.productDescriptionImages
                                        @on-click-attach-file="onClickProductDescriptionImageFile"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 border-right">
                        <div class="form-group row form-required">
                            <label for="inputProductSale" class="col-sm-2 col-form-label">판매분류</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-check-square"></i></span></div>
                                    <div class="form-control">
                                        <label class="input-label mr-3" for="productSubscribe">
                                            <input type="radio"
                                                id="productSubscribe"
                                                value="1"
                                                :checked="productFormData.productSubscribe === 1"
                                                @change="onChangeTypeRadio('productSubscribe')"
                                                @input="onInputCheckEvent" 
                                            />
                                            <span class="radio-icon"></span>
                                            <span class="label-text">구독</span>
                                        </label>
                                        <label class="input-label mr-3" for="productRental">
                                            <input type="radio"
                                                id="productRental"
                                                value="1"
                                                :checked="productFormData.productRental === 1"
                                                @change="onChangeTypeRadio('productRental')"
                                                @input="onInputCheckEvent" 
                                            />
                                            <span class="radio-icon"></span>
                                            <span class="label-text">렌탈형 구독</span>
                                        </label>
                                        <label class="input-label mr-3" for="productSale">
                                            <input type="radio"
                                                id="productSale"
                                                value="1"
                                                :checked="productFormData.productSale === 1"
                                                @change="onChangeTypeRadio('productSale')"
                                                @input="onInputCheckEvent" 
                                            />
                                            <span class="radio-icon"></span>
                                            <span class="label-text">판매</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-if="productFormData.productSubscribe === 1">
                            <div class="form-group row form-required">
                                <label for="inputProductSaleSubscribeRegularPrice" class="col-sm-2 col-form-label">구독 정상가(월)</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-won-sign"></i></span></div>
                                        <input type="number" class="form-control" id="inputProductSaleSubscribeRegularPrice" placeholder="구독 정상가를 입력해주세요." autocomplete="off" tabindex="2"
                                        v-model="productFormData.productSubscribeRegularPrice" 
                                        @input="onInputCheckEvent" 
                                        :required = "productFormData.productSubscribe === 1"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row form-required">
                                <label for="inputProductSaleSubscribePrice" class="col-sm-2 col-form-label">구독 판매가(월)</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-won-sign"></i></span></div>
                                        <input type="number" class="form-control" id="inputProductSaleSubscribePrice" placeholder="구독 판매가를 입력해주세요." autocomplete="off" tabindex="2"
                                        v-model="productFormData.productSubscribePrice" 
                                        @input="onInputCheckEvent" 
                                        :required = "productFormData.productSubscribe === 1"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row form-required">
                                <label for="inputProductSaleSubscribeDeposit" class="col-sm-2 col-form-label">구독 보증금</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-won-sign"></i></span></div>
                                        <input type="number" class="form-control" id="inputProductSaleSubscribeDeposit" placeholder="구독 보증금을 입력해주세요." autocomplete="off" tabindex="2"
                                        v-model="productFormData.productSubscribeDeposit" 
                                        @input="onInputCheckEvent" 
                                        :required = "productFormData.productSubscribe === 1"
                                        />
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="productFormData.productRental === 1">
                            <div class="form-group row form-required">
                                <label for="inputProductSaleRentalRegularPrice" class="col-sm-2 col-form-label">렌탈 정상가(월)</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-won-sign"></i></span></div>
                                        <input type="number" class="form-control" id="inputProductSaleRentalRegularPrice" placeholder="렌탈형 구독 정상가를 입력해주세요." autocomplete="off" tabindex="2"
                                        v-model="productFormData.productRentalRegularPrice" 
                                        @input="onInputCheckEvent" 
                                        :required = "productFormData.productRental === 1"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row form-required">
                                <label for="inputProductSaleRentalPrice" class="col-sm-2 col-form-label">렌탈 판매가(월)</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-won-sign"></i></span></div>
                                        <input type="number" class="form-control" id="inputProductSaleRentalPrice" placeholder="렌탈형 구독 판매가를 입력해주세요." autocomplete="off" tabindex="2"
                                        v-model="productFormData.productRentalPrice" 
                                        @input="onInputCheckEvent" 
                                        :required = "productFormData.productRental === 1"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row form-required">
                                <label for="inputProductSaleRentalPrice" class="col-sm-2 col-form-label">렌탈형 구독 개월</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-won-sign"></i></span></div>
                                        <input type="number" class="form-control" id="inputProductSaleRentalMonths" placeholder="렌탈형 구독 개월을 입력해주세요." autocomplete="off" tabindex="2"
                                        v-model="productFormData.productRentalMonths" 
                                        @input="onInputCheckEvent" 
                                        :required = "productFormData.productRental === 1"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row form-required">
                                <label for="inputProductSaleRentalDeposit" class="col-sm-2 col-form-label">렌탈형 구독 보증금</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-won-sign"></i></span></div>
                                        <input type="number" class="form-control" id="inputProductSaleRentalDeposit" placeholder="렌탈형 구독 보증금을 입력해주세요." autocomplete="off" tabindex="2"
                                        v-model="productFormData.productRentalDeposit" 
                                        @input="onInputCheckEvent" 
                                        :required = "productFormData.productRental === 1"
                                        />
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="productFormData.productSale === 1">
                            <div class="form-group row form-required">
                                <label for="inputProductSaleRegularPrice" class="col-sm-2 col-form-label">판매 정상가</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-won-sign"></i></span></div>
                                        <input type="number" class="form-control" id="inputProductSaleRegularPrice" placeholder="판매 정상가를 입력해주세요." autocomplete="off" tabindex="2"
                                        v-model="productFormData.productSaleRegularPrice" 
                                        @input="onInputCheckEvent" 
                                        :required = "productFormData.productSale === 1"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row form-required">
                                <label for="inputProductSalePrice" class="col-sm-2 col-form-label">판매가</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-won-sign"></i></span></div>
                                        <input type="number" class="form-control" id="inputProductSalePrice" placeholder="판매가를 입력해주세요." autocomplete="off" tabindex="2"
                                        v-model="productFormData.productSalePrice" 
                                        @input="onInputCheckEvent" 
                                        :required = "productFormData.productSale === 1"
                                        />
                                    </div>
                                </div>
                            </div>
                        </template>

                        <hr />

                        <template v-for="(item, index) in productFormData.productDefaultItems" :key="index">
                            <div class="form-group row form-required mb-1">
                                <label for="inputProductSale" class="col-sm-2 col-form-label">기본사양({{ index + 1 }})</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-list-check"></i></span>
                                        </div>
                                        <select class="form-control" 
                                        v-model="productFormData.productDefaultItemsFilter[index]"
                                        placeholder="기본사양 표기명 입력해주세요." 
                                        autocomplete="off"
                                        required
                                        @change="onChangeProductItemName('default', index)"
                                        pattern-hint="기본사양은 필수로 선택해야 합니다."
                                        >
                                            <option value="">-- 선택 --</option>
                                            <option v-for="(dItem, dIndex) in defaultItemList" :key="dIndex" :value="dItem">{{ dItem.itemName }}</option>
                                        </select>
                                    </div>
                                    <small class="input-hint"></small>
                                </div>
                            </div>
                            <div class="form-group row" v-show="false">
                                <label for="inputProductSale" class="col-sm-2 col-form-label">&nbsp;</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                        <input type="text" class="form-control"
                                        v-model="item.productItemName"
                                        placeholder="기본사양 표기명 입력해주세요." 
                                        autocomplete="off"
                                        @blur="onInputCheckEvent" 
                                        pattern-hint="기본사양 표기명은 필수로 입력해야 합니다."
                                        />
                                    </div>
                                    <small class="input-hint"></small>
                                </div>
                            </div>
                        </template>

                        <template v-for="(item, index) in productFormData.productOptionItems" :key="index">
                            <div class="form-group row mb-1">
                                <label for="inputProductSale" class="col-sm-2 col-form-label">
                                    추가옵션({{ index + 1 }})
                                    <template v-if="index === 0">
                                        <span class="ml-1" role="button" @click="onClickOptionItemAdd()"><i class="fas fa-circle-plus"></i></span>
                                    </template>
                                    <template v-else>
                                        <span class="ml-1" role="button" @click="onClickOptionItemRemove(index)"><i class="fas fa-circle-minus"></i></span>
                                    </template>
                                </label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-list-check"></i></span>
                                        </div><select class="form-control" 
                                        v-model="productFormData.productOptionItemsFilter[index]"
                                        @change="onChangeProductItemName('option', index)"
                                        >
                                            <option value="">-- 선택 --</option>
                                            <option v-for="(dItem, dIndex) in optionItemList" :key="dIndex" :value="dItem">{{ dItem.itemName }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1" v-show="false">
                                <label class="col-sm-2 col-form-label">&nbsp;</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                        <input 
                                            type="text" 
                                            class="form-control"
                                            v-model="item.productItemName"
                                            placeholder="옵션 표기명 입력해주세요." 
                                            autocomplete="off"
                                            @blur="onInputCheckEvent" 
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1" v-if="productFormData.productSubscribe === 1">
                                <label class="col-sm-2 col-form-label">&nbsp;</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-won-sign"></i></span></div>
                                        <input 
                                            type="number" 
                                            class="form-control"
                                            v-model="item.productItemSubscribePrice"
                                            placeholder="구독 - 옵션 가격을 입력해주세요." 
                                            autocomplete="off"
                                            @blur="onInputCheckEvent" 
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row" v-if="productFormData.productRental === 1">
                                <label class="col-sm-2 col-form-label">&nbsp;</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-won-sign"></i></span></div>
                                        <input 
                                            type="number" 
                                            class="form-control"
                                            v-model="item.productItemRentalPrice"
                                            placeholder="렌탈형 구독 - 옵션 가격을 입력해주세요." 
                                            autocomplete="off"
                                            @blur="onInputCheckEvent" 
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1" v-if="productFormData.productSale === 1">
                                <label class="col-sm-2 col-form-label">&nbsp;</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-won-sign"></i></span></div>
                                        <input 
                                            type="number" 
                                            class="form-control"
                                            v-model="item.productItemSalePrice"
                                            placeholder="판매 - 옵션 가격을 입력해주세요." 
                                            autocomplete="off"
                                            @blur="onInputCheckEvent" 
                                        />
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-for="(item, index) in productFormData.productServiceItems" :key="index">
                            <div class="form-group row mb-1">
                                <label for="inputProductSale" class="col-sm-2 col-form-label">
                                    서비스({{ index + 1 }})
                                    <template v-if="index === 0">
                                        <span class="ml-1" role="button" @click="onClickServiceItemAdd()"><i class="fas fa-circle-plus"></i></span>
                                    </template>
                                    <template v-else>
                                        <span class="ml-1" role="button" @click="onClickServiceItemRemove(index)"><i class="fas fa-circle-minus"></i></span>
                                    </template>
                                </label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-list-check"></i></span>
                                        </div>
                                        <select 
                                            class="form-control" 
                                            v-model="productFormData.productServiceItemsFilter[index]"
                                            @change="onChangeProductItemName('service', index)"
                                        >
                                            <option value="">-- 선택 --</option>
                                            <option v-for="(dItem, dIndex) in serviceItemList" :key="dIndex" :value="dItem">{{ dItem.itemName }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1" v-show="false">
                                <label class="col-sm-2 col-form-label">&nbsp;</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                        <input type="text" class="form-control"
                                        v-model="item.productItemName"
                                        placeholder="서비스 표기명 입력해주세요." 
                                        autocomplete="off"
                                        @blur="onInputCheckEvent" 
                                        />
                                    </div>
                                </div>
                            </div>

                            <!--서비스 가격은 0원으로 처리함-->
                            <div v-show="false">
                                <div class="form-group row mb-1" v-if="productFormData.productSubscribe === 1">
                                    <label class="col-sm-2 col-form-label">&nbsp;</label>
                                    <div class="col-sm-10">
                                        <div class="input-group">
                                            <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-won-sign"></i></span></div>
                                            <input type="number" class="form-control"
                                            v-model="item.productItemSubscribePrice"
                                            placeholder="구독 - 서비스 가격을 입력해주세요." 
                                            autocomplete="off"
                                            @blur="onInputCheckEvent" 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row mb-1" v-if="productFormData.productRental === 1">
                                    <label class="col-sm-2 col-form-label">&nbsp;</label>
                                    <div class="col-sm-10">
                                        <div class="input-group">
                                            <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-won-sign"></i></span></div>
                                            <input type="number" class="form-control"
                                            v-model="item.productItemRentalPrice"
                                            placeholder="렌탈형 구독 - 서비스 가격을 입력해주세요." 
                                            autocomplete="off"
                                            @blur="onInputCheckEvent" 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row mb-1" v-if="productFormData.productSale === 1">
                                    <label class="col-sm-2 col-form-label">&nbsp;</label>
                                    <div class="col-sm-10">
                                        <div class="input-group">
                                            <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-won-sign"></i></span></div>
                                            <input type="number" class="form-control"
                                            v-model="item.productItemSalePrice"
                                            placeholder="판매 - 서비스 가격을 입력해주세요." 
                                            autocomplete="off"
                                            @blur="onInputCheckEvent" 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </template>

                    </div>
                    <div class="col-sm-4">
                        <div class="form-group row form-required">
                            <label for="inputProductPurpose" class="col-sm-2 col-form-label">상품용도</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-check-square"></i></span></div>
                                    <div class="form-control">
                                        <label class="input-label mr-3" for="productPurposeGaming">
                                            <input type="checkbox"
                                                id="productPurposeGaming"
                                                value="1"
                                                :checked="productFormData.productPurposeGaming === 1"
                                                @change="productFormData.productPurposeGaming = $event.target.checked ? 1 : 0"
                                                @input="onInputCheckEvent" 
                                            />
                                            <span class="checkbox-icon"></span>
                                            <span class="label-text">게이밍용</span>
                                        </label>
                                        <label class="input-label mr-3" for="productPurposeProfessional">
                                            <input type="checkbox"
                                                id="productPurposeProfessional"
                                                value="1"
                                                :checked="productFormData.productPurposeProfessional === 1"
                                                @change="productFormData.productPurposeProfessional = $event.target.checked ? 1 : 0"
                                                @input="onInputCheckEvent" 
                                            />
                                            <span class="checkbox-icon"></span>
                                            <span class="label-text">전문가용</span>
                                        </label>
                                        <label class="input-label mr-3" for="productPurposeWork">
                                            <input type="checkbox"
                                                id="productPurposeWork"
                                                value="1"
                                                :checked="productFormData.productPurposeWork === 1"
                                                @change="productFormData.productPurposeWork = $event.target.checked ? 1 : 0"
                                                @input="onInputCheckEvent" 
                                            />
                                            <span class="checkbox-icon"></span>
                                            <span class="label-text">업무용</span>
                                        </label>
                                        <label class="input-label mr-3" for="productPurposeStudy">
                                            <input type="checkbox"
                                                id="productPurposeStudy"
                                                value="1"
                                                :checked="productFormData.productPurposeStudy === 1"
                                                @change="productFormData.productPurposeStudy = $event.target.checked ? 1 : 0"
                                                @input="onInputCheckEvent" 
                                            />
                                            <span class="checkbox-icon"></span>
                                            <span class="label-text">학습용</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputProductIs" class="col-sm-2 col-form-label">상품표기</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-check-square"></i></span></div>
                                    <div class="form-control">
                                        <label class="input-label mr-3" for="productIsRecommend">
                                            <input type="checkbox"
                                                id="productIsRecommend"
                                                value="1"
                                                :checked="productFormData.productIsRecommend === 1"
                                                @change="productFormData.productIsRecommend = $event.target.checked ? 1 : -1"
                                                @input="onInputCheckEvent" 
                                            />
                                            <span class="checkbox-icon"></span>
                                            <span class="label-text">추천상품</span>
                                        </label>
                                        <label class="input-label mr-3" for="productIsSpecialPrice">
                                            <input type="checkbox"
                                                id="productIsSpecialPrice"
                                                value="1"
                                                :checked="productFormData.productIsSpecialPrice === 1"
                                                @change="productFormData.productIsSpecialPrice = $event.target.checked ? 1 : -1"
                                                @input="onInputCheckEvent" 
                                            />
                                            <span class="checkbox-icon"></span>
                                            <span class="label-text">특가상품</span>
                                        </label>
                                        <label class="input-label mr-3" for="productIsPromotion">
                                            <input type="checkbox"
                                                id="productIsPromotion"
                                                value="1"
                                                :checked="productFormData.productIsPromotion === 1"
                                                @change="productFormData.productIsPromotion = $event.target.checked ? 1 : -1"
                                                @input="onInputCheckEvent" 
                                            />
                                            <span class="checkbox-icon"></span>
                                            <span class="label-text">기획상품</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-for="(item, index) in productFormData.convertProductCategory" :key="index">
                            <div class="form-group row">
                                <label for="inputProductCategory" class="col-sm-2 col-form-label">
                                    카테고리({{ index + 1 }}) 
                                </label>
                                <div class="col-sm-4">
                                    <div class="input-group">
                                        <select 
                                        v-model="item.parentUid"
                                        class="form-control" 
                                        autocomplete="off"
                                        @change="onInputCheckEvent" 
                                        >
                                            <option value="">-- 선택 --</option>
                                            <option v-for="(parent, index) in parentCategoryList" :key="index" :value="parent.categoryUid">{{ parent.categoryName }}</option>
                                        </select>
                                    </div>
                                    <small class="input-hint"></small>
                                </div>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <select 
                                        v-model="item.childUid"
                                        class="form-control" 
                                        autocomplete="off"
                                        >
                                            <option value="">-- 선택 --</option>
                                            <option v-for="(child, index) in getCategoryFilteredChildList(item)" :key="index" :value="child.categoryUid">{{ child.categoryName }}</option>
                                        </select>
                                    </div>
                                    <small class="input-hint"></small>
                                </div>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <select 
                                        v-model="item.thirdUid"
                                        class="form-control" 
                                        autocomplete="off"
                                        >
                                            <option value="">-- 선택 --</option>
                                            <option v-for="(third, index) in getCategoryFilteredThirdList(item)" :key="index" :value="third.categoryUid">{{ third.categoryName }}</option>
                                        </select>
                                    </div>
                                    <small class="input-hint"></small>
                                </div>
                            </div>
                        </template>

                        <template v-for="(item, index) in productFormData.convertProductComposition" :key="index">
                            <div class="form-group row">
                                <label for="inputProductCategory" class="col-sm-2 col-form-label">
                                    상품구성({{ index + 1 }}) 
                                    <template v-if="index === 0">
                                        <span class="ml-1" role="button" @click="onClickCompositionAdd()"><i class="fas fa-circle-plus"></i></span>
                                    </template>
                                    <template v-else>
                                        <span class="ml-1" role="button" @click="onClickCompositionRemove(index)"><i class="fas fa-circle-minus"></i></span>
                                    </template>
                                </label>
                                <div class="col-sm-5">
                                    <div class="input-group">
                                        <select 
                                        v-model="item.parentUid"
                                        class="form-control" 
                                        autocomplete="off"
                                        @change="onInputCheckEvent" 
                                        >
                                            <option value="">-- 선택 --</option>
                                            <option v-for="(parent, index) in parentCompositionList" :key="index" :value="parent.compositionUid">{{ parent.compositionName }}</option>
                                        </select>
                                    </div>
                                    <small class="input-hint"></small>
                                </div>
                                <div class="col-sm-5">
                                    <div class="input-group">
                                        <select 
                                        v-model="item.childUid"
                                        class="form-control" 
                                        autocomplete="off"
                                        >
                                            <option value="">-- 선택 --</option>
                                            <option v-for="(child, index) in getCompositionFilteredChildList(item)" :key="index" :value="child.compositionUid">{{ child.compositionName }}</option>
                                        </select>
                                    </div>
                                    <small class="input-hint"></small>
                                </div>
                            </div>
                        </template>
                        
                        <div class="form-group row">
                            <label for="inputContent" class="col-sm-2 col-form-label">비고</label>
                            <div class="col-sm-10">
                                <div class="pt-2 pb-2">
                                    <smarteditor2 
                                    v-model="productFormData.convertProductNotes"
                                    :editor-id="'editor1'"
                                    ref="editor1"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</form>
        </template>
        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">닫기</button>
			<button type="submit" class="btn btn-primary" :disabled="submitButtonDisabled" @mouseenter="onOverSubmitButton" @click="onClickSave()">{{ mode === 'create'?'저장':'수정' }}</button>
        </template>
	</Modal>

	<alert ref="alertRef" :title="'알림'" />
    <!--
    <modal-product-category-selected ref="modalProductCategorySelectedRef" />@onCloseModal="onCloseModalProductCategorySelected"-->

</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { unescapeHtmlEntities  } from '@/helper/utils/etcUtils.js'

import Modal from '@/modals/Modal'
import Alert from '@/modals/common/Alert'

import ComponentFormFileUpload from "@/components/admin/ComponentFormFileUpload"
import ComponentFormSmarteditor2 from "@/components/admin/ComponentFormSmarteditor2"

export default defineComponent({
    name       : 'modalProductUpdatePage',
    components: {
		Modal,
		'alert' : Alert,
        'component-form-file-upload' : ComponentFormFileUpload,
        'smarteditor2' : ComponentFormSmarteditor2,
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){
		const { openModal, closeModal, baseModal, onHandleValidateInput, $axiosConnect, storeState } = commonMixin()

        const mode                     = ref('create')
        const closeModalData           = ref({})
        const alertRef                 = ref(null)
        const submitButtonDisabled     = ref(true)

        const productFormRef           = ref(null)
        const productFormData          = ref({})

        const productImages            = ref([])
        const productDescriptionImages = ref([])

        const defaultItemList          = ref([])
        const optionItemList           = ref([])
        const serviceItemList          = ref([])

        const parentCompositionList    = ref([])
        const childCompositionList     = ref([])

        const parentCategoryList       = ref([])
        const childCategoryList        = ref([])
        const thirdCategoryList        = ref([])

        const modalProductCategorySelectedRef = ref(null)

        const editor1 = ref(null)

		const open = (payload) => {
            initialize()

            if (payload.item && payload.mode === 'update') {
                productFormData.value = JSON.parse(JSON.stringify(payload.item))
                mode.value = payload.mode
                getDeatil()
            }

            //하위등록시
            if(payload.item && payload.item.uid){
                productFormData.value.parentUid = payload.item.uid
            }

            return openModal()
		}

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			closeModal(closeModalData.value)
		}
        
		/**
         * 입력 값의 유효성을 검사하고, 암호화된 아이디와 비밀번호를 생성하는 메서드
         * 유효성 검사 결과에 따라 submitDisabled 속성을 설정합니다.
         * @method onInputCheckEvent
         * @returns {void}
         */
         const onInputCheckEvent = () => {
            return !(submitButtonDisabled.value = !onHandleValidateInput(productFormRef.value))
        }

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onOverSubmitButton = () => {
            const formElements = productFormRef.value.querySelectorAll('input, select, textarea')
            formElements.forEach(input => {
                input.blur()
            })
        }

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
        const onClickSave = async () => {
            if(onInputCheckEvent()){
                //Editor Content
                editor1.value?.syncContent()

                // Utility function for processing product data
                const processProductData = (items, keyPrefix) => {
                    const mergedData = items.map(item => [item.parentUid, item.childUid, item.thirdUid].filter(Boolean).join('/'))
                    formData.set(`${keyPrefix}`, items.map(item => item.thirdUid || item.childUid || item.parentUid).join(','))
                    formData.set(`${keyPrefix}All`, mergedData.join(','))
                }

                const formData = new FormData()
                for (const key in productFormData.value) {
                    const value = productFormData.value[key]
                    if (value !== null && value !== undefined) {
                        if (key === 'productDefaultItems' || key === 'productOptionItems' || key === 'productServiceItems') {
                            const productItemsMerge = onSaveConvertProductItems(value, productFormData.value[key + 'Filter'])
                            formData.set(key, JSON.stringify(productItemsMerge))
                        } else if (key === 'convertProductComposition') {
                            processProductData(value, 'productComposition')
                        } else if (key === 'convertProductCategory') {
                            processProductData(value, 'productCategory')
                        } else {
                            formData.set(key, value)
                        }
                    }
                }

                // 파일들을 FormData에 추가
                productImages.value.forEach((file, index) => {
                    formData.append(`productImages[${index}]`, file)
                })
                productDescriptionImages.value.forEach((file, index) => {
                    formData.append(`productDescriptionImages[${index}]`, file)
                })
                
                onProcessSave(formData)
            }
        }

        /**
         * 로그인 프로세스를 처리하는 메서드
         * @method onProcessSignIn
         * @param {FormData} formData - 로그인에 필요한 폼 데이터
         * @returns {void}
         */
        const onProcessSave = async (formData) => {
            try {

                let response
                if(mode.value === 'create'){
                    response = await $axiosConnect('MEMBER', `/admin/product`, 'POST', formData)
                }else{
                    const uid = productFormData.value.uid
                    response = await $axiosConnect('MEMBER', `/admin/product/${uid}`, 'POST', formData)
                }

                if (response.success && response.body && response.body.list.length > 0) {

                    initialize()

                    closeModalData.value = {
                        isUpdate : true,
                        item     : response.body.list[0]
                    }

                    alertRef.value.open({ 
                        'contents' : response.message,
                        'callback' : close
                    })

                }else{
                    alertRef.value.open({ 'contents' : response.message })
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

        const onSaveConvertProductItems = (productItems, productItemsFilter) => {
            return productItems.map((item, index) => {
                const filter = productItemsFilter[index] || {}
                return {
                    productItemName          : item.productItemName || '',
                    productItemRentalPrice   : filter.itemType === 1 ? productFormData.value.productRentalPrice : item.productItemRentalPrice || 0,
                    productItemSalePrice     : filter.itemType === 1 ? productFormData.value.productSalePrice : item.productItemSalePrice || 0,
                    productItemSubscribePrice: filter.itemType === 1 ? productFormData.value.productSubscribePrice : item.productItemSubscribePrice || 0,
                    ...filter
                }
            }).filter(item => item.productItemName)
        }

        const getDeatil = async () => {
            try {
                const uid = productFormData.value.uid
                const response = await $axiosConnect('MEMBER', `/admin/product/${uid}`, 'GET')

                if (response.success && response.body && response.body.list.length > 0) {

                    const list = JSON.parse(JSON.stringify(response.body.list[0]))
                    productFormData.value = {
                        ...list,
                        converUid                   : list.uid.toString().padStart(8, '0'),
                        convertRegDate              : convertDateToStr(list.regDate),
                        convertUpDate               : convertDateToStr(list.upDate),
                        convertProductState         : storeState.value[list.productState],
                        productImages               : list.upfiles.filter(file => file.upfilesColumn === 'productImages'),
                        productDescriptionImages    : list.upfiles.filter(file => file.upfilesColumn === 'productDescriptionImages'),
                        productDefaultItems         : list.productItems.filter(item => item.itemType === 1).length ? list.productItems.filter(item => item.itemType === 1) : [{}],
                        productOptionItems          : list.productItems.filter(item => item.itemType === 2).length ? list.productItems.filter(item => item.itemType === 2) : [{}],
                        productServiceItems         : list.productItems.filter(item => item.itemType === 3).length ? list.productItems.filter(item => item.itemType === 3) : [{}],
                        productDefaultItemsFilter   : list.productItems.filter(item => item.itemType === 1).length ? list.productItems.filter(item => item.itemType === 1).map(({ itemName, itemType, itemUid }) => ({ itemName, itemType, itemUid })) : [{}],
                        productOptionItemsFilter    : list.productItems.filter(item => item.itemType === 2).length ? list.productItems.filter(item => item.itemType === 2).map(({ itemName, itemType, itemUid }) => ({ itemName, itemType, itemUid })) : [{}],
                        productServiceItemsFilter   : list.productItems.filter(item => item.itemType === 3).length ? list.productItems.filter(item => item.itemType === 3).map(({ itemName, itemType, itemUid }) => ({ itemName, itemType, itemUid })) : [{}],
                        convertProductNotes         : list.productNotes?unescapeHtmlEntities(list.productNotes):'&nbsp;',
                        convertProductComposition   : list.productCompositionAll ? list.productCompositionAll.split(',').map(item => { const [parentUid, childUid] = item.split('/').map(Number); return { parentUid, childUid }; }).filter(item => item.parentUid || item.childUid) : [{}],
                        convertProductCategory      : list.productCategoryAll ? list.productCategoryAll.split(',').map(item => { const [parentUid, childUid, thirdUid] = item.split('/').map(Number); return { parentUid, childUid, thirdUid }; }).filter(item => item.parentUid || item.childUid || item.thirdUid) : [{}],
                    }
                }else{
                    alertRef.value.open({ 'contents' : response.message })
                    close()
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const onClickProductImageFile = (filesInfo) => {
            productImages.value = filesInfo.map(fileInfo => fileInfo.file)

            productFormData.value.productImagesOrder = []
            filesInfo.forEach(fileInfo => {
                productFormData.value.productImagesOrder.push({
                    "name"  : fileInfo.upfilesOriginalName?fileInfo.upfilesOriginalName:fileInfo.name,
                    "order" : fileInfo.order
                })
            })
            productFormData.value.productImagesOrder = JSON.stringify(productFormData.value.productImagesOrder)

            onInputCheckEvent();
        };

        const onClickProductDescriptionImageFile = (filesInfo) => {
            productDescriptionImages.value = filesInfo.map(fileInfo => fileInfo.file)

            productFormData.value.productDescriptionImagesOrder = []
            filesInfo.forEach(fileInfo => {
                productFormData.value.productDescriptionImagesOrder.push({
                    "name"  : fileInfo.upfilesOriginalName?fileInfo.upfilesOriginalName:fileInfo.name,
                    "order" : fileInfo.order
                })
            })
            productFormData.value.productDescriptionImagesOrder = JSON.stringify(productFormData.value.productDescriptionImagesOrder)

            onInputCheckEvent();
        }

        const getCategoryList = async () => {
            try {
                let defaultParams = {
                    'page'          : 1,
                    'pageSize'      : 1000,
                    'categoryState' : 1
                }
                const response = await $axiosConnect('MEMBER', `/admin/categoryProduct`, 'GET', defaultParams)

                let list = []
                if(response.body){
                    list = JSON.parse(JSON.stringify(response.body.list))
                    if(list.length > 0){
                        list = list.map(({ uid, categoryName, groups, depth }) => {
                            return {
                                categoryUid    : uid,
                                categoryName   : categoryName,
                                categoryGroups : groups,
                                categoryDepth  : depth
                            }
                        })
                    }
                    parentCategoryList.value = list.filter(category => category.categoryDepth === 0)
                    childCategoryList.value  = list.filter(category => category.categoryDepth === 1)
                    thirdCategoryList.value  = list.filter(category => category.categoryDepth === 2)
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const getCategoryFilteredChildList = (item) => {
            const parentCategory = parentCategoryList.value.find(parent => parent.categoryUid === item.parentUid)
            if (parentCategory) {
                const { categoryGroups } = parentCategory
                return childCategoryList.value.filter(child => categoryGroups === child.categoryGroups && child.categoryDepth === 1)
            }
            return []
        }
        
        const getCategoryFilteredThirdList = (item) => {
            const parentCategory = parentCategoryList.value.find(parent => parent.categoryUid === item.parentUid)
            if (parentCategory) {
                const { categoryGroups } = parentCategory
                return childCategoryList.value.filter(child => categoryGroups === child.categoryGroups && child.categoryDepth === 2)
            }
            return []
        }

        const getItemList = async () => {
            try {
                let defaultParams = {
                    'page'      : 1,
                    'pageSize'  : 1000,
                    'itemState' : 1
                }
                const response = await $axiosConnect('MEMBER', `/admin/item`, 'GET', defaultParams)

                let list = []
                if(response.body){
                    list = JSON.parse(JSON.stringify(response.body.list))
                    if(list.length > 0){
                        list = list.map(({ uid, itemName,itemType }) => {
                            return {
                                itemUid  : uid,
                                itemName : itemName,
                                itemType : itemType
                                
                            }
                        })
                    }
                    defaultItemList.value = list.filter(item => item.itemType === 1)
                    optionItemList.value  = list.filter(item => item.itemType === 2)
                    serviceItemList.value = list.filter(item => item.itemType === 3)
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const onClickOptionItemAdd = () => {
            productFormData.value.productOptionItems.push({})
            productFormData.value.productOptionItemsFilter.push({})
        }

        const onClickOptionItemRemove = (index) => {
            productFormData.value.productOptionItems.splice(index, 1)
            productFormData.value.productOptionItemsFilter.splice(index, 1)
        }

        const onClickServiceItemAdd = () => {
            productFormData.value.productServiceItems.push({})
            productFormData.value.productServiceItemsFilter.push({})
        }

        const onClickServiceItemRemove = (index) => {
            productFormData.value.productServiceItems.splice(index, 1)
            productFormData.value.productServiceItemsFilter.splice(index, 1)
        }

        const getCompositionList = async () => {
            try {
                let defaultParams = {
                    'page'      : 1,
                    'pageSize'  : 1000,
                    'compositionState' : 1
                }
                const response = await $axiosConnect('MEMBER', `/admin/compositionProduct`, 'GET', defaultParams)

                let list = []
                if(response.body){
                    list = JSON.parse(JSON.stringify(response.body.list))
                    if(list.length > 0){
                        list = list.map(({ uid, compositionName, groups, depth }) => {
                            return {
                                compositionUid    : uid,
                                compositionName   : compositionName,
                                compositionGroups : groups,
                                compositionDepth  : depth
                            }
                        })
                    }
                    parentCompositionList.value = list.filter(composition => composition.compositionDepth === 0)
                    childCompositionList.value  = list.filter(composition => composition.compositionDepth === 1)
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const getCompositionFilteredChildList = (item) => {
            const parentComposition = parentCompositionList.value.find(parent => parent.compositionUid === item.parentUid)
            if (parentComposition) {
                const { compositionGroups } = parentComposition
                return childCompositionList.value.filter(child => compositionGroups === child.compositionGroups && child.compositionDepth === 1)
            }
            return []
        }

        const onClickCompositionAdd = () => {
            productFormData.value.convertProductComposition.push({})
        }

        const onClickCompositionRemove = (index) => {
            productFormData.value.convertProductComposition.splice(index, 1)
        }

        const onChangeTypeRadio = (selectedOption) => {
            if (selectedOption === 'productSubscribe') {
                productFormData.value.productSubscribe = 1;
                productFormData.value.productRental    = -1;
                productFormData.value.productSale      = -1;
            } else if (selectedOption === 'productRental') {
                productFormData.value.productSubscribe = -1;
                productFormData.value.productRental    = 1;
                productFormData.value.productSale      = -1;
            } else if (selectedOption === 'productSale') {
                productFormData.value.productSubscribe = -1;
                productFormData.value.productRental    = -1;
                productFormData.value.productSale      = 1;
            }
            onInputCheckEvent()
        }

        const onChangeProductItemName = (type, index) => {
            let selectedItem;
            if (type === 'default') {
                selectedItem = productFormData.value.productDefaultItemsFilter[index];
                productFormData.value.productDefaultItems[index].productItemName = selectedItem.itemName;
            } else if (type === 'option') {
                selectedItem = productFormData.value.productOptionItemsFilter[index];
                productFormData.value.productOptionItems[index].productItemName = selectedItem.itemName;
            } else if (type === 'service') {
                selectedItem = productFormData.value.productServiceItemsFilter[index];
                productFormData.value.productServiceItems[index].productItemName = selectedItem.itemName;
            }
            onInputCheckEvent()
        }

        

        const initialize = async () => {
            mode.value            = "create"

            productFormData.value = {
                productName                  : '',
                productDescription           : '',
                productNotes                 : '',
                productState                 : 1,
                productImages                : [],
                productDescriptionImages     : [],
                
                productSale                  : 0,
                productSaleRegularPrice      : 0,
                productSalePrice             : 0,
                productSubscribe             : 1,
                productSubscribeRegularPrice : 0,
                productSubscribePrice        : 0,
                productRental                : 0,
                productRentalRegularPrice    : 0,
                productRentalPrice           : 0,
                productRentalMonths          : 0,

                productIsRecommend           : 0,
                productIsSpecialPrice        : 0,
                productIsPromotion           : 0,
                productPurposeGaming         : 1,
                productPurposeProfessional   : 0,
                productPurposeWork           : 0,
                productPurposeStudy          : 0,

                productDefaultItems        : [{}],
                productOptionItems         : [{}],
                productServiceItems        : [{}],
                productDefaultItemsFilter  : [''],
                productOptionItemsFilter   : [''],
                productServiceItemsFilter  : [''],

                convertProductComposition  : [{}],
                convertProductCategory     : [{}],

                convertProductNotes        : ""
            }

            productImages.value            = []
            productDescriptionImages.value = []

            defaultItemList.value        = []
            optionItemList.value         = []
            serviceItemList.value        = []

            closeModalData.value = {
                isUpdate : false,
                item     : ''
            }

            await getCategoryList()
            await getItemList()
            await getCompositionList()
        }

        onMounted(async () => {
            await initialize()
        })
       

        return {
			open,
			close,
            mode,
            submitButtonDisabled,

			alertRef,
			productFormRef,
			productFormData,

            modalProductCategorySelectedRef,

            defaultItemList,
            optionItemList,
            serviceItemList,

            parentCompositionList,
            childCompositionList,
            parentCategoryList,
            childCategoryList,
            thirdCategoryList,

            editor1,

			onInputCheckEvent,
            onOverSubmitButton,
            onClickSave,
            onClickProductImageFile,
            onClickProductDescriptionImageFile,
            onClickOptionItemAdd,
            onClickOptionItemRemove,
            onClickServiceItemAdd,
            onClickServiceItemRemove,
            onClickCompositionAdd,
            onClickCompositionRemove,
            getCompositionFilteredChildList,
            getCategoryFilteredChildList,
            getCategoryFilteredThirdList,
            onChangeTypeRadio,
            onChangeProductItemName,

            //commonMixin
            storeState,
            baseModal, 
		}
    }
})
</script>
<style scoped>
::v-deep .ql-editor {
    height: 100px;
    overflow: scroll;
    overflow-x: hidden;
}
</style>