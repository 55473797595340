<template>
    <main>
        <div class="container">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <div class="page-tab-title">
                                <ul>
                                    <li class="active"><span>상품 만들기</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="sticky-top mb-3 pt-3">
                                <div class="card">
                                    <div class="card-header">
                                        <h4 class="card-title">검색</h4>
                                    </div>
                                    <div class="card-body">
                                        <template v-for="(item, index) in parentCompositionList" :key="index">
                                            <div class="search-row" v-show="getCompositionFilteredChildList(item).length > 0">
                                                <div class="search-name">{{ item.compositionName }}</div>
                                                <div class="row">
                                                    <div class="col-xs-12 col-md-6"
                                                        v-for="(childItem, childIndex) in getCompositionFilteredChildList(item)" :key="childIndex">
                                                        <label class="input-label" :for="'searchCommaProductComposition-' + index + '-' + childIndex">
                                                            <input type="radio"
                                                                :id="'searchCommaProductComposition-' + index + '-' + childIndex"
                                                                :name="'composition-' + index"
                                                                v-model="searchCommaProductComposition[index]"
                                                                :value="childItem.compositionUid"
                                                                @change="onChangeSearchParamComposition"
                                                            />
                                                            <span class="radio-icon"></span>
                                                            <span class="label-text">{{ childItem.compositionName }}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                    <div class="card-footer">
                                        <button type="button" class="btn btn-sub-2 btn-block" @click="onClickSearchList()">찾기</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="row">
                                <template v-for="(item, index) in list" :key="index">
                                    <div class="col-12 col-sm-6 col-lg-4">
                                        <div class="product-item" data-aos-delay="000" @click="onClickNavigateToProductDetail(item.uid)" role="button">
                                            <div class="product-inner-item">
                                                <div class="product-item-type">
                                                    <template v-if="item.productSubscribe === 1"><span class="product-item-subscribe-text">구독</span></template>
                                                    <template v-if="item.productRental === 1"><span class="product-item-rental-text">렌탈형 구독</span></template>
                                                    <template v-if="item.productSale === 1"><span class="product-item-sale-text">판매</span></template>
                                                </div>
                                                <picture class="product-image">
                                                    <template v-if="item.convertProductImages && item.convertProductImages.length > 0 ">
                                                        <img class="absolute-center" 
                                                            :src="`${imgURL}${item.convertProductImages[0].upfilesThumnailPath}/${item.convertProductImages[0].upfilesFileName}`" 
                                                            :alt="item.convertProductName"
                                                        />
                                                    </template>
                                                    <template v-else>
                                                        <img class="absolute-center" 
                                                            src="@/assets/images/common/logo_kor.png"
                                                            :alt="item.convertProductName"
                                                        />
                                                    </template>
                                                </picture>
                                                <div class="product-content">
                                                    <h5 class="product-name">{{ item.convertProductName }}</h5>
                                                    <p class="product-notes">{{ item.convertProductDescription }}</p>
                                                    <div class="product-purpose">
                                                        <template v-if="item.productPurposeGaming === 1"><span class="product-purpose-gaming">게이밍용</span></template>
														<template v-if="item.productPurposeProfessional === 1"><span class="product-purpose-professional">전문가용</span></template>
														<template v-if="item.productPurposeStudy === 1"><span class="product-purpose-study">학습용</span></template>
														<template v-if="item.productPurposeWork === 1"><span class="product-purpose-work">업무용</span></template>
                                                    </div>
                                                    <template v-if="item.productSubscribe === 1">
                                                        <div class="product-price-box">
                                                            <span class="product-price-subscribe-text">구독</span>
                                                            <span class="product-price-before">월 {{ item.convertProductSubscribeRegularPrice }}원</span>
                                                            <span class="product-price-after">월 {{ item.convertProductSubscribePrice }}원</span>
                                                        </div>
                                                    </template>
                                                    <template v-else-if="item.productRental === 1">
                                                        <div class="product-price-box">
                                                            <span class="product-price-rental-text">렌탈형 구독</span>
                                                            <span class="product-price-before">월 {{ item.convertProductRentalRegularPrice }}원</span>
                                                            <span class="product-price-after">월 {{ item.convertProductRentalPrice }}원</span>
                                                        </div>
                                                    </template>
                                                    <template v-else-if="item.productSale === 1">
                                                        <div class="product-price-box">
                                                            <span class="product-price-sale-text">판매</span>
                                                            <span class="product-price-before">월 {{ item.convertProductSaleRegularPrice }}원</span>
                                                            <span class="product-price-after">월 {{ item.convertProductSalePrice }}원</span>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>

                            <div class="row justify-content-center mt-8">
                                <div class="col-12 col-sm-12 col-lg-12">
                                    <div class="product-list-paging">
                                        <template v-if="listSearchCount > 0">
                                            <small>전체 {{ listTotalCount }}개의 상품 중 {{ listSearchCount }}개의 상품을 보고 있습니다.</small>
                                            <div class="progress">
                                                <div class="progress-bar bg-dark" role="progressbar" 
                                                :style="{ width: (listSearchCount / listTotalCount * 100) + '%' }"
                                                ></div>
                                            </div>
                                        </template>
                                        <template v-if="listSearchCount < listTotalCount">
                                            <button class="btn col-sm-2 btn-black-1 mrg-horizon-auto" @click="onClickMoreProductList()" v-if="listSearchCount < listTotalCount">상품 더보기</button>
                                        </template>
										<template v-else>
											<div class="product-request" v-if="Math.ceil(listTotalCount/listPageSize) === listCurrentPage">
												<p class="font-size-xl">찾고자 하는 상품이 못찾으셨나요?</p>
                                                <hr/>
                                                <div class="product-request-group">
                                                    <div>
                                                        <span>선택한 조건의 상품을 요청하세요.</span>
                                                        <span role="button" @click="onClickModalProductRequest()">상품 요청하기</span>
                                                    </div>
                                                    <div>
                                                        <span>1:1 문의로 원하는 상품을 문의해보세요.</span>
                                                        <span role="button" @click="router.push({ name: 'webContactPage' })">1:1 문의</span>
                                                    </div>
                                                    <div>
                                                        <span>담당자와 직접 전화로 문의해서 원하는 상품을 찾을 수 있어요.</span>
                                                        <span role="button" @click="router.push({ name: 'webCallRequestPage' })">전화상담요청</span>
                                                    </div>
                                                </div>
											</div>
										</template>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js';
import { convertDateToStr } from '@/helper/utils/dateUtils.js';
import { numberFormat, truncateText } from '@/helper/utils/etcUtils.js';

export default defineComponent({
    name: 'webProductSearchPage',
    setup() {
        const { $axiosConnect, router, imgURL } = commonMixin();

        const list = ref([]);
        const listCurrentPage = ref(1);
        const listPageSize = ref(12);
        const listTotalCount = ref(0);
        const listSearchCount = ref(0);
        const queryStringParams = ref({});
        const applySearchParams = ref({});

        const parentCompositionList = ref([]);
        const childCompositionList = ref([]);
        const searchCommaProductComposition = ref([]);

        const getList = async () => {
            try {
                const defaultParams = {
                    page: listCurrentPage.value,
                    pageSize: listPageSize.value
                };
                let params = Object.assign({}, defaultParams, applySearchParams.value);
                const response = await $axiosConnect('GUEST', `/web/product`, 'GET', params);

                if(response.body){
                    const products = JSON.parse(JSON.stringify(response.body.list))
                    listTotalCount.value  = JSON.parse(JSON.stringify(response.body.total))
                    listSearchCount.value += products.length

                    const processedProducts  = products.map(list => {
                        return {
                            ...list,
                            converUid                           : list.uid.toString().padStart(6, '0'),
							convertProductName                  : truncateText(list.productName, 75),
							convertProductDescription           : truncateText(list.productDescription, 75),
							converRegDate                       : convertDateToStr(list.regDate),
							converUpDate                        : convertDateToStr(list.upDate),
							convertProductSubscribeRegularPrice : numberFormat(list.productSubscribeRegularPrice),
							convertProductSubscribePrice        : numberFormat(list.productSubscribePrice),
							convertProductRentalRegularPrice    : numberFormat(list.productRentalRegularPrice),
							convertProductRentalPrice           : numberFormat(list.productRentalPrice),
							convertProductRentalMonths          : numberFormat(list.productRentalMonths),
							convertProductRentalMonthPrice      : numberFormat(list.productRentalPrice/list.productRentalMonths),
							convertProductSaleRegularPrice      : numberFormat(list.productSaleRegularPrice),
							convertProductSalePrice             : numberFormat(list.productSalePrice),
							convertProductViewCount             : numberFormat(list.productViewCount),
							convertProductImages                : list.upfiles.filter(file => file.upfilesColumn === 'productImages'),
							convertProductDescriptionImages     : list.upfiles.filter(file => file.upfilesColumn === 'productDescriptionImages')
                        }
                    })
                    list.value = [...list.value, ...processedProducts];
                }
            } catch (error) {
                console.error("error:", error);
            }
        };

        const onClickMoreProductList = () => {
            listCurrentPage.value++
            getList();
        };

        const onClickSearchList = () => {
            applySearchParams.value = { ...queryStringParams.value };
            list.value = [];
            listTotalCount.value = listSearchCount.value = 0;
            getList();
        };

        const onChangeSearchParamComposition = () => {
            queryStringParams.value['searchComma[PRODUCT_COMPOSITION]'] = searchCommaProductComposition.value.join(',');
        };

        const getCompositionFilteredChildList = (parentComposition) => {
            return childCompositionList.value.filter(child => child.compositionGroups === parentComposition.compositionGroups);
        };

        const onClickNavigateToProductDetail = (uid) => {
            router.push({ name: 'webProductDetailPage', params: { uid } });
        };

        const getCompositionList = async () => {
            try {
                const response = await $axiosConnect('GUEST', '/web/compositionProduct', 'GET', {
                    page: 1,
                    pageSize: 1000,
                    compositionState: 1
                });

                if (response.body) {
                    const list = response.body.list.map(({ uid, compositionName, groups, depth }) => ({
                        compositionUid: uid,
                        compositionName,
                        compositionGroups: groups,
                        compositionDepth: depth
                    }));
                    parentCompositionList.value = list.filter(composition => composition.compositionDepth === 0);
                    childCompositionList.value  = list.filter(composition => composition.compositionDepth === 1);
                }
            } catch (error) {
                console.error('Error fetching composition list:', error);
            }
        }

        const onClickModalProductRequest = () => {
            console.log("applySearchParams.value:", applySearchParams.value)
        }

        const onCloseModalProductRequest = () => {

        }

        const initialize = async () => {
			
            list.value              = []
            listTotalCount.value    = 0
            listSearchCount.value   = 0
			applySearchParams.value = {}
			queryStringParams.value = {}

            const routeQuery  = router.currentRoute.value.query || {}
			const composition = routeQuery.composition || ''
            if(composition){
                searchCommaProductComposition.value = composition.split(',') || []
                queryStringParams.value['searchComma[PRODUCT_COMPOSITION]'] = composition;
            }

            await getCompositionList();
            onClickSearchList();
        }

        onMounted(async () => {
            await initialize()

            
        });

        watch(() => router.currentRoute.value.query, async () => {
            await initialize();
        });

        return {
            imgURL,
            router,

            list,
            listCurrentPage,
            listPageSize,
            listTotalCount,
            listSearchCount,

            searchCommaProductComposition,
            parentCompositionList,
            childCompositionList,
            
            
            onClickSearchList,
            onClickMoreProductList,
            getCompositionFilteredChildList,
            onChangeSearchParamComposition,
            onClickNavigateToProductDetail,
            onClickModalProductRequest,
            onCloseModalProductRequest
        };
    }
});
</script>