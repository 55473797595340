<template>
    <Modal ref="baseModal" :title="`비밀번호 확인`" :width="`600px`" :isDrag="false" @close="close">
        <template #body>
            <form ref="passwordChangeFormRef">
				<div class="row">
                    <div class="col-sm-12">
						<div class="form-group row form-required">
							<label for="inputSubject" class="col-sm-3 col-form-label">현재 비밀번호</label>
							<div class="col-sm-9">
								<div class="input-group">
									<div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
									<input type="password" class="form-control" id="inputMemberPassword" placeholder="현재 비밀번호를 입력해주세요." autocomplete="new-password" 
										tabindex="1"
										required 
										maxlength="20" 
										pattern="^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{8,20}$" 
										pattern-hint="현재 비밀번호는 최소 1개의 숫자 또는 특수문자를 포함한 영문자 8~20자 입니다."
										v-model="passwordChangeFormData.memberPassword" 
										@input="onInputCheckEvent()" 
										@keydown.enter.prevent
									/>
								</div>
								<small class="input-hint-block active">내 정보 수정을 위하여 현재 비밀번호를 입력해주세요.</small>
								<small class="input-hint"></small>
							</div>
						</div>
					</div>
				</div>
			</form>
        </template>
        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()" tabindex="2">취소</button>
			<button type="button" class="btn btn-primary-1" :disabled="submitButtonDisabled" @click="onClickSave" tabindex="3">확인</button>
        </template>
	</Modal>

	<alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, ref } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { encryptPassword } from '@/helper/plugins/security.js'
import Modal from '@/modals/Modal'
import Alert from '@/modals/common/Alert'

export default defineComponent({
    name       : 'modalPasswordChangePage',
    components: {
		Modal,
		'alert' : Alert,
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){
		const { onHandleValidateInput, $axiosConnect } = commonMixin()

        const baseModal = ref(null)
		const resolvePromise = ref(null)

        const submitButtonDisabled  = ref(true)
        const closeModalData        = ref({})
		const mode                  = ref('password')
		const alertRef              = ref(null)

		const passwordChangeFormRef  = ref(null)
		const passwordChangeFormData = ref({
            memberPassword               : '',
			memberPasswordEncrypted      : '',
        })

		const samePasswordHint = ref('')
		const rePasswordHint   = ref('')

		/**
         * 입력 값의 유효성을 검사하고, 암호화된 아이디와 비밀번호를 생성하는 메서드
         * 유효성 검사 결과에 따라 submitDisabled 속성을 설정합니다.
         * @method onInputCheckEvent
         * @returns {void}
         */
         const onInputCheckEvent = () => {
			onHandlePasswordEncrypt()
            return !(submitButtonDisabled.value = !onHandleValidateInput(passwordChangeFormRef.value))
        }

		/**
         * 입력된 비밀번호를 암호화하는 메서드
         * @method onHandleEncrypt
         * @returns {void}
         */
		const onHandlePasswordEncrypt = async () => {
            passwordChangeFormData.value.memberPasswordEncrypted = await encryptPassword(passwordChangeFormData.value.memberPassword)
        }

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onOverSubmitButton = () => {
            const formElements = passwordChangeFormRef.value.querySelectorAll('input, select, textarea')
            formElements.forEach(input => {
                input.blur()
            });
        }

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
        const onClickSave = async () => {
            if(onInputCheckEvent()){
				console.log(passwordChangeFormData.value);
                const formData = new FormData();
                for (const key in passwordChangeFormData.value) {
                    if (passwordChangeFormData.value[key] !== '') {
                        formData.set(key, passwordChangeFormData.value[key]);
                    }
                }
                onProcessSave(formData)
            }
        }

        /**
         * 로그인 프로세스를 처리하는 메서드
         * @method onProcessSignIn
         * @param {FormData} formData - 로그인에 필요한 폼 데이터
         * @returns {void}
         */
        const onProcessSave = async (formData) => {
            try {

                const response = await $axiosConnect('MEMBER', `/web/passwordCheck`, 'POST', formData)
                if (response.success && response.body && response.body.list === passwordChangeFormData.value.memberPasswordEncrypted) {
                    
                    passwordChangeFormData.value = {
                        memberPassword               : '',
						memberPasswordEncrypted      : '',
                    }
                    closeModalData.value = {
                        isUpdate : true,
                        item     : 1
                    }
					close()

                }else{
                    alertRef.value.open({ 'contents' : response.message })
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

        const initialize = async () => {
			mode.value = 'change'
            //submitButtonDisabled.value = false

			passwordChangeFormData.value = {
                memberPassword               : '',
				memberPasswordEncrypted      : '',
            }

            closeModalData.value = {
                isUpdate : false
            }
        }

		const open = async () => {
            //Open Modal
			baseModal.value.openModal()
            await initialize()
			return new Promise((resolve) => {
				resolvePromise.value = resolve;
			});
		};

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			baseModal.value.closeModal()
			resolvePromise.value(closeModalData.value)
		};

        return {
			baseModal, 
			open,
			close,
            submitButtonDisabled,
            mode,
			alertRef,

			passwordChangeFormRef,
			passwordChangeFormData,
			samePasswordHint,
			rePasswordHint,

			onClickSave,
			onInputCheckEvent,
			onOverSubmitButton
		}
    }
});
</script>