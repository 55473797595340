<template>
    <div class="row">
        
        <div class="col-md-6">
            <div class="sticky-top mb-3">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">상품 구성 목록</h4>
                        <div class="card-tools">
                            <button type="button" class="btn btn-primary btn-sm" @click="onClickModalCompositionUpdate('create')"><i class="fas fa-plus"></i> 상위 상품 구성 추가</button>
                        </div>
                    </div>
                    <div class="card-body">
                        <tree :nodes="compositionList" :config="treeConfig" @node-focus="onClickGetDeatil" @node-blur="onBlurGetDetail" @node-opened="onClickNodeOpened" @node-closed="onClickNodeClosed">
                            <template #before-input="props">
                                <span class="tree-depth">{{ props.node.custom.depth + 1 }}</span>
                            </template>
                            <template #after-input="props">
                                <template v-if="props.node.childCount > 0">({{ props.node.childCount }})</template>
                                <!--
                                <button type="button" class="btn btn-outline-info btn-xs ml-2" @click="onClickModalCompositionUpdate('update', props.node)"><i class="fas fa-pencil-alt"></i></button>
                                <button type="button" class="btn btn-outline-secondary btn-xs ml-1" @click="onClickModalCompositionDelete('delete', props.node)"><i class="fas fa-minus"></i></button>
                                <button type="button" class="btn btn-outline-secondary btn-xs ml-1" @click="onClickModalCompositionUpdate('create', props.node)"><i class="fas fa-plus"></i></button>
                                -->
                            </template>
                        </tree>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="card card-primary">
                <div class="card-body p-0" v-if="compositionFormData.compositionName">
                    <div class="card-header">
                        <h4 class="card-title">상품 구성 상세보기</h4>
                        <div class="card-tools">
                            <button type="button" class="btn btn-danger btn-sm" @click="onClickModalCompositionDelete('delete', compositionFormData)">삭제</button>&nbsp;
                            <button type="button" class="btn btn-primary btn-sm" @click="onClickModalCompositionUpdate('update', compositionFormData)">수정</button>&nbsp;
                            <button type="button" class="btn btn-info btn-sm" @click="onClickModalCompositionUpdate('create', compositionFormData)" v-if="compositionFormData.depth === 0">하위 상품 구성 추가</button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="form-group row form-required">
                            <label for="inputCompositionName" class="col-sm-2 col-form-label">상품 구성명</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ compositionFormData.compositionName }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row form-required">
                            <label for="selectCompositionState" class="col-sm-2 col-form-label">사용여부</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-check-circle"></i></span>
                                    </div>
                                    <div class="form-control">
                                        <label v-for="(item, key) in storeState" :key="key" class="input-label mr-3">
                                            <input type="radio"
                                                name="compositionState"
                                                :value="key"
                                                v-model="compositionFormData.compositionState"
                                                disabled
                                            />
                                            <span class="radio-icon"></span>
                                            <span class="label-text">{{ item }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputCompositionNotes" class="col-sm-2 col-form-label">비고</label>
                            <div class="col-sm-10">
                                <div class="input-content text-sm" v-html="compositionFormData.convertCompositionNotes"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <alert ref="alertRef" :title="'알림'" />
    <modal-composition-update ref="modalCompositionUpdateRef" @onCloseModal="onCloseModalCompositionUpdate" />
    <modal-composition-delete ref="modalCompositionDeleteRef" @onCloseModal="onCloseModalCompositionDelete" />

</template>

<script>
import { defineComponent, ref, onMounted, watch, nextTick } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import Alert from '@/modals/common/Alert'
import { unescapeHtmlEntities  } from '@/helper/utils/etcUtils.js'

import ModalCompositionUpdate from '@/modals/admin/product/ModalCompositionUpdate'
import ModalCompositionDelete from '@/modals/admin/product/ModalCompositionDelete'

import Treeview from 'vue3-treeview';
import 'vue3-treeview/dist/style.css';

export default defineComponent({
    name       : 'adminCompositionProductListPage',
    components : {
        'alert' : Alert,
        'modal-composition-update': ModalCompositionUpdate,
        'modal-composition-delete': ModalCompositionDelete,
        'tree' : Treeview
    },
    setup() {
        const { $axiosConnect, storeState } = commonMixin()

        const alertRef             = ref(null)
        const submitButtonDisabled = ref(true)

        const compositionFormData = ref({
            compositionName         : '',
            compositionNotes        : '',
            compositionState        : 1,
            convertCompositionNotes : '',
        })

        const compositionList = ref({})
        const treeConfig = ref({
            roots      : [],
            dragAndDrop: false,
            openedIcon: {
                type : 'class',
                class: 'fas fa-folder-open',
            },
            closedIcon: {
                type : 'class',
                class: 'fas fa-folder',
            }
        })
        const treeTopNode = ref(null);
        const openNodes   = ref([]);
        
        const modalCompositionUpdateRef  = ref(null)
        const modalCompositionDeleteRef  = ref(null)

        onMounted(() => {
            getList()
        })

        
        watch([compositionList, openNodes], () => {
            nextTick(() => {
                replaceSVGWithFAIcons()
            })
        }, { deep: true })

        const getList = async () => {
            try {
                const response = await $axiosConnect('MEMBER', `/admin/compositionProduct?page=1&pageSize=10000`, 'GET');

                compositionList.value = {}

                if(response.body.list && response.body.list.length > 0){
                    const nodes = buildNodes(response.body.list)
                    compositionList.value = JSON.parse(JSON.stringify(nodes))

                    const openNodeIds = new Set(openNodes.value.map(Number))
                    Object.values(compositionList.value).forEach(composition => {
                        if (openNodeIds.has(composition.custom.uid)) {
                            composition.state.opened = true
                        }
                    })

                    const roots = response.body.list.filter(item => item.depth === 0 && item.orders === 0).map(item => String(item.uid));
                    treeConfig.value.roots = roots
                    treeTopNode.value = nodes[roots[0]]

                    onClickGetDeatil(treeTopNode.value)
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

        const buildNodes = (data) => {
            const nodes = {};

            data.forEach(item => {
                nodes[item.uid] = {
                    text     : item.compositionName,
                    state    : {
                        dropable : item.depth !== 0,
                        draggable: item.depth !== 0,
                        opened   : true
                    },
                    custom   : item,
                    children : []
                };

                if (item.depth > 0) {
                    const parentId = data.find(parent => parent.groups === item.groups && parent.depth === item.depth - 1)?.uid;
                    if (parentId !== undefined) {
                        if (!nodes[parentId].children) {
                            nodes[parentId].children = [];
                        }
                        nodes[parentId].children.push(String(item.uid));
                    }
                }
            });

            // Count children for each node and remove empty children arrays
            Object.values(nodes).forEach(node => {
                if (node.children.length === 0) {
                    delete node.children;
                } else {
                    node.childCount = node.children.length;
                }
            });
            return nodes;
        };

        const onClickModalCompositionUpdate = (mode, item = '') => {
            modalCompositionUpdateRef.value.open({ 
                'mode' : mode,
                'item' : item ? (item.custom?item.custom:item) : {}
            });
        }

        const onCloseModalCompositionUpdate = () => {
            setTimeout(() => {
                getList();
            }, 100)
        }

        const onClickModalCompositionDelete = (mode, item) => {
            modalCompositionDeleteRef.value.open({ 
                'mode' : mode,
                'item' : item ? (item.custom?item.custom:item) : {}
            });
        }

        const onCloseModalCompositionDelete = () => {
            setTimeout(() => {
                getList();
            }, 100)
        }

        const onClickGetDeatil = async (node) => {
            try {
                const item = node.custom
                const response = await $axiosConnect('MEMBER', `/admin/compositionProduct/${item.uid}`, 'GET');
                if (response.success && response.body && response.body.list.length > 0) {
                    const list = JSON.parse(JSON.stringify(response.body.list[0]))
                    compositionFormData.value = {
                        ...list,
                        convertCompositionNotes : list.compositionNotes?unescapeHtmlEntities(list.compositionNotes):'&nbsp;',
                    }
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const onBlurGetDetail = () => {
            //onClickGetDeatil(treeTopNode.value)
        }

        const onClickNodeOpened = (node) => {
            if (!openNodes.value.some(id => id === node.id)) {
                openNodes.value.push(node.id);
            }
        }

        const onClickNodeClosed = (node) => {
            openNodes.value = openNodes.value.filter(id => id !== node.id);
        }

        const replaceSVGWithFAIcons = () => {
            const svgIcons = document.querySelectorAll('svg');
            svgIcons.forEach(svgIcon => {
                const parent = svgIcon.parentNode;
                const faIcon = document.createElement('i');
                faIcon.className = 'far fa-folder';
                parent.replaceChild(faIcon, svgIcon);
            });
        };
        

        return {
            alertRef,
            submitButtonDisabled,
            compositionFormData,
            compositionList,
            treeConfig,
            storeState,

            modalCompositionUpdateRef,
            modalCompositionDeleteRef,

            onClickModalCompositionUpdate,
            onCloseModalCompositionUpdate,
            onClickGetDeatil,
            onBlurGetDetail,
            onClickNodeOpened,
            onClickNodeClosed,
            onClickModalCompositionDelete,
            onCloseModalCompositionDelete
        }

    }
});
</script>
<style scoped>
::v-deep .tree .input-wrapper .node-text {
    cursor: pointer;
}
::v-deep .tree .node-wrapper.focused  .input-wrapper .node-text {
    color: #007bff;
}
.tree-depth {
    background-color: #666;
    color: #FFF;
    font-size: 0.6rem;
    width: 15px;
    height: 15px;
    display: flex;
    border-radius: 100%;
    flex-direction: row;
    justify-content: center;
}
</style>