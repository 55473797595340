<template>
    <div class="header">
        <div class="container">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <div class="page-tab-title">
                                <ul>
                                    <li class="active"><span>회원가입</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2 justify-content-center">
                        <div class="col-md-5 col-sm-12">
                            <div class="content-header-steplist">
                                <div class="steplist-step">
                                    <span class="steplist-step-circle">1</span>
                                    <span class="steplist-step-label">유형선택</span>
                                </div>
                                <div class="steplist-step">
                                    <span class="steplist-step-circle">2</span>
                                    <span class="steplist-step-label">약관동의</span>
                                </div>
                                <div class="steplist-step">
                                    <span class="steplist-step-circle">3</span>
                                    <span class="steplist-step-label">인증</span>
                                </div>
                                <div class="steplist-step">
                                    <span class="steplist-step-circle">4</span>
                                    <span class="steplist-step-label">정보입력</span>
                                </div>
                                <div class="steplist-step active">
                                    <span class="steplist-step-circle">5</span>
                                    <span class="steplist-step-label">가입완료</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="content login-content">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-sm-12">
                            <div class="card" style="padding-left: 20%; padding-right: 20%;">
                                <div class="card-body join-card-body">
                                    <p class="login-box-msg">회원정보 완료</p>

                                    <form ref="signUpFormRef" @submit.prevent="onClickSignUp">
                                        <div class="row justify-content-center">
                                            <div class="col-md-12 col-sm-12 text-center">
                                                <p class="font-weight-bold font-size-md">회원가입해 주셔서 감사합니다.</p>
                                                <p class="font-weight-bold font-size-lg">이제 원하시는 구독서비스를 신청해 보세요!</p>
                                            </div>
                                        </div>

                                        <!---->
                                        <div class="row justify-content-center mt-1 aos-init aos-animate">
                                            <div class="col-md-12 col-sm-12 overflow-hidden">
                                                <swiper
                                                    ref="swiperRef"
                                                    :spaceBetween   = "20"
                                                    :slidesPerView  = "1"
                                                    :autoplay="{
                                                        delay: 5000,
                                                        disableOnInteraction: false
                                                    }"
                                                    :loop = swiperLoop
                                                    :loopedSlides = "1"
                                                    :modules="modules"
                                                    @swiper="onSwiper"
                                                >
                                                    <template v-for="(item, index) in productRecommendList" :key="index">
                                                        <swiper-slide>
                                                            <div class="product-item" data-aos-delay="000" @click="onClickNavigateToProductDetail(item.uid)" role="button">
                                                                <div class="product-inner-item">
                                                                    <div class="product-item-type">
                                                                        <template v-if="item.productSubscribe === 1"><span class="product-item-subscribe-text">구독</span></template>
                                                                        <template v-if="item.productRental === 1"><span class="product-item-rental-text">렌탈형 구독</span></template>
                                                                        <template v-if="item.productSale === 1"><span class="product-item-sale-text">판매</span></template>
                                                                    </div>
                                                                    <picture class="product-image" style="max-width: 160px;">
                                                                        <template v-if="item.convertProductImages && item.convertProductImages.length > 0 ">
                                                                            <img class="absolute-center" 
                                                                                :src="`${imgURL}${item.convertProductImages[0].upfilesThumnailPath}/${item.convertProductImages[0].upfilesFileName}`" 
                                                                                :alt="item.convertProductName"
                                                                            />
                                                                        </template>
                                                                        <template v-else>
                                                                            <img class="absolute-center" 
                                                                                src="@/assets/images/common/logo_kor.png"
                                                                                :alt="item.convertProductName"
                                                                            />
                                                                        </template>
                                                                    </picture>
                                                                    <div class="product-content">
                                                                        <h5 class="product-name">{{ item.convertProductName }}</h5>
                                                                        <p class="product-notes">{{ item.convertProductDescription }}</p>
                                                                        <div class="product-purpose">
                                                                            <template v-if="item.productPurposeGaming === 1"><span class="product-purpose-gaming">전문가용</span></template>
                                                                            <template v-if="item.productPurposeProfessional === 1"><span class="product-purpose-professional">게이밍용</span></template>
                                                                            <template v-if="item.productPurposeStudy === 1"><span class="product-purpose-study">업무용</span></template>
                                                                            <template v-if="item.productPurposeWork === 1"><span class="product-purpose-work">학습용</span></template>
                                                                        </div>
                                                                        <template v-if="item.productSubscribe === 1">
                                                                            <div class="product-price-box">
                                                                                <span class="product-price-subscribe-text">구독</span>
                                                                                <span class="product-price-before">월 {{ item.convertProductSubscribeRegularPrice }}원</span>
                                                                                <span class="product-price-after">월 {{ item.convertProductSubscribePrice }}원</span>
                                                                            </div>
                                                                        </template>
                                                                        <template v-else-if="item.productRental === 1">
                                                                            <div class="product-price-box">
                                                                                <span class="product-price-rental-text">렌탈형 구독</span>
                                                                                <span class="product-price-before">월 {{ item.convertProductRentalRegularPrice }}원</span>
                                                                                <span class="product-price-after">월 {{ item.convertProductRentalPrice }}원</span>
                                                                            </div>
                                                                        </template>
                                                                        <template v-else-if="item.productSale === 1">
                                                                            <div class="product-price-box">
                                                                                <span class="product-price-sale-text">판매</span>
                                                                                <span class="product-price-before">월 {{ item.convertProductSaleRegularPrice }}원</span>
                                                                                <span class="product-price-after">월 {{ item.convertProductSalePrice }}원</span>
                                                                            </div>
                                                                        </template>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </swiper-slide>
                                                    </template>
                                                </swiper>
                                            </div>
                                        </div>
                                        <!---->


                                        <div class="row justify-content-center">
                                            <div class="col-md-5 col-sm-12">
                                                <div class="mt-5 mb-3">
                                                    <button type="button" class="btn btn-block btn-special-2"
                                                    @click="router.push({ name: 'webSignInPage' })"
                                                    >로그인</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { defineComponent, onMounted, ref, nextTick } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { numberFormat, truncateText  } from '@/helper/utils/etcUtils.js'

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Autoplay, Parallax } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

export default defineComponent({
    name: 'webSignUpStepComplatePage',
    components: {
        Swiper,
        SwiperSlide
    },
    setup() {
        const { $axiosConnect, imgURL, router } = commonMixin()

        const modules = [Navigation, Pagination, Autoplay, Parallax];
        const swiperRef  = ref(null);
        const swiperLoop = ref(false);

        const productRecommendList = ref([])

        const getProductRecommendList = async () => {
            try {
                let params = {
                    'page'    : 1,
                    'pageSize': 10
                };
                const response = await $axiosConnect('GUEST', `/web/product`, 'GET', params);

                productRecommendList.value = []
                if(response.body){
                    productRecommendList.value = JSON.parse(JSON.stringify(response.body.list))
                }

                productRecommendList.value = productRecommendList.value.map(list => {
                    return {
                        ...list,
                        converUid                           : list.uid.toString().padStart(6, '0'),
                        convertProductName                  : truncateText(list.productName, 75),
                        convertProductDescription           : truncateText(list.productDescription, 65),
                        converRegDate                       : convertDateToStr(list.regDate),
                        converUpDate                        : convertDateToStr(list.upDate),
                        convertProductSubscribeRegularPrice : numberFormat(list.productSubscribeRegularPrice),
                        convertProductSubscribePrice        : numberFormat(list.productSubscribePrice),
                        convertProductRentalRegularPrice    : numberFormat(list.productRentalRegularPrice),
                        convertProductRentalPrice           : numberFormat(list.productRentalPrice),
                        convertProductRentalMonths          : numberFormat(list.productRentalMonths),
                        convertProductRentalMonthPrice      : numberFormat(list.productRentalPrice/list.productRentalMonths),
                        convertProductSaleRegularPrice      : numberFormat(list.productSaleRegularPrice),
                        convertProductSalePrice             : numberFormat(list.productSalePrice),
                        convertProductViewCount             : numberFormat(list.productViewCount),
                        convertProductImages                : list.upfiles.filter(file => file.upfilesColumn === 'productImages'),
                        convertProductDescriptionImages     : list.upfiles.filter(file => file.upfilesColumn === 'productDescriptionImages')
                    }
                })

                nextTick(() => {
                    if (swiperRef.value) {
                        swiperLoop.value = true
                        swiperRef.value.update()
                    }
                })

            } catch (error) {
                console.error("error:", error)
            }
        }

        const onSwiper = (swiper) => {
            swiperRef.value = swiper;
        };

        const initialize = async () => {
            productRecommendList.value    = []
        }

        onMounted(async () => {
            await initialize()
            await getProductRecommendList()
        })

        return {
            modules,
            imgURL,
            productRecommendList,
            swiperRef,
            swiperLoop,
            router,
            onSwiper
        }
    }
});
</script>