<template>
	<div class="date-range-group">
		<div class="d-flex">
			<div class="w-50 pr-1">
				<label :for="`${dateRangeName}-start`" class="d-none">시작일</label>
				<input
					type="date"
					:id="`${dateRangeName}-start`"
					v-model="startDate"
					v-on:input="onDateChange"
					class="form-control"
				/>
			</div>
			<div class="w-50 pl-1">
				<label :for="`${dateRangeName}-end`" class="d-none">종료일</label>
				<input
					type="date"
					:id="`${dateRangeName}-end`"
					v-model="endDate"
					v-on:input="onDateChange"
					class="form-control"
				/> 
			</div>
		</div>
	</div>
	<div class="date-range-group-buttons">
		<button type="button" class="btn btn-dark btn-xs mr-1" @click="onClickRangeDate(0)">오늘</button>
		<button type="button" class="btn btn-dark btn-xs mr-1" @click="onClickRangeDate(7)">일주일</button>
		<button type="button" class="btn btn-dark btn-xs mr-1" @click="onClickRangeDate(30)">1개월</button>
		<button type="button" class="btn btn-dark btn-xs mr-1" @click="onClickRangeDate(180)">6개월</button>
		<button type="button" class="btn btn-dark btn-xs" @click="onClickRangeDate(-1)">전체</button>
	</div>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue'

export default defineComponent({
	name: 'ComponentFormDateRange',
	props: {
        dateRangeDefault: {
            type: Array,
            required: false,
            default: () => []
        },
        dateRangeName: {
            type: String,
            required: true,
            default: ''
        }
    },
	emits : ['onChangeDate'],
	setup(props, {emit}) {
		const startDate = ref('');
        const endDate   = ref('');

		onMounted(() => {
			onHandleReset()
        })

		watch([startDate, endDate], () => {
            onChangeDate();
        });

		const onChangeDate = () => {
			emit('onChangeDate', {
                name     : props.dateRangeName,
                selecteds: [startDate.value, endDate.value]
            });
		}

		const onClickRangeDate = (value) => {
			if (value === -1) {
                startDate.value = '2020-01-01';
                endDate.value = new Date().toISOString().substr(0, 10)
            } else {
                if (!startDate.value) {
                    startDate.value = new Date().toISOString().substr(0, 10)
                }
                if (!endDate.value) {
                    endDate.value = new Date().toISOString().substr(0, 10)
                }
                
                //const currentDate = new Date()
                const newEndDate = new Date(endDate.value)
                newEndDate.setDate(newEndDate.getDate() - value)
                startDate.value = newEndDate.toISOString().substr(0, 10)
            }
            onChangeDate()
		}

		const onHandleReset = () => {
            if (props.dateRangeDefault.length > 0) {
                startDate.value = props.dateRangeDefault[0].toString();
                endDate.value   = props.dateRangeDefault[1].toString();
            }else{
				startDate.value = '2020-01-01';
                endDate.value = new Date().toISOString().substr(0, 10)
			}
        };

		return {
			startDate,
            endDate,
            onChangeDate,
            onClickRangeDate,
            onHandleReset
		}
	}
})
</script>