<template>
    <Modal ref="baseModal" :title="`결제`" :width="`600px`">
        <template #body>
			<p class="text-md text-bold">결제금액 : {{ numberFormat(orderFormData.orderTotalPrice) }}원</p>
            <p>{{ orderFormData.orderRecipientName }}님의 {{ (orderFormData.converLastPayment?.paymentRound) + 1 }}회차 결제를 진행하시겠습니까?</p>
        </template>
        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">닫기</button>
			<button type="submit" class="btn btn-primary" @click="onClickPayment">결제</button>
        </template>
	</Modal>

	<alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import axios from 'axios'
import commonMixin from '@/helper/mixins/commonMixin.js'
import Modal from '@/modals/Modal'
import Alert from '@/modals/common/Alert'
import { numberFormat } from '@/helper/utils/etcUtils.js'

export default defineComponent({
    name       : 'modalOrderPaymentPage',
    components: {
		Modal,
		'alert' : Alert,
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){
		const { openModal, closeModal, baseModal, storeMemberInfo } = commonMixin()

		const alertRef        = ref(null)
        const orderFormData   = ref([])
        const mode            = ref('payment')
        const closeModalData  = ref({})

		const paymentData     = ref({
			mchtId     : 'nxca_jt_gu',
			ver        : '0A19',
			method     : 'CA',
			bizType    : 'B0',
			encCd      : '23',
			mchtTrdNo  : '',
			trdDt      : '',
			trdTm      : '',
			mobileYn   : 'N',
			osType     : 'W',
			instmtMon  : '00',
			crcCd      : 'KRW',
			taxTypeCd  : '',
			taxAmt     : '',
			vatAmt     : '',
			taxFreeAmt : '',
			svcAmt     : '',
			trdAmt     : '',
			billKey    : '',
			pmtprdNm   : '',
			mchtCustNm : '',
			mchtCustId : '',
			email      : '',
			notiUrl    : '',
			mchtParam  : '',
			pktHash    : ''
		})

		const setDateTime = () => {
            const currDate = new Date()
            const year  = currDate.getFullYear().toString()
            const month = ("0" + (currDate.getMonth() + 1)).slice(-2)
            const day   = ("0" + currDate.getDate()).slice(-2)
            const hours = ("0" + currDate.getHours()).slice(-2)
            const mins  = ("0" + currDate.getMinutes()).slice(-2)
            const secs  = ("0" + currDate.getSeconds()).slice(-2)

            paymentData.value.trdDt = `${year}${month}${day}`
            paymentData.value.trdTm = `${hours}${mins}${secs}`
            paymentData.value.mchtTrdNo = `DDOKDOK_PAYMENT${year}${month}${day}${hours}${mins}${secs}`
        }

        const initialize = async () => {
            mode.value = 'payment'

            orderFormData.value = []

            closeModalData.value = {
                isUpdate : false,
                item     : ''
            }

			setDateTime()
        }

        onMounted(async () => {
            await initialize()
        })

		const open = (payload) => {
            initialize()

            if (payload.item && payload.mode === 'payment') {
                orderFormData.value = JSON.parse(JSON.stringify(payload.item))
                mode.value = payload.mode

				paymentData.value.trdAmt     = orderFormData.value.orderTotalPrice
				paymentData.value.billKey    = orderFormData.value.orderPaymentBillkey
				paymentData.value.pmtprdNm   = orderFormData.value.productName
				
				paymentData.value.mchtCustNm = orderFormData.value.payment[orderFormData.value.payment.length - 1]?.paymentInfo['고객명']
				paymentData.value.mchtCustId = orderFormData.value.payment[orderFormData.value.payment.length - 1]?.paymentInfo['상점고객아이디']
				paymentData.value.email      = orderFormData.value.payment[orderFormData.value.payment.length - 1]?.paymentInfo['고객이메일']
				paymentData.value.notiUrl    = ''
				paymentData.value.mchtParam  = 'orderUid=' + orderFormData.value.uid + '&memberUid=' + storeMemberInfo.value.uid + '&memberId=' + storeMemberInfo.value.memberId + '&memberName=' + storeMemberInfo.value.memberName + '&userUid=' + orderFormData.value.payment[0]?.memberUid
            }
            return openModal()
		};

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			closeModal(closeModalData.value)
		};

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
        const onClickPayment = async () => {
			const formData = new FormData()
			for (const key in paymentData.value) {
				if (paymentData.value[key] !== '') {
					formData.set(key, paymentData.value[key]);
				}
			}
			onProcessSave(formData)
        }

        /**
         * 로그인 프로세스를 처리하는 메서드
         * @method onProcessSignIn
         * @param {FormData} formData - 로그인에 필요한 폼 데이터
         * @returns {void}
         */
        const onProcessSave = (formData) => {
			axios.post('/EXTEND/hecto/billkey_pay_showResult.php', formData)
			.then(response => {
				if(response.data === 'success'){
					closeModalData.value = {
                        isUpdate : true,
                        item     : ''
                    }

                    alertRef.value.open({ 
                        'contents' : '결제에 성공하였습니다.',
                        'callback' : close
                    })

				}else{
					alertRef.value.open({ 'contents' : '결제에 실패했습니다.' })
				}
			})
			.catch(error => {
				// 에러 처리 로직
				console.error(error);
			});
        }

        return {
			open,
			close,
            mode,

			alertRef,
			orderFormData,

            onClickPayment,
			numberFormat,

            //commonMixin
            baseModal			
		}
    }
});
</script>