import store from '@/store'
import router from '@/router'
import { getCurrentInstance, computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useCookies } from "vue3-cookies"
import { useNotification } from '@kyvg/vue3-notification'

const commonMixin = () => {
    const route               = useRoute()
    const { cookies }         = useCookies()
    const { notify }          = useNotification()
    const $axiosConnect       = getCurrentInstance().appContext.config.globalProperties.$axiosConnect
    const $fileDownload       = getCurrentInstance().appContext.config.globalProperties.$fileDownload

    const storeMemberInfo     = computed(() => store.getters["member/getMemberInfo"])
    const storeSignInState    = computed(() => !!store.getters["member/getAccessToken"] && !!storeMemberInfo.value)
    const storeToken          = computed(() => store.getters["member/getAccessToken"])
    const storeState          = computed(() => store.getters["common/getState"])
    const storeProductPurpose = computed(() => store.getters["common/getProductPurpose"])
    const storeItemType       = computed(() => store.getters["common/getItemType"])
    const storeAuthority      = computed(() => store.getters["member/getAuthority"])
    
    const imgURL              = ref(process.env.VUE_APP_BASE_IMG_URL)

    /**
     * 입력 값의 유효성을 검사하는 메서드
     * @method onHandleValidateInput
     * @param {Event} event - 발생한 이벤트 객체
     * @returns {boolean} 유효성 검사 결과
     */
    const onHandleValidateInput = (form) => {
        if (form) {
            const formElements = form.querySelectorAll('input, select, textarea')
            let isValid = true
            //let notifyCalled = false // notify 호출 여부를 저장하는 변수

            // 첫 번째 오류 발생 시 반복을 중지하고 notify를 호출
            isValid = !Array.from(formElements).some((input) => {
                const inputGroup = input.closest('.form-group')
                const inputHint = inputGroup ? inputGroup.querySelector('.input-hint') : null

                if (!input.checkValidity()) {
                    // notify가 아직 호출되지 않았다면 한 번만 호출
                    /*
                    if (!notifyCalled && input.hasAttribute('pattern-hint')) {
                        notify({
                            group: "top-right",
                            title: "입력 오류 안내",
                            text: `${input.getAttribute('pattern-hint')}`,
                            type: 'error'
                        })
                        notifyCalled = true // notify가 호출되었음을 기록
                    }
                    */
                    // 오류 발생 시 힌트 표시
                    if (inputHint) {
                        inputHint.style.display = 'block'
                        inputHint.innerText = input.getAttribute('pattern-hint') || '입력 오류'
                    }
                    return true
                    
                } else {
                    // 오류가 없는 경우 힌트 숨기기
                    if (inputHint) {
                        inputHint.style.display = 'none'
                    }
                    return false
                }
            })

            //console.log(form.checkValidity())
            //console.log(isValid)
            //console.log(form.checkValidity() && isValid)
            // 전체 폼의 유효성 검사 결과와 개별 필드 검사 결과를 함께 확인
            return form.checkValidity() && isValid
        }
    }

    /**
     * 파일 확장자가 이미지인지 확인하는 메서드
     * @method onHandleIsImage
     * @param {string} fileExtension - 파일 확장자
     * @returns {boolean} 이미지 여부
     */
    const onHandleIsImage = (fileExtension) => {
        return ["jpg", "jpeg", "png", "gif"].includes(fileExtension)
    }

    /**
     * 모달을 열고 Promise를 반환하는 메서드
     * @method openModal
     * @returns {Promise} 모달 닫힘 시 결과를 반환하는 Promise
     */
    const baseModal = ref(null)
    const resolvePromise = ref(null)
    const openModal = () => {
        baseModal.value.openModal()
        return new Promise((resolve) => {
            resolvePromise.value = resolve
        })
    }

    /**
     * 모달을 닫고 결과를 반환하는 메서드
     * @method closeModal
     * @param {*} closeModalData - 모달 닫힘 시 반환할 데이터
     * @returns {void}
     */
    const closeModal = (closeModalData) => {
        baseModal.value.closeModal()
        resolvePromise.value(closeModalData)
    }

    return {
        store,
        router,
        route,
        cookies,
        $axiosConnect,
        $fileDownload,
        notify,
		storeSignInState,
        storeMemberInfo,
        storeState,
        storeProductPurpose,
        storeItemType,
        storeAuthority,
        storeToken,
        imgURL,
        baseModal,
        onHandleValidateInput,
        onHandleIsImage,
        openModal,
        closeModal
    }
}

export default commonMixin