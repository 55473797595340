import { createApp } from 'vue'
import App from './App.vue'
import Router from './router'
import Store from './store'
import HttpRequest from './helper/plugins/httpRequest'
import VueCryptojs from 'vue-cryptojs'
import Notifications from '@kyvg/vue3-notification'
import VueGoogleCharts from 'vue-google-charts';

const app = createApp(App)
app.use(Router)
app.use(Store)
app.use(HttpRequest)
app.use(VueCryptojs)
app.use(Notifications)
app.use(VueGoogleCharts)

app.mount('#app');
