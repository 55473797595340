<template>
    <Modal ref="baseModal" :title="`장바구니 상품 삭제`" :width="`600px`">
        <template #body>
            <form ref="compositionFormRef">
                <p>해당 상품({{ cartData.productName }})을 장바구니에서 삭제하시겠습니까?</p>
            </form>
        </template>
        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">취소</button>
			<button type="submit" class="btn btn-primary-1" @click="onClickDelete">삭제</button>
        </template>
	</Modal>

	<alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import Modal from '@/modals/Modal'
import Alert from '@/modals/common/Alert'

export default defineComponent({
    name       : 'modalCompositionDeletePage',
    components: {
		Modal,
		'alert' : Alert,
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){
		const { openModal, closeModal, baseModal, $axiosConnect } = commonMixin()

		const alertRef            = ref(null)
        const compositionFormRef  = ref(null)
        const cartData = ref({})
        const mode                = ref('delete')
        const closeModalData      = ref({})

        const initialize = async () => {
            mode.value = 'delete'

            cartData.value = {}

            closeModalData.value = {
                isUpdate : false,
                item     : ''
            }
        }

        onMounted(async () => {
            await initialize()
        })

		const open = (payload) => {
            initialize()

            if (payload.item && payload.mode === 'delete') {
                cartData.value = JSON.parse(JSON.stringify(payload.item))
                mode.value = payload.mode
            }

            return openModal()
		};

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			closeModal(closeModalData.value)
		};

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
        const onClickDelete = async () => {
            onProcessSave()
        }

        /**
         * 로그인 프로세스를 처리하는 메서드
         * @method onProcessSignIn
         * @param {FormData} formData - 로그인에 필요한 폼 데이터
         * @returns {void}
         */
        const onProcessSave = async () => {
            try {

                const uid = cartData.value.uid
                const response = await $axiosConnect('MEMBER', `/web/cart/${uid}`, 'DELETE')

                if (response.success && response.body && response.body.list > 0) {

                    initialize()
                    
                    closeModalData.value = {
                        isUpdate : true,
                        item     : ''
                    }
                    close()

                }else{
                    alertRef.value.open({ 'contents' : response.message })
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

        return {
			open,
			close,
            mode,

			alertRef,
			compositionFormRef,
			cartData,

            onClickDelete,

            //commonMixin
            baseModal, 
			
		}
    }
});
</script>