<template>
    <main>
        <div class="container">
			<section class="content-header">
				<div class="container-fluid">
					<div class="row mb-2">
						<div class="col-sm-12">
							<div class="page-tab-title">
								<ul>
                                    <li class="active"><span>MY 페이지</span></li>
									<li @click="router.push({ name: 'webMyInfoPage' })"><span>내 정보</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
            <div class="content">
                <div class="container-fluid">
					<div class="row">
						<div class="col-sm-12">
                            <ul class="tab-list">
								<li @click="router.push({ name: 'webMyPageOrderStatusPage' })"><span>구독현황</span></li>
								<li @click="router.push({ name: 'webMyPageCartPage' })"><span>장바구니</span></li>
								<li @click="router.push({ name: 'webMyPageReviewPage' })"><span>나의 리뷰</span></li>
								<li @click="router.push({ name: 'webMyPageContactPage' })"><span>나의 1:1 문의</span></li>
								<li @click="router.push({ name: 'webMyPageCallRequestPage' })"><span>나의 전화상담요청</span></li>
								<li class="active"><span>쿠폰</span></li>
							</ul>
						</div>
					</div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { defineComponent } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'

export default defineComponent({
    name       : 'webMyPageCouponPage',
	components: {
	},
    setup() {
		const { router } = commonMixin()

		return {
			router,
        }
    }
});
</script>