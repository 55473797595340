<template>
    <main>
        <div class="container">
			<section class="content-header">
				<div class="container-fluid">
					<div class="row mb-2">
						<div class="col-sm-12">
							<div class="page-tab-title">
								<ul>
                                    <li class="active"><span>MY 페이지</span></li>
									<li @click="router.push({ name: 'webMyInfoPage' })"><span>내 정보</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
            <div class="content mypage">
                <div class="container-fluid">
					<div class="row">
						<div class="col-sm-12">
                            <ul class="tab-list">
								<li @click="router.push({ name: 'webMyPageOrderStatusPage' })"><span>구독현황</span></li>
								<li class="active"><span>장바구니</span></li>
								<li @click="router.push({ name: 'webMyPageReviewPage' })"><span>나의 리뷰</span></li>
								<li @click="router.push({ name: 'webMyPageContactPage' })"><span>나의 1:1 문의</span></li>
								<li @click="router.push({ name: 'webMyPageCallRequestPage' })"><span>나의 전화상담요청</span></li>
								<!--<li @click="router.push({ name: 'webMyPageCouponPage' })"><span>쿠폰</span></li>-->
							</ul>
						</div>
					</div>
					<div class="row justify-content-center">
						
						<template v-if="list && list.length > 0">
							<template v-for="(item, index) in list" :key="index">
								<div class="col-md-12 col-sm-12">
									<div class="product-item min-height-auto" data-aos-delay="000" >
										<div class="product-inner-item flex-row" role="button" @click="onClickNavigateToProductDetail(item.uid)">
											<picture class="product-image" style="max-width: 200px">
												<template v-if="item.convertProductImages && item.convertProductImages.length > 0 ">
													<img class="absolute-center" 
														:src="`${imgURL}${item.convertProductImages[0].upfilesThumnailPath}/${item.convertProductImages[0].upfilesFileName}`" 
														:alt="item.convertProductName"
													/>
												</template>
												<template v-else>
													<img class="absolute-center" 
														src="@/assets/images/common/logo_kor.png"
														:alt="item.convertProductName"
													/>
												</template>
											</picture>
											<div class="product-content">
												<h5 class="product-name">{{ item.productName }}</h5>
												<p class="product-notes">{{ item.productDescription }}</p>
												<hr />
												<div class="row text-sm">
													<div class="col-sm-2">기본사항</div>
													<div class="col-sm-10">{{ item.convertProductDefaultItems[0]?.productItemName }}</div>
												</div>
												<template v-if="item.convertProductOptionItems && item.convertProductOptionItems.length > 0">
													<hr />
													<div class="row text-sm">
														<div class="col-sm-2">추가옵션</div>
														<div class="col-sm-10">
															<template v-for="(optionItem, optionIndex) in item.convertProductOptionItems" :key="optionIndex">
															{{ optionItem?.productItemName }}
															<span v-if="optionIndex !== item.productOptionItems.length - 1"> / </span>
															</template>
														</div>
													</div>
												</template>
												<template v-if="item.convertProductServiceItems && item.convertProductServiceItems.length > 0">
													<hr />
													<div class="row text-sm">
														<div class="col-sm-2">서비스</div>
														<div class="col-sm-10">
															<template v-for="(serviceItem, serviceIndex) in item.convertProductServiceItems" :key="serviceIndex">
															{{ serviceItem.productItemName }}
															<span v-if="serviceIndex !== item.productServiceItems.length - 1"> / </span>
															</template>
														</div>
													</div>
												</template>
											</div>
										</div>
										<div class="cart-price">
											<hr />
											<div class="text-right">
												<template v-if="item.orderType==='subscribe'">
													<span class="mr-4 font-size-xl font-weight-semi-bold">월 예상 구독료 {{ numberFormat(item.orderTotalPrice) }}원</span>
													<button class="btn btn-special-1 btn-block" @click="onClickOrder(item)">구독</button>
												</template>
												<template v-if="item.orderType==='rental'">
													<span class="mr-4 font-size-xl font-weight-semi-bold">월 예상 렌탈형 구독료 {{ numberFormat(item.orderTotalPrice) }}원</span>
													<button class="btn btn-special-1 btn-block" @click="onClickOrder(item)">렌탈형 구독</button>
												</template>
												<template v-if="item.orderType==='sale'">
													<span class="mr-4 font-size-xl font-weight-semi-bold">{{ numberFormat(item.orderTotalPrice) }}원</span>
													<button class="btn btn-special-1 btn-block" @click="onClickOrder(item)">구매</button>
												</template>
											</div>
										</div>
										<div class="cart-Delete">
											<span role="button" @click="onClickProductCartDelete(item)"><i class="fas fa-trash"></i></span>
										</div>
									</div>

								</div>
							</template>
						</template>
						<template v-else>
							<div class="col-md-12 col-sm-12">
								<div class="card mt-4">
									<div class="card-body text-center">
										장바구니에 담은 상품이 없습니다.
									</div>
								</div>
							</div>
							<div>
								<div class="row justify-content-center mt-1">
									<div class="col-12 col-sm-12 col-lg-12">
										<div class="product-list-paging">
											<div class="product-request etc">
												<p class="font-size-xl">똑.독의 다양한 상품을 구경해보세요.</p>
												<hr/>
												<div class="product-request-group">
													<div>
														<span>A~Z까지 선택해서 상품을 찾아보세요.</span>
														<span role="button" @click="router.push({ name: 'webProductCustomPage' })">상품 찾기</span>
													</div>
													<div>
														<span>난, 게이밍 전문가!</span>
														<span role="button" @click="onClickNavigateToProduct('gaming')">게이밍용</span>
													</div>
													<div>
														<span>난, 그래픽/동영상 전문가!</span>
														<span role="button" @click="onClickNavigateToProduct('professional')">전문가용</span>
													</div>
													<div>
														<span>일반 사무, 업무용에는!</span>
														<span role="button" @click="onClickNavigateToProduct('work')">업무용</span>
													</div>
													<div>
														<span>이번 시험은 왠지 100점</span>
														<span role="button" @click="onClickNavigateToProduct('study')">학습용</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>

                    </div>
                </div>
            </div>
        </div>
    </main>

	<modal-product-cart-delete ref="modalProductCartDeleteRef" @onCloseModal="onCloseProductCartDelete" />

</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { numberFormat } from '@/helper/utils/etcUtils.js'

import ModalProductCartDelete from '@/modals/web/product/ModalProductCartDelete'

export default defineComponent({
    name       : 'webMyPageCartPage',
	components: {
		'modal-product-cart-delete' : ModalProductCartDelete
	},
    setup() {
		const { router, $axiosConnect, imgURL, store } = commonMixin()

		const list              = ref([])
        const listTotalCount    = ref(0)
        const listSearchCount   = ref(0)

		const modalProductCartDeleteRef = ref(null)

		const getCartList = async () => {
            try {
                let defaultParams = {
                    'page'     : 1,
                    'pageSize' : 1000
                };
                const response = await $axiosConnect('MEMBER', `/web/cart`, 'GET', defaultParams);
				
				if(response.body){
                    const products = JSON.parse(JSON.stringify(response.body.list))
                    listTotalCount.value  = JSON.parse(JSON.stringify(response.body.total))
                    listSearchCount.value += products.length

					console.log(list)

                    const processedProducts  = products.map(list => {
                        return {
                            ...list,
                            converUid                               : list.uid.toString().padStart(6, '0'),
                            converRegDate                           : convertDateToStr(list.regDate),
                            converUpDate                            : convertDateToStr(list.upDate),
							convertProductImages                    : list.upfiles.filter(file => file.upfilesColumn === 'productImages'),
							convertProductDefaultItems              : list.productAllItems.filter(item => item.itemType === 1),
							convertProductOptionItems               : list.productAllItems.filter(item => item.itemType === 2),
							convertProductServiceItems              : list.productAllItems.filter(item => item.itemType === 3),
                        }
                    })
                    list.value = [...list.value, ...processedProducts];
                }
                
            } catch (error) {
                console.error("error:", error)
            }
        }

		const onClickOrder = (item) => {
			const productOrderData = [{
				orderTotalPrice   : item.orderTotalPrice,
				defaultTotalPrice : item.orderDefaultTotalPrice,
				optionTotalPrice  : item.orderOptionTotalPrice,
				serviceTotalPrice : item.orderServiceTotalPrice,
				orderType         : item.orderType
			}]

			const productOrder = {
				productDefaultItems : item.convertProductDefaultItems,
				productOptionItems  : item.convertProductOptionItems,
				productServiceItems : item.convertProductServiceItems,
				productOrder        : productOrderData,
				productFormData     : JSON.parse(JSON.parse(item.productItems)),
				productCart         : [item]
			}

			store.dispatch('common/setProductOrderData', productOrder)
			router.push({ name: 'webOrderCheckPage' })
		}

		const onClickProductCartDelete = (item) => {
			modalProductCartDeleteRef.value.open({
				'mode' : 'delete',
				'item' : item
			})
		}

		const onCloseProductCartDelete = () => {
			initialize()
		}

		const onClickNavigateToProduct = (searchPurpose) => {
            router.push({ name: 'webProductListPage', params: { searchPurpose: searchPurpose } });
        }

		const onClickNavigateToProductDetail = (uid) => {
            router.push({ name: 'webProductDetailPage', params: { uid } });
        }

		const initialize = async () => {
			list.value            = []
			listTotalCount.value  = 0
			listSearchCount.value = 0

			await getCartList()
		}

		onMounted(async () => {
            await initialize()
        })

		return {
			router,
			imgURL,
			list,
			modalProductCartDeleteRef,
			numberFormat,

			onClickOrder,
			onClickProductCartDelete,
			onCloseProductCartDelete,
			onClickNavigateToProduct,
			onClickNavigateToProductDetail
        }
    }
});
</script>
<style scoped>
.cart-price {
	padding: 0.5rem 2rem 1rem 2rem;
}
.cart-Delete {
	position: absolute;
	top: 10px;
	right: 30px;
	color: #000;
}
</style>