<template>
    <div class="wrapper">

        <!-- TOP Navbar -->
        <nav class="main-header navbar navbar-expand navbar-white navbar-light">
            <!-- Left navbar links -->
            <ul class="navbar-nav">
                <li class="nav-item">
                    <span class="nav-link" data-widget="pushmenu" role="button" @click="onToggleSidebar"><i class="fas fa-bars"></i></span>
                </li>
                <li class="nav-item d-none d-sm-inline-block">
                    <router-link to="/" class="nav-link">웹사이트</router-link>
                </li>
                <!--
                <li class="nav-item d-none d-sm-inline-block">
                    <router-link to="/admin" class="nav-link">Contact</router-link>
                </li>
                -->
            </ul>

            <!-- Right navbar links -->
            <ul class="navbar-nav ml-auto">

                <!--USER-->
                <li class="nav-item dropdown" :class="{ show: isMessageDropdownOpen }">

                    <div class="user-panel d-flex mt-1">
                        <div class="image">
                            <img src="@/assets/images/common/logo_eng.png" style="width: 6rem;">
                        </div>
                        <div class="info">
                            <span class="d-block" role="button" data-toggle="dropdown" @click="onToggleMemberDropdown">{{ storeMemberInfo?.memberName }}</span>
                        </div>
                        <div class="info" role="button" @click="onClickSignOut()" title="로그아웃">
                            <i class="fa-solid fa-user-slash mr-2"></i>
                        </div>
                    </div>
                </li>

                <!-- Messages Dropdown Menu -->
                <li class="nav-item dropdown" :class="{ show: isMessageDropdownOpen }">
                    <a class="nav-link" data-toggle="dropdown" href="#" @click="onToggleMessageDropdown">
                        <i class="far fa-comments"></i>
                        <span class="badge badge-danger navbar-badge">3</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" :class="{ show: isMessageDropdownOpen }">
                        <a href="#" class="dropdown-item">
                        <!-- Message Start -->
                            <div class="media">
                                <img src="dist/img/user1-128x128.jpg" alt="User Avatar" class="img-size-50 mr-3 img-circle">
                                <div class="media-body">
                                    <h3 class="dropdown-item-title">
                                        Brad Diesel
                                        <span class="float-right text-sm text-danger"><i class="fas fa-star"></i></span>
                                    </h3>
                                    <p class="text-sm">Call me whenever you can...</p>
                                    <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                                </div>
                            </div>
                        <!-- Message End -->
                        </a>
                        <div class="dropdown-divider"></div>
                        <a href="#" class="dropdown-item">
                            <!-- Message Start -->
                            <div class="media">
                                <img src="dist/img/user8-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
                                <div class="media-body">
                                    <h3 class="dropdown-item-title">
                                        John Pierce
                                        <span class="float-right text-sm text-muted"><i class="fas fa-star"></i></span>
                                    </h3>
                                    <p class="text-sm">I got your message bro</p>
                                    <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                                </div>
                            </div>
                            <!-- Message End -->
                        </a>
                        <div class="dropdown-divider"></div>
                        <a href="#" class="dropdown-item">
                            <!-- Message Start -->
                            <div class="media">
                                <img src="dist/img/user3-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
                                <div class="media-body">
                                    <h3 class="dropdown-item-title">
                                        Nora Silvester
                                        <span class="float-right text-sm text-warning"><i class="fas fa-star"></i></span>
                                    </h3>
                                    <p class="text-sm">The subject goes here</p>
                                    <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                                </div>
                            </div>
                            <!-- Message End -->
                        </a>
                        <div class="dropdown-divider"></div>
                        <a href="#" class="dropdown-item dropdown-footer">See All Messages</a>
                    </div>
                </li>
                <!--
                <li class="nav-item">
                    <a class="nav-link" data-widget="control-sidebar" data-controlsidebar-slide="true" href="#" role="button">
                        <i class="fas fa-th-large"></i>
                    </a>
                </li>
                -->
            </ul>
        </nav>
        <!-- /.navbar -->

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary"><!--elevation-4-->
            <!-- Brand Logo -->
            <a href="/admin" class="brand-link">
                <img src="@/assets/images/common/logo_kor.png" alt="똑.독" class="brand-image" style="opacity: .8">
                <span class="brand-text font-weight-bold" style="vertical-align: text-top !important;">ADMIN</span>
            </a>

            <!-- LEFT Sidebar -->
            <div class="sidebar">

                <!-- Sidebar Menu -->
                <nav class="mt-2">
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <template v-for="(group, groupIndex) in groupedMenuItems" :key="groupIndex">
                            <li class="nav-header" v-if="group[0].group">{{ group[0].group }}</li>
                            <ComponentSideBarMenuItem
                                v-for="(item, index) in group"
                                :key="item.seq"
                                :item="item"
                                @toggle="onToggleSideBarMenuItem(groupIndex, index)"
                            />
                        </template>
                    </ul>
                </nav>
                <!-- /.sidebar-menu -->
            </div>
            <!-- /.sidebar -->
        </aside>


        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">{{ pageTitle }}</h1>
                        </div><!-- /.col -->
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <!--
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active">Dashboard v1</li>
                                -->
                                <li class="breadcrumb-item" v-for="(item, index) in pageBreadcrumbs" :key="index">
                                    <a :href="item.link">{{ item.text }}</a>
                                </li>
                            </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->

            <!-- Main content -->
            <section class="content">
                <!-- Container fluid -->
                <div class="container-fluid">
                    <router-view />
                </div>
                <!-- /.container-fluid -->
            </section>
            <!-- /.content -->
        </div>
        <!-- /.content-wrapper -->

        <footer class="main-footer">
            <strong>Copyright &copy; 2024 <a href="#" target="_blank">DIXLAB</a>.</strong>
            All rights reserved.
            <div class="float-right d-none d-sm-inline-block">
                <b>Version</b> 1.0
            </div>
        </footer>
        <div id="sidebar-overlay" @click="onToggleSidebar"></div>

    </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted, onUnmounted } from 'vue'
import { adminMenus } from '@/menu/admin/menu.js';
import ComponentSideBarMenuItem from '@/components/admin/ComponentSideBarMenuItem';
import commonMixin from '@/helper/mixins/commonMixin'

export default defineComponent({
    name : 'adminLayoutPage',
    components : {
        ComponentSideBarMenuItem
    },
    setup() {

        onMounted(async () => {
            /*
            require('../../assets/style/admin/adminlte.css')
            require('../../assets/plugins/fontawesome-free/css/all.min.css')
            require('../../assets/style/admin/overwrite.css')
            */
            document.body.classList.remove('login-page')
            document.body.classList.add('sidebar-mini')
            document.body.classList.add('layout-fixed')
            
            updateWindowWidth()
            window.addEventListener('resize', updateWindowWidth);
        })

        onUnmounted(() => {
            window.removeEventListener('resize', updateWindowWidth);
        });

        const { route, storeMemberInfo, $axiosConnect, store } = commonMixin()

        const pageTitle             = ref('')
        const pageBreadcrumbs       = ref([])
        const isMessageDropdownOpen = ref(false)
        const isMemberDropdownOpen  = ref(false)

        const windowSize            = ref(0)

        const groupedMenuItems = ref(
            adminMenus.reduce((groups, item) => {
                const group = item.group || 'DEFAULT'
                if (!groups[group]) {
                    groups[group] = []
                }
                groups[group].push(item)
                return groups
            }, {})
        )

        const onToggleSideBarMenuItem = (groupIndex, itemIndex) => {
            const group = groupedMenuItems.value[groupIndex]
            if (group && group[itemIndex]) {
                const item = group[itemIndex];
                item.isOpen = !item.isOpen;
                if (item.isOpen && item.children && item.children.length > 0) {
                    item.children.forEach(child => {
                        child.isOpen = !child.isOpen;
                    });
                }
            }
            
        };

        watch(route, () => {
            updateMenuOpenState();
        });

        /*
        const updateMenuOpenState = () => {
            Object.values(groupedMenuItems.value).forEach(group => {
                group.forEach(item => {
                    if (item.children.length > 0) {
                        item.isOpen = item.children.some(child => child.path === route.path)
                        item.children.forEach(child => {
                            if (child.path === route.path) {
                                pageTitle.value = child.title
                                //children상위의 title와 link를 먼저 pageBreadcrumbs.value에 push한다.
                                pageBreadcrumbs.value = [{ text: item.title, link: item.path }]
                                pageBreadcrumbs.value.push({ text: child.title, link: child.path })
                            }
                        });
                    }else{
                        if (item.path === route.path) {
                            pageTitle.value = item.title;
                            pageBreadcrumbs.value = [{ text: item.title, link: item.path }];
                        }
                    }
                });
            });
        }
        */
        const updateMenuOpenState = () => {
            pageBreadcrumbs.value = [];

            const findAndUpdateBreadcrumbs = (items, path = []) => {
                for (const item of items) {
                    if (item.children && item.children.length > 0) {
                        if (item.children.some(child => child.path === route.path)) {
                            item.isOpen = true;
                            pageBreadcrumbs.value = path.map(p => ({ text: p.title, link: p.path }));
                            pageBreadcrumbs.value.push({ text: item.title, link: item.path });

                            item.children.forEach(child => {
                                if (child.path === route.path) {
                                    pageTitle.value = child.title;
                                    pageBreadcrumbs.value.push({ text: child.title, link: child.path });
                                }
                            });
                        } else {
                            item.isOpen = false;
                            findAndUpdateBreadcrumbs(item.children, [...path, { title: item.title, path: item.path }]);
                        }
                    } else {
                        if (item.path === route.path) {
                            pageTitle.value = item.title;
                            pageBreadcrumbs.value = path.map(p => ({ text: p.title, link: p.path }));
                            pageBreadcrumbs.value.push({ text: item.title, link: item.path });
                        }
                    }
                }
            };

            findAndUpdateBreadcrumbs(Object.values(groupedMenuItems.value).flat());
        };
        updateMenuOpenState()
        

        const onToggleMessageDropdown = (event) => {
            event.preventDefault()
            isMessageDropdownOpen.value = !isMessageDropdownOpen.value
        }

        const onToggleMemberDropdown = (event) => {
            event.preventDefault()
            isMemberDropdownOpen.value = !isMemberDropdownOpen.value
        }

        const onToggleSidebar = () => {
            if(windowSize.value > 1010){
                document.body.classList.toggle('sidebar-collapse')
            }else{
                document.body.classList.toggle('sidebar-open')
            }
        };

        const onClickSignOut = async () => {
            try {
                await $axiosConnect('MEMBER', '/admin/signOut', 'GET')
                store.dispatch('member/signOut');
                location.href='/admin'
                
            } catch (error) {
                console.error("error:", error)
            }
        }

        const updateWindowWidth = () => {
            windowSize.value = parseInt(window.innerWidth)
        };

        return {
            pageTitle,
            pageBreadcrumbs,
            groupedMenuItems,
            isMessageDropdownOpen,
            isMemberDropdownOpen,
            storeMemberInfo,

            onToggleSideBarMenuItem,
            onToggleSidebar,
            onToggleMessageDropdown,
            onToggleMemberDropdown,
            onClickSignOut
        }

    }
})
</script>
<style scoped>
.brand-link .brand-image {
    box-shadow: none;
    padding: 2px;
}
.sidebar-collapse .brand-image {
    max-width: 100%;
    margin: 0;
}
</style>