<template>
    <main>
        <div class="container">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <div class="page-tab-title">
                                <ul>
                                    <li class="active"><span>상품 만들기</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <template v-for="(item, index) in parentCompositionList" :key="index">
                                <div class="row" v-show="getCompositionFilteredChildList(item).length > 0">
                                    <div class="product-custom">
                                        <h4 class="text-center">{{ item.compositionName }}</h4>
                                        <ul class="mt-4 mb-5">
                                            <li v-for="(childItem, childIndex) in getCompositionFilteredChildList(item)" :key="childIndex">
                                                <label class="input-label custom-label" :for="'option-item-' + index + '-' + childIndex">
                                                    <input type="checkbox"
                                                        :id="'option-item-' + index + '-' + childIndex"
                                                        :value="childItem.compositionUid"
                                                        :checked="productSelectedComposition[item.compositionUid] === childItem.compositionUid"
                                                        @change="toggleSelection(item.compositionUid, childItem.compositionUid)"
                                                    />
                                                    <span class="label-text">{{ childItem.compositionName }}</span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="row justify-content-center mt-8">
                        <div class="col-12 col-sm-12 col-lg-3">
                            <div class="product-list-paging">
                                <button class="btn btn-special-2" @click="onClickMoreProductList">상품 만들기</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'

export default defineComponent({
    name: 'webProductListPage',
    setup() {
        const { $axiosConnect, router } = commonMixin()

        const parentCompositionList = ref([])
        const childCompositionList = ref([])

        // 객체로 선언하여 각 부모 구성에 대해 선택된 자식 구성 Uid를 기록
        const productSelectedComposition = ref({})

        const getCompositionList = async () => {
            try {
                let defaultParams = {
                    'page': 1,
                    'pageSize': 1000,
                    'compositionState': 1
                }
                const response = await $axiosConnect('GUEST', `/web/compositionProduct`, 'GET', defaultParams)

                let list = []
                if (response.body) {
                    list = JSON.parse(JSON.stringify(response.body.list))
                    if (list.length > 0) {
                        list = list.map(({ uid, compositionName, groups, depth }) => ({
                            compositionUid: uid,
                            compositionName: compositionName,
                            compositionGroups: groups,
                            compositionDepth: depth
                        }))
                    }
                    parentCompositionList.value = list.filter(composition => composition.compositionDepth === 0)
                    childCompositionList.value = list.filter(composition => composition.compositionDepth === 1)
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const getCompositionFilteredChildList = (parentComposition) => {
            if (parentComposition) {
                const { compositionGroups } = parentComposition
                return childCompositionList.value.filter(child => compositionGroups === child.compositionGroups && child.compositionDepth === 1)
            }
            return []
        }

        // 선택 토글 함수
        const toggleSelection = (parentUid, childUid) => {
            if (productSelectedComposition.value[parentUid] === childUid) {
                productSelectedComposition.value[parentUid] = 0;
            } else {
                productSelectedComposition.value[parentUid] = childUid;
            }
        };

        const onClickMoreProductList = () => {
            const selectedCompositions = Object.keys(productSelectedComposition.value)
                .map(key => productSelectedComposition.value[key] || 0)
                .join(',')
            router.push({ name: 'webProductCustomSearchPage', query: { composition: selectedCompositions } });
        };

        const initialize = async () => {
            await getCompositionList()
            parentCompositionList.value.forEach(parent => {
                productSelectedComposition.value[parent.compositionUid] = 0;
            });
        }

        onMounted(async () => {
            await initialize()
        })

        return {
            parentCompositionList,
            childCompositionList,
            productSelectedComposition,

            getCompositionFilteredChildList,
            toggleSelection,
            onClickMoreProductList,
        }
    }
});
</script>