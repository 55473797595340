<template>
    <Modal ref="baseModal" :title="`제휴문의 ${mode === 'create' ? '추가' : '수정'}`" :width="`800px`">
        <template #body>
			<form ref="businessRequestFormRef" @submit.prevent="onClickSave">
				<div class="form-group row form-required">
					<label for="inputSubject" class="col-sm-2 col-form-label">제목</label>
					<div class="col-sm-10">
						<div class="input-group">
							<div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
							<input type="text" class="form-control" id="inputSubject" placeholder="제목을 입력해주세요." autocomplete="off" tabindex="1"
							required 
							maxlength="255" 
                            pattern-hint="제목은 필수로 입력해야 합니다"
							v-model="businessRequestFormData.subject"
							@blur="onInputCheckEvent()" 
							/>
						</div>
                        <small class="input-hint"></small>
					</div>
				</div>
                <div class="form-group row form-required">
					<label for="inputSubject" class="col-sm-2 col-form-label">회사명</label>
					<div class="col-sm-10">
						<div class="input-group">
							<div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
							<input type="text" class="form-control" id="businessCompany" placeholder="회사명을 입력해주세요." autocomplete="off" tabindex="1"
							required 
							maxlength="255" 
                            pattern-hint="회사명은 필수로 입력해야 합니다"
							v-model="businessRequestFormData.businessCompany"
							@blur="onInputCheckEvent()" 
							/>
						</div>
                        <small class="input-hint"></small>
					</div>
				</div>
                <div class="form-group row form-required">
					<label for="inputSubject" class="col-sm-2 col-form-label">이름</label>
					<div class="col-sm-10">
						<div class="input-group">
							<div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
							<input type="text" class="form-control" id="businessName" placeholder="이름을 입력해주세요." autocomplete="off" tabindex="1"
							required 
							maxlength="50" 
							pattern="^[a-zA-Z가-힣]+$"
							pattern-hint="이름은 한글/영문으로 실명을 입력해야 합니다."
							v-model="businessRequestFormData.businessName"
							@input="onInputCheckEvent()" 
							/>
						</div>
                        <small class="input-hint"></small>
					</div>
				</div>
                <div class="form-group row">
					<label for="inputSubject" class="col-sm-2 col-form-label">직위</label>
					<div class="col-sm-10">
						<div class="input-group">
							<div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
							<input type="text" class="form-control" id="businessPosition" placeholder="직위를 입력해주세요." autocomplete="off" tabindex="1"
							maxlength="255" 
							v-model="businessRequestFormData.businessPosition"
							@blur="onInputCheckEvent()" 
							/>
						</div>
                        <small class="input-hint"></small>
					</div>
				</div>
                <div class="form-group row">
					<label for="inputSubject" class="col-sm-2 col-form-label">이메일</label>
					<div class="col-sm-10">
						<div class="input-group">
							<div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
							<input type="text" class="form-control" id="businessEmail" placeholder="이메일을 입력해주세요." autocomplete="off" tabindex="5"
							maxlength="100" 
							pattern="^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$"
							pattern-hint="이메일을 형식에 맞게 정확히 입력해야 합니다."
							v-model="businessRequestFormData.businessEmail"
							@blur="onInputCheckEvent()" 
							/>
						</div>
                        <small class="input-hint"></small>
					</div>
				</div>
                <div class="form-group row form-required">
					<label for="inputSubject" class="col-sm-2 col-form-label">휴대폰</label>
					<div class="col-sm-10">
						<div class="input-group">
							<div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
							<input type="text" class="form-control" id="businessMobile" placeholder="휴대폰 번호를 입력해주세요." autocomplete="off" tabindex="5"
							required
							maxlength="11" 
							pattern="^010\d{8}$"
							pattern-hint="휴대폰 번호는 010으로 시작해야하며, 숫자만 입력해야 합니다."
							v-model="businessRequestFormData.businessMobile"
							@blur="onInputCheckEvent()" 
							/>
						</div>
                        <small class="input-hint"></small>
					</div>
				</div>
                <div class="form-group row form-required">
					<label for="inputContent" class="col-sm-2 col-form-label">내용</label>
					<div class="col-sm-10">
                        <div class="pt-2 pb-2">
                            <smarteditor2 
                            v-model="businessRequestFormData.content"
                            :editor-id="'editor1'"
                            ref="editor1"
                            />
                        </div>
					</div>
				</div>
                <div class="form-group row">
					<label for="selectBusinessRequestState" class="col-sm-2 col-form-label">파일</label>
					<div class="col-sm-10">
						<div class="input-group">
							<component-form-file-upload 
                                ref="fileUploadRef"
                                :maxSize="10240"
                                accept="png"
                                :initialFiles = businessRequestFormData.upfiles
                                @on-click-attach-file="onClickAttachFile"
                            />
						</div>
					</div>
				</div>
			</form>
        </template>
        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">닫기</button>
			<button type="submit" class="btn btn-primary" :disabled="submitButtonDisabled" @mouseenter="onOverSubmitButton" @click="onClickSave()">{{ mode === 'create'?'저장':'수정' }}</button>
        </template>
	</Modal>

	<alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import Modal from '@/modals/Modal'
import Alert from '@/modals/common/Alert'
import { unescapeHtmlEntities  } from '@/helper/utils/etcUtils.js'

import ComponentFormFileUpload from "@/components/admin/ComponentFormFileUpload"
import ComponentFormSmarteditor2 from "@/components/admin/ComponentFormSmarteditor2"

export default defineComponent({
    name       : 'modalBusinessRequestUpdatePage',
    components: {
		Modal,
		'alert' : Alert,
        'component-form-file-upload' : ComponentFormFileUpload,
        'smarteditor2' : ComponentFormSmarteditor2,
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){
		const { onHandleValidateInput, $axiosConnect, store } = commonMixin()

        const baseModal            = ref(null)
		const resolvePromise       = ref(null)

        const submitButtonDisabled = ref(true)
        const mode                 = ref('create')
        const closeModalData       = ref({})
        const alertRef             = ref(null)

        const storeState           = ref({})
        const storeCallState       = ref({})
        
        const businessRequestFormRef        = ref(null)
        const businessRequestFormData       = ref({
            subject : '',
            content : '',
            answer  : '',
            state   : 0,
            step    : 0,
            upfiles : []
        })

        const editor1 = ref(null)

        const uploadedFiles = ref([])

        onMounted(() => {
            storeState.value     = store.getters["common/getState"]
            storeCallState.value = store.getters["common/getCallState"]
        })

		const open = (payload) => {

            mode.value = 'create'
            businessRequestFormData.value = {
                subject : '',
                content : '',
                answer  : '',
                state   : 0,
                upfiles : []
            }

            if (payload.item && payload.mode === 'update') {
                businessRequestFormData.value = JSON.parse(JSON.stringify(payload.item))
                mode.value = payload.mode
                getDeatil()
            }else{
                if(payload.item && payload.item.uid){
                    businessRequestFormData.value.parentUid = payload.item.uid
                }
            }

            closeModalData.value = {
                isUpdate : false,
                item     : ''
            }

            //Open Modal
			baseModal.value.openModal()
			return new Promise((resolve) => {
				resolvePromise.value = resolve;
			});
		};

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			baseModal.value.closeModal()
			resolvePromise.value(closeModalData.value)
		};
        
		/**
         * 입력 값의 유효성을 검사하고, 암호화된 아이디와 비밀번호를 생성하는 메서드
         * 유효성 검사 결과에 따라 submitDisabled 속성을 설정합니다.
         * @method onInputCheckEvent
         * @returns {void}
         */
         const onInputCheckEvent = () => {
            return !(submitButtonDisabled.value = !onHandleValidateInput(businessRequestFormRef.value))
        }

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onOverSubmitButton = () => {
            const formElements = businessRequestFormRef.value.querySelectorAll('input, select, textarea')
            formElements.forEach(input => {
                input.blur()
            });
        }

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
        const onClickSave = async () => {
            if(onInputCheckEvent()){
                //Editor Content
                editor1.value?.syncContent()

                const formData = new FormData();
                for (const key in businessRequestFormData.value) {
                    if (businessRequestFormData.value[key] !== '') {
                        formData.set(key, businessRequestFormData.value[key]);
                    }
                }

                // 파일들을 FormData에 추가
                uploadedFiles.value.forEach((file, index) => {
                    formData.append(`files[${index}]`, file)
                })

                onProcessSave(formData)
            }
        }

        /**
         * 로그인 프로세스를 처리하는 메서드
         * @method onProcessSignIn
         * @param {FormData} formData - 로그인에 필요한 폼 데이터
         * @returns {void}
         */
        const onProcessSave = async (formData) => {
            try {

                let response
                if(mode.value === 'create'){
                    response = await $axiosConnect('MEMBER', `/admin/businessRequest`, 'POST', formData)
                }else{
                    const uid = businessRequestFormData.value.uid
                    response = await $axiosConnect('MEMBER', `/admin/businessRequest/${uid}`, 'POST', formData)
                }

                if (response.success && response.body && response.body.list.length > 0) {
                    
                    businessRequestFormData.value = {
                        subject : '',
                        content : '',
                        answer  : '',
                        state   : 0,
                        step    : 0,
                        upfiles : []
                    }

                    closeModalData.value = {
                        isUpdate : true,
                        item     : response.body.list[0]
                    }

                    alertRef.value.open({ 
                        'contents' : response.message,
                        'callback' : close
                    })

                }else{
                    alertRef.value.open({ 'contents' : response.message })
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

        const getDeatil = async () => {
            try {
                const uid = businessRequestFormData.value.uid
                const response = await $axiosConnect('MEMBER', `/admin/businessRequest/${uid}`, 'GET')

                if (response.success && response.body && response.body.list.length > 0) {

                    const detail = JSON.parse(JSON.stringify(response.body.list[0]))
                    businessRequestFormData.value = {
                        ...detail,
                        convertContent : unescapeHtmlEntities(detail.content),
                        convertAnswer  : unescapeHtmlEntities(detail.answer)
                    }
                    
                }else{
                    alertRef.value.open({ 'contents' : response.message })
                    close()
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const onClickAttachFile = (filesInfo) => {
            uploadedFiles.value = filesInfo.map(fileInfo => fileInfo.file)

            businessRequestFormData.value.uploadedFilesOrder = []
            filesInfo.forEach(fileInfo => {
                businessRequestFormData.value.uploadedFilesOrder.push({
                    "name"  : fileInfo.upfilesOriginalName?fileInfo.upfilesOriginalName:fileInfo.name,
                    "order" : fileInfo.order
                })
            })
            businessRequestFormData.value.uploadedFilesOrder = JSON.stringify(businessRequestFormData.value.uploadedFilesOrder)

            onInputCheckEvent()
        }

        return {
			baseModal, 
			open,
			close,
            mode,
            submitButtonDisabled,
            editor1,

			alertRef,
			businessRequestFormRef,
			businessRequestFormData,
            storeState,
            storeCallState,

			onInputCheckEvent,
            onOverSubmitButton,
            onClickSave,
            onClickAttachFile,
		}
    }
});
</script>
<style scoped>
::v-deep .ql-editor {
    height: 200px;
    overflow: scroll;
    overflow-x: hidden;
}
</style>