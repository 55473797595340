<template>
    <main>
        <div class="container">
			<section class="content-header">
				<div class="container-fluid">
					<div class="row mb-2">
						<div class="col-sm-12">
							<div class="page-tab-title">
								<ul>
									<li class="active"><span>딕스랩 소개</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
            <div class="content">
                <div class="container-fluid">
					<div class="row">
						<div class="col-md-12">

                            <div class="card">
                                <div class="card-body position-relative">
                                    <!--임시 시작-->
									<picture>
										<img style="max-width: 100%;"
											src="@/assets/images/web/company/dixlab_img.png" 
										/>
									</picture>
									<!--임시 끝-->
                                </div>
                            </div>

						</div>
					</div>
                </div>
            </div>
        </div>
    </main>

</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name       : 'webDixlabCompanyPage',
    setup() {

		return {
        }
    }
});
</script>