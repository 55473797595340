<template>
    <teleport to="body">
        <div class="modal fade" 
            :class="{ 'show': isModalVisible }" 
            :style="{ 'display': isModalVisible ? 'block' : 'none' }"
            v-if="isModalVisible"
            @click="isOverlay ? overlayClick() : null"
        >
            <div class="modal-dialog">
                <vue-draggable-resizable
                    :drag-handle="'.drag-handle'"
                    :resizable="true"
                    :style="{
                        'width'   : width,
                        'position': 'relative',
                        'margin'  : 'auto',
                        'height'  : 'auto'
                    }"
                >
                <div class="modal-content">
                    <div class="modal-header" :class="{ 'drag-handle': isDrag }" v-if="title">
                        <h4 class="modal-title" v-html="title"></h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="onClickCloseModal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" @click.stop>
                        <slot name="body"></slot>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <slot name="footer"></slot>
                    </div>
                </div>
                </vue-draggable-resizable>

            </div>

            <div class="modal-backdrop fade"
                :class="{ 'show': isModalVisible }"
                :style="{ 'display': isModalVisible ? 'block' : 'none' }" 
                v-if="isModalVisible"
            ></div>
            
        </div>
        
    </teleport>
</template>

<script>
import { defineComponent, ref  } from 'vue'
import VueDraggableResizable from 'vue-draggable-resizable'

export default defineComponent({
    name : 'modalPage',

    props: {
        isDrag: {
            type: Boolean,
            default: false
        },
        isOverlay: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        width: {
            type: String,
            default: '300px'
        },
    },
    components: {
        'vue-draggable-resizable' : VueDraggableResizable
	},
    emits: ['close'],
	setup(props, { emit }) {

		const isModalVisible = ref(false)

        const openModal = () => {
            isModalVisible.value = true
            document.body.style.overflow = 'hidden'
            window.addEventListener('scroll', handleScroll, { passive: true })
            window.addEventListener('touchstart', handleScroll, { passive: true })
        }

        const onClickCloseModal = () => {
            emit('close')
            closeModal()
        }

        const closeModal = () => {
            isModalVisible.value = false
            document.body.style.overflow = ''
            window.removeEventListener('scroll', handleScroll)
            window.removeEventListener('touchstart', handleScroll);
        }

		const handleScroll = (event) => {
            event.preventDefault()
            event.stopPropagation()
        }

        const overlayClick = () => {
            if (props.isOverlayClick !== false) {
                closeModal()
            }
        }

		return {
            isModalVisible,

            openModal,
            closeModal,
            overlayClick,
            onClickCloseModal
        }
    }
})
</script>
<style scoped>
.drag-handle {
    cursor: move;
}
</style>