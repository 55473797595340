<template>
    <div class="header">
        <div class="container">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <div class="page-tab-title">
                                <ul>
                                    <li class="active"><span>회원가입</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2 justify-content-center">
                        <div class="col-md-5 col-sm-12">
                            <div class="content-header-steplist">
                                <div class="steplist-step" role="button" @click="onClickSignUpStep1()">
                                    <span class="steplist-step-circle">1</span>
                                    <span class="steplist-step-label">유형선택</span>
                                </div>
                                <div class="steplist-step active">
                                    <span class="steplist-step-circle">2</span>
                                    <span class="steplist-step-label">약관동의</span>
                                </div>
                                <div class="steplist-step">
                                    <span class="steplist-step-circle">3</span>
                                    <span class="steplist-step-label">인증</span>
                                </div>
                                <div class="steplist-step">
                                    <span class="steplist-step-circle">4</span>
                                    <span class="steplist-step-label">정보입력</span>
                                </div>
                                <div class="steplist-step">
                                    <span class="steplist-step-circle">5</span>
                                    <span class="steplist-step-label">가입완료</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="content login-content">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-sm-12">
                            <div class="card">
                                <div class="card-body join-card-body">
                                    <p class="login-box-msg">이용약관 및 개인정보처리방침 등 동의</p>

                                    <form ref="signUpFormRef" @submit.prevent="onClickSignUp">
                                        <div class="row justify-content-center">
                                            <div class="col-md-8 col-sm-12">
                                                <div class="form-group row mb-4">
                                                    <div class="col-sm-12">
                                                        <div class="input-group mb-2">
                                                            <label class="input-label" for="memberAgreeService" @click="onClickModalSignUpAgree('memberAgreeService')">
                                                                <input type="checkbox"
                                                                    id="memberAgreeService"
                                                                    name="memberAgreeService"
                                                                    v-model="signUpFormData.memberAgreeService"
                                                                    @change="onInputCheckEvent()"
                                                                    required
                                                                />
                                                                <span class="checkbox-icon"></span>
                                                                <span class="label-text">이용약관 동의</span>
                                                                <span class="required-text">(필수)</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <div class="input-group">
                                                            <div class="agree-text" 
                                                            role="button" 
                                                            @click="onClickModalSignUpAgree('memberAgreeService')"
                                                            >구독 서비스 이용약관</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row mb-4">
                                                    <div class="col-sm-12">
                                                        <div class="input-group mb-2">
                                                            <label class="input-label" for="memberAgreePolicy" @click="onClickModalSignUpAgree('memberAgreePolicy')">
                                                                <input type="checkbox"
                                                                    id="memberAgreePolicy"
                                                                    name="memberAgreePolicy"
                                                                    v-model="signUpFormData.memberAgreePolicy"
                                                                    @change="onInputCheckEvent()"
                                                                    required
                                                                />
                                                                <span class="checkbox-icon"></span>
                                                                <span class="label-text">개인정보처리방침 동의</span>
                                                                <span class="required-text">(필수)</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <div class="input-group">
                                                            <div class="agree-text" 
                                                            role="button" 
                                                            @click="onClickModalSignUpAgree('memberAgreePolicy')"
                                                            >개인정보 수집 및 이용</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row mb-4">
                                                    <div class="col-sm-12">
                                                        <div class="input-group mb-2">
                                                            <label class="input-label" for="memberAgreeSharing" @click="onClickModalSignUpAgree('memberAgreeSharing')">
                                                                <input type="checkbox"
                                                                    id="memberAgreeSharing"
                                                                    name="memberAgreeSharing"
                                                                    v-model="signUpFormData.memberAgreeSharing"
                                                                    @change="onInputCheckEvent()"
                                                                />
                                                                <span class="checkbox-icon"></span>
                                                                <span class="label-text">개인정보 제공 동의</span>
                                                                <span class="required-text">(선택)</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <div class="input-group">
                                                                <div class="agree-text" 
                                                                role="button" 
                                                                @click="onClickModalSignUpAgree('memberAgreeSharing')"
                                                                >개인정보 제3자 제공 안내</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row mb-4">
                                                    <div class="col-sm-12">
                                                        <div class="input-group mb-2">
                                                            <label class="input-label" for="memberAgreeReceiving" @click="onClickModalSignUpAgree('memberAgreeReceiving')">
                                                                <input type="checkbox"
                                                                    id="memberAgreeReceiving"
                                                                    name="memberAgreeReceiving"
                                                                    v-model="signUpFormData.memberAgreeReceiving"
                                                                    @change="onInputCheckEvent()"
                                                                />
                                                                <span class="checkbox-icon"></span>
                                                                <span class="label-text">정보수신 동의</span>
                                                                <span class="required-text">(선택)</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <div class="input-group">
                                                                <div class="agree-text" 
                                                                role="button" 
                                                                @click="onClickModalSignUpAgree('memberAgreeReceiving')"
                                                                >정보수신 동의 안내</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-md-5 col-sm-12">
                                                <div class="mt-5 mb-3">
                                                    <button type="submit" class="btn btn-block btn-special-2"
                                                    :disabled="submitButtonDisabled" @mouseenter="onOverSubmitButton"
                                                    >다음</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <modal-sign-up-agree ref="modalSignUpAgreeRef" @onCloseModal="onCloseModalSignUpAgree" />

</template>

<script>
import { defineComponent, ref, onMounted, computed, nextTick } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'

import ModalSignUpAgree from '@/modals/web/account/ModalSignUpAgree'
export default defineComponent({
    name: 'webSignUpStep2Page',
    components: {
        'modal-sign-up-agree' : ModalSignUpAgree
	},
    setup() {
        const { onHandleValidateInput, store, router } = commonMixin()

        const submitButtonDisabled = ref(true)
        const signUpFormRef        = ref(null)
        const signUpFormData       = ref({
            memberAuthority         : '0',
            memberAgreeService      : false,
            memberAgreePolicy       : false,
            memberAgreeSharing      : false,
            memberAgreeReceiving    : false,
            memberEmail             : '',
            memberName              : '',
            memberBirth             : '',
            memberBirthYear         : new Date().getFullYear() - 20,
            memberBirthMonth        : 1,
            memberBirthDay          : 1,
            memberGender            : '',
            memberMobile            : '',
            memberMobileCarrier     : '',
            memberAuthNumber        : '000000',
            memberId                : '',
            memberPassword          : '',
            memberPasswordEncrypted : '',
            memberRePassword        : '',
        })
        const modalSignUpAgreeRef = ref(null)

        /**
         * 입력 값의 유효성을 검사하고, 암호화된 아이디와 비밀번호를 생성하는 메서드
         * 유효성 검사 결과에 따라 submitDisabled 속성을 설정합니다.
         * @method onInputCheckEvent
         * @returns {void}
         */
        const onInputCheckEvent = async () => {
            await nextTick();
            return !(submitButtonDisabled.value = !onHandleValidateInput(signUpFormRef.value))
        }

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onOverSubmitButton = () => {
            const formElements = signUpFormRef.value.querySelectorAll('input, select, textarea')
            formElements.forEach(input => {
                input.blur()
            });
        }

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
         const onClickSignUp = () => {
            if(onInputCheckEvent()){
                const formDataObj = {}
                for (const key in signUpFormData.value) {
                    if(signUpFormData.value[key] !== ''){
                        formDataObj[key] = signUpFormData.value[key]
                    }
                }
                store.dispatch('member/setSignUpStep', JSON.parse(JSON.stringify(formDataObj)))
                router.replace({ name: 'webSignUpStep3Page' })
            }
        }

        const onClickModalSignUpAgree = (item) => {
            signUpFormData.value[item] = false
            onInputCheckEvent()

            modalSignUpAgreeRef.value.open({
                'mode' : 'view',
                'item' : item
            });
        }

        const onCloseModalSignUpAgree = (payload) => {
            signUpFormData.value[payload.item] = false

            if (payload.isUpdate) {
                signUpFormData.value[payload.item] = true
            }

            onInputCheckEvent()
        }

        const onClickSignUpStep1 = () => {
            signUpFormData.value.memberAgreeService      = false
            signUpFormData.value.memberAgreePolicy       = false
            signUpFormData.value.memberAgreeSharing      = false
            signUpFormData.value.memberAgreeReceiving    = false
            
            signUpFormData.value.memberEmail             = ''
            signUpFormData.value.memberName              = ''
            signUpFormData.value.memberBirth             = ''
            signUpFormData.value.memberBirthYear         = new Date().getFullYear() - 20,
            signUpFormData.value.memberBirthMonth        = 1
            signUpFormData.value.memberBirthDay          = 1
            signUpFormData.value.memberGender            = ''
            signUpFormData.value.memberMobile            = ''
            signUpFormData.value.memberMobileCarrier     = ''
            signUpFormData.value.memberAuthNumber        = ''
            signUpFormData.value.memberId                = ''
            signUpFormData.value.memberPassword          = ''
            signUpFormData.value.memberPasswordEncrypted = ''
            signUpFormData.value.memberRePassword        = ''

            const formDataObj = {}
            for (const key in signUpFormData.value) {
                if(signUpFormData.value[key] !== ''){
                    formDataObj[key] = signUpFormData.value[key]
                }
            }
            store.dispatch('member/setSignUpStep', JSON.parse(JSON.stringify(formDataObj)))

            router.push({ 
                name: 'webSignUpStep1Page', 
                query: { from: 'webSignUpStep2Page' } 
            })
        }

        const initialize = async () => {
            submitButtonDisabled.value = true
            const getSignUpFormData = computed(() => store.getters["member/getSignUpFormData"])
            Object.assign(signUpFormData.value, getSignUpFormData.value)
            onInputCheckEvent()
        }

        onMounted(async () => {
            await initialize()
        })

        return {
            submitButtonDisabled,
            signUpFormRef,
            signUpFormData,

            modalSignUpAgreeRef,
            router,

            onInputCheckEvent,
            onOverSubmitButton,
            onClickSignUp,
            onClickModalSignUpAgree,
            onCloseModalSignUpAgree,
            onClickSignUpStep1
        }
    }
});
</script>