<template>
    <main>
        <div class="container">
			<section class="content-header">
				<div class="container-fluid">
					<div class="row mb-2">
						<div class="col-sm-12">
							<div class="page-tab-title">
								<ul>
									<li class="active"><span>{{ purpose }}</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
            <div class="content">
                <div class="container-fluid">
					<div class="row">
						<div class="col-md-12">
							<div class="row">
								<template v-for="(item, index) in list" :key="index">
									<div class="col-12 col-sm-6 col-lg-3">
										<div class="product-item" data-aos-delay="000" @click="onClickNavigateToProductDetail(item.uid)" role="button">
											<div class="product-inner-item">
                                                <div class="product-item-type">
                                                    <template v-if="item.productSubscribe === 1"><span class="product-item-subscribe-text">구독</span></template>
                                                    <template v-if="item.productRental === 1"><span class="product-item-rental-text">렌탈형 구독</span></template>
                                                    <template v-if="item.productSale === 1"><span class="product-item-sale-text">판매</span></template>
                                                </div>
												<picture class="product-image">
													<template v-if="item.convertProductImages && item.convertProductImages.length > 0 ">
                                                        <img class="absolute-center" 
                                                            :src="`${imgURL}${item.convertProductImages[0].upfilesThumnailPath}/${item.convertProductImages[0].upfilesFileName}`" 
                                                            :alt="item.convertProductName"
                                                        />
                                                    </template>
                                                    <template v-else>
                                                        <img class="absolute-center" 
                                                            src="@/assets/images/common/logo_kor.png"
                                                            :alt="item.convertProductName"
                                                        />
                                                    </template>
												</picture>
												<div class="product-content">
													<h5 class="product-name">{{ item.convertProductName }}</h5>
													<p class="product-notes">{{ item.convertProductDescription }}</p>
													<div class="product-purpose">
                                                        <template v-if="item.productPurposeGaming === 1"><span class="product-purpose-gaming">게이밍용</span></template>
														<template v-if="item.productPurposeProfessional === 1"><span class="product-purpose-professional">전문가용</span></template>
														<template v-if="item.productPurposeStudy === 1"><span class="product-purpose-study">학습용</span></template>
														<template v-if="item.productPurposeWork === 1"><span class="product-purpose-work">업무용</span></template>
                                                    </div>
                                                    <template v-if="item.productSubscribe === 1">
                                                        <div class="product-price-box">
                                                            <span class="product-price-subscribe-text" v-if="false">구독</span>
                                                            <span class="product-price-before">월 {{ item.convertProductSubscribeRegularPrice }}원</span>
                                                            <span class="product-price-after">월 {{ item.convertProductSubscribePrice }}원</span>
                                                        </div>
                                                    </template>
                                                    <template v-else-if="item.productRental === 1">
                                                        <div class="product-price-box">
                                                            <span class="product-price-rental-text" v-if="false">렌탈형 구독</span>
                                                            <span class="product-price-before">월 {{ item.convertProductRentalRegularPrice }}원</span>
                                                            <span class="product-price-after">월 {{ item.convertProductRentalPrice }}원</span>
                                                        </div>
                                                    </template>
                                                    <template v-else-if="item.productSale === 1">
                                                        <div class="product-price-box">
                                                            <span class="product-price-sale-text" v-if="false">판매</span>
                                                            <span class="product-price-before">월 {{ item.convertProductSaleRegularPrice }}원</span>
                                                            <span class="product-price-after">월 {{ item.convertProductSalePrice }}원</span>
                                                        </div>
                                                    </template>
												</div>
											</div>
										</div>
									</div>
								</template>
							</div>

							<div class="row justify-content-center mt-8">
                                <div class="col-12 col-sm-12 col-lg-12">
                                    <div class="product-list-paging">
                                        <template v-if="listSearchCount > 0">
                                            <small>전체 {{ listTotalCount }}개의 상품 중 {{ listSearchCount }}개의 상품을 보고 있습니다.</small>
                                            <div class="progress">
                                                <div class="progress-bar bg-dark" role="progressbar" 
                                                :style="{ width: (listSearchCount / listTotalCount * 100) + '%' }"
                                                ></div>
                                            </div>
                                        </template>

                                        <template v-if="listSearchCount < listTotalCount">
                                            <button class="btn col-sm-2 btn-black-1 mrg-horizon-auto" @click="onClickMoreProductList()" v-if="listSearchCount < listTotalCount">상품 더보기</button>
                                        </template>
										<template v-else>
											<div class="product-request" v-if="Math.ceil(listTotalCount/listPageSize) === listCurrentPage">
												<p class="font-size-xl">찾고자 하는 상품이 못찾으셨나요?</p>
                                                <hr/>
                                                <div class="product-request-group">
                                                    <div>
                                                        <span>A~Z까지 선택해서 상품을 찾아보세요.</span>
                                                        <span role="button" @click="router.push({ name: 'webProductCustomPage' })">상품 찾기</span>
                                                    </div>
                                                    <div>
                                                        <span>1:1 문의로 원하는 상품을 문의해보세요.</span>
                                                        <span role="button" @click="router.push({ name: 'webContactPage' })">1:1 문의</span>
                                                    </div>
                                                    <div>
                                                        <span>담당자와 직접 전화로 문의해서 원하는 상품을 찾을 수 있어요.</span>
                                                        <span role="button" @click="router.push({ name: 'webCallRequestPage' })">전화상담요청</span>
                                                    </div>
                                                </div>
											</div>
										</template>
                                    </div>
                                </div>
                            </div>

						</div>
					</div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { numberFormat, truncateText  } from '@/helper/utils/etcUtils.js'

export default defineComponent({
    name       : 'webProductListPage',
	components : {
    },
    props: {
        searchPurpose: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const { $axiosConnect, store, router, imgURL } = commonMixin()

        const purpose          = ref('all')

		const listCurrentPage   = ref(1);
        const listPageSize      = ref(12);
		const list              = ref([])
        const listTotalCount    = ref(0)
        const listSearchCount   = ref(0)
		const queryStringParams = ref({})
        const applySearchParams = ref({})

        const storeProductPurpose = ref({})

		const getProductList = async () => {
            try {
                let defaultParams = {
                    'page'     : listCurrentPage.value,
                    'pageSize' : listPageSize.value
                };
                applySearchParams.value = { ...queryStringParams.value }
                let params = Object.assign({}, defaultParams, queryStringParams.value);
                const response = await $axiosConnect('GUEST', `/web/product`, 'GET', params);

                if(response.body){
                    const products = JSON.parse(JSON.stringify(response.body.list))
                    listTotalCount.value  = JSON.parse(JSON.stringify(response.body.total))
                    listSearchCount.value += products.length

                    const processedProducts  = products.map(list => {
                        return {
                            ...list,
                            converUid                           : list.uid.toString().padStart(6, '0'),
							convertProductName                  : truncateText(list.productName, 75),
							convertProductDescription           : truncateText(list.productDescription, 75),
							converRegDate                       : convertDateToStr(list.regDate),
							converUpDate                        : convertDateToStr(list.upDate),
							convertProductSubscribeRegularPrice : numberFormat(list.productSubscribeRegularPrice),
							convertProductSubscribePrice        : numberFormat(list.productSubscribePrice),
							convertProductRentalRegularPrice    : numberFormat(list.productRentalRegularPrice),
							convertProductRentalPrice           : numberFormat(list.productRentalPrice),
							convertProductRentalMonths          : numberFormat(list.productRentalMonths),
							convertProductRentalMonthPrice      : numberFormat(list.productRentalPrice/list.productRentalMonths),
							convertProductSaleRegularPrice      : numberFormat(list.productSaleRegularPrice),
							convertProductSalePrice             : numberFormat(list.productSalePrice),
							convertProductViewCount             : numberFormat(list.productViewCount),
							convertProductImages                : list.upfiles.filter(file => file.upfilesColumn === 'productImages'),
							convertProductDescriptionImages     : list.upfiles.filter(file => file.upfilesColumn === 'productDescriptionImages')
                        }
                    })
                    list.value = [...list.value, ...processedProducts];
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

        const onClickMoreProductList = () => {
            listCurrentPage.value++
            getProductList()
        }

        const onClickNavigateToProductDetail = (uid) => {
            router.push({ name: 'webProductDetailPage', params: { uid } });
        }

        const initialize = async () => {
            list.value              = []
            listCurrentPage.value   = 1
            listTotalCount.value    = 0
            listSearchCount.value   = 0
            applySearchParams.value = {}
            queryStringParams.value = {}

            const searchPurpose = props.searchPurpose
            if(searchPurpose === 'all' || searchPurpose === 'gaimg'){
                queryStringParams.value['searchCheck[0][PRODUCT_PURPOSE_GAMING]'] = 1;
            }
            if(searchPurpose === 'all' || searchPurpose === 'professional'){
                queryStringParams.value['searchCheck[0][PRODUCT_PURPOSE_PROFESSIONAL]'] = 1;
            }
            if(searchPurpose === 'all' || searchPurpose === 'work'){
                queryStringParams.value['searchCheck[0][PRODUCT_PURPOSE_WORK]'] = 1;
            }
            if(searchPurpose === 'all' || searchPurpose === 'study'){
                queryStringParams.value['searchCheck[0][PRODUCT_PURPOSE_STUDY]'] = 1;
            }

            storeProductPurpose.value = store.getters["common/getProductPurpose"];
            purpose.value = storeProductPurpose.value[props.searchPurpose] || '전체상품';
        }

        onMounted(async () => {
            await initialize()
            await getProductList()
        })

        watch(() => props.searchPurpose, async () => {
            await initialize();
            await getProductList();
        });

		return {
            imgURL,
            router,

            list,
            listCurrentPage,
            listPageSize,
            listTotalCount,
            listSearchCount,

            purpose,
            storeProductPurpose,

            onClickMoreProductList,
            onClickNavigateToProductDetail
        }
    }
});
</script>