<template>
    <Modal ref="baseModal" :title="`주문 ${mode === 'create' ? '추가' : '수정'}`" :width="`95%`">
        <template #body>
			<form ref="orderFormRef">
                <div class="row">
                    <div class="col-sm-4 border-right">
                        <div class="form-group row form-required">
                            <label for="productName" class="col-sm-2 col-form-label">상품명</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.productName }}</span>
                                </div>
                            </div>
                        </div>
                        <template v-for="(item, index) in orderFormData.orderItems" :key="index">
                            <div class="form-group row">
                                <label for="productDescription" class="col-sm-2 col-form-label">주문상품 {{ index + 1 }}</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                        <span class="form-control">{{ item.productItemName }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">주문구분</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control" v-if="orderFormData.orderType==='subscribe'">구독</span>
                                    <span class="form-control" v-if="orderFormData.orderType==='rental'">렌탈형 구독</span>
                                    <span class="form-control" v-if="orderFormData.orderType==='sale'">판매</span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">계약기간</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.convertOrderMonths }}개월</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">총 주문가격</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.convertOrderTotalPrice }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">보증금</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.convertOrderDepositPrice }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">기본가격</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.convertOrderDefaultTotalPrice }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">옵션가격</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.convertOrderOptionTotalPrice }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">서비스가격</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.convertOrderServiceTotalPrice }}</span>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-sm-4 border-right">
                        <div class="form-group row form-required">
                            <label for="productName" class="col-sm-2 col-form-label">주문자</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.regMemberName }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row form-required">
                            <label for="productName" class="col-sm-2 col-form-label">주문자 ID</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.regMemberId }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row form-required">
                            <label for="productName" class="col-sm-2 col-form-label">주문자 전화번호</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.orderMobile }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row form-required">
                            <label for="productName" class="col-sm-2 col-form-label">주문일</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.convertRegDate }}</span>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div class="form-group row form-required">
                            <label for="productName" class="col-sm-2 col-form-label">수령인</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.orderRecipientName }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row form-required">
                            <label for="productName" class="col-sm-2 col-form-label">휴대폰 번호</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.orderRecipientMobile }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row form-required mb-3">
                            <label for="memberName" class="col-sm-2 col-form-label">주소</label>
                            <div class="col-sm-10">
                                <div class="row">
                                    <div class="col-md-4 col-sm-6">
                                        <div class="input-group">
                                            <span class="form-control">{{ orderFormData.orderZipcode }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-12 col-sm-12">
                                        <div class="input-group">
                                            <span class="form-control">{{ orderFormData.orderAddr }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-12 col-sm-12">
                                        <div class="input-group">
                                            <span class="form-control">{{ orderFormData.orderAddrDetail }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <div class="form-group row form-required">
                            <label for="productName" class="col-sm-2 col-form-label">배송상태</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.orderDeliveryState }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row form-required">
                            <label for="productName" class="col-sm-2 col-form-label">구독상태</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.orderState }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productName" class="col-sm-2 col-form-label">구독시작일</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.converOrderContractDate }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row form-required">
                            <label for="inputContent" class="col-sm-2 col-form-label">비고</label>
                            <div class="col-sm-10">
                                <div class="input-content" v-html="orderFormData.convertOrderContent"></div>
                            </div>
                        </div>
                    </div>
                </div>
			</form>
        </template>
        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">닫기</button>
        </template>
	</Modal>

	<alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr, getDateDifference } from '@/helper/utils/dateUtils.js'
import { numberFormat, truncateText, unescapeHtmlEntities } from '@/helper/utils/etcUtils.js'

import Modal from '@/modals/Modal'
import Alert from '@/modals/common/Alert'

export default defineComponent({
    name       : 'modalOrderUpdatePage',
    components: {
		Modal,
		'alert' : Alert,
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){
		const { openModal, closeModal, baseModal, storeState, $axiosConnect, imgURL } = commonMixin()

        const mode                     = ref('create')
        const closeModalData           = ref({})
        const alertRef                 = ref(null)
        const submitButtonDisabled     = ref(true)

        const orderFormRef           = ref(null)
        const orderFormData          = ref({})

		const open = (payload) => {
            initialize()

            if (payload.item && payload.mode === 'detail') {
                orderFormData.value = JSON.parse(JSON.stringify(payload.item))
                mode.value = payload.mode
                getDeatil()
            }

            return openModal()
		}

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			closeModal(closeModalData.value)
		}

        const getDeatil = async () => {
            try {
                const uid = orderFormData.value.uid
                const response = await $axiosConnect('MEMBER', `/admin/order/${uid}`, 'GET')

                if (response.success && response.body && response.body.list.length > 0) {

                    const list = JSON.parse(JSON.stringify(response.body.list[0]))
                    orderFormData.value = {
                        ...list,
                        convertUid                      : list.uid.toString().padStart(6, '0'),
                        convertProductName              : list.productName,
                        convertProductDescription       : truncateText(list.productDescription, 50),
                        convertRegDate                  : convertDateToStr(list.regDate),
                        convertUpDate                   : convertDateToStr(list.upDate),
                        convertOrderDepositPrice        : numberFormat(list.orderDepositPrice),
                        convertOrderTotalPrice          : numberFormat(list.orderTotalPrice),
                        convertOrderDefaultTotalPrice   : numberFormat(list.orderDefaultTotalPrice),
                        convertOrderOptionTotalPrice    : numberFormat(list.orderOptionTotalPrice),
                        convertOrderServiceTotalPrice   : numberFormat(list.orderServiceTotalPrice),
                        convertOrderMonths              : numberFormat(list.orderMonths),
                        convertDateDifference           : getDateDifference(parseInt(list.orderContractDate)),
                        converOrderContractDate         : convertDateToStr(list.orderContractDate),
                        convertProductImages            : list.productUpfiles.filter(file => file.upfilesColumn === 'productImages'),
                        convertProductDescriptionImages : list.productUpfiles.filter(file => file.upfilesColumn === 'productDescriptionImages'),
                        convertOrderContent             : unescapeHtmlEntities(list.orderContent),
                    }
                }else{
                    alertRef.value.open({ 'contents' : response.message })
                    close()
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const initialize = async () => {
            mode.value            = "datail"

            orderFormData.value = {}

            closeModalData.value = {
                isUpdate : false,
                item     : ''
            }
        }

        onMounted(async () => {
            await initialize()
        })
       

        return {
			open,
			close,
            mode,
            submitButtonDisabled,

			alertRef,
			orderFormRef,
			orderFormData,

            //commonMixin
            storeState,
            baseModal, 
            imgURL,
		}
    }
})
</script>