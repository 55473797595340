<template>
    <div class="header">
        <div class="container">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <div class="page-tab-title">
                                <ul>
                                    <li class="active"><span>회원가입</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2 justify-content-center">
                        <div class="col-md-5 col-sm-12">
                            <div class="content-header-steplist">
                                <div class="steplist-step">
                                    <span class="steplist-step-circle">1</span>
                                    <span class="steplist-step-label">유형선택</span>
                                </div>
                                <div class="steplist-step">
                                    <span class="steplist-step-circle">2</span>
                                    <span class="steplist-step-label">약관동의</span>
                                </div>
                                <div class="steplist-step" role="button" @click="onClickSignUpStep3()">
                                    <span class="steplist-step-circle">3</span>
                                    <span class="steplist-step-label">인증</span>
                                </div>
                                <div class="steplist-step active">
                                    <span class="steplist-step-circle">4</span>
                                    <span class="steplist-step-label">정보입력</span>
                                </div>
                                <div class="steplist-step">
                                    <span class="steplist-step-circle">5</span>
                                    <span class="steplist-step-label">가입완료</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="content login-content">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-sm-12">
                            <div class="card">
                                <div class="card-body join-card-body">
                                    <p class="login-box-msg">회원정보 입력</p>

                                    <form ref="signUpFormRef" @submit.prevent="onClickSignUp">
                                        <div class="row justify-content-center">
                                            <div class="col-md-12 col-sm-12">
                                                <div class="form-group row form-required mb-3">
                                                    <label for="memberName" class="col-sm-3 col-form-label">이름</label>
                                                    <div class="col-sm-9">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" id="memberName" placeholder="이름을 입력해주세요." autocomplete="off" tabindex="1"
                                                            required 
                                                            maxlength="50" 
                                                            pattern="^[a-zA-Z가-힣]+$"
                                                            pattern-hint="이름은 한글/영문으로 실명을 입력해야 합니다."
                                                            v-model="signUpFormData.memberName"
                                                            @input="onInputCheckEvent()" 
                                                            />
                                                        </div>
                                                    </div>
                                                    <small class="input-hint with-label-3"></small>
                                                </div>
                                                <div class="form-group row form-required mb-3">
                                                    <label for="memberAuthNumber" class="col-sm-3 col-form-label">아이디</label>
                                                    <div class="col-sm-6">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" id="inputMemberId" placeholder="아이디를 입력해주세요." autocomplete="off" tabindex="1"
                                                                required 
                                                                maxlength="15" 
                                                                pattern="^[a-z][a-z0-9]{5,15}$" 
                                                                pattern-hint="아이디는 영문 소문자로 시작하고 영문 소문자와 숫자를 합친 6~15자 입니다."
                                                                v-model="signUpFormData.memberId" 
                                                                @input="onInputCheckEvent()" 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <button type="button" class="btn btn-web-primary btn-block"
                                                        :disabled="duplicateMemberIdDisabled"
                                                        @click="onClickDuplicateMemberId"
                                                        >중복확인</button>
                                                    </div>
                                                    <small class="input-hint with-label-3"></small>
                                                    <small class="input-hint-block with-label-3 active" v-if="signUpFormData.memberId && !duplicateMemberIdDisabled">아이디를 중복확인 해주세요.</small>
                                                </div>
                                                <div class="form-group row form-required mb-3">
                                                    <label for="memberPassword" class="col-sm-3 col-form-label">비밀번호</label>
                                                    <div class="col-sm-9">
                                                        <div class="input-group">
                                                            <input type="password" class="form-control" id="memberPassword" placeholder="비밀번호를 입력해주세요." autocomplete="new-password" tabindex="2"
                                                                required 
                                                                maxlength="20" 
                                                                pattern="^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{8,20}$" 
                                                                pattern-hint="비밀번호는 최소 1개의 숫자 또는 특수문자를 포함한 영문자 8~20자 입니다."
                                                                v-model="signUpFormData.memberPassword" 
                                                                @input="onInputCheckEvent()" 
                                                            />
                                                        </div>
                                                    </div>
                                                    <small class="input-hint with-label-3"></small>
                                                </div>
                                                <div class="form-group row form-required mb-3">
                                                    <label for="memberRePassword" class="col-sm-3 col-form-label">비밀번호 확인</label>
                                                    <div class="col-sm-9">
                                                        <div class="input-group">
                                                            <input type="password" class="form-control" id="memberRePassword" placeholder="비밀번호를 입력해주세요." autocomplete="new-password" tabindex="2"
                                                                required 
                                                                maxlength="20" 
                                                                pattern="^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{8,20}$" 
                                                                pattern-hint="비밀번호와 똑같은 비밀번호를 입력해주세요."
                                                                v-model="signUpFormData.memberRePassword" 
                                                                @input="onInputCheckEvent()" 
                                                            />
                                                        </div>
                                                    </div>
                                                    <small class="input-hint with-label-3"></small>
                                                </div>

                                                <template v-if="parseInt(signUpFormData.memberAuthority) > 10">
                                                    <div class="form-group row form-required mb-3">
                                                        <label for="memberCompanyName" class="col-sm-3 col-form-label">회사명</label>
                                                        <div class="col-sm-9">
                                                            <div class="input-group">
                                                                <input type="text" class="form-control" id="memberCompanyName" placeholder="회사명을 입력해주세요." autocomplete="off" tabindex="1"
                                                                required 
                                                                maxlength="50" 
                                                                pattern="^[a-zA-Z가-힣]+$"
                                                                pattern-hint="회사명은 한글/영문으로 입력해야 합니다."
                                                                v-model="signUpFormData.memberCompanyName"
                                                                @input="onInputCheckEvent()" 
                                                                />
                                                            </div>
                                                        </div>
                                                        <small class="input-hint with-label-3"></small>
                                                    </div>
                                                    <div class="form-group row form-required mb-3">
                                                        <label for="memberCompanyBiznumber" class="col-sm-3 col-form-label">사업자등록번호</label>
                                                        <div class="col-sm-9">
                                                            <div class="input-group">
                                                                <input type="text" class="form-control" id="memberCompanyBiznumber" placeholder="사업자등록번호를 입력해주세요." autocomplete="off" tabindex="1"
                                                                required 
                                                                maxlength="11" 
                                                                pattern="^[0-9]+$"
                                                                pattern-hint="사업자등록번호는 숫자만 입력해야 합니다."
                                                                v-model="signUpFormData.memberCompanyBiznumber"
                                                                @input="onInputCheckEvent()" 
                                                                />
                                                            </div>
                                                        </div>
                                                        <small class="input-hint with-label-3"></small>
                                                    </div>
                                                </template>

                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-md-5 col-sm-12">
                                                <div class="mt-5 mb-3">
                                                    <button type="submit" class="btn btn-block btn-special-2"
                                                    :disabled="submitButtonDisabled" @mouseenter="onOverSubmitButton"
                                                    >다음</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, ref, onMounted, computed, nextTick } from 'vue';
import { encryptPassword } from '@/helper/plugins/security.js'
import commonMixin from '@/helper/mixins/commonMixin.js'

import Alert from '@/modals/common/Alert'

export default defineComponent({
    name: 'webSignUpStep4Page',
    components: {
		'alert' : Alert,
	},
    setup() {
        const { onHandleValidateInput, store, router, $axiosConnect, notify } = commonMixin()

        const submitButtonDisabled = ref(true)
        const signUpFormRef        = ref(null)
        const signUpFormData       = ref({
            memberAuthority         : '0',
            memberAgreeService      : false,
            memberAgreePolicy       : false,
            memberAgreeSharing      : false,
            memberAgreeReceiving    : false,
            memberEmail             : '',
            memberName              : '',
            memberBirth             : '',
            memberBirthYear         : new Date().getFullYear() - 20,
            memberBirthMonth        : 1,
            memberBirthDay          : 1,
            memberGender            : '',
            memberMobile            : '',
            memberMobileCarrier     : '',
            memberAuthNumber        : '000000',
            memberId                : '',
            memberPassword          : '',
            memberPasswordEncrypted : '',
            memberRePassword        : '',
            memberCompanyName       : '',
            memberCompanyBiznumber  : '',
        })
        const memberIdTemp = ref('ddokdok')
        
        const alertRef            = ref(null)

        /**
         * 입력 값의 유효성을 검사하고, 암호화된 아이디와 비밀번호를 생성하는 메서드
         * 유효성 검사 결과에 따라 submitDisabled 속성을 설정합니다.
         * @method onInputCheckEvent
         * @returns {void}
         */
        const onInputCheckEvent = async () => {
            await nextTick()
            onHandlePasswordEncrypt()
            return !(
                submitButtonDisabled.value = !(
                    onHandleValidateInput(signUpFormRef.value) && (signUpFormData.value.memberPassword === signUpFormData.value.memberRePassword) && (memberIdTemp.value === signUpFormData.value.memberId)
                )
            );
        }

        /**
         * 입력된 비밀번호를 암호화하는 메서드
         * @method onHandleEncrypt
         * @returns {void}
         */
         const onHandlePasswordEncrypt = async () => {
            signUpFormData.value.memberPasswordEncrypted = await encryptPassword(signUpFormData.value.memberPassword)
        }

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onOverSubmitButton = () => {
            const formElements = signUpFormRef.value.querySelectorAll('input, select, textarea')
            formElements.forEach(input => {
                input.blur()
            });
        }

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
         const onClickSignUp = () => {
            if(onInputCheckEvent()){
                const formData = new FormData();
                for (const key in signUpFormData.value) {
                    if(signUpFormData.value[key] !== ''){
                        formData.set(key, signUpFormData.value[key])
                    }
                }

                const memberBirthMonth = signUpFormData.value.memberBirthMonth < 10 ? `0${signUpFormData.value.memberBirthMonth}` : signUpFormData.value.memberBirthMonth;
                const memberBirthDay   = signUpFormData.value.memberBirthDay < 10 ? `0${signUpFormData.value.memberBirthDay}` : signUpFormData.value.memberBirthDay;
                const memberBirth      = `${signUpFormData.value.memberBirthYear}${memberBirthMonth}${memberBirthDay}`;
                
                formData.set('memberBirth',          memberBirth)
                formData.set('memberState',          1)
                formData.set('memberPasswordState',  1)
                formData.set('memberAgreePolicy',    signUpFormData.value.memberAgreePolicy?1:0)
                formData.set('memberAgreeReceiving', signUpFormData.value.memberAgreeReceiving?1:0)
                formData.set('memberAgreeService',   signUpFormData.value.memberAgreeService?1:0)
                formData.set('memberAgreeSharing',   signUpFormData.value.memberAgreeSharing?1:0)
                
                onProcessSignIn(formData)
            }
        }

        /**
         * 로그인 프로세스를 처리하는 메서드
         * @method onProcessSignIn
         * @param {FormData} formData - 로그인에 필요한 폼 데이터
         * @returns {void}
         */
         const onProcessSignIn = async (formData) => {
            try {
                const response = await $axiosConnect('GUEST', '/web/signUp', 'POST', formData)
                if (response.success) {
                    router.replace({
						path: '/signUpStepComplate'
					})
                }else{
                    notify({
                        group : "top-center",
						title : "회원가입 실패",
						text  : response.message,
                        type  : 'error'
					});
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

        const duplicateMemberIdDisabled = computed(() => {
            return memberIdTemp.value === signUpFormData.value.memberId
        })

        const onClickSignUpStep3 = () => {

            signUpFormData.value.memberName              = ''
            signUpFormData.value.memberBirth             = ''
            signUpFormData.value.memberBirthYear         = new Date().getFullYear() - 20,
            signUpFormData.value.memberBirthMonth        = 1
            signUpFormData.value.memberBirthDay          = 1
            signUpFormData.value.memberGender            = ''
            signUpFormData.value.memberMobile            = ''
            signUpFormData.value.memberMobileCarrier     = ''
            signUpFormData.value.memberAuthNumber        = '000000'
            signUpFormData.value.memberId                = ''
            signUpFormData.value.memberPassword          = ''
            signUpFormData.value.memberPasswordEncrypted = ''
            signUpFormData.value.memberRePassword        = ''

            const formDataObj = {}
            for (const key in signUpFormData.value) {
                if(signUpFormData.value[key] !== ''){
                    formDataObj[key] = signUpFormData.value[key]
                }
            }
            store.dispatch('member/setSignUpStep', JSON.parse(JSON.stringify(formDataObj)))

            router.push({ 
                name: 'webSignUpStep3Page', 
                query: { from: 'webSignUpStep4Page' } 
            })
        }

        const onClickDuplicateMemberId = async () => {

            const formData = new FormData();
            for (const key in signUpFormData.value) {
                if(signUpFormData.value[key] !== ''){
                    formData.set(key, signUpFormData.value[key])
                }
            }

            const response = await $axiosConnect('GUEST', '/web/duplicateMemberId', 'POST', formData)
            if (response.body && response.body.memberId) {
                memberIdTemp.value = response.body.memberId
            }
            onInputCheckEvent()
            alertRef.value.open({ 'contents' : response.message })
        }

        const initialize = async () => {
            submitButtonDisabled.value = true
            const getSignUpFormData = computed(() => store.getters["member/getSignUpFormData"])
            Object.assign(signUpFormData.value, getSignUpFormData.value)
        }

        onMounted(async () => {
            await initialize()
        })

        return {
            submitButtonDisabled,
            signUpFormRef,
            signUpFormData,
            duplicateMemberIdDisabled,
            router,
            alertRef,

            onInputCheckEvent,
            onOverSubmitButton,
            onClickSignUp,
            onClickSignUpStep3,
            onClickDuplicateMemberId
        }
    }
});
</script>