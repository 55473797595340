<template>
    <main>
        <div class="container">
			<section class="content-header">
				<div class="container-fluid">
					<div class="row mb-2">
						<div class="col-sm-12">
							<div class="page-tab-title">
								<ul>
                                    <li class="active"><span>MY 페이지</span></li>
									<li @click="router.push({ name: 'webMyInfoPage' })"><span>내 정보</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
            <div class="content">
                <div class="container-fluid">
					<div class="row">
						<div class="col-sm-12">
                            <ul class="tab-list">
								<li @click="router.push({ name: 'webMyPageOrderStatusPage' })"><span>구독현황</span></li>
								<li @click="router.push({ name: 'webMyPageCartPage' })"><span>장바구니</span></li>
								<li @click="router.push({ name: 'webMyPageReviewPage' })"><span>나의 리뷰</span></li>
								<li @click="router.push({ name: 'webMyPageContactPage' })"><span>나의 1:1 문의</span></li>
								<li class="active"><span>나의 전화상담요청</span></li>
								<!--<li @click="router.push({ name: 'webMyPageCouponPage' })"><span>쿠폰</span></li>-->
							</ul>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-md-12">

                            <template v-if="list && list.length > 0">
                                <div class="card" style="min-height: 500px">
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <table class="table table-hover web-table">
                                                <colgroup>
                                                    <col />
                                                    <col width="25%" />
                                                    <col width="15%" />
                                                </colgroup>
                                                <tbody>
                                                    <template v-for="(item, index) in list" :key="index">
                                                        <tr @click="onClickViewPage(item.uid)" role="button">
                                                            <td>
                                                                <span class="badge badge-success ml-1 mb-1" v-if="item.callState === 1">{{ item.converCallState }}</span> 
                                                                <span class="badge badge-secondary ml-1 mb-1" v-if="item.callState === 0">{{ item.converCallState }}</span> 
                                                                <span class="list-subject ml-2">{{ item.convertSubject }}</span>    
                                                            </td>
                                                            <td class="text-sm">전화요청일 : {{ item.convertCallRequestDate }}</td>
                                                            <td class="text-xs text-right">{{ item.convertRegDate }}</td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <ul class="pagination pagination-sm m-0 float-right">
                                                    <li class="page-item" v-if="listCurrentPage !== 1">
                                                        <span class="page-link" role="button" @click="onChangeListPreviousPage">&laquo;</span>
                                                    </li>
                                                    <li 
                                                        v-for="pageNumber in listTotalPages" :key="pageNumber"
                                                        :class="['page-item', { active: parseInt(listCurrentPage) === parseInt(pageNumber) }]"
                                                    >
                                                        <span class="page-link" role="button" @click="onChangeListPage(pageNumber)">{{ pageNumber }}</span>
                                                    </li>
                                                    <li class="page-item" v-if="listCurrentPage < listTotalPages">
                                                        <span class="page-link" role="button" @click="onChangeListNextPage">&raquo;</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </template>
                            <template v-else>
                                <div class="col-md-12 col-sm-12 pl-0 pr-0">
                                    <div class="card mt-1">
                                        <div class="card-body text-center">
                                            나의 전화상담요청이 없습니다.
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="row justify-content-center mt-1">
                                        <div class="col-12 col-sm-12 col-lg-12">
                                            <div class="product-list-paging">
                                                <div class="product-request etc">
                                                    <p class="font-size-xl">똑.독의 다양한 상품을 구경해보세요.</p>
                                                    <hr/>
                                                    <div class="product-request-group">
                                                        <div>
                                                            <span>A~Z까지 선택해서 상품을 찾아보세요.</span>
                                                            <span role="button" @click="router.push({ name: 'webProductCustomPage' })">상품 찾기</span>
                                                        </div>
                                                        <div>
                                                            <span>난, 게이밍 전문가!</span>
                                                            <span role="button" @click="onClickNavigateToProduct('gaming')">게이밍용</span>
                                                        </div>
                                                        <div>
                                                            <span>난, 그래픽/동영상 전문가!</span>
                                                            <span role="button" @click="onClickNavigateToProduct('professional')">전문가용</span>
                                                        </div>
                                                        <div>
                                                            <span>일반 사무, 업무용에는!</span>
                                                            <span role="button" @click="onClickNavigateToProduct('work')">업무용</span>
                                                        </div>
                                                        <div>
                                                            <span>이번 시험은 왠지 100점</span>
                                                            <span role="button" @click="onClickNavigateToProduct('study')">학습용</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>

						</div>
					</div>
                </div>
            </div>
        </div>

    </main>
</template>

<script>
import { defineComponent,onMounted, ref } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr } from '@/helper/utils/dateUtils.js'
import { truncateText } from '@/helper/utils/etcUtils.js'

export default defineComponent({
    name       : 'webMyPageCallRequestPage',
	components: {
	},
    setup() {
		const { $axiosConnect, imgURL, router, store } = commonMixin()

		const list            = ref([])
        const listCurrentPage = ref(1);
        const listPageSize    = ref(10);
        const listTotalPages  = ref(0);
        const listAllCount    = ref(0);
        const listTotalCount  = ref(0);
        const listSearchCount = ref(0);

        const storeCallState = ref({})

		const getList = async () => {
			try {
                let defaultParams = {
                    'page'       : listCurrentPage.value,
                    'pageSize'   : listPageSize.value
                };
                const response = await $axiosConnect('MEMBER', `/web/callRequest`, 'GET', defaultParams);

                if(response.body){
                    list.value            = JSON.parse(JSON.stringify(response.body.list))
                    listAllCount.value    = JSON.parse(JSON.stringify(response.body.allTotal))
                    listTotalCount.value  = JSON.parse(JSON.stringify(response.body.total))
                    listTotalPages.value  = listTotalCount.value > 0 ? Math.ceil(listTotalCount.value / listPageSize.value) : 1
                    listSearchCount.value = list.value.length

					if(listSearchCount.value > 0){
						list.value = list.value.map(list => {
							return {
								...list,
								convertUid             : list.uid.toString().padStart(6, '0'),
								convertSubject         : truncateText(list.subject, 75),
								convertContent         : truncateText(list.content, 75),
                                convertCallRequestDate : convertDateToStr(list.callRequestDate, '', '시분'),
								convertRegMemberName   : truncateText(list.regMemberName, 75),
								convertRegDate         : convertDateToStr(list.regDate),
								convertUpDate          : convertDateToStr(list.upDate),
                                converCallState        : storeCallState.value[list.callState],
							}
						})
					}

                }

            } catch (error) {
                console.error("error:", error)
            }
		}
		const onChangeListPage = (page) => {
            if(page){
                listCurrentPage.value = page
            }
            getList()
        }
        const onChangeListPreviousPage = () => {
            if (listCurrentPage.value > 1) {
                listCurrentPage.value--
                getList()
            }
        }

        const onChangeListNextPage = () => {
            if (listCurrentPage.value < listTotalPages.value) {
                listCurrentPage.value++
                getList()
            }
        }

        const onClickViewPage = (uid) => {
            router.push({ name: 'webMyPageCallRequestDetailPage', params: { uid: uid }, query: { page: listCurrentPage.value } });
        }

        const onClickNavigateToProduct = (searchPurpose) => {
            router.push({ name: 'webProductListPage', params: { searchPurpose: searchPurpose } });
        }

		const initialize = async () => {
            storeCallState.value = store.getters["common/getCallState"]
			await getList()
        }

        onMounted(async () => {
            await initialize()
        })

		return {
			router,
			imgURL,

			list,
			listCurrentPage,
			listTotalPages,

			onChangeListPreviousPage,
			onChangeListPage,
			onChangeListNextPage,
            onClickViewPage,
            onClickNavigateToProduct
        }
    }
});
</script>