<template>
    <div class="header">
        <div class="container">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <div class="page-tab-title">
                                <ul>
                                    <li class="active"><span>아이디 찾기</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="content login-content">
                <div class="container-fluid">
                    <div class="row justify-content-center mt-1 aos-init aos-animate">
                        <div class="col-md-5 col-sm-12 overflow-hidden">
                            <swiper
                                ref="swiperRef"
                                :spaceBetween   = "20"
                                :slidesPerView  = "1"
                                :autoplay="{
                                    delay: 5000,
                                    disableOnInteraction: false
                                }"
                                :loop = swiperLoop
                                :loopedSlides = "1"
                                :modules="modules"
                                @swiper="onSwiper"
                            >
                                <template v-for="(item, index) in productRecommendList" :key="index">
                                    <swiper-slide>
                                        <div class="product-item" data-aos-delay="000" @click="onClickNavigateToProductDetail(item.uid)" role="button">
											<div class="product-inner-item">
                                                <div class="product-item-type">
                                                    <template v-if="item.productSubscribe === 1"><span class="product-item-subscribe-text">구독</span></template>
                                                    <template v-if="item.productRental === 1"><span class="product-item-rental-text">렌탈형 구독</span></template>
                                                    <template v-if="item.productSale === 1"><span class="product-item-sale-text">판매</span></template>
                                                </div>
												<picture class="product-image" style="width: 65%;">
													<template v-if="item.convertProductImages && item.convertProductImages.length > 0 ">
                                                        <img class="absolute-center" 
                                                            :src="`${imgURL}${item.convertProductImages[0].upfilesThumnailPath}/${item.convertProductImages[0].upfilesFileName}`" 
                                                            :alt="item.convertProductName"
                                                        />
                                                    </template>
                                                    <template v-else>
                                                        <img class="absolute-center" 
                                                            src="@/assets/images/common/logo_kor.png"
                                                            :alt="item.convertProductName"
                                                        />
                                                    </template>
												</picture>
												<div class="product-content">
													<h5 class="product-name">{{ item.convertProductName }}</h5>
													<p class="product-notes">{{ item.convertProductDescription }}</p>
													<div class="product-purpose">
                                                        <template v-if="item.productPurposeGaming === 1"><span class="product-purpose-gaming">전문가용</span></template>
                                                        <template v-if="item.productPurposeProfessional === 1"><span class="product-purpose-professional">게이밍용</span></template>
                                                        <template v-if="item.productPurposeStudy === 1"><span class="product-purpose-study">업무용</span></template>
                                                        <template v-if="item.productPurposeWork === 1"><span class="product-purpose-work">학습용</span></template>
                                                    </div>
                                                    <template v-if="item.productSubscribe === 1">
                                                        <div class="product-price-box">
                                                            <span class="product-price-subscribe-text">구독</span>
                                                            <span class="product-price-before">월 {{ item.convertProductSubscribeRegularPrice }}원</span>
                                                            <span class="product-price-after">월 {{ item.convertProductSubscribePrice }}원</span>
                                                        </div>
                                                    </template>
                                                    <template v-else-if="item.productRental === 1">
                                                        <div class="product-price-box">
                                                            <span class="product-price-rental-text">렌탈형 구독</span>
                                                            <span class="product-price-before">월 {{ item.convertProductRentalRegularPrice }}원</span>
                                                            <span class="product-price-after">월 {{ item.convertProductRentalPrice }}원</span>
                                                        </div>
                                                    </template>
                                                    <template v-else-if="item.productSale === 1">
                                                        <div class="product-price-box">
                                                            <span class="product-price-sale-text">판매</span>
                                                            <span class="product-price-before">월 {{ item.convertProductSaleRegularPrice }}원</span>
                                                            <span class="product-price-after">월 {{ item.convertProductSalePrice }}원</span>
                                                        </div>
                                                    </template>
												</div>
											</div>
										</div>
                                    </swiper-slide>
                                </template>
                            </swiper>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-md-5 col-sm-12">
                            <div class="card">
                                <div class="card-body login-card-body">
                                    <p class="login-box-msg">아이디 찾기</p>

                                    <form ref="idFindFormRef" @submit.prevent="onClickIdFind">
                                        <div class="form-group row">
                                            <label for="inputReNewMemberPassword" class="col-sm-3 col-form-label">이름</label>
                                            <div class="col-sm-9">
                                                <div class="input-group">
                                                    <input type="text" class="form-control" id="memberName" placeholder="이름을 입력해주세요." autocomplete="off" tabindex="1"
                                                    required 
                                                    maxlength="50" 
                                                    pattern="^[a-zA-Z가-힣]+$"
                                                    pattern-hint="이름은 한글/영문으로 실명을 입력해야 합니다."
                                                    v-model="idFindFormData.memberName"
                                                    @input="onInputCheckEvent()" 
                                                    />
                                                    <div class="input-group-append">
                                                        <div class="input-group-text">
                                                            <span class="fas fa-id-card"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <small class="input-hint"></small>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="inputReNewMemberPassword" class="col-sm-3 col-form-label">이메일</label>
                                            <div class="col-sm-9">
                                                <div class="input-group">
                                                    <input type="text" class="form-control" id="memberEmail" placeholder="이메일을 입력해주세요." autocomplete="off" tabindex="5"
                                                    required
                                                    maxlength="100" 
                                                    pattern="^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$"
                                                    pattern-hint="이메일을 형식에 맞게 정확히 입력해야 합니다."
                                                    v-model="idFindFormData.memberEmail"
                                                    @blur="onInputCheckEvent()" 
                                                    />
                                                    <div class="input-group-append">
                                                        <div class="input-group-text">
                                                            <span class="fas fa-lock"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <small class="input-hint"></small>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="mt-3 mb-3">
                                                    <button type="submit" class="btn btn-block btn-special-2"
                                                    :disabled="submitButtonDisabled" @mouseenter="onOverSubmitButton"
                                                    >아이디 찾기</button>
                                                </div>
                                            </div>
                                        </div>
                                        <template v-if="idFindText">
                                            <hr />
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="mt-3 mb-3 text-center font-weight-bold">{{ idFindText }}</div>
                                                </div>
                                            </div>
                                        </template>
                                    </form>

                                    <hr class="login-line" />
                                    <div class="mb-1">
                                        <div class="login-footer">
                                            <span role="button" @click="router.push({ name: 'webSignUpStep1Page' })">회원가입</span>
                                            <span role="button" @click="router.push({ name: 'webSignInPage' })">로그인</span>
                                            <span role="button" @click="router.push({ name: 'webPasswordFindPage' })">비밀번호 찾기</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, onMounted, ref, nextTick } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { numberFormat, truncateText  } from '@/helper/utils/etcUtils.js'
import { encryptPassword } from '@/helper/plugins/security.js'
import alert from '@/modals/common/Alert'

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Autoplay, Parallax } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

export default defineComponent({
    name: 'webIdFindPage',
    components: {
        Swiper,
        SwiperSlide,
        'alert' : alert
    },
    setup() {
        const modules = [Navigation, Pagination, Autoplay, Parallax];

        const { onHandleValidateInput, $axiosConnect, router, imgURL } = commonMixin()

        const alertRef             = ref(null)
        const submitButtonDisabled = ref(true)
        const idFindFormRef        = ref(null)
        const idFindFormData       = ref({
            memberName  : '',
            memberEmail : '',
        })
        const idFindText = ref('')

        const productRecommendList    = ref([])
        const swiperRef  = ref(null);
        const swiperLoop = ref(false);

        /**
         * 입력 값의 유효성을 검사하고, 암호화된 아이디와 비밀번호를 생성하는 메서드
         * 유효성 검사 결과에 따라 submitDisabled 속성을 설정합니다.
         * @method onInputCheckEvent
         * @returns {void}
         */
         const onInputCheckEvent = () => {
            onHandlePasswordEncrypt()
            return !(submitButtonDisabled.value = !onHandleValidateInput(idFindFormRef.value))
        }

        /**
         * 입력된 비밀번호를 암호화하는 메서드
         * @method onHandleEncrypt
         * @returns {void}
         */
        const onHandlePasswordEncrypt = async () => {
            idFindFormData.value.memberPasswordEncrypted = await encryptPassword(idFindFormData.value.memberPassword)
        }

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
        const onOverSubmitButton = () => {
            const formElements = idFindFormRef.value.querySelectorAll('input, select, textarea')
            formElements.forEach(input => {
                input.blur()
            });
        }

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
        const onClickIdFind = async () => {
            if(onInputCheckEvent()){
                const formData = new FormData();
                for (const key in idFindFormData.value) {
                    if(idFindFormData.value[key] !== ''){
                        formData.set(key, idFindFormData.value[key])
                    }
                }
                onProcessIdFind(formData)
            }
        }

        /**
         * 로그인 프로세스를 처리하는 메서드
         * @method onProcessIdFind
         * @param {FormData} formData - 로그인에 필요한 폼 데이터
         * @returns {void}
         */
        const onProcessIdFind = async (formData) => {
            try {
                const response = await $axiosConnect('GUEST', '/web/idFind', 'POST', formData)
                idFindText.value = response.message

                idFindFormData.value.memberName = ''
                idFindFormData.value.memberEmail = ''
                
            } catch (error) {
                console.error("error:", error)
            }
        }

        const getProductRecommendList = async () => {
            try {
                let params = {
                    'page'    : 1,
                    'pageSize': 10
                };
                const response = await $axiosConnect('GUEST', `/web/product`, 'GET', params);

                productRecommendList.value = []
                if(response.body){
                    productRecommendList.value = JSON.parse(JSON.stringify(response.body.list))
                }

                productRecommendList.value = productRecommendList.value.map(list => {
                    return {
                        ...list,
                        converUid                           : list.uid.toString().padStart(6, '0'),
                        convertProductName                  : truncateText(list.productName, 75),
                        convertProductDescription           : truncateText(list.productDescription, 65),
                        converRegDate                       : convertDateToStr(list.regDate),
                        converUpDate                        : convertDateToStr(list.upDate),
                        convertProductSubscribeRegularPrice : numberFormat(list.productSubscribeRegularPrice),
                        convertProductSubscribePrice        : numberFormat(list.productSubscribePrice),
                        convertProductRentalRegularPrice    : numberFormat(list.productRentalRegularPrice),
                        convertProductRentalPrice           : numberFormat(list.productRentalPrice),
                        convertProductRentalMonths          : numberFormat(list.productRentalMonths),
                        convertProductRentalMonthPrice      : numberFormat(list.productRentalPrice/list.productRentalMonths),
                        convertProductSaleRegularPrice      : numberFormat(list.productSaleRegularPrice),
                        convertProductSalePrice             : numberFormat(list.productSalePrice),
                        convertProductViewCount             : numberFormat(list.productViewCount),
                        convertProductImages                : list.upfiles.filter(file => file.upfilesColumn === 'productImages'),
                        convertProductDescriptionImages     : list.upfiles.filter(file => file.upfilesColumn === 'productDescriptionImages')
                    }
                })

                nextTick(() => {
                    if (swiperRef.value) {
                        swiperLoop.value = true
                        swiperRef.value.update()
                    }
                })

            } catch (error) {
                console.error("error:", error)
            }
        }

        const onSwiper = (swiper) => {
            swiperRef.value = swiper;
        };

        const onClickNavigateToProductDetail = (uid) => {
            router.push({ name: 'webProductDetailPage', params: { uid } });
        }

        const initialize = async () => {
            idFindFormData.value.memberName = ''
            idFindFormData.value.memberEmail = ''
            idFindText.value = ''

            productRecommendList.value    = []
            
        }

        onMounted(async () => {
            await initialize()
            await getProductRecommendList()
        })

        return {
            modules,
            imgURL,
            productRecommendList,
            swiperRef,
            swiperLoop,

            alertRef,
            submitButtonDisabled,
            idFindFormRef,
            idFindFormData,
            router,
            idFindText,

            onOverSubmitButton,
            onInputCheckEvent,
            onClickIdFind,
            onSwiper,
            onClickNavigateToProductDetail
        };
    }
});
</script>
<style scoped>
.product-item {
    border: 0;
}
</style>