/**
 * 숫자에 천 단위 구분기호를 추가하는 함수입니다.
 * @param {number} num - 천 단위 구분기호를 추가할 숫자
 * @returns {string} - 천 단위 구분기호가 추가된 문자열 형식의 숫자
 */
export const numberFormat = (num) => {
    try {
        if (num === null || num === undefined) {
            return '';
        }

        const cleanedNum = num.toString().replace(/,/g, '');
        const regexp = /\B(?=(\d{3})+(?!\d))/g;
        return cleanedNum.replace(regexp, ',');
    } catch (e) {
        return num;
    }
};

/**
 * 주어진 최대 길이로 텍스트를 자르는 함수입니다.
 * 한글 문자는 2글자로 계산됩니다.
 * @param {string} text - 자를 텍스트
 * @param {number} maxLength - 최대 길이 (문자 수)
 * @returns {string} - 잘린 텍스트. 최대 길이를 초과할 경우 '···' 추가
 */
export const truncateText = (text, maxLength) => {
    if (typeof text !== 'string') {
        return '';
    }

    let length = 0;
    for (let i = 0; i < text.length; i++) {
        const charCode = text.charCodeAt(i);
        if (charCode >= 0xAC00 && charCode <= 0xD7AF) {
            length += 2;
        } else {
            length += 1;
        }

        if (length > maxLength) {
            return text.slice(0, i) + '···';
        }
    }
    return text;
};

/**
 * 주어진 최대 너비를 기준으로 텍스트를 자르는 함수입니다.
 * 텍스트의 픽셀 너비를 기준으로 자르며, 폰트 설정을 적용할 수 있습니다.
 * @param {string} text - 자를 텍스트
 * @param {number} maxWidth - 최대 너비 (픽셀 단위)
 * @param {string} [font='16px Arial'] - 폰트 설정
 * @returns {string} - 잘린 텍스트. 최대 너비를 초과할 경우 '···' 추가
 */
export const truncateTextByPixel = (text, maxWidth, font) => {
    if (typeof text !== 'string') return '';

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font || '16px Arial';

    let width = context.measureText(text).width;

    if (width <= maxWidth) {
        return text;
    }

    let truncatedText = '';
    for (let i = 0; i < text.length; i++) {
        truncatedText += text[i];
        width = context.measureText(truncatedText + '...').width;
        if (width > maxWidth) {
            return truncatedText.slice(0, -1) + '···';
        }
    }

    return text;
};

/**
 * 텍스트를 HTML 엔티티로 변환하는 함수입니다.
 * & < > " ' 문자를 HTML 엔티티로 변환합니다.
 * @param {string} text - HTML 엔티티로 변환할 텍스트
 * @returns {string} - HTML 엔티티로 변환된 텍스트
 */
export const escapeHtmlEntities = (text) => {
    if (typeof text !== 'string') return text;
    return text
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
};

/**
 * HTML 엔티티를 디코딩하는 함수입니다.
 * &amp; &lt; &gt; &quot; &#039; 문자를 원래 문자로 변환합니다.
 * @param {string} text - 디코딩할 HTML 엔티티
 * @returns {string} - 디코딩된 텍스트
 */
export const unescapeHtmlEntities = (text) => {
    if (typeof text !== 'string') return text;
    return text
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'");
};