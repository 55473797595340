<template>
    <main>
        <div class="container">
			<section class="content-header">
				<div class="container-fluid">
					<div class="row mb-2">
						<div class="col-sm-12">
							<div class="page-tab-title">
								<ul>
                                    <li class="active"><span>MY 페이지</span></li>
									<li @click="router.push({ name: 'webMyInfoPage' })"><span>내 정보</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
						<div class="col-sm-12">
                            <ul class="tab-list">
								<li @click="router.push({ name: 'webMyPageOrderStatusPage' })"><span>구독현황</span></li>
								<li @click="router.push({ name: 'webMyPageCartPage' })"><span>장바구니</span></li>
								<li @click="router.push({ name: 'webMyPageReviewPage' })"><span>나의 리뷰</span></li>
								<li class="active"><span>나의 1:1 문의</span></li>
								<li @click="router.push({ name: 'webMyPageCallRequestPage' })"><span>나의 전화상담요청</span></li>
								<!--<li @click="router.push({ name: 'webMyPageCouponPage' })"><span>쿠폰</span></li>-->
							</ul>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-md-12">

                            <div class="card" style="min-height: 500px">
                                <div class="card-body">
                                    <div class="font-weight-bold text-lg">[{{ detail.convertAnswerState }}] {{ detail.subject }}</div>
                                    <div class="mt-1 text-xs">{{ detail.convertRegDate }}</div>
                                    <hr />
                                    <div class="mt-3" v-html="detail.convertContent"></div>
                                </div>
                                <div class="card-footer">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <button class="btn btn-sub-2 float-right" @click="onClickList()">목록</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

						</div>
					</div>
                </div>
            </div>
        </div>
    </main>

    <alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { unescapeHtmlEntities  } from '@/helper/utils/etcUtils.js'

import Alert from '@/modals/common/Alert'

export default defineComponent({
    name       : 'webProductViewPage',
    components: {
		'alert' : Alert,
	},
    props: {
        uid: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const { $axiosConnect, router, store } = commonMixin()
        const alertRef       = ref(null)

		const detail = ref({})
        const storeAnswerState = ref({})

        const getDeatil = async () => {
            try {
                const uid = props.uid
                const response = await $axiosConnect('MEMBER', `/web/contact/${uid}`, 'GET')

                if (response.success && response.body && response.body.list.length > 0) {

                    const list = JSON.parse(JSON.stringify(response.body.list[0]))
                    detail.value = {
                        ...list,
                        convertRegDate     : convertDateToStr(list.regDate),
                        convertUpDate      : convertDateToStr(list.upDate),
                        convertContent     : unescapeHtmlEntities(list.content),
                        convertAnswerState : storeAnswerState.value[list.answerState]
                    }

                }else{
                    alertRef.value.open({ 'contents' : response.message })
                    close()
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const initialize = async () => {
            detail.value = []
        }

        const onClickList = () => {

            const routeQuery = router.currentRoute.value.query || {}
			const page = routeQuery.page || ''

            router.push({ name: 'webMyPageContactPage', query: { page: page } });
        }

        onMounted(async () => {
            storeAnswerState.value = store.getters["common/getAnswerState"]
            await initialize()
            await getDeatil()
        })

        watch(() => props.uid, async () => {
            await initialize();
            await getDeatil();
        });

		return {
            router,
            detail,

            onClickList
        }
    }
});
</script>