<template>
    <div class="login-box">
        <div class="login-logo">
            <!--<b>똑.</b>독-->
            <img src="@/assets/images/common/logo_kor.png" alt="똑.독" style="max-width: 100px;">
        </div>

        <div class="card">
            <div class="card-body login-card-body">
                <p class="login-box-msg">로그인</p>
                <form ref="signInFormRef" @submit.prevent="onClickSignIn">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group mb-3">
                                <div class="input-group">
                                    <label for="inputMemberId" class="d-none">아이디</label>
                                    <input type="text" class="form-control" id="inputMemberId" placeholder="아이디를 입력해주세요." autocomplete="off" tabindex="1"
                                        required 
                                        maxlength="15" 
                                        pattern="^[a-z][a-z0-9]{5,15}$" 
                                        pattern-hint="아이디를 정확히 입력해주세요."
                                        v-model="signInFormData.memberId" 
                                        @blur="onInputCheckEvent" 
                                        @keyup.enter="onClickSignIn"
                                    />
                                    <div class="input-group-append">
                                        <div class="input-group-text">
                                            <span class="fas fa-id-card"></span>
                                        </div>
                                    </div>
                                    <small class="input-hint">.</small>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <div class="input-group">
                                    <label for="inputMemberPassword" class="d-none">비밀번호</label>
                                    <input type="password" class="form-control" id="inputMemberPassword" placeholder="비밀번호를 입력해주세요." autocomplete="new-password" tabindex="2"
                                        required 
                                        maxlength="20" 
                                        pattern="^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{8,20}$" 
                                        pattern-hint="비밀번호를 정확히 입력해주세요."
                                        v-model="signInFormData.memberPassword" 
                                        @blur="onInputCheckEvent" 
                                        @keyup.enter="onClickSignIn"
                                    />
                                    <div class="input-group-append">
                                        <div class="input-group-text">
                                            <span class="fas fa-key"></span>
                                        </div>
                                    </div>
                                    <small class="input-hint"></small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <button type="submit" class="btn btn-primary btn-block" tabindex="3" :disabled="submitButtonDisabled" @mouseenter="onOverSubmitButton">로그인</button>
                        </div>

                    </div>
                </form>
            </div>
        </div>

        <alert ref="alertRef" :title="'알림'" />

    </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { encryptPassword } from '@/helper/plugins/security.js'
import commonMixin from '@/helper/mixins/commonMixin.js'
import alert from '@/modals/common/Alert'

export default defineComponent({
    name : 'adminSignPage',
    mounted() {
        document.body.classList.add('login-page')
    },
    components: {
        'alert' : alert
	},
    setup(){

        const { onHandleValidateInput, $axiosConnect, store, notify } = commonMixin()

        const alertRef             = ref(null)
        const submitButtonDisabled = ref(true)
        const signInFormRef        = ref(null)
        const signInFormData       = ref({
            memberId                : '',
            memberPassword          : '',
            memberPasswordEncrypted : ''
        })

        /**
         * 입력 값의 유효성을 검사하고, 암호화된 아이디와 비밀번호를 생성하는 메서드
         * 유효성 검사 결과에 따라 submitDisabled 속성을 설정합니다.
         * @method onInputCheckEvent
         * @returns {void}
         */
         const onInputCheckEvent = () => {
            return !(submitButtonDisabled.value = !onHandleValidateInput(signInFormRef.value))
        }

        /**
         * 입력된 비밀번호를 암호화하는 메서드
         * @method onHandleEncrypt
         * @returns {void}
         */
        const onHandlePasswordEncrypt = async () => {
            signInFormData.value.memberPasswordEncrypted = await encryptPassword(signInFormData.value.memberPassword.trim())
        }

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
        const onOverSubmitButton = () => {
            const formElements = signInFormRef.value.querySelectorAll('input, select, textarea')
            formElements.forEach(input => {
                input.blur()
            });
        }

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
        const onClickSignIn = async () => {
            await onHandlePasswordEncrypt()

            if(onInputCheckEvent()){
                const formData = new FormData();
                for (const key in signInFormData.value) {
                    if(signInFormData.value[key] !== ''){
                        formData.set(key, signInFormData.value[key].trim())
                    }
                }
                onProcessSignIn(formData)
            }
        }

        /**
         * 로그인 프로세스를 처리하는 메서드
         * @method onProcessSignIn
         * @param {FormData} formData - 로그인에 필요한 폼 데이터
         * @returns {void}
         */
        const onProcessSignIn = async (formData) => {
            try {
                const response = await $axiosConnect('NORMAL', '/admin/signIn', 'POST', formData)
                if (response.success) {
                    store.dispatch('member/signIn', response.body);
                    location.href="/admin"
                }else{
                    //alertRef.value.open({ 'contents' : response.message })
                    notify({
                        group : "top-center",
						title : "로그인 실패",
						text  : response.message,
                        type  : 'error'
					});
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

        onMounted(() => {
            //window.location.reload();
        })

        return {
            alertRef,
            submitButtonDisabled,
            signInFormRef,
            signInFormData,

            onOverSubmitButton,
            onInputCheckEvent,
            onClickSignIn
        }

    }
});
</script>