<template>
    <main>
        <div class="container">
			<section class="content-header">
				<div class="container-fluid">
					<div class="row mb-2">
						<div class="col-sm-12">
							<div class="page-tab-title">
								<ul>
									<li class="active" @click="router.push({ name: 'webNoticeListPage' })"><span>공지사항</span></li>
                                    <li @click="router.push({ name: 'webFaqListPage' })"><span>FAQ</span></li>
                                    <li @click="router.push({ name: 'webContactPage' })"><span>1:1 문의</span></li>
                                    <li @click="router.push({ name: 'webCallRequestPage' })"><span>전화상담요청</span></li>
                                    <li><span>챗봇</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
            <div class="content">
                <div class="container-fluid">
					<div class="row">
						<div class="col-md-12">

                            <div class="card">
                                <div class="card-body">
                                    <table class="table table-hover">
                                        <colgroup>
                                            <col />
                                            <col width="15%" />
                                        </colgroup>
                                        <tbody>
                                            <template v-for="(item, index) in list" :key="index">
                                                <tr @click="onClickNavigateToView(item)" role="button">
                                                    <td>{{ item.convertSubject }}</td>
                                                    <td class="text-xs text-right">{{ item.convertRegDate }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-footer">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <ul class="pagination pagination-sm m-0">
                                                <li class="page-item" v-if="listCurrentPage !== 1">
                                                    <span class="page-link" role="button" @click="onChangeListPreviousPage">&laquo;</span>
                                                </li>
                                                <li 
                                                    v-for="pageNumber in listTotalPages" :key="pageNumber"
                                                    :class="['page-item', { active: parseInt(listCurrentPage) === parseInt(pageNumber) }]"
                                                >
                                                    <span class="page-link" role="button" @click="onChangeListPage(pageNumber)">{{ pageNumber }}</span>
                                                </li>
                                                <li class="page-item" v-if="listCurrentPage < listTotalPages">
                                                    <span class="page-link" role="button" @click="onChangeListNextPage">&raquo;</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

						</div>
					</div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { truncateText  } from '@/helper/utils/etcUtils.js'

export default defineComponent({
    name       : 'webProductListPage',
    setup() {
        const { $axiosConnect, router } = commonMixin()

		const list              = ref([])

        const listCurrentPage       = ref(1);
        const listPageSize          = ref(10);
        const listTotalPages        = ref(0);
        const listAllCount          = ref(0);
        const listTotalCount        = ref(0);
        const listSearchCount       = ref(0);
        const queryStringParams     = ref({})
        const applySearchParams     = ref({})

		const imgURL = ref(process.env.VUE_APP_BASE_IMG_URL)

		const getList = async () => {
            try {
                let defaultParams = {
                    'page'     : listCurrentPage.value,
                    'pageSize' : listPageSize.value
                };
                applySearchParams.value = { ...queryStringParams.value }
                let params = Object.assign({}, defaultParams, queryStringParams.value);
                const response = await $axiosConnect('GUEST', `/web/notice`, 'GET', params);

                if(response.body){
                    list.value            = JSON.parse(JSON.stringify(response.body.list))
                    listAllCount.value    = JSON.parse(JSON.stringify(response.body.allTotal))
                    listTotalCount.value  = JSON.parse(JSON.stringify(response.body.total))
                    listTotalPages.value  = listTotalCount.value > 0 ? Math.ceil(listTotalCount.value / listPageSize.value) : 1
                    listSearchCount.value = list.value.length
                }

                if(listSearchCount.value > 0){
                    list.value = list.value.map(list => {
                        return {
                            ...list,
                            convertUid                               : list.uid.toString().padStart(6, '0'),
                            convertSubject                          : truncateText(list.subject, 75),
                            convertRegDate                           : convertDateToStr(list.regDate),
                            convertUpDate                            : convertDateToStr(list.upDate),
                        }
                    })
                }

            } catch (error) {
                console.error("error:", error)
            }
        }
        
        const onChangeListPage = (page) => {
            if(page){
                listCurrentPage.value = page
            }
            getList()
        }
        const onChangeListPreviousPage = () => {
            if (listCurrentPage.value > 1) {
                listCurrentPage.value--
                getList()
            }
        }

        const onChangeListNextPage = () => {
            if (listCurrentPage.value < listTotalPages.value) {
                listCurrentPage.value++
                getList()
            }
        }
        
        const onGetSelectListTotalPages = computed(() => {
            const pages = []
            if (listTotalPages.value <= 20) {
                for (let i = 1; i <= listTotalPages.value; i++) {
                    pages.push(i)
                }
            } else {
                for (let i = 1; i <= 20; i++) {
                    pages.push(i)
                }
                for (let i = 30; i <= listTotalPages.value; i += 10) {
                    pages.push(i)
                }
                if (listCurrentPage.value > 20 && !pages.includes(listCurrentPage.value)) {
                    pages.push(listCurrentPage.value)
                }
            }
            return pages
        })

        const onClickNavigateToView = (item) => {
            const uid = item.uid
            router.push({ name: 'webNoticeDetailPage', params: { uid }, query: { page: listCurrentPage.value } });
        }

        const initialize = async () => {
            list.value              = []
            listTotalCount.value    = 0
            listSearchCount.value   = 0
            applySearchParams.value = {}
            queryStringParams.value = {}

            const routeQuery = router.currentRoute.value.query || {}
			const page = routeQuery.page || ''
            if(page){
                onChangeListPage(page)
            }
        }

        onMounted(async () => {
            await initialize()
            await getList()
        })

		return {
            imgURL,
            router,
            list,

            listCurrentPage,
            listPageSize,
            listTotalPages,
            listAllCount,
            listTotalCount,
            listSearchCount,
            onGetSelectListTotalPages,

            onChangeListPage,
            onChangeListPreviousPage,
            onChangeListNextPage,
            onClickNavigateToView,
        
        }
    }
});
</script>