<template>
    <div class="header">
        <div class="container">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <div class="page-tab-title">
                                <ul>
                                    <li class="active"><span>회원가입</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2 justify-content-center">
                        <div class="col-md-5 col-sm-12">
                            <div class="content-header-steplist">
                                <div class="steplist-step active">
                                    <span class="steplist-step-circle">1</span>
                                    <span class="steplist-step-label">유형선택</span>
                                </div>
                                <div class="steplist-step">
                                    <span class="steplist-step-circle">2</span>
                                    <span class="steplist-step-label">약관동의</span>
                                </div>
                                <div class="steplist-step">
                                    <span class="steplist-step-circle">3</span>
                                    <span class="steplist-step-label">인증</span>
                                </div>
                                <div class="steplist-step">
                                    <span class="steplist-step-circle">4</span>
                                    <span class="steplist-step-label">정보입력</span>
                                </div>
                                <div class="steplist-step">
                                    <span class="steplist-step-circle">5</span>
                                    <span class="steplist-step-label">가입완료</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="content login-content">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-sm-12">
                            <div class="card" style="padding-left: 20%; padding-right: 20%;">
                                <div class="card-body join-card-body">
                                    <p class="login-box-msg">가입유형</p>

                                    <form ref="signUpFormRef" @submit.prevent="onClickSignUp">
                                        <div class="row justify-content-center">
                                            <div class="col-md-5 col-sm-12">
                                                <div class="form-group row mb-3">
                                                    <div class="col-sm-12">
                                                        <div class="input-group">
                                                            <label class="input-label input-radio-button" for="user_10">
                                                                <input type="radio"
                                                                    id="user_10"
                                                                    name="memberAuthority"
                                                                    value="10"
                                                                    required 
                                                                    v-model="signUpFormData.memberAuthority" 
                                                                    @input="onInputCheckEvent()" 
                                                                />
                                                                <span class="label-text">개인회원</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row mb-3">
                                                    <div class="col-sm-12">
                                                        <div class="input-group">
                                                            <label class="input-label input-radio-button" for="user_11">
                                                                <input type="radio"
                                                                    id="user_11"
                                                                    name="memberAuthority"
                                                                    value="11"
                                                                    v-model="signUpFormData.memberAuthority" 
                                                                    @input="onInputCheckEvent()" 
                                                                />
                                                                <span class="label-text">기업회원(개인)</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row mb-3">
                                                    <div class="col-sm-12">
                                                        <div class="input-group">
                                                            <label class="input-label input-radio-button" for="user_12">
                                                                <input type="radio"
                                                                    id="user_12"
                                                                    name="memberAuthority"
                                                                    value="12"
                                                                    v-model="signUpFormData.memberAuthority" 
                                                                    @input="onInputCheckEvent()" 
                                                                />
                                                                <span class="label-text">기업회원(법인)</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-md-5 col-sm-12">
                                                <div class="mt-5 mb-3">
                                                    <button type="submit" class="btn btn-block btn-special-2"
                                                    :disabled="submitButtonDisabled" @mouseenter="onOverSubmitButton"
                                                    >다음</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed, nextTick } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'

export default defineComponent({
    name: 'webSignUpStep1Page',
    setup() {
        const { onHandleValidateInput, store, router } = commonMixin()

        const submitButtonDisabled = ref(true)
        const signUpFormRef        = ref(null)
        const signUpFormData       = ref({
            memberAuthority         : '0',
            memberAgreeService      : false,
            memberAgreePolicy       : false,
            memberAgreeSharing      : false,
            memberAgreeReceiving    : false,
            memberName              : '',
            memberEmail             : '',
            memberBirth             : '',
            memberBirthYear         : new Date().getFullYear() - 20,
            memberBirthMonth        : 1,
            memberBirthDay          : 1,
            memberGender            : '',
            memberMobile            : '',
            memberMobileCarrier     : '',
            memberAuthNumber        : '000000',
            memberId                : '',
            memberPassword          : '',
            memberPasswordEncrypted : '',
            memberRePassword        : '',
        })

        /**
         * 입력 값의 유효성을 검사하고, 암호화된 아이디와 비밀번호를 생성하는 메서드
         * 유효성 검사 결과에 따라 submitDisabled 속성을 설정합니다.
         * @method onInputCheckEvent
         * @returns {void}
         */
        const onInputCheckEvent = async () => {
            await nextTick();
            return !(submitButtonDisabled.value = !onHandleValidateInput(signUpFormRef.value))
        }

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onOverSubmitButton = () => {
            const formElements = signUpFormRef.value.querySelectorAll('input, select, textarea')
            formElements.forEach(input => {
                input.blur()
            });
        }

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
        const onClickSignUp = async () => {
            if(onInputCheckEvent()){
                const formDataObj = {}
                for (const key in signUpFormData.value) {
                    if(signUpFormData.value[key] !== ''){
                        formDataObj[key] = signUpFormData.value[key]
                    }
                }
                store.dispatch('member/setSignUpStep', JSON.parse(JSON.stringify(formDataObj)))
                router.replace({ name: 'webSignUpStep2Page' })
            }
        }

        const initialize = async () => {
            submitButtonDisabled.value = true

            if(store.getters["common/getPreviousPage"] === 'webSignUpStep2Page'){
                const getSignUpFormData = computed(() => store.getters["member/getSignUpFormData"])
                Object.assign(signUpFormData.value, getSignUpFormData.value)
            }else{
                signUpFormData.value = {
                    memberAuthority         : '0',
                    
                    memberAgreeService      : false,
                    memberAgreePolicy       : false,
                    memberAgreeSharing      : false,
                    memberAgreeReceiving    : false,

                    memberEmail             : '',
                    memberName              : '',
                    memberBirth             : '',
                    memberBirthYear         : new Date().getFullYear() - 20,
                    memberBirthMonth        : 1,
                    memberBirthDay          : 1,
                    memberGender            : '',
                    memberMobile            : '',
                    memberMobileCarrier     : '',
                    memberAuthNumber        : '',
                    memberId                : '',
                    memberPassword          : '',
                    memberPasswordEncrypted : '',
                    memberRePassword        : '',
                }
            }
            onInputCheckEvent()
        }

        onMounted(async () => {
            await initialize()
        })

        return {
            submitButtonDisabled,
            signUpFormRef,
            signUpFormData,

            onInputCheckEvent,
            onOverSubmitButton,
            onClickSignUp
        }
    }
});
</script>