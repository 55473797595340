<template>
    <nav class="main-header navbar navbar-expand-md">
        <div class="container">
            <span href="/" class="navbar-brand" role="button" @click="router.push({ name: 'webMainPage' })">
                <img src="@/assets/images/common/logo_kor.png" alt="똑독" class="brand-image">
            </span>
            <button class="navbar-toggler order-1" type="button" @click="onClickToggleNavbar">
                <i class="fas fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse order-3 mobile-menu" :class="{'show': navbarCollapsed}">
                <ul class="navbar-nav">
                    <li class="nav-item mobile-show"><span @click="onClickToggleNavbar"><i class="fas fa-xmark"></i></span></li>
                    <li class="nav-item"><span class="nav-link font-weight-bold" role="button" @click="onClickNavigateToProduct('all')">전체상품</span></li>
                    <li class="nav-item"><span class="nav-link font-weight-bold" role="button" @click="onClickNavigateToProduct('gaming')">게이밍용</span></li>
                    <li class="nav-item"><span class="nav-link font-weight-bold" role="button" @click="onClickNavigateToProduct('professional')">전문가용</span></li>
                    <li class="nav-item"><span class="nav-link font-weight-bold" role="button" @click="onClickNavigateToProduct('work')">업무용</span></li>
                    <li class="nav-item"><span class="nav-link font-weight-bold" role="button" @click="onClickNavigateToProduct('study')">학습용</span></li>
                    <li class="nav-item"><span class="nav-link font-weight-bold" role="button" @click="router.push({ name: 'webEventListPage' })">이벤트</span></li>
                    <li class="nav-item"><span class="nav-link font-weight-bold" role="button" @click="router.push({ name: 'webNoticeListPage' })">고객센터</span></li>
                    <li class="nav-item mobile-show" v-if="!storeSignInState"><span class="nav-link font-weight-bold" role="button" @click="router.push({ name: 'webSignInPage' })">로그인</span></li>
                    <li class="nav-item mobile-show" v-if="!storeSignInState"><span class="nav-link font-weight-bold" role="button" @click="router.push({ name: 'webSignUpStep1Page' })">회원가입</span></li>
                    <li class="nav-item mobile-show" v-if="storeSignInState"><span class="nav-link font-weight-bold" role="button" @click="onClickNavigateToMyPage()">MY 페이지</span></li>
                    <li class="nav-item mobile-show" v-if="storeSignInState"><span class="nav-link font-weight-bold" role="button" @click="onClickSignOut()">로그아웃</span></li>
                </ul>
            </div>

            <ul class="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto align-items-center top-bar">
                <li class="nav-item nav-item-search">
                    <form class="form-inline ml-0 ml-md-3" @submit.prevent="onClickNavigateToProductSearch()">
                        <div class="input-group input-group-sm input-nav">
                            <input class="form-control form-control-navbar" type="search" placeholder="상품 검색" v-model="searchText">
                            <div class="input-group-append">
                                <button class="btn btn-navbar" type="button" @click="onClickNavigateToProductSearch()">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </li>
                <li class="nav-item" v-if="!storeSignInState">
                    <span class="nav-link nav-link-right" role="button" @click="router.push({ name: 'webSignInPage' })"><i class="far fa-circle-user font-size-lg"></i></span>
                </li>
                <li class="nav-item" v-if="!storeSignInState">
                    <span class="nav-link nav-link-right pr-0 pl-0" role="button" @click="router.push({ name: 'webSignUpStep1Page' })"><i class="fas fa-user-plus font-size-lg"></i></span>
                </li>
                <li class="nav-item" v-if="storeSignInState">
                    <span class="nav-link nav-link-right pr-0" role="button" @click="onClickNavigateToMyPage()"><i class="fas fa-circle-user font-size-lg"></i></span>
                </li>
                <li class="nav-item" v-if="storeSignInState">
                    <span class="nav-link nav-link-right pr-0" role="button" @click="onClickSignOut()"><i class="fas fa-right-from-bracket font-size-lg"></i></span>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'

export default defineComponent({
    name : 'defaultHeaderPage',
    components: {},
    setup() {
        const { $axiosConnect, router, storeSignInState, store } = commonMixin()

        const searchText = ref("")
        const navbarCollapsed = ref(false);

        const onClickNavigateToProduct = (searchPurpose) => {
            router.push({ name: 'webProductListPage', params: { searchPurpose: searchPurpose } });
        }

        const onClickNavigateToProductSearch = () => {
            if(searchText.value){
                router.push({ name: 'webProductSearchPage', query: { search: searchText.value } });
                searchText.value = ''
            }else{
                alert("검색어를 입력해주세요.")
            }
        }

        const onClickNavigateToMyPage = () => {
            router.push({ name: 'webMyPageOrderStatusPage' })
        }

        const onClickSignOut = async () => {
            try {
                await $axiosConnect('MEMBER', '/web/signOut', 'GET')
                store.dispatch('member/signOut');
                router.replace({
                    path: '/'
                })
                
            } catch (error) {
                console.error("error:", error)
            }
        }

        const onClickToggleNavbar = () => {
            navbarCollapsed.value = !navbarCollapsed.value;
        }

        const initialize = async () => {
            document.body.classList.add('layout-top-nav')
            searchText.value = ""
        }

        onMounted(async () => {
            await initialize()
        })

        watch(() => router.currentRoute.value, () => {
            navbarCollapsed.value = false
        });

        return {
            router,
            searchText,
            storeSignInState,
            navbarCollapsed,

            onClickNavigateToProduct,
            onClickNavigateToProductSearch,
            onClickSignOut,
            onClickNavigateToMyPage,
            onClickToggleNavbar
        }
    }
});
</script>