<template>
    <Modal ref="baseModal" :title="`제품 상세보기`" :width="`800px`">
        <template #body>
            <div class="form-group row form-required">
                <label for="inputItemType" class="col-sm-2 col-form-label">제품구분</label>
                <div class="col-sm-10">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-check-circle"></i></span>
                        </div>
                        <div class="form-control">
                            <label v-for="(item, key) in storeItemType" :key="key" class="input-label mr-3" :for="`itemType_${key}`">
                                <input type="radio"
                                    :id="`itemType_${key}`"
                                    name="itemType"
                                    :value="key"
                                    v-model="itemFormData.itemType"
                                    disabled
                                />
                                <span class="radio-icon"></span>
                                <span class="label-text">{{ item }}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <template v-for="(item, index) in itemFormData.convertItemCategory" :key="index">
                <div class="form-group row">
                    <label for="inputItemCategory" class="col-sm-2 col-form-label">
                        카테고리({{ index + 1 }}) 
                    </label>
                    <div class="col-sm-10">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-list-check"></i></span>
                            </div>
                            <span class="form-control">
                                {{ getCategoryName(item.parentUid) }}
                                {{ item.childUid ? ' > ' + getCategoryName(item.childUid) : '' }}
                                {{ item.thirdUid ? ' > ' + getCategoryName(item.thirdUid) : '' }}
                            </span>
                        </div>
                    </div>
                </div>
            </template>
            <div class="form-group row form-required">
                <label for="inputAdministratorName" class="col-sm-2 col-form-label">제품명</label>
                <div class="col-sm-10">
                    <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                        <span class="form-control">{{ itemFormData.itemName }}</span>
                    </div>
                </div>
            </div>
            <div class="form-group row form-required">
                <label for="inputItemState" class="col-sm-2 col-form-label">사용여부</label>
                <div class="col-sm-10">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-check-circle"></i></span>
                        </div>
                        <div class="form-control">
                            <label v-for="(item, key) in storeState" :key="key" class="input-label mr-3" :for="`state_${key}`">
                                <input type="radio"
                                    :id="`state_${key}`"
                                    name="itemState"
                                    :value="key"
                                    v-model="itemFormData.itemState"
                                    disabled
                                />
                                <span class="radio-icon"></span>
                                <span class="label-text">{{ item }}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="form-group row">
                <label for="inputAdministratorName" class="col-sm-2 col-form-label">제품이미지</label>
                <div class="col-sm-10">
                    <template v-if="Array.isArray(itemFormData.convertItemImages) && itemFormData.convertItemImages.length > 0">
                        <div class="file-upload">
                            <div class="file-preview">
                                <div v-for="(file, index) in itemFormData.convertItemImages" :key="index" class="file-preview-item">
                                    <div class="file-preview-image-box">
                                        <template v-if="onHandleIsImage(file.upfilesExt)">
                                            <img :src="`${imgURL}${file.upfilesThumnailPath}/${file.upfilesFileName}`" :alt="file.name" class="file-preview-image" />
                                        </template>
                                        <template v-else>
                                            <div class="file-preview-file"><i class="fas fa-file-alt"></i></div>
                                        </template>
                                    </div>
                                    <p class="mb-0 file-name">{{ file.upfilesOriginalName }}.{{ file.upfilesExt }}</p>
                                    <p class="mb-0">({{ file.upfilesSize }} KB)</p>
                                    <button type="submit" class="btn btn-primary btn-xs" @click="onClickUpfileDownload(file)">다운로드</button>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-file-upload"></i></span>
                            </div>
                            <span class="form-control">첨부된 파일이 없습니다.</span>
                        </div>
                    </template>
                </div>
            </div>
            <div class="form-group row">
                <label for="inputContent" class="col-sm-2 col-form-label">비고</label>
                <div class="col-sm-10">
                    <div class="input-content text-sm" v-html="itemFormData.convertItemNotes"></div>
                </div>
            </div>
            <hr />
            <div class="form-group row form-required">
                <label for="inputAdministratorName" class="col-sm-2 col-form-label">등록정보</label>
                <div class="col-sm-10">
                    <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-user-pen"></i></span></div>
                        <span class="form-control justify-content-between">
                            <span>{{ itemFormData.regMemberName }} ({{ itemFormData.regMemberId }})</span>
                            <span>IP : {{ itemFormData.regIp }}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="form-group row form-required">
                <label for="inputAdministratorName" class="col-sm-2 col-form-label">등록일</label>
                <div class="col-sm-10">
                    <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-calendar-day"></i></span></div>
                        <span class="form-control">{{ itemFormData.convertRegDate }}</span>
                    </div>
                </div>
            </div>
            <div class="form-group row form-required" v-if="itemFormData.upMemberName">
                <label for="inputAdministratorName" class="col-sm-2 col-form-label">수정정보</label>
                <div class="col-sm-10">
                    <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-user-pen"></i></span></div>
                        <span class="form-control justify-content-between">
                            <span>{{ itemFormData.upMemberName }} ({{ itemFormData.upMemberId }})</span>
                            <span>IP : {{ itemFormData.upIp }}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="form-group row form-required" v-if="itemFormData.convertUpDate">
                <label for="inputAdministratorName" class="col-sm-2 col-form-label">수정일</label>
                <div class="col-sm-10">
                    <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-calendar-day"></i></span></div>
                        <span class="form-control">{{ itemFormData.convertUpDate }}</span>
                    </div>
                </div>
            </div>
        </template>

        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">닫기</button>
			<button type="submit" class="btn btn-primary" @click="onClickMovementUpdate">수정</button>
        </template>
	</Modal>

	<alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { unescapeHtmlEntities  } from '@/helper/utils/etcUtils.js'
import Modal from '@/modals/Modal'
import Alert from '@/modals/common/Alert'

export default defineComponent({
    name       : 'modalItemDetailPage',
    components: {
		Modal,
		'alert' : Alert,
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){
		const { openModal, closeModal, baseModal, $axiosConnect, $fileDownload, storeState, storeItemType, imgURL, onHandleIsImage } = commonMixin()

        const mode           = ref('detail')
        const closeModalData = ref({})
        const alertRef       = ref(null)

        const itemFormData   = ref({})
        const categoryList    = ref([])

		const open = (payload) => {
            initialize()

            if (payload.item && payload.mode === 'detail') {
                itemFormData.value = JSON.parse(JSON.stringify(payload.item))
                mode.value = payload.mode
                getDeatil()
            }
            return openModal()
		};

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			closeModal(closeModalData.value)
		};

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
        const onClickMovementUpdate = () => {
            closeModalData.value = {
                isUpdate : true,
                item     : itemFormData.value
            }
            close()
        }

        const getDeatil = async () => {
            try {
                const uid = itemFormData.value.uid
                const response = await $axiosConnect('MEMBER', `/admin/item/${uid}`, 'GET')

                if (response.success && response.body && response.body.list.length > 0) {

                    const list = JSON.parse(JSON.stringify(response.body.list[0]))
                    itemFormData.value = {
                        ...list,
                        converUid           : list.uid.toString().padStart(8, '0'),
                        convertRegDate      : convertDateToStr(list.regDate),
                        convertUpDate       : convertDateToStr(list.upDate),
                        convertItemImages   : list.upfiles.filter(file => file.upfilesColumn === 'itemImages'),
                        convertItemNotes    : list.itemNotes?unescapeHtmlEntities(list.itemNotes):'&nbsp;',
                        convertItemCategory : list.itemCategoryAll ? list.itemCategoryAll.split(',').map(item => { const [parentUid, childUid, thirdUid] = item.split('/').map(Number); return { parentUid, childUid, thirdUid }; }).filter(item => item.parentUid || item.childUid || item.thirdUid) : [],
                    }

                }else{
                    alertRef.value.open({ 'contents' : response.message })
                    close()
                }
            } catch (error) {
                console.error("error:", error)
            }

        }

        const onClickUpfileDownload = async (upfile) => {
            try {
                const uid = upfile.uid
                await $fileDownload(upfile.upfilesOriginalName + '.' + upfile.upfilesExt, `/common/uploads/download/${uid}`)
            } catch (error) {
                console.error("error:", error)
            }
        }

        const getCategoryList = async () => {
            try {
                let defaultParams = {
                    'page'      : 1,
                    'pageSize'  : 1000,
                    'compositionState' : 1
                }
                const response = await $axiosConnect('MEMBER', `/admin/categoryItem`, 'GET', defaultParams)

                let list = []
                if(response.body){
                    list = JSON.parse(JSON.stringify(response.body.list))
                    if(list.length > 0){
                        categoryList.value = list.map(({ uid, categoryName, groups, depth }) => {
                            return {
                                categoryUid    : uid,
                                categoryName   : categoryName,
                                categoryGroups : groups,
                                categoryDepth  : depth
                            }
                        })
                    }
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const getCategoryName = (uid) => {
            const category = categoryList.value.find(category => category.categoryUid === uid)
            if(category){
                return category.categoryName
            }
            return ''
        }

        const initialize = async () => {
            itemFormData.value = {
                itemName   : '',
                itemNotes  : '',
                itemState  : 1,
                itemImages : []
            }

            closeModalData.value = {
                isUpdate : false,
                item     : ''
            }

            await getCategoryList()
        }

        onMounted(async () => {
            await initialize()
        })

        return {
			open,
			close,
            mode,

			alertRef,
			itemFormData,
            categoryList,

            onClickMovementUpdate,
            onClickUpfileDownload,
            getCategoryName,

            //commonMixin
            baseModal, 
            imgURL,
            storeState,
            storeItemType,
            onHandleIsImage
		}
    }
});
</script>