<template>
    <form ref="orderPaymentFormRef" @submit.prevent="onClickPaymentCheck">
        <div class="header">
            <div class="container">
                <section class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-12">
                                <div class="page-tab-title">
                                    <ul>
                                        <li class="active"><span>{{ orderPaymentTitle }}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="content order-content">
                    <div class="container-fluid">
                        <div class="row justify-content-center">
                            <template v-for="(item, index) in order.productFormData" :key="index">
                                <div class="col-md-8 col-sm-12">
                                    <div class="product-item" data-aos-delay="000" >
                                        <div class="product-inner-item">
                                            <picture class="product-image">
                                                <template v-if="item.convertProductImages && item.convertProductImages.length > 0 ">
                                                    <img class="absolute-center" 
                                                        :src="`${imgURL}${item.convertProductImages[0].upfilesThumnailPath}/${item.convertProductImages[0].upfilesFileName}`" 
                                                        :alt="item.convertProductName"
                                                    />
                                                </template>
                                                <template v-else>
                                                    <img class="absolute-center" 
                                                        src="@/assets/images/common/logo_kor.png"
                                                        :alt="item.convertProductName"
                                                    />
                                                </template>
                                            </picture>
                                            <div class="product-content">
                                                <h5 class="product-name">{{ item.convertProductName }}</h5>
                                                <p class="product-notes">{{ item.productDescription }}</p>
                                                <hr />
                                                <div class="row text-sm">
                                                    <div class="col-sm-2">기본사항</div>
                                                    <div class="col-sm-10">{{ item.productDefaultItems[0].productItemName }}</div>
                                                </div>
                                                <hr />
                                                <div class="row text-sm">
                                                    <div class="col-sm-2">추가옵션</div>
                                                    <div class="col-sm-10">
                                                        <template v-for="(optionItem, optionIndex) in item.productOptionItems" :key="optionIndex">
                                                        {{ optionItem.productItemName }}
                                                        <span v-if="optionIndex !== item.productOptionItems.length - 1"> / </span>
                                                        </template>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div v-show="item.productServiceItems.length > 0">
                                                    <div class="row text-sm">
                                                        <div class="col-sm-2">서비스</div>
                                                        <div class="col-sm-10">
                                                            <template v-for="(serviceItem, serviceIndex) in item.productServiceItems" :key="serviceIndex">
                                                            {{ serviceItem.productItemName }}
                                                            <span v-if="serviceIndex !== item.productServiceItems.length - 1"> / </span>
                                                            </template>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="order-payment-text" v-html="orderPaymentText"  v-if="productDistributor==='ddokdok'"></div>

            <div class="container">
                <div class="content order-content">
                    <div class="container-fluid">
                        
                        <!--
                        <div class="row justify-content-center">
                            <div class="col-md-8 col-sm-12">
                                <div class="card">
                                    <div class="card-body join-card-body">
                                        <p class="login-box-msg">구독 할인 선택</p>
                                        <div class="row justify-content-center">
                                            <div class="col-md-12 col-sm-12">////</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-8 col-sm-12">
                                <div class="card">
                                    <div class="card-body join-card-body">
                                        <p class="login-box-msg">리워드 할인 선택</p>
                                        <div class="row justify-content-center">
                                            <div class="col-md-12 col-sm-12">////</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        -->
                        <div class="row justify-content-center" v-if="productDistributor==='ddokdok'">
                            <div class="col-md-8 col-sm-12">
                                <div class="card">
                                    <div class="card-body join-card-body">
                                        <p class="login-box-msg">수령 정보 입력</p>
                                        <div class="row justify-content-center">
                                            <div class="col-md-12 col-sm-12">
                                                <div class="form-group row form-required mb-3">
                                                    <label class="col-sm-3">&nbsp;</label>
                                                    <div class="col-sm-9">
                                                        <div class="">
                                                            <div class="input-group">
                                                                <label class="input-label" for="memberAgreeService">
                                                                    <input type="checkbox"
                                                                        id="memberAgreeService"
                                                                        name="memberAgreeService"
                                                                        v-model="memberInfoUse"
                                                                        @click="onClickMemberInfoUse()"
                                                                    />
                                                                    <span class="checkbox-icon" tabindex="3"></span>
                                                                    <span class="label-text text-sm">내정보와 동일하게 적용</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <small class="input-hint with-label-3"></small>
                                                </div>
                                                <div class="form-group row form-required mb-3">
                                                    <label for="memberName" class="col-sm-3 col-form-label">수령인</label>
                                                    <div class="col-sm-9">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" id="memberName" placeholder="수령인 이름을 입력해주세요." autocomplete="off" tabindex="1"
                                                            required
                                                            maxlength="50" 
                                                            pattern="^[a-zA-Z가-힣]+$"
                                                            pattern-hint="이름은 한글/영문으로 실명을 입력해야 합니다."
                                                            v-model="orderPaymentFormData.orderRecipientName"
                                                            @blur="onInputCheckEvent()" 
                                                            />
                                                        </div>
                                                    </div>
                                                    <small class="input-hint with-label-3"></small>
                                                </div>
                                                <div class="form-group row form-required mb-3">
                                                    <label for="memberResidentNumber" class="col-sm-3 col-form-label">휴대폰 번호</label>
                                                    <div class="col-sm-9">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" id="memberResidentNumber" placeholder="수령인의 휴대폰 번호를 입력해주세요." autocomplete="off" tabindex="2"
                                                            required
                                                            maxlength="11" 
                                                            pattern="^010\d{8}$"
                                                            pattern-hint="휴대폰 번호는 010으로 시작해야하며, 숫자만 입력해야 합니다."
                                                            v-model="orderPaymentFormData.orderRecipientMobile"
                                                            @blur="onInputCheckEvent()" 
                                                            />
                                                        </div>
                                                    </div>
                                                    <small class="input-hint with-label-3"></small>
                                                </div>
                                                <div class="form-group row form-required mb-3">
                                                    <label for="orderZipcode" class="col-sm-3 col-form-label align-self-baseline">주소</label>
                                                    <div class="col-sm-9">
                                                        <div class="row">
                                                            <div class="col-md-4 col-sm-6">
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control" id="orderZipcode" placeholder="우편번호" autocomplete="off" tabindex="5"
                                                                    required
                                                                    maxlength="5" 
                                                                    pattern="^\d{5}$"
                                                                    pattern-hint="우편번호를 입력해야 합니다."
                                                                    v-model="orderPaymentFormData.orderZipcode"
                                                                    @input="onInputCheckEvent()" 
                                                                    readonly
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 col-sm-6">
                                                                <button type="button" class="btn btn-primary-reverse-2 btn-block"
                                                                @click="onClickFindAddress"
                                                                >주소찾기</button>
                                                            </div>
                                                        </div>
                                                        <div class="row mt-2">
                                                            <div class="col-md-12 col-sm-12">
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control" id="orderAddr" placeholder="주소를 입력해주세요." autocomplete="off" tabindex="5"
                                                                    required
                                                                    pattern-hint="주소를 입력해야 합니다."
                                                                    v-model="orderPaymentFormData.orderAddr"
                                                                    @input="onInputCheckEvent()" 
                                                                    readonly
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mt-2">
                                                            <div class="col-md-12 col-sm-12">
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control" id="orderAddrDetail" placeholder="상세 주소를 입력해주세요." autocomplete="off" tabindex="5"
                                                                    pattern-hint="상세 주소를 입력해야 합니다."
                                                                    v-model="orderPaymentFormData.orderAddrDetail"
                                                                    @blur="onInputCheckEvent()" 
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <small class="input-hint with-label-3"></small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="order-payment-text" v-html="orderPaymentText"></div>

            <div class="container mb-5">
                <div class="content order-content">
                    <div class="container-fluid">
                        <div class="row justify-content-center">
                            <div class="col-md-8 col-sm-12">
                                <div class="order-payment-comment" v-if="productDistributor==='ddokdok'">
                                    <p>* 보증금은 장비 반납시 3영업일 내에 환불됩니다. (총 상품 비용의 10%내외)</p>
                                    <p>단, 장비의 파손 등 상품 회손으로 인하여 수리비 및 상품대금 발생시 보증금에서 차액 하거나 추가 비용을 요청 할 수있습니다.</p>
                                    <p>* 결재는 보증금은 즉시 결재가 되고 월 구독료는 상품 수령일을 기준으로 매월 결재가 됩니다. 월 구독료 연채시 보증금에서 차액 후 지급됩니다.</p>
                                </div>
                                <div class="row justify-content-center mt-4">
                                    <div class="col-md-6 col-sm-6">
                                        <button type="button" class="btn btn-black-4 btn-block"
                                        @click="onClickCancel()"
                                        >취소</button>
                                    </div>
                                    <div class="col-md-6 col-sm-6">
                                        <button type="submit" class="btn btn-special-1 btn-block"
                                        @mouseenter="onOverSubmitButton"
                                        :disabled="submitButtonDisabled"
                                        >{{ orderPaymentTitle }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </form>

    <div
    style="
        position: fixed;
        left: 0;
        top:0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.4);
        z-index: 1999;
    "
    v-show="isBackground"
    ></div>
</template>

<script>
import { defineComponent, ref, onMounted, computed, nextTick, onBeforeUnmount } from 'vue'
import axios from 'axios'
import commonMixin from '@/helper/mixins/commonMixin.js'
import store from '@/store'
import { numberFormat } from '@/helper/utils/etcUtils.js'

export default defineComponent({
    name: 'webOrderPaymentPage',
    beforeRouteLeave(to, from, next) {
        if(to.name !== 'webOrderCompletePage'){
            store.dispatch('common/cleanProductOrderData')
        }
        next()
    },
    setup() {
        const { onHandleValidateInput, store, imgURL, $axiosConnect, router, storeMemberInfo, storeToken } = commonMixin()

        const submitButtonDisabled = ref(true)
        const orderPaymentFormRef  = ref(null)
        const orderPaymentFormData = ref({
			orderRecipientName   : '',
			orderRecipientMobile : '',
			orderAddr            : '',
			orderAddrDetail      : '',
			orderZipcode         : '',
            discountCard         : '',
            discountCoupon       : '',
            discountPrepayment   : '',
        })
        const order = ref({})
        const orderPaymentTitle  = ref("")
        const orderPaymentText   = ref("")
        const productDistributor = ref("ddokdok")
        const memberInfoUse      = ref(false)

        const orderUid             = ref(0)
        const productUid           = ref(0)
        const paymentIntervalApi   = ref(null)
        const paymentIntervalPopup = ref(null)
        const popopWindow          = ref(null)
        const isBackground         = ref(false)

        /**
         * 입력 값의 유효성을 검사하고, 암호화된 아이디와 비밀번호를 생성하는 메서드
         * 유효성 검사 결과에 따라 submitDisabled 속성을 설정합니다.
         * @method onInputCheckEvent
         * @returns {void}
         */
         const onInputCheckEvent = () => {
            return !(submitButtonDisabled.value = !onHandleValidateInput(orderPaymentFormRef.value))
        }

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onOverSubmitButton = () => {
            const formElements = orderPaymentFormRef.value.querySelectorAll('input, select, textarea')
            formElements.forEach(input => {
                input.blur()
            });
        }

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
         const onClickPaymentCheck = () => {
            
            if(onInputCheckEvent()){

                order.value.orderPaymentFormData = []
                order.value.orderPaymentFormData.push(orderPaymentFormData.value)

                const formData = new FormData()
                for (const key in order.value) {
                    if (order.value[key] !== '') {
                        formData.set(key, JSON.stringify(order.value[key]))
                    }
                }
				if(order.value.orderDistributor === 'ddokdok'){
                    onProcessSave(formData)
				}else{
					onProcessSaveRetal(formData)
				}
            }
            
        }

        /**
         * 로그인 프로세스를 처리하는 메서드
         * @method onProcessSignIn
         * @param {FormData} formData - 로그인에 필요한 폼 데이터
         * @returns {void}
         */
        const onProcessSave = async (formData) => {
            try {
                const response = await $axiosConnect('MEMBER', `/web/order`, 'POST', formData)
                if (response.success && response.body && response.body.list.length > 0) {
                    const orderData   = response.body.list
                    orderUid.value    = orderData[0].uid
                    productUid.value  = orderData[0].productUid

                    isBackground.value = true
                    //결제창 열기
                    popopWindow.value = popupCenter();
                    if (popopWindow.value) {
                        // Create a hidden form and append the FormData values dynamically
                        const form = document.createElement('form');
                        form.setAttribute('method', 'POST');
                        form.setAttribute('action', `${window.location.origin}/EXTEND/hecto/payment.php`);
                        form.setAttribute('target', 'payment_popup');

                        // Append all the form data from FormData object to the form
                        for (let [key, value] of formData.entries()) {
                            const input = document.createElement('input');
                            input.type = 'hidden';
                            input.name = key;
                            input.value = value;
                            form.appendChild(input);
                        }

                        //orderUid값 추가
                        const input = document.createElement('input');
                        input.type  = 'hidden';
                        input.name  = 'mchtParam';
                        input.value = 'orderUid=' + orderUid.value + '&memberUid=' + storeMemberInfo.value.uid + '&memberId=' + storeMemberInfo.value.memberId + '&memberName=' + storeMemberInfo.value.memberName + '&memberMobile=' + storeMemberInfo.value.memberMobile + '&memberEmail=' + storeMemberInfo.value.memberEmail;
                        form.appendChild(input);

                        // Submit the form to the popup popWindow
                        popopWindow.value.document.body.appendChild(form);
                        form.submit();
                    }

                    paymentIntervalPopup.value = setInterval(popupPaymentCloseCall, 1000);
					paymentIntervalApi.value   = setInterval(onHandlePaymentCheck, 1000);
                }else{
                    isBackground.value = false
                    //alertRef.value.open({ 'contents' : response.message })
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

		/**
         * 로그인 프로세스를 처리하는 메서드
         * @method onProcessSignIn
         * @param {FormData} formData - 로그인에 필요한 폼 데이터
         * @returns {void}
         */
        const onProcessSaveRetal = async (formData) => {
            try {
                const response = await $axiosConnect('MEMBER', `/web/order`, 'POST', formData)
                if (response.success && response.body && response.body.list.length > 0) {
                    const orderData   = response.body.list
                    orderUid.value    = orderData[0].uid
                    productUid.value  = orderData[0].productUid

					router.push({ name: 'webOrderComplatePage', params: { orderUid : orderUid.value }, query: { complate: 'complate', 'productUid' : productUid.value } });
                }else{
                    isBackground.value = false
                    //alertRef.value.open({ 'contents' : response.message })
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

		const popupCenter = () => {
            const _width = 445;
            const _height = 660;
            const xPos = (document.body.offsetWidth / 2) - (_width / 2); // Center alignment
            const yPos = (window.innerHeight / 2) - (_height / 2); // Center vertically
            const popupWindow = window.open('', 'payment_popup', `width=${_width}, height=${_height}, left=${xPos}, top=${yPos}, menubar=yes, status=yes, titlebar=yes, resizable=yes`)
            return popupWindow
        }

        const popupPaymentCloseCall = (result, payment) => {
			result
			payment
            if (popopWindow.value && popopWindow.value.closed) {
                isBackground.value = false
                clearInterval(paymentIntervalApi.value);
                clearInterval(paymentIntervalPopup.value);
            }
        }

        const onHandlePaymentCheck = async () => {
            try {
                if(orderUid.value > 0){
                    const baseURL = process.env.VUE_APP_BASE_API_URL
                    const response = await axios({
                        method: 'GET',
                        url: `${baseURL}/web/order/${orderUid.value}`,
                        headers: {
                            'Authorization': `Bearer ${storeToken.value}`,
                            'Content-Type': 'multipart/form-data; charset=UTF-8;'
                        },
                        timeout: 600 * 1000
                    });
                    if (response.data.success && response.data.body && response.data.body.list.length > 0) {
                        if(parseInt(response.data.body.list[0].orderPaymentState) === 1 && response.data.body.list[0].orderPaymentInfo){
                            isBackground.value = false
                            clearInterval(paymentIntervalApi.value);
                            clearInterval(paymentIntervalPopup.value);
                            router.push({ name: 'webOrderComplatePage', params: { orderUid : orderUid.value }, query: { complate: 'complate', 'productUid' : productUid.value } });
                        }
                    }
                }
            } catch (error) {//ORDER_PAYMENT_INFO
                console.error("error:", error)
            }
        }

        const loadDaumPostCodeScript = () => {
            return new Promise((resolve, reject) => {
                if (document.getElementById('daum-postcode-script')) {
                    resolve();
                    return;
                }

                const script = document.createElement('script');
                script.id = 'daum-postcode-script';
                script.src = 'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
                script.onload = resolve;
                script.onerror = reject;
                document.body.appendChild(script);
            });
        };

        const openDaumPostcode = () => {
            new window.daum.Postcode({
                oncomplete: function(data) {
                    // Get the address and postal code and update the form data
                    orderPaymentFormData.value.orderZipcode = data.zonecode;
                    orderPaymentFormData.value.orderAddr = data.address;
                    // Focus on the detailed address input field after auto-filling
                    nextTick(() => {
                        document.getElementById('orderAddrDetail').focus();
                    });
                },
            }).open();
        };

        const onClickFindAddress = async () => {
            try {
                await loadDaumPostCodeScript();
                openDaumPostcode();
            } catch (error) {
                console.error('Failed to load Daum Postcode script:', error);
            }
        };

        const onClickMemberInfoUse = () => {
            memberInfoUse.value = !memberInfoUse.value
			
            if(memberInfoUse.value){
                orderPaymentFormData.value.orderRecipientName = storeMemberInfo.value.memberName
                orderPaymentFormData.value.orderRecipientMobile = storeMemberInfo.value.memberMobile
                orderPaymentFormData.value.orderZipcode = storeMemberInfo.value.memberZipcode
                orderPaymentFormData.value.orderAddr = storeMemberInfo.value.memberAddr
                orderPaymentFormData.value.orderAddrDetail = storeMemberInfo.value.memberAddrDetail
            }else{
                orderPaymentFormData.value.orderRecipientName = ""
                orderPaymentFormData.value.orderRecipientMobile = ""
                orderPaymentFormData.value.orderZipcode = ""
                orderPaymentFormData.value.orderAddr = ""
                orderPaymentFormData.value.orderAddrDetail = ""
            }
            
            onHandleValidateInput(orderPaymentFormRef.value)
            onInputCheckEvent()
        }

        const onClickCancel = () => {
            const pUid = productUid.value
            router.push({ name: 'webProductDetailPage', params: { pUid } });
        }

        const initialize = async () => {
            submitButtonDisabled.value = true
            orderUid.value = 0

            const getProductOrderData = computed(() => store.getters["common/getProductOrderData"])
            Object.assign(order.value, getProductOrderData.value)

            const orderType                    = order.value.productOrder[0].orderType
            const orderTotalPrice              = order.value.productOrder[0].orderTotalPrice
            const defaultTotalPrice            = order.value.productOrder[0].defaultTotalPrice
            const productSubscribeDeposit      = order.value.productFormData[0].productSubscribeDeposit
            const productRentalDeposit         = order.value.productFormData[0].productRentalDeposit
            const productSubscribeRegularPrice = order.value.productFormData[0].productSubscribeRegularPrice
            const productRentalRegularPrice    = order.value.productFormData[0].productRentalRegularPrice
            const productSaleRegularPrice      = order.value.productFormData[0].productSaleRegularPrice
            
            productDistributor.value = order.value.productFormData[0].productDistributor

            if(
                !order.value.orderCheckFormData || !order.value.orderCheckFormData.length ||
                !order.value.productFormData || !order.value.productFormData.length ||
                !order.value.productOrder || !order.value.productOrder.length ||
                !order.value.productDefaultItems || !order.value.productDefaultItems.length 
            ){
                console.warn("잘못된 접근으로 홈으로 이동합니다.")
                router.replace({
                    path: '/'
                })
            }

            let text  = ''
            let title = ''
            if(orderType=== 'subscribe'){
                if(productSubscribeDeposit > 0){
                    text = '<span>보증금</span> ' + numberFormat(productSubscribeDeposit) + '원 + '
                }
                text += '월 구독 요금 ' + numberFormat(orderTotalPrice) + '원 결제'
                text += ' <span>(<s>월' + productSubscribeRegularPrice + '원</s>에서 ' + parseInt(((productSubscribeRegularPrice-defaultTotalPrice)/productSubscribeRegularPrice) * 100 ) +'% <b>할인 혜택</b> 적용)</span>'
                title = ' 구독 결제'
            }else if(orderType=== 'rental'){
                if(productRentalDeposit > 0){
                    text = '<span>보증금</span> ' + numberFormat(productRentalDeposit) + '원 + '
                }
                text += '월 렌탈형 구독 요금 ' + numberFormat(orderTotalPrice) + '원'
                text += ' <span>/ ' + order.value.productFormData[0].productRentalMonths + '개월</span>'
                text += ' 렌탈형 구독 결제'
                text += ' <span>(<s>월' + numberFormat(productRentalRegularPrice) + '원</s>에서 ' + parseInt(((productRentalRegularPrice-defaultTotalPrice)/productRentalRegularPrice) * 100 ) +'% <b>할인 혜택</b> 적용)</span>'
                title = '렌탈형 구독 결제'
            }else if(orderType=== 'sale'){
                text +=  numberFormat(orderTotalPrice) + '원 결제'
                text += ' <span>(<s>' + numberFormat(productSaleRegularPrice) + '원</s>에서 ' + parseInt(((productSaleRegularPrice-defaultTotalPrice)/productSaleRegularPrice) * 100 ) +'% <b>할인 혜택</b> 적용)</span>'
                title = ' 구매 결제'
            }

            if(productDistributor.value !=='ddokdok'){
                text  = text.replace(/결제/, '신청')
                title = title.replace(/결제/, '신청')
                submitButtonDisabled.value = false
            }

            orderPaymentText.value  = text
            orderPaymentTitle.value = title
        }

        onMounted(async () => {
            await initialize()
            window.popupPaymentCloseCall = popupPaymentCloseCall
        })

        onBeforeUnmount(() => {
            delete window.popupPaymentCloseCall

            if (paymentIntervalApi.value) {
                clearInterval(paymentIntervalApi.value)
            }
			if(paymentIntervalPopup.value){
				clearInterval(paymentIntervalPopup.value)
			}
        })

        return {
            submitButtonDisabled,
            imgURL,
            orderPaymentFormRef,
            orderPaymentFormData,
            order,
            orderPaymentText,
            orderPaymentTitle,
            productDistributor,
            memberInfoUse,
            isBackground,

            onInputCheckEvent,
            onClickPaymentCheck,
            onClickFindAddress,
            onClickMemberInfoUse,
            onOverSubmitButton,
            popupPaymentCloseCall,
            onClickCancel
        }
    }
})
</script>