<template>
    <div class="input-checkbox-group">
        <label class="input-label" :for="`${checkboxName}-selectAll`">
            <input type="checkbox"
                :id="`${checkboxName}-selectAll`"
                v-model="isAllSelected" 
                @change="onChangeAllSelected"
            />
            <span class="checkbox-icon"></span>
            <span class="label-text">전체</span>
        </label>
        <template v-for="(item, index) in checkboxs" :key="index">
            <label class="input-label" :for="`${checkboxName}-${index}`">
                <input type="checkbox"
                    :id="`${checkboxName}-${index}`"
                    :value="item.value"
                    v-model="selectedList"
                    @change="onChangePartSelected"
                />
                <span class="checkbox-icon"></span>
                <span class="label-text">{{ item.name }}</span>
            </label>
        </template>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted, watch } from 'vue'

export default defineComponent({
    name: 'ComponentFormCheckbox',
    props: {
        checkboxDefault: {
            type: Array,
            required: false,
            default: () => []
        },
        checkboxName: {
            type: String,
            required: true,
            default: ''
        },
        checkboxList: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    emits: ['onChangeCheckbox'],
    setup(props, { emit }) {
        const isAllSelected = ref(false)
        const checkboxs     = ref([])
        const selectedList  = ref([])

        onMounted(() => {
            onHandleReset()
        })

        const onChangePartSelected = () => {
            isAllSelected.value = (selectedList.value.length === checkboxs.value.length)?true:false

            emit('onChangeCheckbox', {
                name     : props.checkboxName,
                selecteds: selectedList.value ? 
                (selectedList.value.length === checkboxs.value.length ? "":[selectedList.value.join(',').toString()]) : []
            })
        }

        const onChangeAllSelected = () => {
            selectedList.value = []

            if(isAllSelected.value){
                Object.values(checkboxs.value).forEach(item => {
                    selectedList.value.push(item.value)
                })
            }
        }

        const onHandleReset = () => {
            checkboxs.value    = props.checkboxList
            selectedList.value = props.checkboxDefault && props.checkboxDefault.length > 0 ? props.checkboxDefault : []
            onChangePartSelected()
        }

        watch(selectedList, () => {
            onChangePartSelected()
        })

        return {
            isAllSelected,
            checkboxs,
            selectedList,
            onChangePartSelected,
            onChangeAllSelected,
            onHandleReset
        }
    }
})
</script>