<template>
    <div class="header">
        <div class="container">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <div class="page-tab-title">
                                <ul>
                                    <li class="active"><span>신청완료</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
			<div class="content order-content">
				<div class="container-fluid">
					<div class="row">
                        <div class="col-sm-12">
                            <h1>신청해주셔서 감사합니다.</h1>
                            <!--<div>{{ orderData.orderMemberName }}님 구독신청을 완료하였습니다.</div>-->
							<div>구독신청을 완료하였습니다.</div>

                            <div v-if="orderData.orderDistributor !== 'ddokdok'">
                                해당 상품은 해피콜 후 구독이 진행됩니다.
                            </div>

                            <br />
                            <button class="btn btn-special-2 btn-block" @click="onClickNavigateToMyPage()">신청 내역 보기</button>
                        </div>
					</div>
				</div>
            </div>
        </div>
    </div>

    <alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import commonMixin from '@/helper/mixins/commonMixin.js'
import Alert from '@/modals/common/Alert'

export default defineComponent({
    name: 'webOrderCompletePage',
    components: {
		'alert' : Alert,
	},
	props: {
        orderUid: {
            type: String,
            required: true
        }
    },
    setup(props) {
		const { router, $axiosConnect } = commonMixin()

        const alertRef = ref(null)
        const orderData = ref({})

        const getDeatil = async () => {
            try {
                const uid = props.orderUid
                const response = await $axiosConnect('MEMBER', `/web/order/${uid}`, 'GET')

                if (response.success && response.body && response.body.list.length > 0) {
                    const list = JSON.parse(JSON.stringify(response.body.list[0]))
                    orderData.value = {
                        ...list,
                    }

                    console.log(orderData.value)
                }else{
                    alertRef.value.open({ 'contents' : response.message })
                    close()
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const onClickNavigateToMyPage = () => {
            router.push({ name: 'webMyPageOrderStatusPage' });
        }

        const initialize = async () => {
			const orderUid   = props.orderUid

			const routeQuery = router.currentRoute.value.query || {}
			const complate   = routeQuery.complate || ''
			const productUid = routeQuery.productUid || 0

			console.log(complate, orderUid, productUid)

            if(complate === 'complate' && orderUid && productUid){
                await getDeatil()
            }else{
                alertRef.value.open({ 
                    'contents' : '잘못된 접근입니다.',  
                    'callback' : ''
                })
            }
        }

        onMounted(async () => {
            await initialize()
        })

        return {
            alertRef,
            orderData,

            onClickNavigateToMyPage
        }
    }
})
</script>