<template>
    <div class="header">
        <div class="container">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <div class="page-tab-title">
                                <ul>
                                    <li class="active"><span>회원가입</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2 justify-content-center">
                        <div class="col-md-5 col-sm-12">
                            <div class="content-header-steplist">
                                <div class="steplist-step">
                                    <span class="steplist-step-circle">1</span>
                                    <span class="steplist-step-label">유형선택</span>
                                </div>
                                <div class="steplist-step" role="button" @click="onClickSignUpStep2">
                                    <span class="steplist-step-circle">2</span>
                                    <span class="steplist-step-label">약관동의</span>
                                </div>
                                <div class="steplist-step active">
                                    <span class="steplist-step-circle">3</span>
                                    <span class="steplist-step-label">인증</span>
                                </div>
                                <div class="steplist-step">
                                    <span class="steplist-step-circle">4</span>
                                    <span class="steplist-step-label">정보입력</span>
                                </div>
                                <div class="steplist-step">
                                    <span class="steplist-step-circle">5</span>
                                    <span class="steplist-step-label">가입완료</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="content login-content">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-sm-12">
                            <div class="card">
                                <div class="card-body join-card-body">
                                    <p class="login-box-msg">이메일 인증</p>

                                    <form ref="signUpFormRef" @submit.prevent="onClickSignUp">
                                        <div class="row justify-content-center">
                                            <div class="col-md-8 col-sm-12">

                                                <div class="form-group row form-required mb-3">
                                                    <label for="memberMobileCarrier" class="col-sm-3 col-form-label align-self-baseline">이메일</label>
                                                    <div class="col-sm-9">
                                                        <div class="row">
															<div class="col-sm-12">
																<div class="input-group">
                                                                    <input type="text" class="form-control" id="memberEmail" placeholder="이메일을 입력해주세요." autocomplete="off" tabindex="5"
                                                                    required
                                                                    maxlength="100" 
                                                                    pattern="^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$"
                                                                    pattern-hint="이메일을 형식에 맞게 정확히 입력해야 합니다."
                                                                    v-model="signUpFormData.memberEmail"
                                                                    @blur="onInputCheckEvent()" 
                                                                    />
                                                                </div>
                                                                <small class="input-hint"></small>
															</div>
                                                        </div>
                                                        <div class="row mt-2">
                                                            <div class="col-sm-12">
                                                                <button type="button" class="btn btn-web-primary btn-block"
                                                                :disabled="!receiveMemberAuthNumberDisabled"
                                                                @click="onClickSendMemberAuthNumber"
                                                                >인증번호 받기</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div class="form-group row form-required mb-3">
                                                    <label for="memberAuthNumber" class="col-sm-3 col-form-label">인증번호</label>
                                                    <div class="col-sm-9">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" id="memberAuthNumber" placeholder="인증번호" autocomplete="off" tabindex="6"
                                                            required
                                                            maxlength="6" 
                                                            pattern="^[a-fA-F0-9]{6}$" 
                                                            pattern-hint="메일로 전송된 인증번호를 6자리로 입력해야 합니다."
                                                            v-model="memberAuthNumber"
                                                            @blur="onInputCheckEvent()" 
                                                            @input="onInputCheckAuthNumber()"
                                                            :disabled="!receiveMemberAuthNumberDisabled"
                                                            />
                                                        </div>
                                                        <small class="input-hint">메일로 전송된 인증번호를 6자리로 입력해야 합니다.</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-md-5 col-sm-12">
                                                <div class="mt-5 mb-3">
                                                    <button type="submit" class="btn btn-block btn-special-2"
                                                    :disabled="submitButtonDisabled" @mouseenter="onOverSubmitButton"
                                                    >다음</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, ref, onMounted, computed, nextTick } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'

import Alert from '@/modals/common/Alert'

export default defineComponent({
    name: 'webSignUpStep3Page',
    components: {
		'alert' : Alert,
	},
    setup() {
        const { onHandleValidateInput, store, router, $axiosConnect } = commonMixin()

        const submitButtonDisabled = ref(true)
        const signUpFormRef        = ref(null)
        const signUpFormData       = ref({
            memberAuthority         : '0',
            memberAgreeService      : false,
            memberAgreePolicy       : false,
            memberAgreeSharing      : false,
            memberAgreeReceiving    : false,
            memberEmail             : '',
            memberName              : '',
            memberBirth             : '',
            memberBirthYear         : new Date().getFullYear() - 20,
            memberBirthMonth        : 1,
            memberBirthDay          : 1,
            memberGender            : '',
            memberMobile            : '',
            memberMobileCarrier     : '',
            memberAuthNumber        : '000000',
            memberId                : '',
            memberPassword          : '',
            memberPasswordEncrypted : '',
            memberRePassword        : '',
        })
        const memberAuthNumber    = ref('')

        const alertRef            = ref(null)


        /**
         * 입력 값의 유효성을 검사하고, 암호화된 아이디와 비밀번호를 생성하는 메서드
         * 유효성 검사 결과에 따라 submitDisabled 속성을 설정합니다.
         * @method onInputCheckEvent
         * @returns {void}
         */
        const onInputCheckEvent = async () => {
            await nextTick();
            return !(
                submitButtonDisabled.value = !(
                    onHandleValidateInput(signUpFormRef.value) && (memberAuthNumber.value === signUpFormData.value.memberAuthNumber)
                )
            )
        }

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onOverSubmitButton = () => {
            const formElements = signUpFormRef.value.querySelectorAll('input, select, textarea')
            formElements.forEach(input => {
                input.blur()
            });
        }

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
         const onClickSignUp = () => {
            if(onInputCheckEvent()){
                const formDataObj = {}
                for (const key in signUpFormData.value) {
                    if(signUpFormData.value[key] !== ''){
                        formDataObj[key] = signUpFormData.value[key]
                    }
                }
                store.dispatch('member/setSignUpStep', JSON.parse(JSON.stringify(formDataObj)))
                router.replace({ name: 'webSignUpStep4Page' })
            }
        }

        const receiveMemberAuthNumberDisabled = computed(() => {
            const emailInput = document.querySelector('#memberEmail');
            return signUpFormData.value.memberEmail && emailInput && emailInput.checkValidity();
        })

        const requestMemberAuthNumberDisabled= computed(() => {
            const authNumberInput = document.querySelector('#memberAuthNumber');
            return signUpFormData.value.memberAuthNumber && authNumberInput && authNumberInput.checkValidity();
        })

        const onInputCheckAuthNumber = () => {
            const authNumberInput = document.querySelector('#memberAuthNumber');
            authNumberInput.closest('.form-group').querySelector('.input-hint').style.display = 'none'

            if(memberAuthNumber.value !== signUpFormData.value.memberAuthNumber){
                authNumberInput.closest('.form-group').querySelector('.input-hint').style.display = 'block'
            }
        }

        const onClickSignUpStep2 = () => {

            signUpFormData.value.memberEmail             = ''
            signUpFormData.value.memberName              = ''
            signUpFormData.value.memberBirth             = ''
            signUpFormData.value.memberBirthYear         = new Date().getFullYear() - 20,
            signUpFormData.value.memberBirthMonth        = 1
            signUpFormData.value.memberBirthDay          = 1
            signUpFormData.value.memberGender            = ''
            signUpFormData.value.memberMobile            = ''
            signUpFormData.value.memberMobileCarrier     = ''
            signUpFormData.value.memberAuthNumber        = '000000'
            signUpFormData.value.memberId                = ''
            signUpFormData.value.memberPassword          = ''
            signUpFormData.value.memberPasswordEncrypted = ''
            signUpFormData.value.memberRePassword        = ''

            const formDataObj = {}
            for (const key in signUpFormData.value) {
                if(signUpFormData.value[key] !== ''){
                    formDataObj[key] = signUpFormData.value[key]
                }
            }
            store.dispatch('member/setSignUpStep', JSON.parse(JSON.stringify(formDataObj)))

            router.push({ 
                name: 'webSignUpStep2Page', 
                query: { from: 'webSignUpStep3Page' } 
            })
        }

        const onClickSendMemberAuthNumber = async () => {
            //console.log("signUpFormData.value:", signUpFormData.value)
            const formData = new FormData();
            for (const key in signUpFormData.value) {
                if(signUpFormData.value[key] !== ''){
                    formData.set(key, signUpFormData.value[key])
                }
            }

            const response = await $axiosConnect('GUEST', '/web/sendAuthNumber', 'POST', formData)
            if (response.body && response.body.code) {
                signUpFormData.value.memberAuthNumber = response.body.code  
            }
            alertRef.value.open({ 'contents' : response.message })

        }

        const initialize = async () => {
            submitButtonDisabled.value = true

            const getSignUpFormData = computed(() => store.getters["member/getSignUpFormData"])
            Object.assign(signUpFormData.value, getSignUpFormData.value)
        }

        onMounted(async () => {
            await initialize()
        })

        return {
            submitButtonDisabled,
            signUpFormRef,
            signUpFormData,
            receiveMemberAuthNumberDisabled,
            requestMemberAuthNumberDisabled,
            memberAuthNumber,

            router,
            alertRef,

            onInputCheckEvent,
            onOverSubmitButton,
            onClickSignUp,
            onClickSignUpStep2,
            onClickSendMemberAuthNumber,
            onInputCheckAuthNumber
        }
    }
});
</script>