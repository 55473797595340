<template>
	<div class="smarteditor-container">
		<textarea :id="editorId" :name="editorId" rows="10" cols="100" ref="editorRef"></textarea>
	</div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import { unescapeHtmlEntities  } from '@/helper/utils/etcUtils.js'

export default defineComponent({
    name: 'ComponentFormSmarteditor2',
    props: {
        editorId: {
            type: String,
            required: true,
        },
        modelValue: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        const editorRef = ref([]);

        const initializeEditor = () => {
            window.nhn.husky.EZCreator.createInIFrame({
                oAppRef       : editorRef.value,
                elPlaceHolder : props.editorId,
                sSkinURI      : "/plugins/smarteditor2/dist/SmartEditor2Skin.html",
                fCreator      : "createSEditor2",
                htParams: {
                    bUseToolbar: true,
                    bUseVerticalResizer: true,
                    bUseModeChanger: true,
                    fOnBeforeUnload: () => {}
                },
                fOnAppLoad: () => {
                    editorRef.value.getById[props.editorId].exec("PASTE_HTML", [unescapeHtmlEntities(props.modelValue)]);
                }
            })
            emit
        }

        const syncContent = () => {
            emit('update:modelValue', editorRef.value.getById[props.editorId].getContents());
        }

        const cleanContent = () => {
            if (editorRef.value.getById[props.editorId]) {
                editorRef.value.getById[props.editorId].exec("SET_IR", [""])
            } else {
                console.error('Editor instance not found.');
            }
        };

        onMounted(async () => {
            if (window.nhn.husky.EZCreator) {
                initializeEditor()
            } else {
                console.error('HuskyEZCreator is not loaded.')
            }
        })

        return {
            editorRef,
            syncContent,
            cleanContent
        }
    }
});
</script>
<style scoped>
.smarteditor-container {
	width: 100%;
}
</style>