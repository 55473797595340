<template>
	<Modal ref="baseModal">
		<template #body>
			<p>{{ contents }}</p>
			<!--
			<br>
			<a @click="close">닫기</a>
			-->
		</template>
		<template #footer>
			<button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">닫기</button>
			<!--
			<button type="button" class="btn btn-outline-light">Save changes</button>
			-->
		</template>
	</Modal>
</template>

<script>
import { defineComponent, ref } from 'vue';
import Modal from '@/modals/Modal'
import commonMixin from '@/helper/mixins/commonMixin.js'

export default defineComponent({
    name : 'alertPage',
	components: {
		Modal,
	},
	setup(){
		const { router } = commonMixin()
		
		const baseModal = ref(null)
		const resolvePromise = ref(null)

		const contents = ref('')
		const url = ref('')
		const callback = ref(null)

		const open = (payload) => {
			baseModal.value.openModal()

			contents.value = payload.contents;
			url.value = payload.url;
			callback.value = payload.callback;

			// Add event listener to detect Enter key press
			document.addEventListener('keydown', onHandleKeyPress);

			return new Promise((resolve) => {
				resolvePromise.value = resolve;
			});
		};

		const close = () => {
			baseModal.value.closeModal()
			resolvePromise.value(false)
			
			if (callback.value) {
                callback.value();
            }

			if(url.value){
				router.push(url.value);
			}
			document.removeEventListener('keydown', onHandleKeyPress);
		};

		const onHandleKeyPress = (event) => {
			if (event.key === 'Enter') {
				close();
			}
		};

		return {
			baseModal, 
			contents,
			open,
			close
		}
	}
});
</script>