<template>
    <label :for="`${textName}`" class="d-none">검색어</label>
    <input
        type="text"
        :id="`${textName}`"
        v-model="text"
        v-on:input="onTextChange"
        autocomplete="off"
        :placeholder="textPlaceholder"
        class="form-control"
    />
</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue'

export default defineComponent({
    name: 'ComponentFormText',  // 이름을 ComponentFormText로 수정
    props: {
        textDefault: {
            type: String,
            required: false,
            default: ''
        },
        textName: {
            type: String,
            required: true,
            default: ''
        },
        textPlaceholder: {
            type: String,
            required: false,
            default: ''
        },
        title: {
            type: String,
            required: false,
            default: ''
        }
    },
    emits: ['onChangeText'],

    setup(props, { emit }) {
        const text = ref('');

        // 컴포넌트가 마운트되었을 때 textDefault 값을 text에 설정
        onMounted(() => {
            onHandleReset()
            text.value = props.textDefault;
        });

        // props.textDefault 값이 변경될 때 text를 업데이트
        watch(
            () => props.textDefault,
            (newValue) => {
                text.value = newValue;
            }
        );

        // text 값이 변경될 때 onChangeText 이벤트 발생
        watch(text, () => {
            onChangeText();
        });

        const onChangeText = () => {
            emit('onChangeText', {
                name: props.textName,
                selecteds: text.value ? [text.value.toString()] : []
            });
        };

        const onHandleReset = () => {
            text.value = ''
        };

        return {
            text,
            onChangeText,
            onHandleReset
        };
    }
});
</script>