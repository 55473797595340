<template>
    <main>
        <div class="container">
			<section class="content-header">
				<div class="container-fluid">
					<div class="row mb-2">
						<div class="col-sm-12">
							<div class="page-tab-title">
								<ul>
                                    <li class="active"><span>MY 페이지</span></li>
									<li @click="router.push({ name: 'webMyInfoPage' })"><span>내 정보</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
            <div class="content mypage">
                <div class="container-fluid">
					<div class="row">
						<div class="col-sm-12">
                            <ul class="tab-list">
								<li @click="router.push({ name: 'webMyPageOrderStatusPage' })"><span>구독현황</span></li>
								<li @click="router.push({ name: 'webMyPageCartPage' })"><span>장바구니</span></li>
								<li class="active"><span>나의 리뷰</span></li>
								<li @click="router.push({ name: 'webMyPageContactPage' })"><span>나의 1:1 문의</span></li>
								<li @click="router.push({ name: 'webMyPageCallRequestPage' })"><span>나의 전화상담요청</span></li>
								<!--<li @click="router.push({ name: 'webMyPageCouponPage' })"><span>쿠폰</span></li>-->
							</ul>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-md-12">

                            <template v-if="list && list.length > 0">
                                <template v-for="(item, index) in list" :key="index">
                                    <div class="col-md-12 col-sm-12">
                                        <div class="product-item min-height-auto" data-aos-delay="000" >
                                            <div class="product-inner-item flex-row" role="button">
                                                <picture class="product-image" style="max-width: 200px" @click="onClickNavigateToProductDetail(item)">
                                                    <template v-if="item.convertProductImages && item.convertProductImages.length > 0 ">
                                                        <img class="absolute-center" 
                                                            :src="`${imgURL}${item.convertProductImages[0].upfilesThumnailPath}/${item.convertProductImages[0].upfilesFileName}`" 
                                                            :alt="item.convertProductName"
                                                        />
                                                    </template>
                                                    <template v-else>
                                                        <img class="absolute-center" 
                                                            src="@/assets/images/common/logo_kor.png"
                                                            :alt="item.convertProductName"
                                                        />
                                                    </template>
                                                </picture>
                                                <div class="product-content">
                                                    <div @click="onClickNavigateToProductDetail(item)">
                                                        <h5 class="product-name">{{ item.convertProduct.productName }}</h5>
                                                        <p class="product-notes">{{ item.convertProduct.productDescription }}</p>
                                                    </div>
                                                    <hr />
                                                    <div @click="onClickNavigateToView(item)">
                                                        <div>
                                                            <span class="product-review-list-stars">
                                                                <span v-for="starIndex in 5" :key="starIndex" :class="{ 'active': starIndex <= item.star }">★</span>
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <div v-html="item.convertContent"></div>
                                                            <p><span class="text-xs">등록일 : {{ item.convertRegDate }}</span></p>
                                                        </div>
                                                        <hr />
                                                        <template v-if="item.convertFiles?.length">
                                                            <div class="review-images">
                                                                <template v-for="(filesItem, filesIndex) in item.convertFiles" :key="filesIndex">
                                                                    <div><img :src="`${imgURL}${filesItem.upfilesThumnailPath}${filesItem.upfilesFileName}`" class="absolute-center"></div>
                                                                </template>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <div class="col-md-12 col-sm-12">
                                    <div class="card mt-4">
                                        <div class="card-body text-center">
                                            장바구니에 담은 상품이 없습니다.
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="row justify-content-center mt-1">
                                        <div class="col-12 col-sm-12 col-lg-12">
                                            <div class="product-list-paging">
                                                <div class="product-request etc">
                                                    <p class="font-size-xl">똑.독의 다양한 상품을 구경해보세요.</p>
                                                    <hr/>
                                                    <div class="product-request-group">
                                                        <div>
                                                            <span>A~Z까지 선택해서 상품을 찾아보세요.</span>
                                                            <span role="button" @click="router.push({ name: 'webProductCustomPage' })">상품 찾기</span>
                                                        </div>
                                                        <div>
                                                            <span>난, 게이밍 전문가!</span>
                                                            <span role="button" @click="onClickNavigateToProduct('gaming')">게이밍용</span>
                                                        </div>
                                                        <div>
                                                            <span>난, 그래픽/동영상 전문가!</span>
                                                            <span role="button" @click="onClickNavigateToProduct('professional')">전문가용</span>
                                                        </div>
                                                        <div>
                                                            <span>일반 사무, 업무용에는!</span>
                                                            <span role="button" @click="onClickNavigateToProduct('work')">업무용</span>
                                                        </div>
                                                        <div>
                                                            <span>이번 시험은 왠지 100점</span>
                                                            <span role="button" @click="onClickNavigateToProduct('study')">학습용</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>

						</div>
					</div>
                </div>
            </div>
        </div>

    </main>
</template>

<script>
import { defineComponent,onMounted, ref } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr } from '@/helper/utils/dateUtils.js'
import { truncateText, unescapeHtmlEntities } from '@/helper/utils/etcUtils.js'

export default defineComponent({
    name       : 'webMyPageReviewPage',
	components: {
	},
    setup() {
		const { $axiosConnect, imgURL, router } = commonMixin()

		const list            = ref([])
        const listCurrentPage = ref(1);
        const listPageSize    = ref(1000);
        const listTotalPages  = ref(0);
        const listAllCount    = ref(0);
        const listTotalCount  = ref(0);
        const listSearchCount = ref(0);

		const getList = async () => {
			try {
                let defaultParams = {
                    'page'       : listCurrentPage.value,
                    'pageSize'   : listPageSize.value
                };
                const response = await $axiosConnect('MEMBER', `/web/review`, 'GET', defaultParams);

                if(response.body){
                    list.value            = JSON.parse(JSON.stringify(response.body.list))
                    listAllCount.value    = JSON.parse(JSON.stringify(response.body.allTotal))
                    listTotalCount.value  = JSON.parse(JSON.stringify(response.body.total))
                    listTotalPages.value  = listTotalCount.value > 0 ? Math.ceil(listTotalCount.value / listPageSize.value) : 1
                    listSearchCount.value = list.value.length

					if(listSearchCount.value > 0){
						list.value = list.value.map(list => {
							return {
								...list,
								convertUid           : list.uid.toString().padStart(6, '0'),
								convertSubject       : truncateText(list.subject, 75),
								convertContent       : unescapeHtmlEntities(list.content),
								convertRegMemberName : truncateText(list.regMemberName, 75),
								convertRegDate       : convertDateToStr(list.regDate),
								convertUpDate        : convertDateToStr(list.upDate),
								convertProduct       : list.product[0],
								convertProductImages : list.product[0]?.upfiles.filter(file => file.upfilesColumn === 'productImages') || [],
                                convertFiles         : list.upfiles.filter(file => file.upfilesColumn === 'files'),
							}
						})
					}
                }
				
            } catch (error) {
                console.error("error:", error)
            }
		}

		const onChangeListPage = (page) => {
            if(page){
                listCurrentPage.value = page
            }
            getList()
        }
        const onChangeListPreviousPage = () => {
            if (listCurrentPage.value > 1) {
                listCurrentPage.value--
                getList()
            }
        }

        const onChangeListNextPage = () => {
            if (listCurrentPage.value < listTotalPages.value) {
                listCurrentPage.value++
                getList()
            }
        }

		const onClickNavigateToProductDetail = (item) => {
            const uid = item.productUid
            router.push({ name: 'webProductDetailPage', params: { uid } });
		}

        const onClickNavigateToView = (item) => {
            const uid = item.uid
            router.push({ name: 'webMyPageReviewDetailPage', params: { uid }, query: { page: listCurrentPage.value } });
        }

        const onClickNavigateToProduct = (searchPurpose) => {
            router.push({ name: 'webProductListPage', params: { searchPurpose: searchPurpose } });
        }

		const initialize = async () => {
			await getList()
        }

        onMounted(async () => {
            await initialize()
        })

		return {
			router,
			imgURL,

			list,
			listCurrentPage,
			listTotalPages,

			onChangeListPreviousPage,
			onChangeListPage,
			onChangeListNextPage,
			onClickNavigateToProductDetail,
            onClickNavigateToView,
            onClickNavigateToProduct
        }
    }
});
</script>
<style scoped>
.product-image-review {
	position: relative;
	display: block;
	width: 100px;
	height: 100px;
}
.review-images {
    display: flex;
    flex-direction: row;
}
.review-images div {
    width: 50px;
    height: 50px;
    overflow: hidden;
    position: relative;
    margin-right: 5px;
}
</style>