<template>
    <div class="wrapper web-wrapper">
        <default-header />
        <div class="content-wrapper">
            <router-view />
        </div>
    </div>
    <default-footer />
</template>

<script>
import { defineComponent, onMounted, onBeforeUnmount } from 'vue';

import DefaultHeader from '@/layouts/inc/DefaultHeader'
import DefaultFooter from '@/layouts/inc/DefaultFooter'

export default defineComponent({
    name       : 'defaultLayoutPage',
    components : {
        'default-header': DefaultHeader,
        'default-footer': DefaultFooter
    },
    setup() {
        const preventDefault = (event) => {
            event.preventDefault()
            event.stopPropagation()
        }

        onMounted(() => {
            document.addEventListener('dragstart', preventDefault, { passive: false })
            document.addEventListener('drag', preventDefault, { passive: false })
            document.addEventListener('dragend', preventDefault, { passive: false })
            document.addEventListener('contextmenu', preventDefault)
        })

        onBeforeUnmount(() => {
            document.removeEventListener('dragstart', preventDefault)
            document.removeEventListener('drag', preventDefault)
            document.removeEventListener('dragend', preventDefault)
            document.removeEventListener('contextmenu', preventDefault)
        })
    }
})
</script>
<style scoped>
body {
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
}
</style>