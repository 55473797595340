<template>
    <main>
        <div class="container">
			<section class="content-header">
				<div class="container-fluid">
					<div class="row mb-2">
						<div class="col-sm-12">
							<div class="page-tab-title">
								<ul>
									<li class="active" @click="router.push({ name: 'webEventListPage' })"><span>이벤트</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
            <div class="content">
                <div class="container-fluid">
					<div class="row">
						<div class="col-md-12">

                            <div class="card">
                                <div class="card-body">
                                    <table class="table table-hover">
                                        <colgroup>
                                            <col />
                                        </colgroup>
                                        <tbody>
                                            <template v-for="(item, index) in list" :key="index">
                                                <tr @click="onClickViewPage(item.uid)" role="button">
                                                    <!--
                                                    <td>
                                                        {{ item.convertSubject }}
                                                        <div class="text-xs">{{ item.convertEventDate }}</div>
                                                    </td>
                                                    <td class="text-xs text-left position-relative">
                                                        {{ item.convertRegDate }}
                                                        <div class="ribbon-wrapper" v-html="item.convertState"></div>
                                                    </td>
                                                    -->
                                                    <td class="position-relative">
                                                        <img :src="item.convertTitleImages ? imgURL + item.convertTitleImages.upfilesThumnailPath + item.convertTitleImages.upfilesFileName : ''" :alt="item.subject" style="width: 100%;">
                                                        <div class="event-date">{{ item.convertEventDate }}</div>
                                                        <div class="ribbon-wrapper" v-html="item.convertState"></div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-footer">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <ul class="pagination pagination-sm m-0 float-right">
                                                <li class="page-item" v-if="listCurrentPage !== 1">
                                                    <span class="page-link" role="button" @click="onChangeListPreviousPage">&laquo;</span>
                                                </li>
                                                <li 
                                                    v-for="pageNumber in listTotalPages" :key="pageNumber"
                                                    :class="['page-item', { active: parseInt(listCurrentPage) === parseInt(pageNumber) }]"
                                                >
                                                    <span class="page-link" role="button" @click="onChangeListPage(pageNumber)">{{ pageNumber }}</span>
                                                </li>
                                                <li class="page-item" v-if="listCurrentPage < listTotalPages">
                                                    <span class="page-link" role="button" @click="onChangeListNextPage">&raquo;</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

						</div>
					</div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { truncateText  } from '@/helper/utils/etcUtils.js'

export default defineComponent({
    name       : 'webEventListPage',
    setup() {
        const { $axiosConnect, router, imgURL } = commonMixin()

		const list              = ref([])

        const listCurrentPage       = ref(1);
        const listPageSize          = ref(10);
        const listTotalPages        = ref(0);
        const listAllCount          = ref(0);
        const listTotalCount        = ref(0);
        const listSearchCount       = ref(0);
        const queryStringParams     = ref({})
        const applySearchParams     = ref({})

		const getList = async () => {
            try {
                let defaultParams = {
                    'page'     : listCurrentPage.value,
                    'pageSize' : listPageSize.value
                };
                applySearchParams.value = { ...queryStringParams.value }
                let params = Object.assign({}, defaultParams, queryStringParams.value);
                const response = await $axiosConnect('GUEST', `/web/event`, 'GET', params);

                if(response.body){
                    list.value            = JSON.parse(JSON.stringify(response.body.list))
                    listAllCount.value    = JSON.parse(JSON.stringify(response.body.allTotal))
                    listTotalCount.value  = JSON.parse(JSON.stringify(response.body.total))
                    listTotalPages.value  = listTotalCount.value > 0 ? Math.ceil(listTotalCount.value / listPageSize.value) : 1
                    listSearchCount.value = list.value.length
                }

                if(listSearchCount.value > 0){
                    list.value = list.value.map(list => {
                        return {
                            ...list,
                            convertUid            : list.uid.toString().padStart(6, '0'),
                            convertSubject        : truncateText(list.subject, 75),
                            convertRegDate        : convertDateToStr(list.regDate),
                            convertUpDate         : convertDateToStr(list.upDate),
                            convertEventStartDate : convertDateToStr(list.eventStartDate),
                            convertEventEndDate   : convertDateToStr(list.eventStartDate),
                            convertEventDate      : "이벤트 기간 : " + convertDateToStr(list.eventStartDate) + ' ~ ' + convertDateToStr(list.eventEndDate),
                            convertState          : list.state===1?'<div class="ribbon bg-primary">진행중</div>':'<div class="ribbon bg-secondary">종료</div>',
                            convertTitleImages    : list.upfiles.filter(file => file.upfilesColumn === 'titleImages')[0],
                        }
                    })

                    console.log(list.value);
                }

            } catch (error) {
                console.error("error:", error)
            }
        }
        
        const onChangeListPage = (page) => {
            if(page){
                listCurrentPage.value = page
            }
            getList()
        }
        const onChangeListPreviousPage = () => {
            if (listCurrentPage.value > 1) {
                listCurrentPage.value--
                getList()
            }
        }

        const onChangeListNextPage = () => {
            if (listCurrentPage.value < listTotalPages.value) {
                listCurrentPage.value++
                getList()
            }
        }
        
        const onGetSelectListTotalPages = computed(() => {
            const pages = []
            if (listTotalPages.value <= 20) {
                for (let i = 1; i <= listTotalPages.value; i++) {
                    pages.push(i)
                }
            } else {
                for (let i = 1; i <= 20; i++) {
                    pages.push(i)
                }
                for (let i = 30; i <= listTotalPages.value; i += 10) {
                    pages.push(i)
                }
                if (listCurrentPage.value > 20 && !pages.includes(listCurrentPage.value)) {
                    pages.push(listCurrentPage.value)
                }
            }
            return pages
        })

        const onClickViewPage = (uid) => {
            router.push({ name: 'webEventDetailPage', params: { uid: uid }, query: { page: listCurrentPage.value } });
        }

        const initialize = async () => {
            list.value              = []
            listTotalCount.value    = 0
            listSearchCount.value   = 0
            applySearchParams.value = {}
            queryStringParams.value = {}

            const routeQuery = router.currentRoute.value.query || {}
			const page = routeQuery.page || ''
            if(page){
                onChangeListPage(page)
            }
        }

        onMounted(async () => {
            await initialize()
            await getList()
        })

		return {
            imgURL,
            router,
            list,

            listCurrentPage,
            listPageSize,
            listTotalPages,
            listAllCount,
            listTotalCount,
            listSearchCount,
            onGetSelectListTotalPages,

            onChangeListPage,
            onChangeListPreviousPage,
            onChangeListNextPage,
            onClickViewPage,
        
        }
    }
});
</script>
<style scoped>
.event-date {
    position: absolute;
    padding: 2px 5px;
    background-color: #333;
    color: #FFF;
    border-radius: 1rem;
    font-size: 0.6rem;
    top: 5px;
    left: 5px;
}
</style>