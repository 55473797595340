<template>
    <div class="row">

        <!--회사소개-->
        <div class="col-md-6">
            <div class="sticky-top mb-3">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">회사소개</h4>
                        <div class="card-tools">
                            <button type="button" class="btn btn-primary btn-sm" @click="onClickSave()">저장</button>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <div class="p-2">
                            <smarteditor2 
                            v-model="initializeFormData.initializeCompanyInfo"
                            :editor-id="'editor1'"
                            ref="editor1"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--구독 도움말-->
        <div class="col-md-6">
            <div class="sticky-top mb-3">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">구독 도움말</h4>
                    </div>
                    <div class="card-body p-0">
                        <div class="p-2">
                            <smarteditor2 
                            v-model="initializeFormData.initializeWebsiteHelp"
                            :editor-id="'editor2'"
                            ref="editor2"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

		<!--이용약관-->
        <div class="col-md-6">
            <div class="sticky-top mb-3">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">이용약관 동의</h4>
                    </div>
                    <div class="card-body p-0">
                        <div class="p-2">
                            <smarteditor2 
                            v-model="initializeFormData.initializeAgreeService"
                            :editor-id="'editor3'"
                            ref="editor3"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--개인정보처리방침-->
        <div class="col-md-6">
            <div class="sticky-top mb-3">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">개인정보처리방침 동의</h4>
                    </div>
                    <div class="card-body p-0">
                        <div class="p-2">
                            <smarteditor2 
                            v-model="initializeFormData.initializeAgreePolicy"
                            :editor-id="'editor4'"
                            ref="editor4"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

		<!--개인정보 제공 동의-->
        <div class="col-md-6">
            <div class="sticky-top mb-3">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">개인정보 제공 동의</h4>
                    </div>
                    <div class="card-body p-0">
                        <div class="p-2">
                            <smarteditor2 
                            v-model="initializeFormData.initializeAgreeSharing"
                            :editor-id="'editor5'"
                            ref="editor5"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

		<!--정보수신 동의-->
        <div class="col-md-6">
            <div class="sticky-top mb-3">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">정보수신 동의</h4>
                    </div>
                    <div class="card-body p-0">
                        <div class="p-2">
                            <smarteditor2 
                            v-model="initializeFormData.initializeAgreeReceiving"
                            :editor-id="'editor6'"
                            ref="editor6"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'

import Alert from '@/modals/common/Alert'

import ComponentFormSmarteditor2 from "@/components/admin/ComponentFormSmarteditor2"

export default defineComponent({
    name       : 'adminWebsiteInfoPage',
    components : {
        'smarteditor2' : ComponentFormSmarteditor2,
		'alert'        : Alert,
    },
    setup() {
		const { $axiosConnect } = commonMixin()

		const initializeFormData = ref({
			initializeCompany           : '딕스랩',
			initializeAgreeService      : '',
            initializeAgreePolicy       : '',
            initializeAgreeSharing      : '',
            initializeAgreeReceiving    : '',
            initializeCompanyInfo       : '',
            initializeWebsiteHelp       : '',
		})

        const editor1 = ref(null)
        const editor2 = ref(null)
        const editor3 = ref(null)
        const editor4 = ref(null)
        const editor5 = ref(null)
        const editor6 = ref(null)

		const onClickSave = async () => {
            editor1.value?.syncContent()
            editor2.value?.syncContent()
            editor3.value?.syncContent()
            editor4.value?.syncContent()
            editor5.value?.syncContent()
            editor6.value?.syncContent()

			const formData = new FormData();
			for (const key in initializeFormData.value) {
				if (initializeFormData.value[key] !== '') {
					formData.set(key, initializeFormData.value[key]);
				}
			}
			onProcessSave(formData)
		}

		/**
         * 로그인 프로세스를 처리하는 메서드
         * @method onProcessSignIn
         * @param {FormData} formData - 로그인에 필요한 폼 데이터
         * @returns {void}
         */
		const onProcessSave = async (formData) => {
            try {
                const response = await $axiosConnect('MEMBER', `/admin/initialize/1`, 'POST', formData)

                if (response.success && response.body && response.body.list.length > 0) {
                    getDeatil()
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

		const getDeatil = async () => {
            try {
                const response = await $axiosConnect('MEMBER', `/admin/initialize/1`, 'GET')

                if (response.success && response.body && response.body.list.length > 0) {

                    const list = JSON.parse(JSON.stringify(response.body.list[0]))
                    initializeFormData.value = {
                        ...list
                    }
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

        const initialize = async () => {
            await getDeatil()
        }
        
        onMounted(async () => {
            await initialize()
        })

        return {
            initializeFormData,
            editor1,
            editor2,
            editor3,
            editor4,
            editor5,
            editor6,

			onClickSave
        }

    }
})
</script>