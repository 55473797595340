<template>
    <main>
        <div class="container">
			<section class="content-header">
				<div class="container-fluid">
					<div class="row mb-2">
						<div class="col-sm-12">
							<div class="page-tab-title">
								<ul>
									<li @click="onClickNavigateToPage('webNoticeListPage')"><span>공지사항</span></li>
                                    <!--<li class="active" @click="onClickNavigateToPage('webFaqListPage')"><span>FAQ</span></li>-->
                                    <li @click="onClickNavigateToPage('webContactPage', 'login')"><span>1:1 문의</span></li>
                                    <li @click="onClickNavigateToPage('webCallRequestPage', 'login')"><span>전화상담요청</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
            <div class="content">
                <div class="container-fluid">
					<div class="row">
						<div class="col-md-12">

                            <div class="card" style="min-height: 500px">
                                <div class="card-body">
                                    <table class="table table-hover web-table">
                                        <colgroup>
                                            <col />
                                        </colgroup>
                                        <tbody>
                                            <template v-for="(item, index) in list" :key="index">
                                                <tr @click="onClickExpandedRow(index)" role="button">
                                                    <td>
                                                        <i class="fas fa-q mr-2"></i> {{ item.convertSubject }}
                                                        <div v-show="expandedRow === index">
                                                            <hr />
                                                            <div v-html="item.convertContent"></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-footer">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <ul class="pagination pagination-sm m-0 float-right">
                                                <li class="page-item" v-if="listCurrentPage !== 1">
                                                    <span class="page-link" role="button" @click="onChangeListPreviousPage">&laquo;</span>
                                                </li>
                                                <li 
                                                    v-for="pageNumber in listTotalPages" :key="pageNumber"
                                                    :class="['page-item', { active: parseInt(listCurrentPage) === parseInt(pageNumber) }]"
                                                >
                                                    <span class="page-link" role="button" @click="onChangeListPage(pageNumber)">{{ pageNumber }}</span>
                                                </li>
                                                <li class="page-item" v-if="listCurrentPage < listTotalPages">
                                                    <span class="page-link" role="button" @click="onChangeListNextPage">&raquo;</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

						</div>
					</div>
                </div>
            </div>
        </div>
    </main>

    <modal-sign-in-confirm ref="modalSignInConfirmRef" @onCloseModal="onCloseModalSignInConfirm" />

</template>

<script>
import { defineComponent, onMounted, ref, computed } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { unescapeHtmlEntities  } from '@/helper/utils/etcUtils.js'

import ModalSignInConfirm from '@/modals/web/account/ModalSignInConfirm'

export default defineComponent({
    name       : 'WebFaqListPage',
    components: {
		'modal-sign-in-confirm' : ModalSignInConfirm
	},
    setup() {
        const { $axiosConnect, router, imgURL, storeSignInState } = commonMixin()

        const modalSignInConfirmRef = ref(null)

		const list                  = ref([])

        const listCurrentPage       = ref(1);
        const listPageSize          = ref(10);
        const listTotalPages        = ref(0);
        const listAllCount          = ref(0);
        const listTotalCount        = ref(0);
        const listSearchCount       = ref(0);
        const queryStringParams     = ref({})
        const applySearchParams     = ref({})

		const expandedRow           = ref(null)

		const getList = async () => {
            try {
                let defaultParams = {
                    'page'     : listCurrentPage.value,
                    'pageSize' : listPageSize.value
                };
                applySearchParams.value = { ...queryStringParams.value }
                let params = Object.assign({}, defaultParams, queryStringParams.value);
                const response = await $axiosConnect('GUEST', `/web/faq`, 'GET', params);

                if(response.body){
                    list.value            = JSON.parse(JSON.stringify(response.body.list))
                    listAllCount.value    = JSON.parse(JSON.stringify(response.body.allTotal))
                    listTotalCount.value  = JSON.parse(JSON.stringify(response.body.total))
                    listTotalPages.value  = listTotalCount.value > 0 ? Math.ceil(listTotalCount.value / listPageSize.value) : 1
                    listSearchCount.value = list.value.length
                }

                if(listSearchCount.value > 0){
                    list.value = list.value.map(list => {
                        return {
                            ...list,
                            convertUid     : list.uid.toString().padStart(6, '0'),
                            convertSubject : list.subject,
                            convertRegDate : convertDateToStr(list.regDate),
                            convertUpDate  : convertDateToStr(list.upDate),
							convertContent : unescapeHtmlEntities(list.content)
                        }
                    })
                }

            } catch (error) {
                console.error("error:", error)
            }
        }
        
        const onChangeListPage = (page) => {
            if(page){
                listCurrentPage.value = page
            }
            getList()
        }
        const onChangeListPreviousPage = () => {
            console.log(">", listCurrentPage.value)
            if (listCurrentPage.value > 1) {
                listCurrentPage.value--
                getList()
            }
        }

        const onChangeListNextPage = () => {
            if (listCurrentPage.value < listTotalPages.value) {
                listCurrentPage.value++
                getList()
            }
        }
        
        const onGetSelectListTotalPages = computed(() => {
            const pages = []
            if (listTotalPages.value <= 20) {
                for (let i = 1; i <= listTotalPages.value; i++) {
                    pages.push(i)
                }
            } else {
                for (let i = 1; i <= 20; i++) {
                    pages.push(i)
                }
                for (let i = 30; i <= listTotalPages.value; i += 10) {
                    pages.push(i)
                }
                if (listCurrentPage.value > 20 && !pages.includes(listCurrentPage.value)) {
                    pages.push(listCurrentPage.value)
                }
            }
            return pages
        })

        const onClickExpandedRow = (index) => {
            expandedRow.value = expandedRow.value === index ? null : index;
        }

        const onClickNavigateToPage = (pageName, loginYN = '') => {
            if (loginYN) {
                if (storeSignInState.value) {
                    router.push({ name: pageName });
                } else {
                    onClickModalSignInConfirm();
                }
            } else {
                router.push({ name: pageName });
            }
        }

        const onClickModalSignInConfirm = () => {
            modalSignInConfirmRef.value.open();
        }

        const onCloseModalSignInConfirm = (payload) => {
            if (payload.isUpdate) {
				const redirectPath = router.currentRoute.value.fullPath
				router.push({
					name : 'webSignInPage',
					query: { redirect: redirectPath }
				})
            }
        }

        const initialize = async () => {
            list.value              = []
            listTotalCount.value    = 0
            listSearchCount.value   = 0
            queryStringParams.value["searchTarget[STATE]"] = 1
            applySearchParams.value = { ...queryStringParams.value }

            const routeQuery = router.currentRoute.value.query || {}
			const page = routeQuery.page || ''
            if(page){
                onChangeListPage(page)
            }
        }

        onMounted(async () => {
            await initialize()
            await getList()
        })

		return {
            imgURL,
            router,
            list,

            listCurrentPage,
            listPageSize,
            listTotalPages,
            listAllCount,
            listTotalCount,
            listSearchCount,
            onGetSelectListTotalPages,
			expandedRow,

            onChangeListPage,
            onChangeListPreviousPage,
            onChangeListNextPage,
            onClickExpandedRow,
            onClickNavigateToPage,
            modalSignInConfirmRef,
            onCloseModalSignInConfirm
        
        }
    }
});
</script>