<template>
    <Modal ref="baseModal" :title="`제품 ${mode === 'create' ? '추가' : '수정'}`" :width="`800px`">
        <template #body>
			<form ref="itemFormRef" @submit.prevent="onClickSave">
                <div class="form-group row form-required">
                    <label for="inputItemType" class="col-sm-2 col-form-label">제품구분</label>
                    <div class="col-sm-10">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-check-circle"></i></span>
                            </div>
                            <div class="form-control">
                                <label v-for="(item, key) in storeItemType" :key="key" class="input-label mr-3" :for="`itemType_${key}`">
                                    <input type="radio"
                                        :id="`itemType_${key}`"
                                        name="itemType"
                                        :value="key"
                                        v-model="itemFormData.itemType"
                                        @input="onInputCheckEvent" 
                                        required
                                    />
                                    <span class="radio-icon"></span>
                                    <span class="label-text">{{ item }}</span>
                                </label>
                            </div>
                        </div>
                        <small class="input-hint">제품구분은 필수로 입력해야 합니다.</small>
                    </div>
                </div>
                <template v-for="(item, index) in itemFormData.convertItemCategory" :key="index">
                    <div class="form-group row">
                        <label for="inputItemCategory" class="col-sm-2 col-form-label">
                            카테고리({{ index + 1 }}) 
                        </label>
                        <div class="col-sm-4">
                            <div class="input-group">
                                <select 
                                v-model="item.parentUid"
                                class="form-control" 
                                autocomplete="off"
                                @change="onInputCheckEvent" 
                                >
                                    <option value="">-- 선택 --</option>
                                    <option v-for="(parent, index) in parentCategoryList" :key="index" :value="parent.categoryUid">{{ parent.categoryName }}</option>
                                </select>
                            </div>
                            <small class="input-hint"></small>
                        </div>
                        <div class="col-sm-3">
                            <div class="input-group">
                                <select 
                                v-model="item.childUid"
                                class="form-control" 
                                autocomplete="off"
                                >
                                    <option value="">-- 선택 --</option>
                                    <option v-for="(child, index) in getCategoryFilteredChildList(item)" :key="index" :value="child.categoryUid">{{ child.categoryName }}</option>
                                </select>
                            </div>
                            <small class="input-hint"></small>
                        </div>
                        <div class="col-sm-3">
                            <div class="input-group">
                                <select 
                                v-model="item.thirdUid"
                                class="form-control" 
                                autocomplete="off"
                                >
                                    <option value="">-- 선택 --</option>
                                    <option v-for="(third, index) in getCategoryFilteredThirdList(item)" :key="index" :value="third.categoryUid">{{ third.categoryName }}</option>
                                </select>
                            </div>
                            <small class="input-hint"></small>
                        </div>
                    </div>
                </template>
				<div class="form-group row form-required">
					<label for="inputItemName" class="col-sm-2 col-form-label">제품명</label>
					<div class="col-sm-10">
						<div class="input-group">
							<div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
							<input type="text" class="form-control" id="inputItemName" placeholder="제품명을 입력해주세요." autocomplete="off" tabindex="1"
							required 
							maxlength="150" 
                            pattern-hint="제품명은 필수로 입력해야 합니다."
							v-model="itemFormData.itemName"
							@input="onInputCheckEvent" 
							/>
						</div>
                        <small class="input-hint"></small>
					</div>
				</div>
				<div class="form-group row form-required">
                    <label for="inputItemState" class="col-sm-2 col-form-label">사용여부</label>
                    <div class="col-sm-10">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-check-circle"></i></span>
                            </div>
                            <div class="form-control">
                                <label v-for="(item, key) in storeState" :key="key" class="input-label mr-3" :for="`itemState_${key}`">
                                    <input type="radio"
                                        :id="`itemState_${key}`"
                                        name="itemState"
                                        :value="key"
                                        v-model="itemFormData.itemState"
                                        @input="onInputCheckEvent" 
                                        required
                                    />
                                    <span class="radio-icon"></span>
                                    <span class="label-text">{{ item }}</span>
                                </label>
                            </div>
                        </div>
                        <small class="input-hint">사용여부는 필수로 입력해야 합니다.</small>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="inputContent" class="col-sm-2 col-form-label">비고</label>
                    <div class="col-sm-10">
                        <div class="pt-2 pb-2">
                            <smarteditor2 
                            v-model="itemFormData.convertItemNotes"
                            :editor-id="'editor1'"
                            ref="editor1"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
					<label for="selectItemState" class="col-sm-2 col-form-label">제품이미지</label>
					<div class="col-sm-10">
						<div class="input-group">
							<component-form-file-upload 
                                ref="itemImagesRef"
                                :maxSize="10240"
                                accept="png"
                                :initialFiles = itemFormData.itemImages
                                @on-click-attach-file="onClickItemImageFile"
                            />
						</div>
					</div>
				</div>
			</form>
        </template>
        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">닫기</button>
			<button type="submit" class="btn btn-primary" :disabled="submitButtonDisabled" @mouseenter="onOverSubmitButton" @click="onClickSave()">{{ mode === 'create'?'저장':'수정' }}</button>
        </template>
	</Modal>

	<alert ref="alertRef" :title="'알림'" />
    
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { unescapeHtmlEntities  } from '@/helper/utils/etcUtils.js'

import Modal from '@/modals/Modal'
import Alert from '@/modals/common/Alert'

import ComponentFormFileUpload from "@/components/admin/ComponentFormFileUpload"
import ComponentFormSmarteditor2 from "@/components/admin/ComponentFormSmarteditor2"

export default defineComponent({
    name       : 'modalItemUpdatePage',
    components: {
		Modal,
		'alert' : Alert,
        'component-form-file-upload' : ComponentFormFileUpload,
        'smarteditor2' : ComponentFormSmarteditor2,
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){
		const { openModal, closeModal, baseModal, onHandleValidateInput, $axiosConnect, storeState, storeItemType } = commonMixin()

        const mode                 = ref('create')
        const closeModalData       = ref({})
        const alertRef             = ref(null)
        const submitButtonDisabled = ref(true)

        const itemFormRef          = ref(null)
        const itemFormData         = ref({})

        const itemImages           = ref([])

        const parentCategoryList   = ref([])
        const childCategoryList    = ref([])
        const thirdCategoryList    = ref([])

        const modalItemCategorySelectedRef  = ref(null)

        const editor1 = ref(null)

		const open = (payload) => {
            initialize()

            if (payload.item && payload.mode === 'update') {
                itemFormData.value = JSON.parse(JSON.stringify(payload.item))
                mode.value = payload.mode
                getDeatil()
            }

            //하위등록시
            if(payload.item && payload.item.uid){
                itemFormData.value.parentUid = payload.item.uid
            }

            return openModal()
		};

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			closeModal(closeModalData.value)
		};
        
		/**
         * 입력 값의 유효성을 검사하고, 암호화된 아이디와 비밀번호를 생성하는 메서드
         * 유효성 검사 결과에 따라 submitDisabled 속성을 설정합니다.
         * @method onInputCheckEvent
         * @returns {void}
         */
         const onInputCheckEvent = () => {
            return !(submitButtonDisabled.value = !onHandleValidateInput(itemFormRef.value))
        }

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onOverSubmitButton = () => {
            const formElements = itemFormRef.value.querySelectorAll('input, select, textarea')
            formElements.forEach(input => {
                input.blur()
            });
        }

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
        const onClickSave = async () => {
            if(onInputCheckEvent()){
                //Editor Content
                editor1.value?.syncContent()

                // Utility function for processing item data
                const processItemData = (items, keyPrefix) => {
                    const mergedData = items.map(item => [item.parentUid, item.childUid, item.thirdUid].filter(Boolean).join('/'))
                    formData.set(`${keyPrefix}`, items.map(item => item.thirdUid || item.childUid || item.parentUid).join(','))
                    formData.set(`${keyPrefix}All`, mergedData.join(','))
                }

                const formData = new FormData();
                for (const key in itemFormData.value) {
                    const value = itemFormData.value[key]
                    if (value !== null && value !== undefined) {
                        if (key === 'convertItemCategory') {
                            processItemData(value, 'itemCategory')
                        } else {
                            formData.set(key, value)
                        }
                    }
                }

                // 파일들을 FormData에 추가
                itemImages.value.forEach((file, index) => {
                    formData.append(`itemImages[${index}]`, file)
                })

                onProcessSave(formData)
            }
        }

        /**
         * 로그인 프로세스를 처리하는 메서드
         * @method onProcessSignIn
         * @param {FormData} formData - 로그인에 필요한 폼 데이터
         * @returns {void}
         */
        const onProcessSave = async (formData) => {
            try {

                let response
                if(mode.value === 'create'){
                    response = await $axiosConnect('MEMBER', `/admin/item`, 'POST', formData)
                }else{
                    const uid = itemFormData.value.uid
                    response = await $axiosConnect('MEMBER', `/admin/item/${uid}`, 'POST', formData)
                }

                if (response.success && response.body && response.body.list.length > 0) {
                    
                    initialize()

                    closeModalData.value = {
                        isUpdate : true,
                        item     : response.body.list[0]
                    }

                    alertRef.value.open({ 
                        'contents' : response.message,
                        'callback' : close
                    })

                }else{
                    alertRef.value.open({ 'contents' : response.message })
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

        const getDeatil = async () => {
            try {
                const uid = itemFormData.value.uid
                const response = await $axiosConnect('MEMBER', `/admin/item/${uid}`, 'GET')

                if (response.success && response.body && response.body.list.length > 0) {

                    const list = JSON.parse(JSON.stringify(response.body.list[0]))
                    itemFormData.value = {
                        ...list,
                        converUid        : list.uid.toString().padStart(8, '0'),
                        convertRegDate   : convertDateToStr(list.regDate),
                        convertUpDate    : convertDateToStr(list.upDate),
                        convertItemState : storeState.value[list.itemState],
                        convertItemType  : storeItemType.value[list.itemType],
                        itemImages       : list.upfiles.filter(file => file.upfilesColumn === 'itemImages'),
                        convertItemNotes         : list.itemNotes?unescapeHtmlEntities(list.itemNotes):'&nbsp;',
                        convertItemCategory      : list.itemCategoryAll ? list.itemCategoryAll.split(',').map(item => { const [parentUid, childUid, thirdUid] = item.split('/').map(Number); return { parentUid, childUid, thirdUid }; }).filter(item => item.parentUid || item.childUid || item.thirdUid) : [{}],
                    }

                }else{
                    alertRef.value.open({ 'contents' : response.message })
                    close()
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const onClickItemImageFile = (files) => {
            itemImages.value = files
            onInputCheckEvent()
        }

        const getCategoryList = async () => {

            try {
                let defaultParams = {
                    'page'          : 1,
                    'pageSize'      : 1000,
                    'categoryState' : 1
                }
                const response = await $axiosConnect('MEMBER', `/admin/categoryItem`, 'GET', defaultParams)

                let list = []
                if(response.body){
                    list = JSON.parse(JSON.stringify(response.body.list))
                    if(list.length > 0){
                        list = list.map(({ uid, categoryName, groups, depth }) => {
                            return {
                                categoryUid    : uid,
                                categoryName   : categoryName,
                                categoryGroups : groups,
                                categoryDepth  : depth
                            }
                        })
                    }
                    parentCategoryList.value = list.filter(category => category.categoryDepth === 0)
                    childCategoryList.value  = list.filter(category => category.categoryDepth === 1)
                    thirdCategoryList.value  = list.filter(category => category.categoryDepth === 2)
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const getCategoryFilteredChildList = (item) => {
            const parentCategory = parentCategoryList.value.find(parent => parent.categoryUid === item.parentUid)
            if (parentCategory) {
                const { categoryGroups } = parentCategory
                return childCategoryList.value.filter(child => categoryGroups === child.categoryGroups && child.categoryDepth === 1)
            }
            return []
        }
        
        const getCategoryFilteredThirdList = (item) => {
            const parentCategory = parentCategoryList.value.find(parent => parent.categoryUid === item.parentUid)
            if (parentCategory) {
                const { categoryGroups } = parentCategory
                return childCategoryList.value.filter(child => categoryGroups === child.categoryGroups && child.categoryDepth === 2)
            }
            return []
        }

        const initialize = async () => {
            mode.value         = "create"

            itemFormData.value = {
                itemName                : '',
                itemNotes               : '',
                itemState               : 1,
                itemType                : 1,
                itemImages              : [],
                convertItemCategory     : [{}],
                convertItemNotes        : ""
            }
            itemImages.value = []

            closeModalData.value = {
                isUpdate : false,
                item     : ''
            }

            await getCategoryList()
        }

        onMounted(async () => {
            await initialize()
        })

        return {
			open,
			close,
            mode,
            submitButtonDisabled,

			alertRef,
			itemFormRef,
			itemFormData,

            editor1,

            modalItemCategorySelectedRef,

            parentCategoryList,
            childCategoryList,
            thirdCategoryList,

			onInputCheckEvent,
            onOverSubmitButton,
            onClickSave,
            onClickItemImageFile,
            getCategoryFilteredChildList,
            getCategoryFilteredThirdList,

            //commonMixin
            storeState,
            storeItemType,
            baseModal, 
		}
    }
});
</script>
<style scoped>
::v-deep .ql-editor {
    height: 100px;
    overflow: scroll;
    overflow-x: hidden;
}
</style>