<template>
    <div class="row">
        <div :class="isViewSearch ? 'col-md-10' : 'col-md-12'">
            <div class="sticky-top mb-3">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">회원 목록</h4>
                        <div class="card-tools">
                            <button type="button" class="btn btn-default btn-sm" @click="onClickRefreshList()"><i class="fas fa-sync-alt"></i></button>&nbsp;
                            <button type="button" class="btn btn-danger btn-sm" @click="onClickModalMemberDelete()">선택 삭제</button>&nbsp;
                            <button type="button" class="btn btn-primary btn-sm" @click="onClickModalMemberUpdate('create')">회원 등록</button>&nbsp;
                            <button type="button" class="btn btn-outline-dark btn-sm" @click="onClickIsViewSearch()">검색{{ isViewSearch?'닫기':'열기' }}</button>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <div class="p-2">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="d-flex align-items-center h-100">총 {{ listAllCount }}건 중 {{ listTotalCount }}건의 검색되었습니다. 
                                        <span class="ml-2 text-bold">( 현재 {{ listCurrentPage }}페이지의 {{ listSearchCount }}건을 보고 있습니다. )</span>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <select class="form-control float-right w-auto text-sm" v-model="listPageSize" @change="onChangeListPageSize()">
                                        <option v-for="pageSize in onGetSelectListPageSize" :key="pageSize" :value="pageSize">
                                        {{ pageSize===listTotalCount?"전체보기":`${pageSize}줄 보기` }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <!--LIST START-->
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <colgroup>
                                    <col width="5%" />
                                    <col width="8%" />
                                    <col />
                                    <col />
                                    <col width="10%" />
                                    <col width="10%" />
                                    <col width="8%" />
                                    <col width="8%" />
                                    <col width="15%" />
                                    <col width="10%" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="input-checkbox">
                                                <label class="input-label" for="isAllSelected">
                                                    <input type="checkbox"
                                                    id="isAllSelected"
                                                    v-model="isAllSelected" 
                                                    @change="onClickAllSelected"
                                                    />
                                                    <span class="checkbox-icon"></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th>번호</th>
                                        <th>이름</th>
                                        <th>아이디</th>
                                        <th>이메일</th>
                                        <th>휴대폰</th>
                                        <th>권한</th>
                                        <th>상태</th>
                                        <th>등록일</th>
                                        <th>관리</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(item, index) in list" :key="index">
                                        <tr>
                                            <td>
                                                <div class="input-checkbox">
                                                    <label class="input-label" :for="`checkbox-${index}`">
                                                        <input type="checkbox"
                                                        :id="`checkbox-${index}`"
                                                        :value="item"
                                                        v-model="selectedList"
                                                        @change="onClickPartSelected()"
                                                        />
                                                        <span class="checkbox-icon"></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td role="button" @click="onClickExpandedRow(index)">{{ item.converUid }}</td>
                                            <td><span role="button" @click="onClickModalMemberDetail(item)">{{ item.memberName }}</span></td>
                                            <td>{{ item.memberId }}</td>
                                            <td>{{ item.memberEmail }}</td>
                                            <td>{{ item.memberMobile }}</td>
                                            <td>{{ item.converMemberAuthority }}</td>
                                            <td>{{ item.converMemberState }}</td>
                                            <td>
                                                {{ item.converRegDate }}
                                                <template v-if="item.converUpDate">
                                                    <br/><span class="text-xs">UPDATE : {{ item.converUpDate }}</span>
                                                </template>
                                            </td>
                                            <td>
                                                <button type="button" class="btn btn-primary btn-xs" @click="onClickModalMemberUpdate('update', item)">수정</button>
                                                &nbsp;
                                                <button type="button" class="btn btn-danger btn-xs" @click="onClickModalMemberDelete(item)">삭제</button>
                                            </td>
                                        </tr>
                                        <tr v-show="expandedRow === index">
                                            <td colspan="10">
                                                
                                                <div class="table-inner-box">
                                                    <div class="row">
                                                        <div class="col-sm-1"><span class="inner-label">등록정보</span></div>
                                                        <div class="col-sm-2"><span>{{ item.regMemberName }} ({{ item.regMemberId }})</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">등록일</span></div>
                                                        <div class="col-sm-2"><span>{{ item.converRegDate }}</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">등록IP</span></div>
                                                        <div class="col-sm-2"><span>{{ item.regIp }}</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">접속일</span></div>
                                                        <div class="col-sm-2"></div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-1"><span class="inner-label">수정정보</span></div>
                                                        <div class="col-sm-2"><span>{{ item.upMemberName }} ({{ item.upMemberId }})</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">수정일</span></div>
                                                        <div class="col-sm-2"><span>{{ item.converUpDate }}</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">수정IP</span></div>
                                                        <div class="col-sm-2"><span>{{ item.upIp }}</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">접속IP</span></div>
                                                        <div class="col-sm-2"></div>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <!--LIST END-->
                    </div>
                    <div class="card-footer clearfix">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <span class="mr-1 ml-1">전체 {{ listTotalPages }} 페이지 중</span>
                                <select class="form-control paging-select" v-model="listCurrentPage" @change="onChangeListPage()">
                                    <option v-for="pageNumber in onGetSelectListTotalPages" :key="pageNumber" :value="pageNumber">
                                        {{ pageNumber }}
                                    </option>
                                </select>
                                <span>페이지를 보고 있습니다</span>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <ul class="pagination pagination-sm m-0 float-right">
                                    <li class="page-item" v-if="listCurrentPage !== 1">
                                        <span class="page-link" @click="onChangeListPreviousPage">&laquo;</span>
                                    </li>
                                    <li class="page-item" v-for="pageNumber in listTotalPages" :key="pageNumber">
                                        <span class="page-link" @click="onChangeListPage(pageNumber)">{{ pageNumber }}</span>
                                    </li>
                                    <li class="page-item" v-if="listCurrentPage < listTotalPages">
                                        <span class="page-link" @click="onChangeListNextPage">&raquo;</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-2" v-if="isViewSearch">
            <form @submit.prevent="onClickSearchList">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">목록 검색</h3>
                    <div class="card-tools">
                        <button type="button" class="btn btn-success btn-sm" @click="onClickSearchReset()">초기화</button>&nbsp;
                        <button type="button" class="btn btn-primary btn-sm" @click="onClickSearchList()">검색</button>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="search-wrap">
                        <ul class="flex-column">
                            <li>
                                <div class="search-row">
                                    <div class="search-name">상태</div>
                                    <div>
                                        <component-form-checkbox
                                            ref="searchCheckMemberStateRef"
                                            checkbox-name="searchCheck[0][MEMBER_STATE]" 
                                            :checkbox-list="[
                                                { name: '사용', value: 1 },
                                                { name: '정지', value: 0 }
                                            ]" 
                                            :checkbox-default="[1, 0]" 
                                            @on-change-checkbox="onHandleSearchParam"
                                        />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="search-row">
                                    <div class="search-name">상태</div>
                                    <div>
                                        <component-form-checkbox
                                            ref="searchCheckMemberAuthorityRef"
                                            checkbox-name="searchCheck[1][MEMBER_AUTHORITY]" 
                                            :checkbox-list="[
                                                { name: '개인회원',       value: 10 },
                                                { name: '기업회원(개인)', value: 11 },
                                                { name: '기업회원(법인)', value: 12 }
                                            ]" 
                                            :checkbox-default="[10, 11, 12]" 
                                            @on-change-checkbox="onHandleSearchParam"
                                        />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="search-row">
                                    <div class="search-name">비밀번호 상태</div>
                                    <div class="w-100">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <component-form-radio
                                                    ref="searchTargetMemberPasswordState"
                                                    radio-name="searchTarget[MEMBER_PASSWORD_STATE]"
                                                    :radio-list="[
                                                        { name: '사용', value: 1 },
                                                        { name: '정지', value: 0 }
                                                    ]"
                                                    :radio-default="[1]"
                                                    @on-change-radio="onHandleSearchParam"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="search-row">
                                    <div class="search-name">기간검색</div>
                                    <div class="w-100">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12">
                                                <component-form-select
                                                    ref="searchDateRangeWhereRef"
                                                    select-name="searchDateRangeWhere" 
                                                    :select-list="[
                                                        { name: '등록일', value: 'REG_DATE' },
                                                        { name: '수정일', value: 'UP_DATE' }
                                                    ]" 
                                                    select-placeholder="- 검색기간 -" 
                                                    :select-default="[]" 
                                                    @on-change-select="onHandleSearchParam"
                                                />
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-sm-12 col-md-12">
                                                <component-form-date-range
                                                    ref="searchDateRangeRef"
                                                    date-range-name="searchDateRange"
                                                    :date-range-default="[]"
                                                    @on-change-date="onHandleSearchParam" 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="search-row">
                                    <div class="search-name">일자검색</div>
                                    <div class="w-100">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-5">
                                                <component-form-select
                                                    ref="searchDateWhereRef"
                                                    select-name="searchDateWhere" 
                                                    :select-list="[
                                                        { name: '수정일', value: 'UP_DATE' },
                                                        { name: '등록일', value: 'REG_DATE' }
                                                    ]" 
                                                    select-placeholder="- 구분 -" 
                                                    :select-default="[]" 
                                                    @on-change-select="onHandleSearchParam"
                                                />
                                            </div>
                                            <div class="col-sm-12 col-md-7">
                                                <component-form-date
                                                    ref="searchDateRef"
                                                    date-name="searchDate"
                                                    :date-default="[]"
                                                    @on-change-date="onHandleSearchParam" 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="search-row">
                                    <div class="search-name">검색어</div>
                                    <div class="w-100">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-5">
                                                <component-form-select
                                                    ref="searchTextWhereRef"
                                                    select-name="searchTextWhere" 
                                                    :select-list="[
                                                        { name: '상품명', value: 'PRODUCT_NAME' },
                                                    ]" 
                                                    select-placeholder="- 구분 -" 
                                                    :select-default="[]" 
                                                    @on-change-select="onHandleSearchParam"
                                                />
                                            </div>
                                            <div class="col-sm-12 col-md-7">
                                                <component-form-text
                                                    ref="searchTextRef"
                                                    text-name="searchText"
                                                    text-placeholder="검색어를 선택해주세요."
                                                    @on-change-text="onHandleSearchParam"
                                                ></component-form-text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            </form>
        </div>
    </div>

    <alert ref="alertRef" :title="'알림'" />
    <modal-member-update ref="modalMemberUpdateRef" @onCloseModal="onCloseModalMemberUpdate" />
    <modal-member-detail ref="modalMemberDetailRef" @onCloseModal="onCloseModalMemberDetail" />
    <modal-member-delete ref="modalMemberDeleteRef" @onCloseModal="onCloseModalMemberDelete" />

</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import Alert from '@/modals/common/Alert'

import ModalMemberUpdate from '@/modals/admin/member/ModalMemberUpdate'
import ModalMemberDetail from '@/modals/admin/member/ModalMemberDetail'
import ModalMemberDelete from '@/modals/admin/member/ModalMemberDelete'

import ComponentFormSelect    from "@/components/admin/ComponentFormSelect"
import ComponentFormCheckbox  from "@/components/admin/ComponentFormCheckbox"
import ComponentFormDateRange from "@/components/admin/ComponentFormDateRange"
import ComponentFormDate      from "@/components/admin/ComponentFormDate"
import ComponentFormText      from "@/components/admin/ComponentFormText"
import ComponentFormRadio     from "@/components/admin/ComponentFormRadio"

export default defineComponent({
    name       : 'adminMemberListPage',
    components : {
        'alert' : Alert,
        'modal-member-update': ModalMemberUpdate,
        'modal-member-detail': ModalMemberDetail,
        'modal-member-delete': ModalMemberDelete,
        'component-form-select'     : ComponentFormSelect,
        'component-form-checkbox'   : ComponentFormCheckbox,
        'component-form-date-range' : ComponentFormDateRange,
        'component-form-date'       : ComponentFormDate,
        'component-form-text'       : ComponentFormText,
        'component-form-radio'      : ComponentFormRadio
    },
    setup() {
        const { $axiosConnect, store } = commonMixin()

        const alertRef              = ref(null)
        const submitButtonDisabled  = ref(true)
        const list                  = ref([])

        const listCurrentPage       = ref(1);
        const listPageSize          = ref(10);
        const listTotalPages        = ref(0);
        const listAllCount          = ref(0);
        const listTotalCount        = ref(0);
        const listSearchCount       = ref(0);
        const listSearchTarget      = ref([])
        const queryStringParams     = ref({})
        const applySearchParams     = ref({})

        const isAllSelected         = ref(false)
        const selectedList          = ref([])

        const expandedRow           = ref(null)
        const isViewSearch          = ref(true)

        const modalMemberUpdateRef  = ref(null)
        const modalMemberDetailRef  = ref(null)
        const modalMemberDeleteRef  = ref(null)

        const storeAuthority = ref({})
        const storeState     = ref({})

        const searchCheckMemberStateRef       = ref(null)
        const searchCheckMemberAuthorityRef  = ref(null)
        const searchDateRangeWhereRef         = ref(null)
        const searchDateRangeRef              = ref(null)
        const searchDateWhereRef              = ref(null)
        const searchDateRef                   = ref(null)
        const searchTargetMemberPasswordState = ref(null)
        const searchTextWhereRef              = ref(null)
        const searchTextRef                   = ref(null)

        onMounted(() => {
            storeAuthority.value = store.getters["member/getAuthority"]
            storeState.value     = store.getters["common/getState"]
            getList()
        })

        const getList = async () => {
            try {
                let defaultParams = {
                    page    : listCurrentPage.value,
                    pageSize: listPageSize.value
                };
                let params = Object.assign({}, defaultParams, applySearchParams.value);
                const response = await $axiosConnect('MEMBER', `/admin/member`, 'GET', params);

                list.value            = []
                listAllCount.value    =  listTotalCount.value  = listTotalPages.value = listSearchCount.value = 0
                if(response.body){
                    list.value            = JSON.parse(JSON.stringify(response.body.list))
                    listAllCount.value    = JSON.parse(JSON.stringify(response.body.allTotal))
                    listTotalCount.value  = JSON.parse(JSON.stringify(response.body.total))
                    listTotalPages.value  = listTotalCount.value > 0 ? Math.ceil(listTotalCount.value / listPageSize.value) : 1
                    listSearchCount.value = list.value.length
                }
                
                if(listSearchCount.value > 0){
                    list.value = list.value.map(list => {
                        return {
                            ...list,
                            converUid             : list.uid.toString().padStart(8, '0'),
                            converRegDate         : convertDateToStr(list.regDate),
                            converUpDate          : convertDateToStr(list.upDate),
                            converMemberAuthority : storeAuthority.value[list.memberAuthority],
                            converMemberState     : storeState.value[list.memberState]
                        }
                    })
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const onChangeListPage = (page) => {
            if(page){
                listCurrentPage.value = page
            }
            getList()
        }
        const onChangeListPreviousPage = () => {
            if (listCurrentPage.value > 1) {
                listCurrentPage.value--
                getList()
            }
        }

        const onChangeListNextPage = () => {
            if (listCurrentPage.value < listTotalPages.value) {
                listCurrentPage.value++
                getList()
            }
        }

        const onChangeListPageSize = () => {
            getList()
        }
        

        const onClickExpandedRow = (index) => {
            expandedRow.value = expandedRow.value === index ? null : index;
        }

        const onGetSelectListTotalPages = computed(() => {
            const pages = []
            if (listTotalPages.value <= 20) {
                for (let i = 1; i <= listTotalPages.value; i++) {
                    pages.push(i)
                }
            } else {
                for (let i = 1; i <= 20; i++) {
                    pages.push(i)
                }
                for (let i = 30; i <= listTotalPages.value; i += 10) {
                    pages.push(i)
                }
                if (listCurrentPage.value > 20 && !pages.includes(listCurrentPage.value)) {
                    pages.push(listCurrentPage.value)
                }
            }
            return pages
        })

        const onGetSelectListPageSize = computed(() => {
            const pageSize = []

            pageSize.push(10);
            if(listTotalCount.value > 10 && listTotalCount.value <= 50){
                pageSize.push(20);
            }
            if(listTotalCount.value > 50){
                pageSize.push(50);
            }
            if(listTotalCount.value > 100){
                pageSize.push(100);
            }
            if(listTotalCount.value > 10 && (listTotalCount.value !== 10 || listTotalCount.value !== 50 || listTotalCount.value !== 100)){
                pageSize.push(listTotalCount.value);
            }

            return pageSize
        })

        const onClickSearchList = () => {
            applySearchParams.value = { ...queryStringParams.value }
            getList()
        }

        const onHandleSearchParam = (param) => {
            queryStringParams.value[param.name] = 
                param.selecteds.length > 0 ? 
                (param.selecteds.length > 1 ? param.selecteds : param.selecteds[0]) : []
        }

        const onClickRefreshList = () => {
            getList()
        }

        const onClickSearchReset = () => {
            queryStringParams.value = {}
            applySearchParams.value = {}
            searchCheckMemberStateRef.value?.onHandleReset()
            searchCheckMemberAuthorityRef.value?.onHandleReset()
            searchDateRangeWhereRef.value?.onHandleReset()
            searchDateRangeRef.value?.onHandleReset()
            searchDateWhereRef.value?.onHandleReset()
            searchDateRef.value?.onHandleReset()
            searchTextWhereRef.value?.onHandleReset()
            searchTextRef.value?.onHandleReset()
            searchTargetMemberPasswordState.value?.onHandleReset()
            getList()
        }

        const onClickAllSelected = () => {
            selectedList.value = []

            if(isAllSelected.value){
                Object.values(list.value).forEach(item => {
                    selectedList.value.push(item)
                })
            }
        }

        const onClickPartSelected = () => {
            isAllSelected.value = (selectedList.value.length === list.value.length)?true:false
        }

        const onClickModalMemberDetail = (item) => {
            modalMemberDetailRef.value.open({
                'mode' : 'detail',
                'item' : item
            })
        }

        const onCloseModalMemberDetail = (payload) => {
            if(payload.isUpdate){
                onClickModalMemberUpdate('update', payload.item)
            }
        }

        const onClickModalMemberUpdate = (mode, item) => {
            modalMemberUpdateRef.value.open({
                'mode' : mode,
                'item' : item
            })
        }

        const onCloseModalMemberUpdate = (payload) => {
            if(payload.isUpdate){
                getList()
            }
        }

        const onClickModalMemberDelete = (item) => {
            if(item || selectedList.value.length > 0){
                modalMemberDeleteRef.value.open({
                    'mode' : 'delete',
                    'item' : item ? [item] : selectedList.value
                })
            }else{
                alertRef.value.open({ 'contents' : '선택된 항목이 없습니다.' })
                return
            }
        }

        const onCloseModalMemberDelete = (payload) => {
            if(payload.isUpdate){
                selectedList.value = []
                getList()
            }
        }

        const onClickIsViewSearch = () => {
            isViewSearch.value = !isViewSearch.value
        }

        return {
            alertRef,
            submitButtonDisabled,
            list,
            expandedRow,
            modalMemberDetailRef,
            modalMemberUpdateRef,
            modalMemberDeleteRef,

            listCurrentPage,
            listPageSize,
            listTotalPages,
            listAllCount,
            listTotalCount,
            listSearchCount,
            listSearchTarget,
            isAllSelected,
            selectedList,
            isViewSearch,

            searchCheckMemberStateRef,
            searchCheckMemberAuthorityRef,
            searchDateRangeWhereRef,
            searchDateRangeRef,
            searchDateWhereRef,
            searchDateRef,
            searchTextWhereRef,
            searchTextRef,
            searchTargetMemberPasswordState,

            onChangeListPage,
            onChangeListPreviousPage,
            onChangeListNextPage,
            onChangeListPageSize,
            onClickExpandedRow,
            onGetSelectListTotalPages,
            onGetSelectListPageSize,
            onClickSearchList,
            onHandleSearchParam,
            onClickRefreshList,
            onClickSearchReset,
            onClickAllSelected,
            onClickPartSelected,
            onClickModalMemberUpdate,
            onCloseModalMemberUpdate,
            onClickModalMemberDetail,
            onCloseModalMemberDetail,
            onClickModalMemberDelete,
            onCloseModalMemberDelete,
            onClickIsViewSearch
        }

    }
});
</script>