<template>
    <Modal ref="baseModal" :title="agreeTitle" :width="`800px`" :isDrag="false" @close="close">
        <template #body>
            <div>
                <div class="modal-agree-text">
					<h3>개인정보 수집 및 이용 동의</h3><br><br>
					1. 개인정보 수집목적 및 이용목적 : <br>
					서비스 및 결제 신청 시 서비스 제공을 위해 본인 확인을 위한 개인정보를 수집·이용<br><br>

					2. 수집하는 개인정보 항목 :<br>
					- 서비스 및 결제 신청 시: 휴대전화번호, 주소, 결제 정보, 암호화된 이용자 확인값(CI)<br>
					- 이용자 본인 요청에 의한 본인확인 이력정보 제공<br>
					- 서비스 이용 과정이나 사업 처리 과정에서 서비스이용기록, 접속로그, 쿠키, 접속 IP, 결제 기록, 불량이용 기록이 생성되어 수집될 수 있습니다.<br><br>
					3. 개인정보의 보유기간 및 이용기간 :<br>
					회사"는 "고객"의 개인정보를 이용목적이 달성되면 지체없이 파기하며 별도의 보관을 하지 않습니다.<br><br>
					4. 미동의시 불이익<br>
					위 개인정보 수집 및 이용에 동의하지 않으실 경우 서비스를 이용하실 수 없습니다.<br><br>
					상기 내용과 같이 개인정보 이용에 동의합니다.
                </div>
            </div>
        </template>
        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">닫기</button>
        </template>
	</Modal>
</template>

<script>
import { defineComponent, ref, nextTick } from 'vue';
import Modal from '@/modals/Modal'

export default defineComponent({
    name       : 'modalSignUpAgreePage',
    components: {
		Modal
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){

        const baseModal = ref(null)
		const resolvePromise = ref(null)

        const submitButtonDisabled  = ref(false)
        const closeModalData        = ref({})
        const signUpAgreeContent    = ref(null)
        const signUpAgreeContentRef = ref(null)
        const agreeTitle            = ref('사용자 동의')
        
        const initialize = async () => {
			agreeTitle.value = '본인 확인 조회 동의';

            await nextTick();
            onScrollContent()
        }

		const open = async () => {
            submitButtonDisabled.value = false

            closeModalData.value = {
                isUpdate : false,
                item     : ''
            }

            //Open Modal
			baseModal.value.openModal()
            await initialize()
			return new Promise((resolve) => {
				resolvePromise.value = resolve;
			});
		};

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			baseModal.value.closeModal()
			resolvePromise.value(closeModalData.value)
		};

        const onScrollContent = () => {
            /*
            const content = signUpAgreeContentRef.value;
            if (content.scrollHeight <= content.clientHeight || content.scrollHeight - content.scrollTop === content.clientHeight) {
                submitButtonDisabled.value = false;
            }
            */
        };

        return {
			baseModal, 
			open,
			close,

            submitButtonDisabled,
            signUpAgreeContentRef,
            signUpAgreeContent,
            agreeTitle,

            onScrollContent
		}
    }
});
</script>