<template>
    <div class="row">
        <div :class="isViewSearch ? 'col-md-10' : 'col-md-12'">
            <div class="sticky-top mb-3">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">제품 목록</h4>
                        <div class="card-tools">
                            <button type="button" class="btn btn-default btn-sm" @click="onClickRefreshList()"><i class="fas fa-sync-alt"></i></button>&nbsp;
                            <button type="button" class="btn btn-danger btn-sm" @click="onClickModalItemDelete()">선택 삭제</button>&nbsp;
                            <button type="button" class="btn btn-primary btn-sm" @click="onClickModalItemUpdate('create')">제품 등록</button>&nbsp;
                            <button type="button" class="btn btn-outline-dark btn-sm" @click="onClickIsViewSearch()">검색{{ isViewSearch?'닫기':'열기' }}</button>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <div class="p-2">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="d-flex align-items-center h-100">총 {{ listAllCount }}건 중 {{ listTotalCount }}건의 검색되었습니다. 
                                        <span class="ml-2 text-bold">( 현재 {{ listCurrentPage }}페이지의 {{ listSearchCount }}건을 보고 있습니다. )</span>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <select class="form-control float-right w-auto text-sm" v-model="listPageSize" @change="onChangeListPageSize()">
                                        <option v-for="pageSize in onGetSelectListPageSize" :key="pageSize" :value="pageSize">
                                        {{ pageSize===listTotalCount?"전체보기":`${pageSize}줄 보기` }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <!--LIST START-->
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <colgroup>
                                    <col width="5%" />
                                    <col width="8%" />
                                    <col width="15%" />
                                    <col width="8%" />
                                    <col />
                                    <col width="10%" />
                                    <col width="5%" />
                                    <col width="10%" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="input-checkbox">
                                                <label class="input-label" for="isAllSelected">
                                                    <input type="checkbox"
                                                    id="isAllSelected"
                                                    v-model="isAllSelected" 
                                                    @change="onClickAllSelected"
                                                    />
                                                    <span class="checkbox-icon"></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th>번호</th>
                                        <th>카테고리</th>
                                        <th>제품구분</th>
                                        <th>제품명</th>
                                        <th>제품이미지</th>
                                        <th>상태</th>
                                        <th>관리</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(item, index) in list" :key="index">
                                        <tr>
                                            <td>
                                                <div class="input-checkbox">
                                                    <label class="input-label" :for="`checkbox-${index}`">
                                                        <input type="checkbox"
                                                        :id="`checkbox-${index}`"
                                                        :value="item"
                                                        v-model="selectedList"
                                                        @change="onClickPartSelected()"
                                                        />
                                                        <span class="checkbox-icon"></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td role="button" @click="onClickExpandedRow(index)">{{ item.converUid }}</td>
                                            <td>
                                                <template v-for="(category, categoryIndex) in item.convertItemCategory" :key="categoryIndex">
                                                    <span>{{ getCategoryName(category.parentUid) }}</span>
                                                    <p v-if="category.childUid" class="mb-0">{{ getCategoryName(category.childUid) }}</p>
                                                    <p v-if="category.thirdUid" class="mb-0">{{ getCategoryName(category.thirdUid) }}</p>
                                                </template>
                                            </td>
                                            <td>{{ item.convertItemType }}</td>
                                            <td 
                                            role="button" 
                                            @click="onClickModalItemDetail(item)"
                                            :class="item.itemState===1 ? '' : 'del'"
                                            >{{ item.itemName }}</td>
                                            <td>
                                                <div class="list-widget">
                                                    <div class="list-widget-item"
                                                    v-for="(file, fileIndex) in item.itemImages" 
                                                    :key="fileIndex"
                                                    ><img :src="`${imgURL}${file.upfilesThumnailPath}/${file.upfilesFileName}`" :alt="file.name" /></div>
                                                </div>
                                            </td>
                                            <td>{{ item.convertItemState }}</td>
                                            <td>
                                                <button type="button" class="btn btn-primary btn-xs" @click="onClickModalItemUpdate('update', item)">수정</button>
                                                &nbsp;
                                                <button type="button" class="btn btn-danger btn-xs" @click="onClickModalItemDelete(item)">삭제</button>
                                            </td>
                                        </tr>
                                        <tr v-show="expandedRow === index">
                                            <td colspan="7">
                                                <div class="table-inner-box">
                                                    <div class="row">
                                                        <div class="col-sm-1"><span class="inner-label">등록정보</span></div>
                                                        <div class="col-sm-2"><span>{{ item.regMemberName }} ({{ item.regMemberId }})</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">등록일</span></div>
                                                        <div class="col-sm-2"><span>{{ item.converRegDate }}</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">등록IP</span></div>
                                                        <div class="col-sm-2"><span>{{ item.regIp }}</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">노출수</span></div>
                                                        <div class="col-sm-2"><span>{{ item.itemViewCount }}</span></div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-1"><span class="inner-label">수정정보</span></div>
                                                        <div class="col-sm-2"><span>{{ item.upMemberName }} ({{ item.upMemberId }})</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">수정일</span></div>
                                                        <div class="col-sm-2"><span>{{ item.converUpDate }}</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">수정IP</span></div>
                                                        <div class="col-sm-2"><span>{{ item.upIp }}</span></div>
                                                        <div class="col-sm-1"></div>
                                                        <div class="col-sm-2"></div>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <!--LIST END-->
                    </div>
                    <div class="card-footer clearfix">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <span class="mr-1 ml-1">전체 {{ listTotalPages }} 페이지 중</span>
                                <select class="form-control paging-select" v-model="listCurrentPage" @change="onChangeListPage()">
                                    <option v-for="pageNumber in onGetSelectListTotalPages" :key="pageNumber" :value="pageNumber">
                                        {{ pageNumber }}
                                    </option>
                                </select>
                                <span>페이지를 보고 있습니다</span>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <ul class="pagination pagination-sm m-0 float-right">
                                    <li class="page-item" v-if="listCurrentPage !== 1">
                                        <span class="page-link" @click="onChangeListPreviousPage">&laquo;</span>
                                    </li>
                                    <li class="page-item" v-for="pageNumber in listTotalPages" :key="pageNumber">
                                        <span class="page-link" @click="onChangeListPage(pageNumber)">{{ pageNumber }}</span>
                                    </li>
                                    <li class="page-item" v-if="listCurrentPage < listTotalPages">
                                        <span class="page-link" @click="onChangeListNextPage">&raquo;</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-2" v-if="isViewSearch">
            <form @submit.prevent="onClickSearchList">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">검색</h3>
                    <div class="card-tools">
                        <button type="button" class="btn btn-success btn-sm" @click="onClickSearchReset()">초기화</button>&nbsp;
                        <button type="button" class="btn btn-primary btn-sm" @click="onClickSearchList()">검색</button>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="search-wrap">
                        <ul class="flex-column">
                            <li>
                                <div class="search-row">
                                    <div class="search-name">제품옵션</div>
                                    <div>
                                        <component-form-checkbox
                                            ref="searchCheckItemTypeRef"
                                            checkbox-name="searchCheck[0][ITEM_TYPE]" 
                                            :checkbox-list="[
                                                { name: '제품',   value: 1 },
                                                { name: '옵션',   value: 2 },
                                                { name: '서비스', value: 3 }
                                            ]" 
                                            :checkbox-default="[1, 2, 3]" 
                                            @on-change-checkbox="onHandleSearchParam"
                                        />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="search-row">
                                    <div class="search-name">상태</div>
                                    <div>
                                        <component-form-checkbox
                                            ref="searchCheckItemStateRef"
                                            checkbox-name="searchCheck[3][ITEM_STATE]" 
                                            :checkbox-list="[
                                                { name: '사용', value: 1 },
                                                { name: '정지', value: 0 }
                                            ]" 
                                            :checkbox-default="[1, 0]" 
                                            @on-change-checkbox="onHandleSearchParam"
                                        />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="search-row">
                                    <div class="search-name">검색어</div>
                                    <div class="w-100">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-5">
                                                <component-form-select
                                                    ref="searchTextWhereRef"
                                                    select-name="searchTextWhere" 
                                                    :select-list="[
                                                        { name: '제품명', value: 'ITEM_NAME' },
                                                    ]" 
                                                    select-placeholder="- 구분 -" 
                                                    :select-default="[]" 
                                                    @on-change-select="onHandleSearchParam"
                                                />
                                            </div>
                                            <div class="col-sm-12 col-md-7">
                                                <component-form-text
                                                    ref="searchTextRef"
                                                    text-name="searchText"
                                                    text-placeholder="검색어를 선택해주세요."
                                                    @on-change-text="onHandleSearchParam"
                                                ></component-form-text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            </form>
        </div>
    </div>

    <alert ref="alertRef" :title="'알림'" />
    <modal-item-update ref="modalItemUpdateRef" @onCloseModal="onCloseModalItemUpdate" />
    <modal-item-detail ref="modalItemDetailRef" @onCloseModal="onCloseModalItemDetail" />
    <modal-item-delete ref="modalItemDeleteRef" @onCloseModal="onCloseModalItemDelete" />

</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import Alert from '@/modals/common/Alert'

import ModalItemUpdate from '@/modals/admin/item/ModalItemUpdate'
import ModalItemDetail from '@/modals/admin/item/ModalItemDetail'
import ModalItemDelete from '@/modals/admin/item/ModalItemDelete'

import ComponentFormCheckbox  from "@/components/admin/ComponentFormCheckbox"
import ComponentFormSelect    from "@/components/admin/ComponentFormSelect"
import ComponentFormText      from "@/components/admin/ComponentFormText"

export default defineComponent({
    name       : 'adminItemListPage',
    components : {
        'alert' : Alert,
        'modal-item-update'      : ModalItemUpdate,
        'modal-item-detail'      : ModalItemDetail,
        'modal-item-delete'      : ModalItemDelete,
        'component-form-checkbox'   : ComponentFormCheckbox,
        'component-form-select'     : ComponentFormSelect,
        'component-form-text'       : ComponentFormText
    },
    setup() {
        const { $axiosConnect, storeState, storeItemType, imgURL } = commonMixin()

        const alertRef              = ref(null)
        const submitButtonDisabled  = ref(true)
        const categoryList          = ref([])

        const list                  = ref([])
        const listCurrentPage       = ref(1);
        const listPageSize          = ref(10);
        const listTotalPages        = ref(0);
        const listAllCount          = ref(0);
        const listTotalCount        = ref(0);
        const listSearchCount       = ref(0);
        const listSearchTarget      = ref([])
        const queryStringParams     = ref({})
        const applySearchParams     = ref({})

        const isAllSelected         = ref(false)
        const selectedList          = ref([])

        const expandedRow           = ref(null)
        const isViewSearch          = ref(true)

        const modalItemUpdateRef  = ref(null)
        const modalItemDetailRef  = ref(null)
        const modalItemDeleteRef  = ref(null)

        const searchCheckItemStateRef = ref(null)
        const searchCheckItemTypeRef  = ref(null)
        const searchTextWhereRef      = ref(null)
        const searchTextRef           = ref(null)

        const getList = async () => {
            try {
                let defaultParams = {
                    'page'     : listCurrentPage.value,
                    'pageSize' : listPageSize.value
                };
                let params = Object.assign({}, defaultParams, applySearchParams.value);
                const response = await $axiosConnect('MEMBER', `/admin/item`, 'GET', params);

                list.value         = []
                listAllCount.value =  listTotalCount.value  = listTotalPages.value = listSearchCount.value = 0

                if(response.body){
                    list.value            = JSON.parse(JSON.stringify(response.body.list))
                    listAllCount.value    = JSON.parse(JSON.stringify(response.body.allTotal))
                    listTotalCount.value  = JSON.parse(JSON.stringify(response.body.total))
                    listTotalPages.value  = listTotalCount.value > 0 ? Math.ceil(listTotalCount.value / listPageSize.value) : 1
                    listSearchCount.value = list.value.length

                    if(listSearchCount.value > 0){
                        list.value = list.value.map(list => {
                            return {
                                ...list,
                                converUid           : list.uid.toString().padStart(6, '0'),
                                converRegDate       : convertDateToStr(list.regDate),
                                converUpDate        : convertDateToStr(list.upDate),
                                convertItemState    : storeState.value[list.itemState],
                                convertItemType     : storeItemType.value[list.itemType],
                                itemImages          : list.upfiles.filter(file => file.upfilesColumn === 'itemImages'),
                                convertItemCategory : list.itemCategoryAll ? list.itemCategoryAll.split(',').map(item => { const [parentUid, childUid, thirdUid] = item.split('/').map(Number); return { parentUid, childUid, thirdUid }; }).filter(item => item.parentUid || item.childUid || item.thirdUid) : [],
                            }
                        })
                    }
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const onChangeListPage = (page) => {
            if(page){
                listCurrentPage.value = page
            }
            getList()
        }
        const onChangeListPreviousPage = () => {
            if (listCurrentPage.value > 1) {
                listCurrentPage.value--
                getList()
            }
        }

        const onChangeListNextPage = () => {
            if (listCurrentPage.value < listTotalPages.value) {
                listCurrentPage.value++
                getList()
            }
        }

        const onChangeListPageSize = () => {
            getList()
        }
        

        const onClickExpandedRow = (index) => {
            expandedRow.value = expandedRow.value === index ? null : index;
        }

        const onGetSelectListTotalPages = computed(() => {
            const pages = []
            if (listTotalPages.value <= 20) {
                for (let i = 1; i <= listTotalPages.value; i++) {
                    pages.push(i)
                }
            } else {
                for (let i = 1; i <= 20; i++) {
                    pages.push(i)
                }
                for (let i = 30; i <= listTotalPages.value; i += 10) {
                    pages.push(i)
                }
                if (listCurrentPage.value > 20 && !pages.includes(listCurrentPage.value)) {
                    pages.push(listCurrentPage.value)
                }
            }
            return pages
        })

        const onGetSelectListPageSize = computed(() => {
            const pageSize = []

            pageSize.push(10);
            if(listTotalCount.value > 10 && listTotalCount.value <= 50){
                pageSize.push(20);
            }
            if(listTotalCount.value > 50){
                pageSize.push(50);
            }
            if(listTotalCount.value > 100){
                pageSize.push(100);
            }
            if(listTotalCount.value > 10 && (listTotalCount.value !== 10 || listTotalCount.value !== 50 || listTotalCount.value !== 100)){
                pageSize.push(listTotalCount.value);
            }

            return pageSize
        })

        const onClickSearchList = () => {
            applySearchParams.value = { ...queryStringParams.value }
            getList()
        }

        const onHandleSearchParam = (param) => {
            queryStringParams.value[param.name] = 
                param.selecteds.length > 0 ? 
                (param.selecteds.length > 1 ? param.selecteds : param.selecteds[0]) : []
        }

        const onClickRefreshList = () => {
            getList()
        }

        const onClickSearchReset = () => {
            queryStringParams.value = {}
            applySearchParams.value = {}
            searchCheckItemStateRef.value?.onHandleReset()
            searchCheckItemTypeRef.value?.onHandleReset()
            searchTextWhereRef.value?.onHandleReset()
            searchTextRef.value?.onHandleReset()
            getList()
        }

        const onClickAllSelected = () => {
            selectedList.value = []

            if(isAllSelected.value){
                Object.values(list.value).forEach(item => {
                    selectedList.value.push(item)
                })
            }
        }

        const onClickPartSelected = () => {
            isAllSelected.value = (selectedList.value.length === list.value.length)?true:false
        }

        const onClickModalItemDetail = (item) => {
            modalItemDetailRef.value.open({
                'mode' : 'detail',
                'item' : item
            })
        }

        const onCloseModalItemDetail = (payload) => {
            if(payload.isUpdate){
                onClickModalItemUpdate('update', payload.item)
            }
        }

        const onClickModalItemUpdate = (mode, item) => {
            modalItemUpdateRef.value.open({
                'mode' : mode,
                'item' : item
            })
        }

        const onCloseModalItemUpdate = (payload) => {
            if(payload.isUpdate){
                getList()
            }
        }

        const onClickModalItemDelete = (item) => {
            if(item || selectedList.value.length > 0){
                modalItemDeleteRef.value.open({
                    'mode' : 'delete',
                    'item' : item ? [item] : selectedList.value
                })
            }else{
                alertRef.value.open({ 'contents' : '선택된 항목이 없습니다.' })
                return
            }
        }

        const onCloseModalItemDelete = (payload) => {
            if(payload.isUpdate){
                selectedList.value = []
                getList()
            }
        }

        const onClickIsViewSearch = () => {
            isViewSearch.value = !isViewSearch.value
        }

        const getCategoryList = async () => {
            try {
                let defaultParams = {
                    'page'          : 1,
                    'pageSize'      : 1000,
                    'categoryState' : 1
                }
                const response = await $axiosConnect('MEMBER', `/admin/categoryItem`, 'GET', defaultParams)

                let list = []
                if(response.body){
                    list = JSON.parse(JSON.stringify(response.body.list))
                    if(list.length > 0){
                        categoryList.value = list.map(({ uid, categoryName, groups, depth }) => {
                            return {
                                categoryUid    : uid,
                                categoryName   : categoryName,
                                categoryGroups : groups,
                                categoryDepth  : depth
                            }
                        })
                    }
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const getCategoryName = (uid) => {
            const category = categoryList.value.find(cat => cat.categoryUid === uid);
            return category ? category.categoryName : '';
        };

        const initialize = async () => {
            await getList()
            await getCategoryList()
        }
        
        onMounted(async () => {
            await initialize()
        })

        return {
            alertRef,
            submitButtonDisabled,
            list,
            expandedRow,
            modalItemDetailRef,
            modalItemUpdateRef,
            modalItemDeleteRef,

            listCurrentPage,
            listPageSize,
            listTotalPages,
            listAllCount,
            listTotalCount,
            listSearchCount,
            listSearchTarget,
            isAllSelected,
            selectedList,
            isViewSearch,

            searchCheckItemStateRef,
            searchCheckItemTypeRef,
            searchTextWhereRef,
            searchTextRef,

            imgURL,

            onChangeListPage,
            onChangeListPreviousPage,
            onChangeListNextPage,
            onChangeListPageSize,
            onClickExpandedRow,
            onGetSelectListTotalPages,
            onGetSelectListPageSize,
            onClickSearchList,
            onHandleSearchParam,

            onClickRefreshList,
            onClickSearchReset,
            onClickAllSelected,
            onClickPartSelected,
            onClickModalItemUpdate,
            onCloseModalItemUpdate,
            onClickModalItemDetail,
            onCloseModalItemDetail,
            onClickModalItemDelete,
            onCloseModalItemDelete,
            onClickIsViewSearch,
            getCategoryName
        }

    }
});
</script>