<template>
    <div class="file-upload">
        <div 
            class="file-upload-area"
            @dragover.prevent="onDragOver"
            @dragleave.prevent="onDragLeave"
            @drop.prevent="onDrop"
            :class="{ 'file-upload-area-dragover': isDragOver }"
        >
            <input 
                type="file" 
                name="files" 
                id="files" 
                @change="onChangeFile" 
                multiple 
                
                style="display: none;" 
                ref="fileInput"
            />
            <!--:accept="accept" -->
            <p @click="onClickFileInput">첨부할 파일을 파일을 넣으시거나 버튼을 이용해주세요.</p>
            <button type="button" class="btn btn-secondary btn-xs" @click="onClickFileInput">파일 첨부</button>
        </div>

        <div class="file-preview">
            <div v-for="(file, index) in uploadedFiles" :key="index" class="file-preview-item">
                <div class="file-preview-image-box">
                    <template v-if="file.isImage">
                        <img :src="file.url" :alt="file.name" class="file-preview-image" />
                    </template>
                    <template v-else>
                        <div class="file-preview-file"><i class="fas fa-file-alt"></i></div>
                    </template>
                </div>
                <p class="mb-0 file-name">{{ file.fullName }}</p><p class="mb-0">({{ file.size }} KB)</p>
                <span 
                class="file-preview-attach-text" 
                :class="file.isNew ? 'btn-danger' : 'btn-primary'"
                >{{ file.isNew ? '신규 파일' : '기존 파일' }}</span>
                <button type="button" class="btn-file-remove" @click="onClickRemoveFile(file, index)"><i class="fas fa-trash"></i></button>
                <!--
                <div v-if=isMoveButton>
                    <span @click="onClickOrderMoveFile(index, -1)" role="button" v-show="index !== 0"><i class="fas fa-circle-arrow-left"></i></span>
                    &nbsp;
                    <span @click="onClickOrderMoveFile(index, 1)" role="button" v-show="index < uploadedFiles.length - 1"><i class="fas fa-circle-arrow-right"></i></span>
                </div>
                -->
                <div>
                    <input 
                        type="number" 
                        name="order" 
                        v-model="file.order"
                        @change="onClickOrderChange"
                        style="border: 1px solid #CCC;"
                    />
                </div>
            </div>
        </div>
    </div>
    <alert ref="alertRef" :title="'알림'" />
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import commonMixin from '@/helper/mixins/commonMixin.js'
import Alert from '@/modals/common/Alert'

export default defineComponent({
    name: 'ComponentFormFileUpload',
    components: {
		'alert' : Alert
	},
    props: {
        maxSize: {
            type    : Number,
            default : 10240,    //10MB
            required: true,
        },
        accept: {
            type    : String,
            default : "*/*",
        },
        initialFiles: {
            type: Array,
            default: () => [],
        },
        mode : {
            type    : String,
            default : "update",
        }
    },
    emits: ['onClickAttachFile'],  // 파일 변경 시 이벤트를 부모로 emit
    setup(props, { emit }) {
        const { $axiosConnect } = commonMixin()

        const alertRef      = ref(null)

        const fileInput     = ref(null)
        const isDragOver    = ref(false)
        const uploadedFiles = ref([])
        const imgURL        = ref(process.env.VUE_APP_BASE_IMG_URL)

        const onHandleIsImage = (fileExtension) => {
            return ["jpg", "jpeg", "png", "gif"].includes(fileExtension)
        }

        const isMoveButton  = ref(false)
        isMoveButton.value = props.mode==="update"?false:true

        watch(() => props.initialFiles, (initialFiles) => {
            if(initialFiles.length > 0){
                uploadedFiles.value = initialFiles.map(file => ({
                    ...file,
                    fullName : `${file.upfilesOriginalName}.${file.upfilesExt}`,
                    name     : file.upfilesFileName,
                    size     : file.upfilesSize,
                    url      : `${imgURL.value}${file.upfilesThumnailPath}${file.upfilesFileName}`,
                    file     : [],
                    isImage  : onHandleIsImage(file.upfilesExt),
                    isNew    : false,
                    order    : file.upfilesOrder
                }))
            }

        }, { immediate: true });


        const onClickFileInput = () => {
            fileInput.value.click()
        }

        const onChangeFile = (e) => {
            onHandleFiles(e.target.files)
        }

        const onDragOver = () => {
            isDragOver.value = true
        }

        const onDragLeave = () => {
            isDragOver.value = false
        }

        const onDrop = (e) => {
            isDragOver.value = false
            onHandleFiles(e.dataTransfer.files)
        }

        const onHandleFiles = (files) => {
            Array.from(files).forEach(file => {
                const fileSize = Math.round((file.size / 1024) * 100) / 100
                const fileExtension = file.name.split(".").pop().toLowerCase()
                const fileName = file.name.split(".").shift()
                const isImage  = onHandleIsImage(fileExtension)

                if (fileSize > props.maxSize) {
                    alert(`File ${fileName} exceeds the maximum size of ${props.maxSize}KB.`)
                } else {
                    const reader = new FileReader()
                    reader.onload = (event) => {
                        uploadedFiles.value.push({
                            fullName : fileName + "." + fileExtension,
                            name     : fileName,
                            size     : fileSize,
                            url      : event.target.result,
                            file     : file,
                            isImage  : isImage,
                            isNew    : true,
                            order    : uploadedFiles.value.length + 1 
                        })
                        emit('onClickAttachFile', uploadedFiles.value)
                    }
                    reader.readAsDataURL(file)
                }
            })
        }

        const onClickRemoveFile = async (item, index) => {
            try {
                if (item.isNew) {
                    onHandleRemoveFile(index);
                } else {
                    const confirmDelete = confirm("기존 파일을 삭제하면 서버에서 삭제됩니다.\n삭제하시겠습니까?");
                    if (confirmDelete) {
                        const response = await $axiosConnect('MEMBER', `/common/uploads/${item.uid}`, 'DELETE');
                        if(response.success){
                            onHandleRemoveFile(index);
                        }else{
                            alertRef.value.open({ 'contents' : response.message })
                        }
                    }
                }
            } catch (error) {
                console.error("파일 삭제 중 오류 발생:", error);
            }
        };

        const onHandleRemoveFile = (index) => {
            uploadedFiles.value.splice(index, 1)
            emit('onClickAttachFile', uploadedFiles.value)
        }

        const onClickOrderMoveFile = (index, direction) => {
            const newIndex = index + direction;
            if (newIndex < 0 || newIndex >= uploadedFiles.value.length) return; // Prevent out of bounds

            // Swap the files
            const temp = uploadedFiles.value[index];
            uploadedFiles.value[index] = uploadedFiles.value[newIndex];
            uploadedFiles.value[newIndex] = temp;
            emit('onClickAttachFile', uploadedFiles.value)
        }

        const onClickOrderChange = () => {
            emit('onClickAttachFile', uploadedFiles.value)
        }

        return {
            fileInput,
            isDragOver,
            uploadedFiles,
            alertRef,
            isMoveButton,

            onClickFileInput,
            onChangeFile,
            onDragOver,
            onDragLeave,
            onDrop,
            onClickRemoveFile,
            onClickOrderMoveFile,
            onClickOrderChange
        }
    }
});
</script>