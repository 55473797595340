<template>
    <main>
        <div class="container">
			<section class="content-header">
				<div class="container-fluid">
					<div class="row mb-2">
						<div class="col-sm-12">
							<div class="page-tab-title">
								<ul>
									<li class="active" @click="router.push({ name: 'webEventListPage' })"><span>이벤트</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
            <div class="content">
                <div class="container-fluid">
					<div class="row">
						<div class="col-md-12">

                            <div class="card">
                                <div class="card-body position-relative">
                                    <!--
                                    <div class="font-weight-bold text-lg">{{ detail.subject }}</div>
                                    <div class="mt-1 text-xs">{{ detail.convertRegDate }}</div>
                                    -->
                                    <div>
                                        <img :src="detail.convertTitleImages ? imgURL + detail.convertTitleImages.upfilesThumnailPath + detail.convertTitleImages.upfilesFileName : ''" :alt="detail.subject" style="width: 100%">
                                    </div>
                                    <hr />
                                    <div class="mt-1 font-weight-bold text-md">{{ detail.convertEventDate }}</div>
                                    <hr />
                                    <div class="mt-3" v-html="detail.convertContent"></div>
                                    <div class="ribbon-wrapper" v-html="detail.convertState"></div>
                                </div>
                                <div class="card-footer">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <button class="btn btn-sub-2 float-right" @click="onClickList()">목록</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

						</div>
					</div>
                </div>
            </div>
        </div>
    </main>

    <alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { unescapeHtmlEntities  } from '@/helper/utils/etcUtils.js'

import Alert from '@/modals/common/Alert'

export default defineComponent({
    name       : 'webEventDetailPage',
    components: {
		'alert' : Alert,
	},
    props: {
        uid: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const { $axiosConnect, router, imgURL } = commonMixin()
        const alertRef       = ref(null)

		const detail = ref({})

        const getDeatil = async () => {
            try {
                const uid = props.uid
                const response = await $axiosConnect('GUEST', `/web/event/${uid}`, 'GET')

                if (response.success && response.body && response.body.list.length > 0) {

                    const list = JSON.parse(JSON.stringify(response.body.list[0]))
                    detail.value = {
                        ...list,
                        convertRegDate        : convertDateToStr(list.regDate),
                        convertUpDate         : convertDateToStr(list.upDate),
                        convertContent        : unescapeHtmlEntities(list.content),
                        convertEventStartDate : convertDateToStr(list.eventStartDate),
                        convertEventEndDate   : convertDateToStr(list.eventStartDate),
                        convertEventDate      : '이벤트 기간 : ' + convertDateToStr(list.eventStartDate) + ' ~ ' + convertDateToStr(list.eventEndDate),
                        convertState          : list.state===1?'<div class="ribbon bg-primary">진행중</div>':'<div class="ribbon bg-secondary">종료</div>',
                        convertTitleImages    : list.upfiles.filter(file => file.upfilesColumn === 'titleImages')[0],
                    }

                }else{
                    alertRef.value.open({ 'contents' : response.message })
                    close()
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const initialize = async () => {
            detail.value = []
        }

        const onClickList = () => {

            const routeQuery = router.currentRoute.value.query || {}
			const page = routeQuery.page || ''

            router.push({ name: 'webEventListPage', query: { page: page } });
        }

        onMounted(async () => {
            await initialize()
            await getDeatil()
        })

        watch(() => props.uid, async () => {
            await initialize();
            await getDeatil();
        });

		return {
            router,
            detail,
            imgURL,

            onClickList
        }
    }
});
</script>