import CryptoJS from 'crypto-js';

/**
 * 비밀번호를 암호화하는 함수입니다. SHA-256 해시 알고리즘을 사용합니다.
 * @async
 * @method encryptPassword
 * @param {string} password - 암호화할 비밀번호
 * @returns {Promise<string>} - 암호화된 비밀번호
 */
export const encryptPassword = async (password) => {
    if (!crypto || !crypto.subtle) {
      const hash = CryptoJS.SHA256(password);
      return hash.toString(CryptoJS.enc.Hex);
    }
    const encoder = new TextEncoder();
    const data = encoder.encode(password);
    const buffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(buffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    return hashHex;
};

/**
 * 주어진 키를 사용하여 일반적인 방식으로 텍스트를 암호화하는 함수입니다.
 * @method encryptNormal
 * @param {string} key - 암호화에 사용할 키
 * @param {string} originalString - 암호화할 평문 텍스트
 * @returns {string} - 암호화된 텍스트
 */
export const encryptNormal = (key, originalString) => {
    const iv = CryptoJS.lib.WordArray.random(16);
    const salt = CryptoJS.lib.WordArray.random(256);
    const iterations = 999;
    const encryptMethodLength = 64; 
  
    const hashKey = CryptoJS.PBKDF2(key, salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: encryptMethodLength / 8,
      iterations: iterations
    });
  
    const encrypted = CryptoJS.AES.encrypt(originalString, hashKey, {
      mode: CryptoJS.mode.CBC,
      iv: iv
    });
  
    const encryptedString = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
  
    const output = {
      ciphertext: encryptedString,
      iv: CryptoJS.enc.Hex.stringify(iv),
      salt: CryptoJS.enc.Hex.stringify(salt),
      iterations: iterations
    };
  
    const encryptedResult = CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(JSON.stringify(output))
    );
  
    return encryptedResult;
};

/**
 * 주어진 키를 사용하여 일반적인 방식으로 텍스트를 복호화하는 함수입니다.
 * @method decryptNormal
 * @param {string} key - 복호화에 사용할 키
 * @param {string} encryptedString - 복호화할 암호문 텍스트
 * @returns {string} - 복호화된 텍스트
 */
export const decryptNormal = (key, encryptedString) => {
  const json = JSON.parse(
    CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(encryptedString))
  );

  const salt = CryptoJS.enc.Hex.parse(json.salt);
  const iv = CryptoJS.enc.Hex.parse(json.iv);

  const encrypted = json.ciphertext;

  let iterations = parseInt(json.iterations);
  if (iterations <= 0) {
    iterations = 999;
  }

  const encryptMethodLength = 64;
  const hashKey = CryptoJS.PBKDF2(key, salt, {
    hasher: CryptoJS.algo.SHA512,
    keySize: encryptMethodLength / 8,
    iterations: iterations
  });

  const decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {
    mode: CryptoJS.mode.CBC,
    iv: iv
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};