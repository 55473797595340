<template>
    <div class="header">
        <div class="container">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <div class="page-tab-title">
                                <ul>
                                    <li class="active"><span>본인 확인 조회</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2 justify-content-center">
                        <div class="col-md-12 col-sm-12 text-center">
                            <span class="order-check-text">구독 가능여부 확인을 위하여 고객님의 본인 확인 조회를 진행합니다. (정보 조회는 신용점수에 영향을 주지 않습니다.)</span>
                        </div>
                    </div>
                </div>
            </section>
            <div class="content login-content">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-md-8 col-sm-12">
                            <div class="card" style="padding-left: 20%; padding-right: 20%;">
                                <div class="card-body join-card-body">
                                    <p class="login-box-msg">본인 확인 조회</p>

                                    <form ref="orderCheckFormRef" @submit.prevent="onClickOrderCheck">
                                        <div class="row justify-content-center">
                                            <div class="col-md-12 col-sm-12">
                                                <div class="form-group row form-required mb-3">
                                                    <label for="memberAgreeService" class="col-sm-3 col-form-label">본인 확인 조회</label>
													<div class="col-sm-9">
                                                        <div class="order-check-input-group">
                                                            <div class="input-group">
                                                                <label class="input-label" for="memberAgreeService">
                                                                    <input type="checkbox"
                                                                        id="memberAgreeService"
                                                                        name="memberAgreeService"
                                                                        v-model="memberAgreeService"
                                                                        required
                                                                        pattern-hint="정보조회 동의를 체크해야 합니다."
                                                                        @change="onInputCheckEvent()" 
                                                                    />
                                                                    <span class="checkbox-icon" tabindex="3"></span>
                                                                    <span class="label-text text-sm">동의합니다.</span>
                                                                </label>
                                                            </div>
                                                            <span class="text-xs" role="button"
                                                            style="
                                                                position: absolute;
                                                                right: 10px;
                                                                top: 10px;
                                                            "
                                                            @click="onClickModalConsentInfo()"
                                                            >본인 확인 조회 동의 자세히보기</span>
                                                        </div>
													</div>
                                                    <small class="input-hint with-label-3"></small>
												</div>
                                                <div class="form-group row form-required mb-3">
                                                    <label for="orderMemberName" class="col-sm-3 col-form-label">이름</label>
                                                    <div class="col-sm-9">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" id="orderMemberName" placeholder="이름을 입력해주세요." autocomplete="off" tabindex="1"
                                                            required
                                                            maxlength="50" 
                                                            pattern="^[a-zA-Z가-힣]+$"
                                                            pattern-hint="이름은 한글/영문으로 실명을 입력해야 합니다."
                                                            v-model="orderCheckFormData.orderMemberName"
                                                            readonly
                                                            @blur="onInputCheckEvent()" 
                                                            />
                                                        </div>
                                                    </div>
                                                    <small class="input-hint-block active with-label-3">이름은 마이페이지 > 내정보에서 변경 가능합니다.&nbsp;<span role="button" @click="onClickNavigateToPage('webMyInfoPage')">[내정보로 이동]</span>
                                                    </small>
                                                    <small class="input-hint with-label-3"></small>
                                                </div>
                                                <!--
												<div class="form-group row form-required mb-3">
                                                    <label for="orderResidentNumber" class="col-sm-3 col-form-label">주민등록번호</label>
                                                    <div class="col-sm-9">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" id="orderResidentNumber" placeholder="주민등록번호를 입력해주세요." autocomplete="off" tabindex="2"
                                                            required
                                                            maxlength="13" 
                                                            pattern="^\d{13}$"
                                                            pattern-hint="주민등록번호는 숫자로 정확히 입력해야 합니다."
                                                            v-model="orderCheckFormData.orderResidentNumber"
                                                            @blur="onInputCheckEvent()" 
                                                            />
                                                        </div>
                                                    </div>
                                                    <small class="input-hint with-label-3"></small>
                                                </div>
                                                -->
                                                <div class="form-group row form-required mb-3">
                                                    <label for="orderBirth" class="col-sm-3 col-form-label">생년월일</label>
                                                    <div class="col-sm-9">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" id="orderBirth" placeholder="생년월일을 입력해주세요." autocomplete="off" tabindex="2"
                                                            required
                                                            maxlength="8" 
                                                            pattern="/^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])$/"
                                                            pattern-hint="생년월일을 8자리 숫자로 정확히 입력해야 합니다."
                                                            v-model="orderCheckFormData.orderBirth"
                                                            @input="onInputCheckEvent()" 
                                                            :readonly="memberAuthNumberResult"
                                                            />
                                                        </div>
                                                    </div>
                                                    <small class="input-hint with-label-3"></small>
                                                </div>
                                                <div class="form-group row form-required mb-3">
                                                    <label for="orderMobile" class="col-sm-3 col-form-label align-self-baseline">휴대폰 번호</label>
                                                    <div class="col-sm-9">
                                                        <div class="row">
															<div class="col-md-12 col-sm-12">
																<div class="input-group">
                                                                    <input type="text" class="form-control" id="orderMobile" placeholder="휴대폰 번호를 입력해주세요." autocomplete="off" tabindex="5"
                                                                    required
                                                                    maxlength="11" 
                                                                    pattern="^010\d{8}$"
                                                                    pattern-hint="휴대폰 번호는 010으로 시작해야하며, 숫자만 입력해야 합니다."
                                                                    v-model="orderCheckFormData.orderMobile"
                                                                    @blur="onInputCheckEvent()" 
                                                                    :readonly="memberAuthNumberResult"
                                                                    />
                                                                </div>
															</div>
                                                        </div>
                                                        <div class="row mt-2">
                                                            <div class="col-sm-12">
                                                                <button type="button" class="btn btn-web-primary btn-block"
                                                                :disabled="!receiveMemberAuthNumberDisabled"
                                                                @click="onClickMemberAuthNumber()"
                                                                >본인인증하기</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <small class="input-hint with-label-3"></small>
                                                </div>
                                                <div class="form-group row form-required mb-3">
                                                    <label for="orderAuthNumber" class="col-sm-3 col-form-label">인증여부</label>
                                                    <div class="col-sm-9">
                                                        <div style="margin-top:5px">{{ memberAuthNumberYN }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-md-5 col-sm-12">
                                                <div class="mt-5 mb-3">
                                                    <button type="submit" class="btn btn-web-primary btn-block"
                                                     @mouseenter="onOverSubmitButton"
                                                     :disabled="submitButtonDisabled && !memberAuthNumberResult"
                                                    >다음</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <modal-consent-info ref="modalConsentInfoRef" />

</template>

<script>
import { defineComponent, ref, onMounted, computed, onBeforeUnmount } from 'vue'
import commonMixin from '@/helper/mixins/commonMixin.js'
import store from '@/store'

import ModalConsentInfo from '@/modals/web/common/ModalConsentInfo'

import SHA256 from 'crypto-js/sha256';

export default defineComponent({
    components: { 
        ModalConsentInfo
    },
    name: 'webOrderCheckPage',

    beforeRouteLeave(to, from, next) {
        if(to.name !== 'webOrderPaymentPage'){
            store.dispatch('common/cleanProductOrderData')
        }
        next()
    },

    setup() {
        const { onHandleValidateInput, store, router, storeMemberInfo } = commonMixin()

        const submitButtonDisabled = ref(true)
        const orderCheckFormRef    = ref(null)
        const orderCheckFormData   = ref({
			orderMemberName        : '',
			orderResidentNumber    : '',
			orderMobile            : '',
            orderBirth     : '',
            orderAuthNumber        : ''
        })
        const memberAgreeService   = ref(false)
        const productOrder         = ref({})

        const modalOrderCheckAgreeRef = ref(null)
        const memberAuthNumberYN      = ref('본인확인 인증을 진행해주세요.')
        const memberAuthNumberResult  = ref(false)

        const inicisIdentityVerification = ref({
            mid          : '',
            reqSvcCd     : '03',
            identifier   : '똑독',
            mTxId        : '',
            authHash     : '',
            flgFixedUser : 'Y',
            userName     : '',
            userPhone    : '',
            userBirth    : '',
            userHash     : '',
            reservedMsg  : 'isUseToken=Y',
            successUrl   : `${window.location.origin}/EXTEND/inicis/inicisIdentityVerificationSuccess.php`,
            failUrl      : `${window.location.origin}/EXTEND/inicis/inicisIdentityVerificationFail.php`,
            logoUrl      : `${window.location.origin}/CDN/common/logo_kor.png`
        })

        const modalConsentInfoRef = ref(null)

        /**
         * 입력 값의 유효성을 검사하고, 암호화된 아이디와 비밀번호를 생성하는 메서드
         * 유효성 검사 결과에 따라 submitDisabled 속성을 설정합니다.
         * @method onInputCheckEvent
         * @returns {void}
         */
        const onInputCheckEvent = () => {
            return !(submitButtonDisabled.value = !(onHandleValidateInput(orderCheckFormRef.value)))
        }

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onOverSubmitButton = () => {
            const formElements = orderCheckFormRef.value.querySelectorAll('input, select, textarea')
            formElements.forEach(input => {
                input.blur()
            });
        }

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
         const onClickOrderCheck = () => {
            if(onInputCheckEvent()){
                productOrder.value.orderCheckFormData = []
                productOrder.value.orderCheckFormData.push(orderCheckFormData.value)
                store.dispatch('common/setProductOrderData', productOrder.value);
                router.push({ name: 'webOrderPaymentPage' });
            }
        }

        const receiveMemberAuthNumberDisabled = computed(() => {
            return orderCheckFormData.value.orderMemberName && 
            orderCheckFormData.value.orderBirth.length === 8 &&
            orderCheckFormData.value.orderMobile.length === 11 &&
            memberAgreeService.value && 
            memberAuthNumberResult.value === false
        })

        const requestMemberAuthNumberDisabled= computed(() => {
            return orderCheckFormData.value.orderAuthNumber
        })

        const onClickNavigateToPage = (pageName) => {
                router.push({ name: pageName });
        }

        const onClickMemberAuthNumber = async () => {
            inicisIdentityVerification.value.userName  = orderCheckFormData.value.orderMemberName.trim()
            inicisIdentityVerification.value.userPhone = orderCheckFormData.value.orderMobile.trim()
            inicisIdentityVerification.value.userBirth = orderCheckFormData.value.orderBirth.trim()
            
            inicisIdentityVerification.value.mTxId = `DDOKDOK_${Date.now()}`
            inicisIdentityVerification.value.mid   = process.env.VUE_APP_INICIS_MID
            const aHash = SHA256(inicisIdentityVerification.value.mid + inicisIdentityVerification.value.mTxId + process.env.VUE_APP_INICIS_KEY).toString();
            const uHash = SHA256(inicisIdentityVerification.value.userName + inicisIdentityVerification.value.userPhone + inicisIdentityVerification.value.mTxId + inicisIdentityVerification.value.userBirth + inicisIdentityVerification.value.reqSvcCd).toString();
            inicisIdentityVerification.value.authHash = aHash
            inicisIdentityVerification.value.userHash = uHash

            const formData = new FormData()
            for (const key in inicisIdentityVerification.value) {
                formData.set(key, inicisIdentityVerification.value[key]);
            }
            
            const window = popupCenter();
            if (window) {
                // Create a hidden form and append the FormData values dynamically
                const form = document.createElement('form');
                form.setAttribute('method', 'POST');
                form.setAttribute('action', 'https://sa.inicis.com/auth');
                form.setAttribute('target', 'sa_popup');

                // Append all the form data from FormData object to the form
                for (let [key, value] of formData.entries()) {
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = key;
                    input.value = value;
                    form.appendChild(input);
                }

                // Submit the form to the popup window
                window.document.body.appendChild(form);
                form.submit();
            }

        }
        
        const popupCenter = () => {
            const _width = 400;
            const _height = 620;
            const xPos = (document.body.offsetWidth / 2) - (_width / 2); // Center alignment
            const yPos = (window.innerHeight / 2) - (_height / 2); // Center vertically
            const popupWindow = window.open('', 'sa_popup', `width=${_width}, height=${_height}, left=${xPos}, top=${yPos}, menubar=yes, status=yes, titlebar=yes, resizable=yes`)
            return popupWindow
        }

        const popupCloseCall = (result, resultCode, resultMsg) => {
            if(result && resultCode === '0000'){
                memberAuthNumberYN.value     = "본인 인증을 확인했습니다. 다음 버튼을 눌러 계속 진행해주세요."
                memberAuthNumberResult.value = true
            }else{
                memberAuthNumberYN.value     = "본인 인증에 실패했습니다. 본인 인증을 다시 시도해주세요."
                memberAuthNumberResult.value = false
            }
            console.log(resultMsg)
            onInputCheckEvent()
        }

        const onClickModalConsentInfo = () => {
            modalConsentInfoRef.value.open({
                'mode' : 'view',
                'item' : ''
            });
        }

        const initialize = async () => {
            submitButtonDisabled.value = true
            
            orderCheckFormData.value.orderMemberName = storeMemberInfo.value.memberName

            const getProductOrderData = computed(() => store.getters["common/getProductOrderData"])
            if (getProductOrderData.value && Object.keys(getProductOrderData.value).length > 0) {
                Object.assign(productOrder.value, getProductOrderData.value)
            } else {
                router.back()
                return
            }
        }

        onMounted(async () => {
            await initialize()
            window.popupCloseCall = popupCloseCall
        })

        onBeforeUnmount(() => {
            delete window.popupCloseCall
        });

        return {
            submitButtonDisabled,
            orderCheckFormRef,
            orderCheckFormData,
            receiveMemberAuthNumberDisabled,
            requestMemberAuthNumberDisabled,
            memberAgreeService,
            modalOrderCheckAgreeRef,
            router,
            memberAuthNumberYN,
            memberAuthNumberResult,
            modalConsentInfoRef,

            onInputCheckEvent,
            onOverSubmitButton,
            onClickOrderCheck,
            onClickNavigateToPage,
            onClickMemberAuthNumber,
            popupCloseCall,
            onClickModalConsentInfo
        }
    }
})
</script>