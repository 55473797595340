import AdminSignInPage                 from '@/views/admin/account/SignIn';

import AdminLayoutPage                 from '@/layouts/admin/AdminLayout';

import AdminMainPage                   from '@/views/admin/Main';

import AdminOrderListPage              from '@/views/admin/order/OrderList';

import AdminProductListPage            from '@/views/admin/product/ProductList';
import AdminCategoryProductListPage    from '@/views/admin/product/CategoryProductList';
import AdminCompositionProductListPage from '@/views/admin/product/CompositionProductList';

import AdminItemCategoryListPage       from '@/views/admin/item/ItemCategoryList';
import AdminItemListPage               from '@/views/admin/item/ItemList';

import AdminMemberListPage             from '@/views/admin/member/MemberList';

import AdminContactListPage            from '@/views/admin/cs/ContactList';
import AdminCallRequestListPage        from '@/views/admin/cs/CallRequestList';
import AdminNoticeListPage             from '@/views/admin/cs/NoticeList';
import AdminFaqListPage                from '@/views/admin/cs/FaqList';
import AdminEventListPage              from '@/views/admin/event/EventList';
import AdminEventResultListPage        from '@/views/admin/event/EventResultList';
import AdminBusinessRequestListPage    from '@/views/admin/cs/BusinessRequestList';

import AdminAdministratorListPage      from '@/views/admin/setting/AdministratorList';
import AdminWebsiteInfoPage            from '@/views/admin/setting/WebsiteInfo';
import AdminCompanyInfoPage            from '@/views/admin/setting/CompanyInfo';

const adminRoutes = [
    {
        path: '/admin',
        name: 'adminLayoutPage',
        components: {
            default: AdminLayoutPage
        },
        meta: { 
            requiresAuth: true,
            layout: 'admin'
        },
        children: [
            {
                path: '',
                name: 'adminMainPage',
                components: {
                    default: AdminMainPage
                }
            },

            //주문관리
            {
                path: 'order',
                name: 'adminOrderListPage',
                components: {
                    default: AdminOrderListPage
                }
            },
            
            //상품관리
            {
                path: 'product',
                name: 'adminProductListPage',
                components: {
                    default: AdminProductListPage
                }
            },
            {
                path: 'product/composition',
                name: 'adminCompositionProductListPage',
                components: {
                    default: AdminCompositionProductListPage
                }
            },
            {
                path: 'product/category',
                name: 'adminCategoryProductListPage',
                components: {
                    default: AdminCategoryProductListPage
                }
            },

            //제품관리
            {
                path: 'item/category',
                name: 'adminItemCategoryListPage',
                components: {
                    default: AdminItemCategoryListPage
                }
            },
            {
                path: 'item',
                name: 'adminItemListPage',
                components: {
                    default: AdminItemListPage
                }
            },

            //회원관리
            {
                path: 'member',
                name: 'adminMemberListPage',
                components: {
                    default: AdminMemberListPage
                }
            },
            
            //웹관리
            {
                path: 'contact',
                name: 'adminContactListPage',
                components: {
                    default: AdminContactListPage
                }
            },
            {
                path: 'callRequest',
                name: 'adminCallRequestListPage',
                components: {
                    default: AdminCallRequestListPage
                }
            },
            {
                path: 'notice',
                name: 'adminNoticeListPage',
                components: {
                    default: AdminNoticeListPage
                }
            },
            {
                path: 'faq',
                name: 'adminFaqListPage',
                components: {
                    default: AdminFaqListPage
                }
            },
            {
                path: 'event',
                name: 'adminEventListPage',
                components: {
                    default: AdminEventListPage
                }
            },
            {
                path: 'eventResult',
                name: 'adminEventResultListPage',
                components: {
                    default: AdminEventResultListPage
                }
            },
            {
                path: 'businessRequest',
                name: 'adminBusinessRequestListPage',
                components: {
                    default: AdminBusinessRequestListPage
                }
            },

            //설정관리
            {
                path: 'administrator',
                name: 'adminAdministratorListPage',
                components: {
                    default: AdminAdministratorListPage
                }
            },
            {
                path: 'websiteInfo',
                name: 'adminWebsiteInfoPage',
                components: {
                    default: AdminWebsiteInfoPage
                }
            },
            {
                path: 'companyInfo',
                name: 'adminCompanyInfoPage',
                components: {
                    default: AdminCompanyInfoPage
                }
            },
        ]
    },
    {
        path: '/admin/adminSignIn',
        name: 'adminSignInPage',
        components: {
            default: AdminSignInPage
        },
        meta: { 
            requiresAuth: false
        }
    }
];

export default adminRoutes;