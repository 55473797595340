const state = {
    isPageLoading: false,
    isDataLoading: false,
    state   : {
        '1' : '사용',
        '0' : '정지'
    },
    itemType   : {
        '1' : '제품',
        '2' : '옵션',
        '3' : '서비스',
    },
    callState   : {
        '0' : '통화대기',
        '1' : '통화완료'
    },
    answerState   : {
        '0' : '답변대기',
        '1' : '답변완료'
    },
    productPurpose   : {
        'gaming'       : '게이밍용',
        'professional' : '전문가용',
        'work'         : '업무용',
        'study'        : '학습용'
    },
    previousPage : null,
    productOrderData : {},
}

const mutations = {
    /*
     * SET_PAGE_LOADING 뮤테이션은 state의 isPageLoading 값을 설정합니다.
     */
    SET_PAGE_LOADING(state, isPageLoading) {
        state.isPageLoading = isPageLoading;
    },

    /*
     * SET_DATA_LOADING 뮤테이션은 state의 isDataLoading 값을 설정합니다.
     */
    SET_DATA_LOADING(state, isDataLoading) {
        state.isDataLoading = isDataLoading;
    },

    SET_PREVIOUS_PAGE(state, pageName) {
        state.previousPage = pageName;
    },

    SET_PRODUCT_ORDER_DATA(state, productOrderData) {
        state.productOrderData = productOrderData;
    },

    CLEAR_PRODUCT_ORDER_DATA(state) {
        state.productOrderData = {}
    },
    
}

const actions = {
    /*
     * setPageLoading 액션은 페이지 로딩 상태를 설정합니다.
     * 만약 isDataLoading이 true인 경우 실행되지 않습니다.
     */
    setPageLoading({ commit, state }, payload) {
        if (!state.isDataLoading) {
            commit('SET_PAGE_LOADING', payload);
        }
    },

    /*
     * setDataLoading 액션은 데이터 로딩 상태를 설정합니다.
     * 만약 isPageLoading이 true인 경우 실행되지 않습니다.
     */
    setDataLoading({ commit, state }, payload) {
        if (!state.isPageLoading) {
            commit('SET_DATA_LOADING', payload);
        }
    },

    setPreviousPage({ commit }, pageName) {
        commit('SET_PREVIOUS_PAGE', pageName);
    },

    setProductOrderData({ commit }, productOrderData) {
        commit('SET_PRODUCT_ORDER_DATA', productOrderData);
    },

    cleanProductOrderData({ commit }) {
        commit('CLEAR_PRODUCT_ORDER_DATA');
    },
}

const getters = {
    getIsPageLoading: state => {
        return state.isPageLoading;
    },
    
    getIsDataLoading: state => {
        return state.isDataLoading;
    },

    /*
     * 토큰 반환 - accessaccessToken, refalseaccessToken은 풀어서 사용
     * getaccessToken
     *
     * @param {Object} state - Vuex 상태 객체
     * @returns {boolean}
     */
    getState: state => {
        return state.state
    },

    getCallState: state => {
        return state.callState
    },

    getAnswerState: state => {
        return state.answerState
    },

    getItemType: state => {
        return state.itemType
    },

    /*
     * 토큰 반환 - accessaccessToken, refalseaccessToken은 풀어서 사용
     * getaccessToken
     *
     * @param {Object} state - Vuex 상태 객체
     * @returns {boolean}
     */
    getProductPurpose: state => {
        return state.productPurpose
    },

    getPreviousPage: state => {
        return state.previousPage;
    },

    getProductOrderData: state => {
        return state.productOrderData
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}