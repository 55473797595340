<template>
    <main>
        <div class="container">
			<section class="content-header">
				<div class="container-fluid">
					<div class="row mb-2">
						<div class="col-sm-12">
							<div class="page-tab-title">
								<ul>
									<li class="active" @click="onClickNavigateToPage('webNoticeListPage')"><span>공지사항</span></li>
                                    <!--<li @click="onClickNavigateToPage('webFaqListPage')"><span>FAQ</span></li>-->
                                    <li @click="onClickNavigateToPage('webContactPage', 'login')"><span>1:1 문의</span></li>
                                    <li @click="onClickNavigateToPage('webCallRequestPage', 'login')"><span>전화상담요청</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
            <div class="content">
                <div class="container-fluid">
					<div class="row">
						<div class="col-md-12">

                            <div class="card" style="min-height: 500px">
                                <div class="card-body">
                                    <div class="font-weight-bold text-lg">{{ detail.subject }}</div>
                                    <div class="mt-1 text-xs">{{ detail.convertRegDate }}</div>
                                    <hr />
                                    <template v-if="detail.convertFiles?.length">
                                        <div class="mt-1 text-xs">첨부파일 : 
                                            <template v-for="(filesItem, filesIndex) in detail.convertFiles" :key="filesIndex">
                                                <span class="ml-2" style="color: #aaa;" role="button" @click="onClickUpfileDownload(filesItem)"><i class="fas fa-file-arrow-down font-size-md"></i>&nbsp;{{ filesItem.upfilesOriginalName + '.' + filesItem.upfilesExt }}</span>
                                            </template>
                                        </div>
                                        <hr />
                                    </template>
                                    <div class="mt-3" v-html="detail.convertContent"></div>
                                </div>
                                <div class="card-footer">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <button class="btn btn-sub-2 float-right" @click="onClickList()">목록</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

						</div>
					</div>
                </div>
            </div>
        </div>
    </main>

    <alert ref="alertRef" :title="'알림'" />
    <modal-sign-in-confirm ref="modalSignInConfirmRef" @onCloseModal="onCloseModalSignInConfirm" />

</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { unescapeHtmlEntities  } from '@/helper/utils/etcUtils.js'

import Alert from '@/modals/common/Alert'
import ModalSignInConfirm from '@/modals/web/account/ModalSignInConfirm'

export default defineComponent({
    name       : 'WebNoticeDetailPage',
    components: {
		'alert' : Alert,
        'modal-sign-in-confirm' : ModalSignInConfirm
	},
    props: {
        uid: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const { $axiosConnect, router, imgURL, storeSignInState } = commonMixin()

        const modalSignInConfirmRef = ref(null)
        const alertRef              = ref(null)
		const detail                = ref({})
        
        imgURL

        const getDeatil = async () => {
            try {
                const uid = props.uid
                const response = await $axiosConnect('GUEST', `/web/notice/${uid}`, 'GET')

                if (response.success && response.body && response.body.list.length > 0) {

                    const list = JSON.parse(JSON.stringify(response.body.list[0]))
                    detail.value = {
                        ...list,
                        convertRegDate  : convertDateToStr(list.regDate),
                        convertUpDate   : convertDateToStr(list.upDate),
                        convertContent  : unescapeHtmlEntities(list.content),
                        convertFiles    : list.upfiles.filter(file => file.upfilesColumn === 'files'),
                    }

                }else{
                    alertRef.value.open({ 'contents' : response.message })
                    close()
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const onClickNavigateToPage = (pageName, loginYN = '') => {
            if (loginYN) {
                if (storeSignInState.value) {
                    router.push({ name: pageName });
                } else {
                    onClickModalSignInConfirm();
                }
            } else {
                router.push({ name: pageName });
            }
        }

        const onClickModalSignInConfirm = () => {
            modalSignInConfirmRef.value.open();
        }

        const onCloseModalSignInConfirm = (payload) => {
            if (payload.isUpdate) {
				const redirectPath = router.currentRoute.value.fullPath
				router.push({
					name : 'webSignInPage',
					query: { redirect: redirectPath }
				})
            }
        }

        const onClickList = () => {

            const routeQuery = router.currentRoute.value.query || {}
			const page = routeQuery.page || ''

            router.push({ name: 'webNoticeListPage', query: { page: page } });
        }

        const initialize = async () => {
            detail.value = []
        }

        onMounted(async () => {
            await initialize()
            await getDeatil()
        })

        watch(() => props.uid, async () => {
            await initialize();
            await getDeatil();
        });

		return {
            router,
            detail,

            onClickList,
            onClickNavigateToPage,
            modalSignInConfirmRef,
            onCloseModalSignInConfirm
        }
    }
});
</script>