<template>
	<div class="swiper-container"
		data-swiper
		data-options='{
			"spaceBetween": 20,
			"slidesPerView": 1,
			"observer": true,
			"observeParents": true,
			"breakpoints": {     
				"540": {
					"slidesPerView": 1,
					"spaceBetween": 0
				},
				"770": {
					"slidesPerView": 2
				},
				"1024": {
					"slidesPerView": 3
				},
				"1200": {
					"slidesPerView": 4
				},
				"1500": {
					"slidesPerView": 4
				}
			}
		}'
	>

	<div class="swiper-wrapper">
		<template v-for="(item, index) in productList" :key="index">
			<div class="swiper-slide align-self-stretch">
				<div class="product-item" data-aos-delay="000" @click="onClickNavigateToProductDetail(item.uid)" role="button">
					<div class="product-inner-item">
						<div class="product-item-type">
							<template v-if="item.productSubscribe === 1"><span class="product-item-subscribe-text">구독</span></template>
							<template v-if="item.productRental === 1"><span class="product-item-rental-text">렌탈형 구독</span></template>
							<template v-if="item.productSale === 1"><span class="product-item-sale-text">판매</span></template>
						</div>
						<picture class="product-image">
							<template v-if="item.convertProductImages && item.convertProductImages.length > 0 ">
								<img class="absolute-center" 
									:src="`${imgURL}${item.convertProductImages[0].upfilesThumnailPath}/${item.convertProductImages[0].upfilesFileName}`" 
									:alt="item.convertProductName"
								/>
							</template>
							<template v-else>
								<img class="absolute-center" 
									src="@/assets/images/common/logo_kor.png"
									:alt="item.convertProductName"
								/>
							</template>
						</picture>
						<div class="product-content">
							<h5 class="product-name">{{ item.convertProductName }}</h5>
							<p class="product-notes">{{ item.convertProductDescription }}</p>
							<div class="product-purpose">
								<template v-if="item.productPurposeGaming === 1"><span class="product-purpose-gaming">게이밍용</span></template>
								<template v-if="item.productPurposeProfessional === 1"><span class="product-purpose-professional">전문가용</span></template>
								<template v-if="item.productPurposeWork === 1"><span class="product-purpose-work">업무용</span></template>
								<template v-if="item.productPurposeStudy === 1"><span class="product-purpose-study">학습용</span></template>
							</div>
							<template v-if="item.productSubscribe === 1">
								<div class="product-price-box">
									<span class="product-price-subscribe-text" v-if="false">구독</span>
									<span class="product-price-before">월 {{ item.convertProductSubscribeRegularPrice }}원</span>
									<span class="product-price-after">월 {{ item.convertProductSubscribePrice }}원</span>
								</div>
							</template>
							<template v-else-if="item.productRental === 1">
								<div class="product-price-box">
									<span class="product-price-rental-text" v-if="false">렌탈형 구독</span>
									<span class="product-price-before">월 {{ item.convertProductRentalRegularPrice }}원</span>
									<span class="product-price-after">월 {{ item.convertProductRentalPrice }}원</span>
								</div>
							</template>
							<template v-else-if="item.productSale === 1">
								<div class="product-price-box">
									<span class="product-price-sale-text" v-if="false">판매</span>
									<span class="product-price-before">월 {{ item.convertProductSaleRegularPrice }}원</span>
									<span class="product-price-after">월 {{ item.convertProductSalePrice }}원</span>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>

	</div>
</template>
<script>
import { defineComponent, onMounted, nextTick, ref } from 'vue'
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { numberFormat, truncateText  } from '@/helper/utils/etcUtils.js'

import Swiper, {
    Navigation,
    Pagination,
    Scrollbar,
    Autoplay,
    Mousewheel,
    Keyboard,
    Parallax,
    Lazy,
    EffectFade,
    Thumbs,
    Controller,
} from 'swiper'
import 'swiper/swiper-bundle.css'
import AOS from 'aos'
import 'aos/dist/aos.css'

Swiper.use([Navigation, Pagination, Scrollbar, Autoplay, Mousewheel, Keyboard, Parallax, Lazy, EffectFade, Thumbs, Controller])

export default defineComponent({
	name : 'ComponentProductSwiper',
	props: {
        selectCheck: {
            type: String,
            required: true,
            default: 'recommend'
        }
    },
	emits : ['onSwiperList'],
    setup(props, {emit}) {
		const { $axiosConnect, imgURL, router } = commonMixin()

		const productList    = ref([])

		const getList = async () => {
            try {
                let params = {
                    'page'    : 1,
                    'pageSize': 10
                };

				if (props.selectCheck === 'recommend') {
					params['searchCheck[0][PRODUCT_IS_RECOMMEND]'] = 1;
				} else if (props.selectCheck === 'specialPrice') {
					params['searchCheck[0][PRODUCT_IS_SPECIAL_PRICE]'] = 1;
				} else if (props.selectCheck === 'promotion') {
					params['searchCheck[0][PRODUCT_IS_PROMOTION]'] = 1;
				}

                const response = await $axiosConnect('GUEST', `/web/product`, 'GET', params);

                productList.value = []

                if(response.body){
                    productList.value = JSON.parse(JSON.stringify(response.body.list))

					productList.value = productList.value.map(list => {
						return {
							...list,
							converUid                           : list.uid.toString().padStart(6, '0'),
							convertProductName                  : truncateText(list.productName, 75),
							convertProductDescription           : truncateText(list.productDescription, 75),
							converRegDate                       : convertDateToStr(list.regDate),
							converUpDate                        : convertDateToStr(list.upDate),
							convertProductSubscribeRegularPrice : numberFormat(list.productSubscribeRegularPrice),
							convertProductSubscribePrice        : numberFormat(list.productSubscribePrice),
							convertProductRentalRegularPrice    : numberFormat(list.productRentalRegularPrice),
							convertProductRentalPrice           : numberFormat(list.productRentalPrice),
							convertProductRentalMonths          : numberFormat(list.productRentalMonths),
							convertProductRentalMonthPrice      : numberFormat(list.productRentalPrice/list.productRentalMonths),
							convertProductSaleRegularPrice      : numberFormat(list.productSaleRegularPrice),
							convertProductSalePrice             : numberFormat(list.productSalePrice),
							convertProductViewCount             : numberFormat(list.productViewCount),
							convertProductImages                : list.upfiles.filter(file => file.upfilesColumn === 'productImages'),
							convertProductDescriptionImages     : list.upfiles.filter(file => file.upfilesColumn === 'productDescriptionImages')
						}
					})
                }
                initializeSwiper()
				emit('onSwiperList', {
					type : props.selectCheck,
					list : productList.value
				});
            } catch (error) {
                console.error("error:", error)
            }
        }

		const onClickNavigateToProductDetail = (uid) => {
            router.push({ name: 'webProductDetailPage', params: { uid } });
        }

		const initializeSwiper = async () => {
            try {
                nextTick(() => {
                    AOS.init();
                    const swipers = document.querySelectorAll('[data-swiper]') || []
                    swipers.forEach((elem) => {
                        try {
                            let options = elem.dataset.options ? JSON.parse(elem.dataset.options) : {}
                            new Swiper(elem, options)
                        } catch (error) {
                            console.error('Error initializing Swiper:', error)
                        }
                    })
                })
            } catch (error) {
                console.error("error:", error)
            }
        }

		const initialize = async () => {
            productList.value = []
        }

		onMounted(async () => {
            await initialize()
			await getList()
            await initializeSwiper()
        })

        return {
			imgURL,
			productList,

			onClickNavigateToProductDetail
        }
    }
})
</script>