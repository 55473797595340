<template>
    <Modal ref="baseModal" :title="`사용자 상세보기`" :width="`800px`">
        <template #body>

            <div class="form-group row form-required">
                <label for="inputMemberName" class="col-sm-2 col-form-label">사용자명</label>
                <div class="col-sm-10">
                    <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                        <span class="form-control">{{ singUpFormData.memberName }}</span>
                    </div>
                </div>
            </div>
            <div class="form-group row form-required">
                <label for="inputMemberName" class="col-sm-2 col-form-label">아이디</label>
                <div class="col-sm-10">
                    <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                        <span class="form-control">{{ singUpFormData.memberId }}</span>
                    </div>
                </div>
            </div>
            <div class="form-group row form-required">
                <label for="inputMemberName" class="col-sm-2 col-form-label">이메일</label>
                <div class="col-sm-10">
                    <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                        <span class="form-control">{{ singUpFormData.memberEmail }}</span>
                    </div>
                </div>
            </div>
            <div class="form-group row form-required">
                <label for="inputMemberName" class="col-sm-2 col-form-label">휴대폰</label>
                <div class="col-sm-10">
                    <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                        <span class="form-control">{{ singUpFormData.memberMobile }}</span>
                    </div>
                </div>
            </div>
            <div class="form-group row form-required">
                <label for="inputMemberName" class="col-sm-2 col-form-label">주소</label>
                <div class="col-sm-10">
                    <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                        <span class="form-control">[{{ singUpFormData.memberZipcode }}] {{ singUpFormData.memberAddr }} {{ singUpFormData.memberAddrDetail }}</span>
                    </div>
                </div>
            </div>
            
            <div class="form-group row form-required">
                    <label for="inputMemberAuthority" class="col-sm-2 col-form-label">권한</label>
                    <div class="col-sm-10">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-check-circle"></i></span>
                            </div>
                            <div class="form-control">
                                <template v-for="(item, key) in storeAuthority" :key="key">
                                    <label class="input-label mr-3" :for="`memberAuthority_${key}`" v-if="key<90">
                                        <input type="radio"
                                            :id="`memberAuthority_${key}`"
                                            :value="key"
                                            v-model="singUpFormData.memberAuthority"
                                            disabled
                                        />
                                        <span class="radio-icon"></span>
                                        <span class="label-text">{{ item }}</span>
                                    </label>
                                </template>
                            </div>
                        </div>
                        <small class="input-hint">권한은 필수로 입력해야 합니다.</small>
                    </div>
                </div>

                <div class="form-group row form-required">
                    <label for="inputMemberState" class="col-sm-2 col-form-label">사용여부</label>
                    <div class="col-sm-10">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-check-circle"></i></span>
                            </div>
                            <div class="form-control">
                                <label v-for="(item, key) in storeState" :key="key" class="input-label mr-3" :for="`memberState_${key}`">
                                    <input type="radio"
                                        :id="`memberState_${key}`"
                                        :value="key"
                                        v-model="singUpFormData.memberState"
                                        disabled
                                    />
                                    <span class="radio-icon"></span>
                                    <span class="label-text">{{ item }}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

            <div class="form-group row">
                <label for="inputMemberName" class="col-sm-2 col-form-label">첨부파일</label>
                <div class="col-sm-10">
                    <template v-if="Array.isArray(singUpFormData.upfiles) && singUpFormData.upfiles.length > 0">
                        <div class="file-upload">
                            <div class="file-preview">
                                <div v-for="(file, index) in singUpFormData.upfiles" :key="index" class="file-preview-item">
                                    <div class="file-preview-image-box">
                                        <template v-if="onHandleIsImage(file.upfilesExt)">
                                            <img :src="`${imgURL}${file.upfilesThumnailPath}/${file.upfilesFileName}`" :alt="file.name" class="file-preview-image" />
                                        </template>
                                        <template v-else>
                                            <div class="file-preview-file"><i class="fas fa-file-alt"></i></div>
                                        </template>
                                    </div>
                                    <p class="mb-0 file-name">{{ file.upfilesOriginalName }}.{{ file.upfilesExt }}</p>
                                    <p class="mb-0">({{ file.upfilesSize }} KB)</p>
                                    <button type="submit" class="btn btn-primary btn-xs" @click="onClickUpfileDownload(file)">다운로드</button>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-file-upload"></i></span>
                            </div>
                            <span class="form-control">첨부된 파일이 없습니다.</span>
                        </div>
                    </template>
                </div>
            </div>
            <div class="form-group row form-required">
                <label for="inputMemberName" class="col-sm-2 col-form-label">등록정보</label>
                <div class="col-sm-10">
                    <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-user-pen"></i></span></div>
                        <span class="form-control justify-content-between">
                            <span>{{ singUpFormData.regMemberName }} ({{ singUpFormData.regMemberId }})</span>
                            <span>IP : {{ singUpFormData.regIp }}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="form-group row form-required">
                <label for="inputMemberName" class="col-sm-2 col-form-label">등록일</label>
                <div class="col-sm-10">
                    <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-calendar-day"></i></span></div>
                        <span class="form-control">{{ singUpFormData.convertRegDate }}</span>
                    </div>
                </div>
            </div>
            <div class="form-group row form-required" v-if="singUpFormData.upMemberName">
                <label for="inputMemberName" class="col-sm-2 col-form-label">수정정보</label>
                <div class="col-sm-10">
                    <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-user-pen"></i></span></div>
                        <span class="form-control justify-content-between">
                            <span>{{ singUpFormData.upMemberName }} ({{ singUpFormData.upMemberId }})</span>
                            <span>IP : {{ singUpFormData.upIp }}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="form-group row form-required" v-if="singUpFormData.convertUpDate">
                <label for="inputMemberName" class="col-sm-2 col-form-label">수정일</label>
                <div class="col-sm-10">
                    <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-calendar-day"></i></span></div>
                        <span class="form-control">{{ singUpFormData.convertUpDate }}</span>
                    </div>
                </div>
            </div>
        </template>

        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">닫기</button>
			<button type="submit" class="btn btn-primary" @click="onClickMovementUpdate">수정</button>
        </template>
	</Modal>

	<alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import Modal from '@/modals/Modal'
import Alert from '@/modals/common/Alert'

export default defineComponent({
    name       : 'modalMemberDetailPage',
    components: {
		Modal,
		'alert' : Alert,
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){
		const { $axiosConnect, $fileDownload, store } = commonMixin()

        const baseModal      = ref(null)
		const resolvePromise = ref(null)

        const mode           = ref('detail')
        const closeModalData = ref({})
        const alertRef       = ref(null)

        const storeAuthority = ref({})
        const storeState     = ref({})

        const singUpFormData = ref({
            memberName              : '',
            memberId                : '',
            memberPassword          : '',
            memberPasswordEncrypted : '',
            memberAuthority         : 90,
            memberPasswordState     : 1,
            memberState             : 1,
            upfiles                 : []
        })

        const imgURL        = ref(process.env.VUE_APP_BASE_IMG_URL)

        onMounted(() => {
            storeAuthority.value = store.getters["member/getAuthority"]
            storeState.value     = store.getters["common/getState"]
        })

		const open = (payload) => {
            if (payload.item && payload.mode === 'detail') {
                singUpFormData.value = JSON.parse(JSON.stringify(payload.item))
                mode.value = payload.mode
                getDeatil()
            }

            closeModalData.value = {
                isUpdate : false,
                item     : ''
            }

            //Open Modal
			baseModal.value.openModal()
			return new Promise((resolve) => {
				resolvePromise.value = resolve;
			});
		};

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			baseModal.value.closeModal()
			resolvePromise.value(closeModalData.value)
		};

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
        const onClickMovementUpdate = () => {
            closeModalData.value = {
                isUpdate : true,
                item     : singUpFormData.value
            }
            close()
        }

        const getDeatil = async () => {
            try {
                const uid = singUpFormData.value.uid
                const response = await $axiosConnect('MEMBER', `/admin/member/${uid}`, 'GET')

                if (response.success && response.body && response.body.list.length > 0) {

                    const detail = JSON.parse(JSON.stringify(response.body.list[0]))
                    singUpFormData.value = {
                        ...detail,
                        converUid              : detail.uid.toString().padStart(8, '0'),
                        convertRegDate         : convertDateToStr(detail.regDate),
                        convertUpDate          : convertDateToStr(detail.upDate),
                        convertMemberAuthority : storeAuthority.value[detail.memberAuthority],
                        convertMemberState     : storeState.value[detail.memberState]
                    }

                }else{
                    alertRef.value.open({ 'contents' : response.message })
                    close()
                }
            } catch (error) {
                console.error("error:", error)
            }

        }

        const onClickUpfileDownload = async (upfile) => {
            try {
                const uid = upfile.uid
                await $fileDownload(upfile.upfilesOriginalName + '.' + upfile.upfilesExt, `/common/uploads/download/${uid}`)
            } catch (error) {
                console.error("error:", error)
            }
        }

        const onHandleIsImage = (fileExtension) => {
            return ["jpg", "jpeg", "png", "gif"].includes(fileExtension)
        }

        return {
			baseModal, 
			open,
			close,
            mode,

			alertRef,
			singUpFormData,
            storeAuthority,
            storeState,

            imgURL,

            onClickMovementUpdate,
            onClickUpfileDownload,
            onHandleIsImage
		}
    }
});
</script>