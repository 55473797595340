<template>
    <main>
        <div class="container">
			<section class="content-header">
				<div class="container-fluid">
					<div class="row mb-2">
						<div class="col-sm-12">
							<div class="page-tab-title">
								<ul>
									<li @click="onClickNavigateToPage('webNoticeListPage')"><span>공지사항</span></li>
                                    <!--<li @click="onClickNavigateToPage('webFaqListPage')"><span>FAQ</span></li>-->
                                    <li @click="onClickNavigateToPage('webContactPage', 'login')"><span>1:1 문의</span></li>
                                    <li class="active" @click="onClickNavigateToPage('webCallRequestPage', 'login')"><span>전화상담요청</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
            <div class="content">
                <div class="container-fluid">
					<div class="row">
						<div class="col-md-12">
                            <div class="card">
                                <form ref="callRequestFormRef" @submit.prevent="onClickSave">			
                                    <div class="card-body">
                                        <div class="form-group row">
                                            <label for="inputSubject" class="col-sm-2 col-form-label">상담 제목</label>
                                            <div class="col-sm-10">
                                                <input type="text" class="form-control" id="inputSubject" placeholder="제목을 입력해주세요." autocomplete="off" tabindex="1"
                                                required 
                                                maxlength="255" 
                                                pattern-hint="제목은 필수로 입력해야 합니다"
                                                v-model="callRequestFormData.subject"
                                                @blur="onInputCheckEvent" 
                                                />
                                            </div>
                                            <small class="input-hint with-label-2"></small>
                                        </div>
                                        <div class="form-group row">
                                            <label for="inputSubject" class="col-sm-2 col-form-label">전화상담 요청일</label>
                                            <div class="col-sm-10">
                                                <div class="input-group">
                                                    <VueCtkDateTimePicker
                                                    v-model="startDate" 
                                                    format="YYYY-MM-DD HH:mm"
                                                    outputFormat="YYYY-MM-DD HH:mm"
                                                    label="전화상담 요청일"
                                                    />
                                                </div>
                                            </div>
                                            <small class="input-hint with-label-2"></small>
                                        </div>
                                        <div class="form-group row">
                                            <label for="inputSubject" class="col-sm-2 col-form-label">상담내용</label>
                                            <div class="col-sm-10">
                                                <div class="pt-2 pb-2">
                                                    <smarteditor2 
                                                    v-model="callRequestFormData.content"
                                                    :editor-id="'editor1'"
                                                    ref="editor1"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="inputSubject" class="col-sm-2 col-form-label">* 전화상담 유의사항</label>
                                            <div class="col-sm-10">
                                                <div class="pt-2 pb-2">
                                                    <ul>
                                                        <li>예약하신 상담 시간에 맞춰 전화 상담이 진행됩니다. 예약 시간 5분 전에는 준비해 주시기 바랍니다.</li>
                                                        <li>상담을 원하시는 내용을 미리 정리해 주시면 더욱 빠르고 정확한 상담이 가능합니다.</li>
                                                        <li>필요한 자료나 서류가 있다면 미리 준비해 주세요.</li>
                                                        <li>상담 중에 개인정보(주민등록번호, 계좌번호 등)를 요청하지 않으니 안심하시고 이용해 주세요.</li>
                                                        <li>상담 시간에 전화 연결이 어려운 경우, 1:1문의로 연락 주시면 상담 시간을 재조정해 드리겠습니다.</li>
                                                        <li>상담 품질 개선을 위해 통화 내용이 녹음될 수 있습니다.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="card-footer">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <button type="submit" class="btn btn-sub-2 float-right" :disabled="submitButtonDisabled" @mouseenter="onOverSubmitButton" @click="onClickSave()">문의 등록</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
					</div>
                </div>
            </div>
        </div>

		<alert ref="alertRef" :title="'알림'" />
        <modal-sign-in-confirm ref="modalSignInConfirmRef" @onCloseModal="onCloseModalSignInConfirm" />

    </main>
</template>

<script>
import { defineComponent, ref } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import Alert from '@/modals/common/Alert'
import { getCurrentLocalDateTimeISO, convertStrToUnixTime } from '@/helper/utils/dateUtils.js'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

import ComponentFormSmarteditor2 from "@/components/web/ComponentFormSmarteditor2"
import ModalSignInConfirm from '@/modals/web/account/ModalSignInConfirm'

export default defineComponent({
    name       : 'WebCallRequestPage',
	components: {
		'alert' : Alert,
        'smarteditor2' : ComponentFormSmarteditor2,
		VueCtkDateTimePicker,
        'modal-sign-in-confirm' : ModalSignInConfirm
	},
    setup() {
		const { onHandleValidateInput, $axiosConnect, router, storeSignInState } = commonMixin()

        const modalSignInConfirmRef = ref(null)

		const alertRef             = ref(null)
		const submitButtonDisabled = ref(true)

		const callRequestFormRef       = ref(null)
        const callRequestFormData      = ref({
            subject : '',
            content : '',
            answer  : '',
            state   : 1,
            answerState : 0,
            upfiles : []
        })

		const editor1 = ref(null)
		const uploadedFiles = ref([])
		const startDate  = ref(getCurrentLocalDateTimeISO())

		/**
         * 입력 값의 유효성을 검사하고, 암호화된 아이디와 비밀번호를 생성하는 메서드
         * 유효성 검사 결과에 따라 submitDisabled 속성을 설정합니다.
         * @method onInputCheckEvent
         * @returns {void}
         */
         const onInputCheckEvent = () => {
            return !(submitButtonDisabled.value = !onHandleValidateInput(callRequestFormRef.value))
        }

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onOverSubmitButton = () => {
            const formElements = callRequestFormRef.value.querySelectorAll('input, select, textarea')
            formElements.forEach(input => {
                input.blur()
            });
        }

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
        const onClickSave = async () => {
            if(onInputCheckEvent()){
                //Editor Content
                editor1.value?.syncContent()
				callRequestFormData.value.callRequestDate = convertStrToUnixTime(startDate.value)

                const formData = new FormData();
                for (const key in callRequestFormData.value) {
                    if (callRequestFormData.value[key] !== '') {
                        formData.set(key, callRequestFormData.value[key]);
                    }
                }

                // 파일들을 FormData에 추가
                uploadedFiles.value.forEach((file, index) => {
                    formData.append(`files[${index}]`, file)
                })

                onProcessSave(formData)
            }
        }

        /**
         * 로그인 프로세스를 처리하는 메서드
         * @method onProcessSignIn
         * @param {FormData} formData - 로그인에 필요한 폼 데이터
         * @returns {void}
         */
        const onProcessSave = async (formData) => {
            try {

                const response = await $axiosConnect('MEMBER', `/web/callRequest`, 'POST', formData)
                if (response.success && response.body && response.body.list.length > 0) {
                    
                    //Editor Clean
                    editor1.value?.cleanContent()

                    callRequestFormData.value = {
                        subject : '',
                        content : '',
                        answer  : '',
                        state   : 1,
                        answerState : 0,
                        upfiles : []
                    }

                    alertRef.value.open({ 
                        'contents' : response.message
                    })

                }else{
                    alertRef.value.open({ 'contents' : response.message })
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

        const onClickNavigateToPage = (pageName, loginYN = '') => {
            if (loginYN) {
                if (storeSignInState.value) {
                    router.push({ name: pageName });
                } else {
                    onClickModalSignInConfirm();
                }
            } else {
                router.push({ name: pageName });
            }
        }

        const onClickModalSignInConfirm = () => {
            modalSignInConfirmRef.value.open();
        }

        const onCloseModalSignInConfirm = (payload) => {
            if (payload.isUpdate) {
				const redirectPath = router.currentRoute.value.fullPath
				router.push({
					name : 'webSignInPage',
					query: { redirect: redirectPath }
				})
            }
        }

		return {
            router,
			alertRef,
			callRequestFormRef,
			callRequestFormData,
			editor1,
			submitButtonDisabled,
			startDate,

			onInputCheckEvent,
			onOverSubmitButton,
			onClickSave,
            onClickNavigateToPage,
            modalSignInConfirmRef,
            onCloseModalSignInConfirm
        }
    }
});
</script>
<style>
button.datepicker-day {
    padding: 0.625rem 0;
}
</style>