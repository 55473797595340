<template>
    <Modal ref="baseModal" :title="`공지사항 삭제`" :width="`800px`">
        <template #body>
            <p v-html="deleteText"></p>
            <p>선택된 항목은 복구되지 않습니다. 그래도 삭제하시겠습니까?</p>
        </template>
        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">닫기</button>
			<button type="submit" class="btn btn-primary" @click="onClickDelete">삭제</button>
        </template>
	</Modal>

	<alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, onMounted, ref, computed } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import Modal from '@/modals/Modal'
import Alert from '@/modals/common/Alert'

export default defineComponent({
    name       : 'modalEventDeletePage',
    components: {
		Modal,
		'alert' : Alert,
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){
		const { openModal, closeModal, baseModal, $axiosConnect } = commonMixin()

		const alertRef       = ref(null)
        const eventFormData  = ref([])
        const mode           = ref('delete')
        const closeModalData = ref({})

        const initialize = async () => {
            mode.value = 'delete'

            eventFormData.value = []

            closeModalData.value = {
                isUpdate : false,
                item     : ''
            }
        }

        onMounted(async () => {
            await initialize()
        })

		const open = (payload) => {
            initialize()

            if (payload.item && payload.mode === 'delete') {
                eventFormData.value = JSON.parse(JSON.stringify(payload.item))
                mode.value = payload.mode
            }

            return openModal()
		};

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			closeModal(closeModalData.value)
		};

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
        const onClickDelete = async () => {
            onProcessDelete()
        }

        /**
         * 로그인 프로세스를 처리하는 메서드
         * @method onProcessSignIn
         * @param {FormData} formData - 로그인에 필요한 폼 데이터
         * @returns {void}
         */
        const onProcessDelete = async () => {
            try {

                const deletePromises = eventFormData.value.map(item => 
                    $axiosConnect('MEMBER', `/admin/event/${item.uid}`, 'DELETE')
                )
                const responses  = await Promise.all(deletePromises)
                const successful = responses.every(response => response.success)

                if (successful) {

                    initialize()

                    closeModalData.value = {
                        isUpdate : true,
                        item     : ''
                    }

                    alertRef.value.open({ 
                        'contents' : '선택한 항목이 성공적으로 삭제되었습니다.',
                        'callback' : close
                    })

                }else{
                    alertRef.value.open({ 'contents' : '삭제에 실패했습니다.' })
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

        const deleteText = computed(() => {
            const deleteItem = '제목:' + eventFormData.value.map(item => item.subject).join(', 제목:');
            const deleteText = '선택 항목 - ' + eventFormData.value.length + '개<p>(' + deleteItem + ')</p>'
            return deleteText;
        });

        return {
			open,
			close,
            mode,

			alertRef,
			eventFormData,
            deleteText,

            onClickDelete,
            
            //commonMixin
            baseModal			
		}
    }
});
</script>