<template>
    <main>
        <div class="container">
			<section class="content-header">
				<div class="container-fluid">
					<div class="row mb-2">
						<div class="col-sm-12">
							<div class="page-tab-title">
								<ul>
                                    <li class="active"><span>MY 페이지</span></li>
									<li @click="router.push({ name: 'webMyInfoPage' })"><span>내 정보</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
            <div class="content mypage">
                <div class="container-fluid">
					<div class="row">
						<div class="col-sm-12">
                            <ul class="tab-list">
								<li class="active"><span>구독현황</span></li>
								<li @click="router.push({ name: 'webMyPageCartPage' })"><span>장바구니</span></li>
								<li @click="router.push({ name: 'webMyPageReviewPage' })"><span>나의 리뷰</span></li>
								<li @click="router.push({ name: 'webMyPageContactPage' })"><span>나의 1:1 문의</span></li>
								<li @click="router.push({ name: 'webMyPageCallRequestPage' })"><span>나의 전화상담요청</span></li>
								<!--<li @click="router.push({ name: 'webMyPageCouponPage' })"><span>쿠폰</span></li>-->
							</ul>
						</div>
					</div>
					<div class="row justify-content-center">

						<template v-if="list && list.length > 0">
							<template v-for="(item, index) in list" :key="index">
								<div class="col-md-12 col-sm-12">

									<div class="product-item min-height-auto" data-aos-delay="000" >
										<div class="text-center mt-4">
											<h4 class="font-size-xl font-weight-bold">
												<template v-if="item.orderState === '신청'">신청 중인 상품</template>
												<template v-if="item.orderState === '구독중'">구독 중인 상품</template>
												<template v-if="item.orderState === '구독종료'">구독 종료 상품</template>
												<template v-if="item.orderState === '구독취소신청'">구독 취소 신청 상품</template>
												<template v-if="item.orderState === '구독취소'">구독 취소 상품</template>
											</h4>
										</div>
										<hr />
										<div class="product-inner-item flex-row" role="button" @click="onClickNavigateToProductDetail(item.uid)">
											<picture class="product-image" style="max-width: 200px;">
												<img class="absolute-center" 
												:src="`${imgURL}${item.convertProductImages[0].upfilesThumnailPath}/${item.convertProductImages[0].upfilesFileName}`" 
												:alt="item.convertProductName"
												>
											</picture>
											<div class="product-content">
												<h5 class="product-name">{{ item.convertProductName }}</h5>
												<p class="product-notes">{{ item.convertProductDescription }}</p>
												<hr />
												<div class="row text-sm">
													<div class="col-sm-2">기본사항</div>
													<div class="col-sm-10">{{ item.convertProductDefaultItems[0]?.productItemName }}</div>
												</div>
												<template v-if="item.convertProductOptionItems && item.convertProductOptionItems.length > 0">
													<hr />
													<div class="row text-sm">
														<div class="col-sm-2">추가옵션</div>
														<div class="col-sm-10">
															<template v-for="(optionItem, optionIndex) in item.convertProductOptionItems" :key="optionIndex">
															{{ optionItem.productItemName }}
															<span v-if="optionIndex !== item.productOptionItems.length - 1"> / </span>
															</template>
														</div>
													</div>
												</template>
												<template v-if="item.convertProductServiceItems && item.convertProductServiceItems.length > 0">
													<hr />
													<div class="row text-sm">
														<div class="col-sm-2">서비스</div>
														<div class="col-sm-10">
															<template v-for="(serviceItem, serviceIndex) in item.convertProductServiceItems" :key="serviceIndex">
															{{ serviceItem.productItemName }}
															<span v-if="serviceIndex !== item.productServiceItems.length - 1"> / </span>
															</template>
														</div>
													</div>
												</template>
											</div>
										</div>
										<hr />
										<div class="order-status-list-row">
											<div>
												<div class="order-status-list-day mb-2">
													<template v-if="item.converOrderContractDate">
														<span class="font-size-xl mr-4 font-weight-bold">구독기간</span>
														<span class="font-size-md mr-2 font-weight-bold">{{ item.convertDateDifference }}</span>
														<span class="font-size-md">(구독 시작일 {{ item.converOrderContractDate }})</span>
													</template>
												</div>
												<div class="order-status-list-text">
													<template v-if="item.orderType==='subscribe'">
														월 구독 요금
													</template>
													<template v-else-if="item.orderType==='rental'">
														월 렌탈형 구독 요금
													</template>
													<span class="ml-1 mr-1 font-size-xl">{{ numberFormat(item.orderTotalPrice) }}원</span>
													<span v-if="item.orderMonths > 0" class="mr-2">/ {{ numberFormat(item.orderMonths) }}개월</span>
													<span v-if="item.orderDepositPrice > 0">(보증금 : {{ numberFormat(item.orderDepositPrice) }}원)</span>
												</div>
											</div>
											<div>
												<template v-if="item.orderState === '구독중'">
													<button class="btn btn-block btn-black-2" @click="onClickModalOrderCancel(item)">구독취소</button>&nbsp;
												</template>

												<template v-if="item.review.length > 0">
													<button class="btn btn-block btn-special-2" @click="onClickReviewViewPage(item)">리뷰보기</button>
												</template>
												<template v-else>
													<template v-if="item.convertDateDifferenceInSeconds > 0">
														<button class="btn btn-block btn-special-1" @click="onClickModalProductReview(item)">리뷰쓰기</button>
													</template>
												</template>
											</div>
										</div>
									</div>
								</div>
							</template>
						</template>
						<template v-else>
							<div class="col-md-12 col-sm-12">
								<div class="card mt-4">
									<div class="card-body text-center">
										구독현황이(해당 내용)이 없습니다.
									</div>
								</div>
							</div>
							<div>
								<div class="row justify-content-center mt-1">
									<div class="col-12 col-sm-12 col-lg-12">
										<div class="product-list-paging">
											<div class="product-request etc">
												<p class="font-size-xl">똑.독의 다양한 상품을 구경해보세요.</p>
												<hr/>
												<div class="product-request-group">
													<div>
														<span>A~Z까지 선택해서 상품을 찾아보세요.</span>
														<span role="button" @click="router.push({ name: 'webProductCustomPage' })">상품 찾기</span>
													</div>
													<div>
														<span>난, 게이밍 전문가!</span>
														<span role="button" @click="onClickNavigateToProduct('gaming')">게이밍용</span>
													</div>
													<div>
														<span>난, 그래픽/동영상 전문가!</span>
														<span role="button" @click="onClickNavigateToProduct('professional')">전문가용</span>
													</div>
													<div>
														<span>일반 사무, 업무용에는!</span>
														<span role="button" @click="onClickNavigateToProduct('work')">업무용</span>
													</div>
													<div>
														<span>이번 시험은 왠지 100점</span>
														<span role="button" @click="onClickNavigateToProduct('study')">학습용</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>

					</div>

					<!--
					<div class="row mb-5">
						<div class="col-sm-12 col-md-6"></div>
						<div class="col-sm-12 col-md-6">
							<ul class="pagination pagination-sm m-0 float-right">
								<li class="page-item" v-if="listCurrentPage !== 1">
									<span class="page-link" @click="onChangeListPreviousPage">&laquo;</span>
								</li>
								<li class="page-item" v-for="pageNumber in listTotalPages" :key="pageNumber" :class="{ 'active': pageNumber === listCurrentPage }">
									<span class="page-link" @click="onChangeListPage(pageNumber)">{{ pageNumber }}</span>
								</li>
								<li class="page-item" v-if="listCurrentPage < listTotalPages">
									<span class="page-link" @click="onChangeListNextPage">&raquo;</span>
								</li>
							</ul>
						</div>
					</div>
					-->
                </div>
            </div>
        </div>

    </main>

	<modal-product-review ref="modalProductReviewRef" @onCloseModal="onCloseModalProductReview" />
	<modal-order-cancel ref="modalOrderCancelRef" @onCloseModal="onCloseModalOrderCancel" />

</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr, getDateDifference, getDateDifferenceInSeconds  } from '@/helper/utils/dateUtils.js'
import { numberFormat, truncateText } from '@/helper/utils/etcUtils.js'

import ModalProductReview from '@/modals/web/product/ModalProductReview'
import ModalOrderCancel from '@/modals/web/mypage/ModalOrderCancel'

export default defineComponent({
    name       : 'webMyPageOrderStatusPage',
	components: {
		'modal-product-review' : ModalProductReview,
		'modal-order-cancel'   : ModalOrderCancel,
	},
    setup() {
		const { router, $axiosConnect, imgURL } = commonMixin()

		const list              = ref([])
        const listCurrentPage   = ref(1);
        const listPageSize      = ref(1000);
        const listTotalPages    = ref(0);
        const listAllCount      = ref(0);
        const listTotalCount    = ref(0);
        const listSearchCount   = ref(0);

		const modalProductReviewRef = ref(null)
		const modalOrderCancelRef   = ref(null)

		const getList = async () => {
            try {
                let defaultParams = {
                    page    : listCurrentPage.value,
                    pageSize: listPageSize.value
                };
                const response = await $axiosConnect('MEMBER', `/web/order`, 'GET', defaultParams);
				list.value            = []
				listAllCount.value    =  listTotalCount.value  = listTotalPages.value = listSearchCount.value = 0

				if(response.body){
					const products        = JSON.parse(JSON.stringify(response.body.list))
					listAllCount.value    = JSON.parse(JSON.stringify(response.body.allTotal))
					listTotalCount.value  = JSON.parse(JSON.stringify(response.body.total))
					listTotalPages.value  = listTotalCount.value > 0 ? Math.ceil(listTotalCount.value / listPageSize.value) : 1
					listSearchCount.value = list.value.length

                    const processedProducts  = products.map(list => {
                        return {
                            ...list,
                            converUid                               : list.uid.toString().padStart(6, '0'),
                            convertProductName                      : truncateText(list.productName, 75),
                            convertProductDescription               : truncateText(list.productDescription, 50),
                            converRegDate                           : convertDateToStr(list.regDate),
                            converUpDate                            : convertDateToStr(list.upDate),
							convertProductImages                    : list.upfiles.filter(file => file.upfilesColumn === 'productImages'),
							convertProductDefaultItems              : list.productItems.filter(item => item.itemType === 1),
							convertProductOptionItems               : list.productItems.filter(item => item.itemType === 2),
							convertProductServiceItems              : list.productItems.filter(item => item.itemType === 3),
							convertDateDifference                   : getDateDifference(parseInt(list.orderContractDate)),
							converOrderContractDate                 : convertDateToStr(list.orderContractDate),
							convertDateDifferenceInSeconds          : getDateDifferenceInSeconds(parseInt(list.orderContractDate)),
                        }
                    })
                    list.value = [...list.value, ...processedProducts];
                }
                
            } catch (error) {
                console.error("error:", error)
            }
        }

		const onChangeListPage = (page) => {
            if(page){
                listCurrentPage.value = page
            }
            getList()
        }
        const onChangeListPreviousPage = () => {
            if (listCurrentPage.value > 1) {
                listCurrentPage.value--
                getList()
            }
        }

        const onChangeListNextPage = () => {
            if (listCurrentPage.value < listTotalPages.value) {
                listCurrentPage.value++
                getList()
            }
        }

        const onChangeListPageSize = () => {
            getList()
        }

		const onClickModalProductReview = (item) => {
			modalProductReviewRef.value.open({
                'mode' : 'create',
                'item' : item
            });
		}

		const onCloseModalProductReview = (payload) => {
			if (payload.isUpdate) {
				getList()
            }
		}

		const onClickReviewViewPage = (item) => {
			router.push({ name: 'webMyPageReviewDetailPage', params: { uid: item.review[0].uid } });
		}

		const onClickNavigateToProduct = (searchPurpose) => {
            router.push({ name: 'webProductListPage', params: { searchPurpose: searchPurpose } });
        }

		const onClickNavigateToProductDetail = (uid) => {
            router.push({ name: 'webProductDetailPage', params: { uid } });
        }

		const onClickModalOrderCancel = (item) => {
			modalOrderCancelRef.value.open({
                'mode' : 'cancel',
                'item' : item
            });
		}

		const onCloseModalOrderCancel = (payload) => {
			if (payload.isUpdate) {
				alert("구독 취소 신청되었습니다.")
				getList()
            }
		}

		const initialize = async () => {
			await getList()
		}

		onMounted(async () => {
            await initialize()
        })

		return {
			router,
			imgURL,
			list,
			listCurrentPage,
			listPageSize,
			listTotalPages,
			listAllCount,
			listTotalCount,
			listSearchCount,
			modalProductReviewRef,
			modalOrderCancelRef,

			onChangeListPage,
			onChangeListPreviousPage,
			onChangeListNextPage,
			onChangeListPageSize,
			numberFormat,
			onClickModalProductReview,
			onCloseModalProductReview,
			onClickReviewViewPage,
			onClickNavigateToProduct,
			onClickNavigateToProductDetail,
			onClickModalOrderCancel,
			onCloseModalOrderCancel
        }
    }
});
</script>