<template>
    <Modal ref="baseModal" :title="`주문 ${mode === 'create' ? '추가' : '수정'}`" :width="`95%`">
        <template #body>
			<form ref="orderFormRef">
                <div class="row">
                    <div class="col-sm-4 border-right">
                        <div class="form-group row form-required">
                            <label for="productName" class="col-sm-2 col-form-label">상품명</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.productName }}</span>
                                </div>
                            </div>
                        </div>
                        <template v-for="(item, index) in orderFormData.orderItems" :key="index">
                            <div class="form-group row">
                                <label for="productDescription" class="col-sm-2 col-form-label">주문상품 {{ index + 1 }}</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                        <span class="form-control">{{ item.productItemName }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">주문구분</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control" v-if="orderFormData.orderType==='subscribe'">구독</span>
                                    <span class="form-control" v-if="orderFormData.orderType==='rental'">렌탈형 구독</span>
                                    <span class="form-control" v-if="orderFormData.orderType==='sale'">판매</span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">계약기간</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.convertOrderMonths }}개월</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">총 주문가격</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.convertOrderTotalPrice }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">보증금</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.convertOrderDepositPrice }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">기본가격</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.convertOrderDefaultTotalPrice }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">옵션가격</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.convertOrderOptionTotalPrice }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">서비스가격</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.convertOrderServiceTotalPrice }}</span>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-sm-8 border-right">
						<div>
							<span class="mb-2 mr-2 font-weight-bold text-lg">{{ getPaymentDueWithinRange(orderFormData.payment[orderFormData.payment.length-1]?.regDate) }}</span>
							<button type="button" class="btn btn-danger btn-sm" v-if="isPaymentDueWithinRange(orderFormData.payment[orderFormData.payment.length-1]?.regDate)">
								{{ orderFormData.payment.length + 1 }}회차 결제하기
							</button>
						</div>
						<div class="table-responsive">
                            <table class="table table-hover">
                                <colgroup>
                                    <col />
                                </colgroup>
                                <thead>
                                    <tr>
										<td>회차</td>
                                        <td>상태</td>
										<td>결제일</td>
										<td>결제금액</td>
										<td>상점주문번호</td>
										<td>취소</td>
									</tr>
								</thead>
								<tbody>
									<template v-for="(item, index) in orderFormData.payment" :key="index">
										<tr>
											<td>{{ item.paymentRound }}</td>
                                            <td>
                                                <span v-if="item.paymentInfo['outStatCd'] === '0021'">성공</span>
                                                <span v-else>실패</span>
                                            </td>
											<td>{{ convertDateToStr(item.regDate,'YYYY-MM-DD', 'HH:MM') }}</td>
											<td>{{ numberFormat(item.paymentTotal) }}</td>
											<td @click="onClickModalPaymentInfoDetail(item)" role="button">{{ item.paymentInfo['mchtTrdNo'] }}</td>
											<td><!--<button type="button" class="btn btn-danger btn-sm" @click="onClickModalPaymentCancel(item)">결제취소</button>--></td>
										</tr>
									</template>
								</tbody>
							</table>
						</div>
					</div>

                </div>
			</form>
        </template>
        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">닫기</button>
        </template>
	</Modal>

	<alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr } from '@/helper/utils/dateUtils.js'
import { numberFormat, truncateText } from '@/helper/utils/etcUtils.js'

import Modal from '@/modals/Modal'
import Alert from '@/modals/common/Alert'

export default defineComponent({
    name       : 'modalOrderUpdatePage',
    components: {
		Modal,
		'alert' : Alert,
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){
		const { openModal, closeModal, baseModal, storeState, $axiosConnect, imgURL } = commonMixin()

        const mode                     = ref('create')
        const closeModalData           = ref({})
        const alertRef                 = ref(null)
        const submitButtonDisabled     = ref(true)

        const orderFormRef           = ref(null)
        const orderFormData          = ref({})

		const open = (payload) => {
            initialize()
			console.log(payload)
            if (payload.item && payload.mode === 'detail') {
                orderFormData.value = JSON.parse(JSON.stringify(payload.item))
                mode.value = payload.mode
                getDeatil()
            }

            return openModal()
		}

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			closeModal(closeModalData.value)
		}

        const getDeatil = async () => {
            try {
                const uid = orderFormData.value.uid
                const response = await $axiosConnect('MEMBER', `/admin/order/${uid}`, 'GET')

                if (response.success && response.body && response.body.list.length > 0) {

                    const list = JSON.parse(JSON.stringify(response.body.list[0]))
                    orderFormData.value = {
                        ...list,
                        convertUid                      : list.uid.toString().padStart(6, '0'),
                        convertProductName              : list.productName,
                        convertProductDescription       : truncateText(list.productDescription, 50),
                        convertRegDate                  : convertDateToStr(list.regDate),
                        convertUpDate                   : convertDateToStr(list.upDate),
                        convertOrderDepositPrice        : numberFormat(list.orderDepositPrice),
                        convertOrderTotalPrice          : numberFormat(list.orderTotalPrice),
                        convertOrderDefaultTotalPrice   : numberFormat(list.orderDefaultTotalPrice),
                        convertOrderOptionTotalPrice    : numberFormat(list.orderOptionTotalPrice),
                        convertOrderServiceTotalPrice   : numberFormat(list.orderServiceTotalPrice),
                        convertOrderMonths              : numberFormat(list.orderMonths),
                        converOrderContractDate         : convertDateToStr(list.orderContractDate),
                    }
                }else{
                    alertRef.value.open({ 'contents' : response.message })
                    close()
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

		const isPaymentDueWithinRange = (lastPaymentRegDate) => {
            if (!lastPaymentRegDate) return false;

            const lastPaymentDate = new Date(lastPaymentRegDate * 1000); // 유닉스 타임스탬프 변환
            const nextMonth = new Date(lastPaymentDate); // 복사해서 새로운 날짜 객체 생성
            nextMonth.setMonth(nextMonth.getMonth() + 1); // 다음 달로 설정

            const paymentDay = lastPaymentDate.getDate(); // 결제한 날
            const currentDate = new Date(); // 현재 날짜

            // 다음 달의 결제일 기준으로 앞 3일, 뒤 3일의 범위
            const startDate = new Date(nextMonth.setDate(paymentDay - 3)); // 다음 달 결제일 - 3일
            const endDate = new Date(nextMonth.setDate(paymentDay + 6)); // 다음 달 결제일 + 3일 (+6은 -3한 후 다시 기준일로 복귀 후 3일 추가)

            // 현재 날짜가 startDate와 endDate 사이인지 확인
            return currentDate >= startDate && currentDate <= endDate;
        };

		const getPaymentDueWithinRange = (lastPaymentRegDate) => {
            if (!lastPaymentRegDate) return false;

            const lastPaymentDate = new Date(lastPaymentRegDate * 1000); // 유닉스 타임스탬프 변환
            const nextMonth = new Date(lastPaymentDate); // 복사해서 새로운 날짜 객체 생성
            nextMonth.setMonth(nextMonth.getMonth() + 1); // 다음 달로 설정

            const paymentDay = lastPaymentDate.getDate(); // 결제한 날

            // 다음 달의 결제일 기준으로 앞 3일, 뒤 3일의 범위
            const startDate = new Date(nextMonth.setDate(paymentDay - 3)); // 다음 달 결제일 - 3일
            const endDate = new Date(nextMonth.setDate(paymentDay + 6)); // 다음 달 결제일 + 3일 (+6은 -3한 후 다시 기준일로 복귀 후 3일 추가)

            // 현재 날짜가 startDate와 endDate 사이인지 확인
            return '다음 회차 결제 가능 기간 : ' + convertDateToStr(startDate.getTime(), '', '') + ' ~ ' + convertDateToStr(endDate.getTime(), '', '');
        };

		const onClickModalPaymentCancel = () => {
//
		}

		const onClickModalPaymentInfoDetail = () => {
			//
		}

        const initialize = async () => {
            mode.value            = "datail"

            orderFormData.value = {}

            closeModalData.value = {
                isUpdate : false,
                item     : ''
            }
        }

        onMounted(async () => {
            await initialize()
        })
       

        return {
			open,
			close,
            mode,
            submitButtonDisabled,

			alertRef,
			orderFormRef,
			orderFormData,

			convertDateToStr,
			numberFormat,
			isPaymentDueWithinRange,
			getPaymentDueWithinRange,
			onClickModalPaymentCancel,
			onClickModalPaymentInfoDetail,

            //commonMixin
            storeState,
            baseModal, 
            imgURL,
		}
    }
})
</script>