<template>
    <div class="row">
        <div :class="isViewSearch ? 'col-md-10' : 'col-md-12'">
            <div class="sticky-top mb-3">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">상품 목록</h4>
                        <div class="card-tools">
                            <button type="button" class="btn btn-default btn-sm" @click="onClickRefreshList()"><i class="fas fa-sync-alt"></i></button>&nbsp;
                            <button type="button" class="btn btn-danger btn-sm" @click="onClickModalProductDelete()">선택 삭제</button>&nbsp;
                            <button type="button" class="btn btn-primary btn-sm" @click="onClickModalProductUpdate('create')">상품 등록</button>&nbsp;
                            <button type="button" class="btn btn-outline-dark btn-sm" @click="onClickIsViewSearch()">검색{{ isViewSearch?'닫기':'열기' }}</button>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <div class="p-2">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="d-flex align-items-center h-100">총 {{ listAllCount }}건 중 {{ listTotalCount }}건의 검색되었습니다. 
                                        <span class="ml-2 text-bold">( 현재 {{ listCurrentPage }}페이지의 {{ listSearchCount }}건을 보고 있습니다. )</span>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <select class="form-control float-right w-auto text-sm" v-model="listPageSize" @change="onChangeListPageSize()">
                                        <option v-for="pageSize in onGetSelectListPageSize" :key="pageSize" :value="pageSize">
                                        {{ pageSize===listTotalCount?"전체보기":`${pageSize}줄 보기` }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <!--LIST START-->
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <colgroup>
                                    <col width="5%" />
                                    <col width="8%" />
                                    <!--
                                    <col width="15%" />
                                    -->
                                    <col width="5%" />
                                    <col />
                                    <col width="15%" />
                                    <col width="10%" />
                                    <col width="5%" />
                                    <col width="5%" />
                                    <col width="11%" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="input-checkbox">
                                                <label class="input-label" for="isAllSelected">
                                                    <input type="checkbox"
                                                    id="isAllSelected"
                                                    v-model="isAllSelected" 
                                                    @change="onClickAllSelected"
                                                    />
                                                    <span class="checkbox-icon"></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th>번호</th>
                                        <!--
                                        <th>카테고리</th>
                                        -->
                                        <th>판매자</th>
                                        <th>상품명</th>
                                        <th>가격</th>
                                        <th>상품이미지</th>
                                        <th>상태</th>
                                        <th>노출수</th>
                                        <th>관리</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(item, index) in list" :key="index">
                                        <tr>
                                            <td>
                                                <div class="input-checkbox">
                                                    <label class="input-label" :for="`checkbox-${index}`">
                                                        <input type="checkbox"
                                                        :id="`checkbox-${index}`"
                                                        :value="item"
                                                        v-model="selectedList"
                                                        @change="onClickPartSelected()"
                                                        />
                                                        <span class="checkbox-icon"></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td role="button" @click="onClickExpandedRow(index)">{{ item.convertUid }}</td>
                                            <!--
                                            <td>
                                                <template v-for="(category, categoryIndex) in item.convertProductCategory" :key="categoryIndex">
                                                    <span>{{ getCategoryName(category.parentUid) }}</span>
                                                    <p v-if="category.childUid" class="mb-0">{{ getCategoryName(category.childUid) }}</p>
                                                    <p v-if="category.thirdUid" class="mb-0">{{ getCategoryName(category.thirdUid) }}</p>
                                                </template>
                                            </td>
                                            -->
                                            <td>
                                                {{ item.productDistributor }}
                                            </td>
                                            <td><span role="button" @click="onClickModalProductDetail(item)">
                                                <div>
                                                    <span class="badge badge-success ml-1" v-if="item.productPurposeGaming===1">게이밍용</span>
                                                    <span class="badge badge-success ml-1" v-if="item.productPurposeProfessional===1">전문가용</span>
                                                    <span class="badge badge-success ml-1" v-if="item.productPurposeWork===1">업무용</span>
                                                    <span class="badge badge-success ml-1" v-if="item.productPurposeStudy===1">학습용</span>
                                                    <!--
                                                    <span class="badge badge-warning ml-1" v-if="item.productSubscribe===1">구독</span>
                                                    <span class="badge badge-warning ml-1" v-if="item.productRental===1">렌탈구독</span>
                                                    -->
                                                    <span class="badge badge-info ml-1" v-if="item.productIsRecommend===1">추천</span>
                                                    <span class="badge badge-info ml-1" v-if="item.productIsSpecialPrice===1">특가</span>
                                                    <span class="badge badge-info ml-1" v-if="item.productIsPromotion===1">기획</span>
                                                </div>
                                                <div>{{ item.productName }}</div>
                                            </span></td>
                                            <td>
                                                <div v-if="item.productSubscribe===1"><span class="badge badge-warning">구독</span> <del>{{ item.convertProductSubscribeRegularPrice }}</del> → <span>{{ item.convertProductSubscribePrice }}</span></div>
                                                <div v-if="item.productRental===1"><span class="badge badge-warning">렌탈구독</span> <del>{{ item.convertProductRentalRegularPrice }}</del> → <span>{{ item.convertProductRentalPrice }}</span> <span>({{ item.convertProductRentalMonths }}개월)</span></div>
                                                <div v-if="item.productSale===1"><span class="badge badge-warning">판매</span> <del>{{ item.convertProductSaleRegularPrice }}</del> → <span>{{ item.convertProductSalePrice }}</span></div>
                                            </td>
                                            <td>
                                                <div class="list-widget">
                                                    <div class="list-widget-item"
                                                    v-for="(file, fileIndex) in item.productImages" 
                                                    :key="fileIndex"
                                                    ><img :src="`${imgURL}${file.upfilesThumnailPath}/${file.upfilesFileName}`" :alt="file.name" /></div>
                                                </div>
                                            </td>
                                            <td>{{ item.convertProductState }}</td>
                                            <td>{{ item.convertProductViewCount }}</td>
                                            <td>
                                                <button type="button" class="btn btn-primary btn-xs" @click="onClickModalProductUpdate('update', item)">수정</button>
                                                &nbsp;
                                                <button type="button" class="btn btn-danger btn-xs" @click="onClickModalProductDelete(item)">삭제</button>
                                                &nbsp;
                                                <button type="button" class="btn btn-info btn-xs" @click="onClickModalProductCopy(item)">복사</button>
                                            </td>
                                        </tr>
                                        <tr v-show="expandedRow === index">
                                            <td colspan="9">
                                                
                                                <div class="table-inner-box">
                                                    <div class="row" v-if="item.productSubscribe===1">
                                                        <div class="col-sm-1"><span class="inner-label">구독정상가</span></div>
                                                        <div class="col-sm-2"><span>{{ item.convertProductSubscribeRegularPrice }}</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">구독판매가</span></div>
                                                        <div class="col-sm-2"><span>{{ item.convertProductSubscribePrice }}</span></div>
                                                        <div class="col-sm-1"></div>
                                                        <div class="col-sm-2"></div>
                                                        <div class="col-sm-1"></div>
                                                        <div class="col-sm-2"></div>
                                                    </div>
                                                    <div class="row" v-if="item.productRental===1">
                                                        <div class="col-sm-1"><span class="inner-label">렌탈정상가</span></div>
                                                        <div class="col-sm-2"><span>{{ item.convertProductRentalRegularPrice }}</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">렌달판매가</span></div>
                                                        <div class="col-sm-2"><span>{{ item.convertProductRentalPrice }}</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">렌탈개월수</span></div>
                                                        <div class="col-sm-2"><span>{{ item.convertProductRentalMonths }}</span></div>
                                                        <div class="col-sm-1"></div>
                                                        <div class="col-sm-2"></div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-1"><span class="inner-label">등록정보</span></div>
                                                        <div class="col-sm-2"><span>{{ item.regMemberName }} ({{ item.regMemberId }})</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">등록일</span></div>
                                                        <div class="col-sm-2"><span>{{ item.convertRegDate }}</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">등록IP</span></div>
                                                        <div class="col-sm-2"><span>{{ item.regIp }}</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">노출수</span></div>
                                                        <div class="col-sm-2"><span>{{ item.productViewCount }}</span></div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-1"><span class="inner-label">수정정보</span></div>
                                                        <div class="col-sm-2"><span>{{ item.upMemberName }} ({{ item.upMemberId }})</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">수정일</span></div>
                                                        <div class="col-sm-2"><span>{{ item.convertUpDate }}</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">수정IP</span></div>
                                                        <div class="col-sm-2"><span>{{ item.upIp }}</span></div>
                                                        <div class="col-sm-1"></div>
                                                        <div class="col-sm-2"></div>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <!--LIST END-->
                    </div>
                    <div class="card-footer clearfix">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <span class="mr-1 ml-1">전체 {{ listTotalPages }} 페이지 중</span>
                                <select class="form-control paging-select" v-model="listCurrentPage" @change="onChangeListPage()">
                                    <option v-for="pageNumber in onGetSelectListTotalPages" :key="pageNumber" :value="pageNumber">
                                        {{ pageNumber }}
                                    </option>
                                </select>
                                <span>페이지를 보고 있습니다</span>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <ul class="pagination pagination-sm m-0 float-right">
                                    <li class="page-item" v-if="listCurrentPage !== 1">
                                        <span class="page-link" @click="onChangeListPreviousPage">&laquo;</span>
                                    </li>
                                    <li class="page-item" v-for="pageNumber in listTotalPages" :key="pageNumber" :class="{ 'active': pageNumber === listCurrentPage }">
                                        <span class="page-link" @click="onChangeListPage(pageNumber)">{{ pageNumber }}</span>
                                    </li>
                                    <li class="page-item" v-if="listCurrentPage < listTotalPages">
                                        <span class="page-link" @click="onChangeListNextPage">&raquo;</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-2" v-if="isViewSearch">
            <form @submit.prevent="onClickSearchList">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">검색</h3>
                    <div class="card-tools">
                        <button type="button" class="btn btn-success btn-sm" @click="onClickSearchReset()">초기화</button>&nbsp;
                        <button type="button" class="btn btn-primary btn-sm" @click="onClickSearchList()">검색</button>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="search-wrap">
                        <ul class="flex-column">
                            <li>
                                <div class="search-row">
                                    <div class="search-name">상태</div>
                                    <div>
                                        <component-form-checkbox
                                            ref="searchCheckProductStateRef"
                                            checkbox-name="searchCheck[3][PRODUCT_STATE]" 
                                            :checkbox-list="[
                                                { name: '사용', value: 1 },
                                                { name: '정지', value: 0 }
                                            ]" 
                                            :checkbox-default="[1, 0]" 
                                            @on-change-checkbox="onHandleSearchParam"
                                        />
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="search-row">
                                    <div class="search-name">판매분류</div>
                                    <div>
                                        <div class="input-checkbox-group">
                                            <label class="input-label" for="searchCheckProductSubscribeRentalSale">
                                                <input type="checkbox"
                                                    id="searchCheckProductSubscribeRentalSale"
                                                    value="1"
                                                    v-model="searchCheckProductSubscribeRentalSale"
                                                    @change="onChangeSearchParamSubscribeRentalSale"
                                                />
                                                <span class="checkbox-icon"></span>
                                                <span class="label-text">전체</span>
                                            </label>
                                            <label class="input-label" for="searchCheckProductSubscribe">
                                                <input type="checkbox"
                                                    id="searchCheckProductSubscribe"
                                                    name="searchCheck[0][PRODUCT_SUBSCRIBE]"
                                                    value="1"
                                                    v-model="searchCheckProductSubscribe"
                                                    @change="onChangeSearchParamSubscribe"
                                                />
                                                <span class="checkbox-icon"></span>
                                                <span class="label-text">구독</span>
                                            </label>
                                            <label class="input-label" for="searchCheckProductRental">
                                                <input type="checkbox"
                                                    id="searchCheckProductRental"
                                                    name="searchCheck[0][PRODUCT_RENTAL]"
                                                    value="1"
                                                    v-model="searchCheckProductRental"
                                                    @change="onChangeSearchParamRental"
                                                />
                                                <span class="checkbox-icon"></span>
                                                <span class="label-text">렌탈형 구독</span>
                                            </label>
                                            <label class="input-label" for="searchCheckProductSale">
                                                <input type="checkbox"
                                                    id="searchCheckProductSale"
                                                    name="searchCheck[0][PRODUCT_SALE]"
                                                    value="1"
                                                    v-model="searchCheckProductSale"
                                                    @change="onChangeSearchParamSale"
                                                />
                                                <span class="checkbox-icon"></span>
                                                <span class="label-text">판매</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="search-row">
                                    <div class="search-name">상품용도</div>
                                    <div>
                                        <div class="input-checkbox-group">
                                            <label class="input-label" for="searchCheckProductPurpose">
                                                <input type="checkbox"
                                                    id="searchCheckProductPurpose"
                                                    value="1"
                                                    v-model="searchCheckProductPurpose"
                                                    @change="onChangeSearchParamPurpose"
                                                />
                                                <span class="checkbox-icon"></span>
                                                <span class="label-text">전체</span>
                                            </label>

                                            <label class="input-label" for="searchCheckProductPurposeGaming">
                                                <input type="checkbox"
                                                    id="searchCheckProductPurposeGaming"
                                                    name="searchCheck[1][PRODUCT_PURPOSE_GAMING]"
                                                    value="1"
                                                    v-model="searchCheckProductPurposeGaming"
                                                    @change="onChangeSearchParamPurposeGaming"
                                                />
                                                <span class="checkbox-icon"></span>
                                                <span class="label-text">게이밍용</span>
                                            </label>
                                            
                                            <label class="input-label" for="searchCheckProductPurposeProfessional">
                                                <input type="checkbox"
                                                    id="searchCheckProductPurposeProfessional"
                                                    name="searchCheck[1][PRODUCT_PURPOSE_PROFESSIONAL]"
                                                    value="1"
                                                    v-model="searchCheckProductPurposeProfessional"
                                                    @change="onChangeSearchParamPurposeProfessional"
                                                />
                                                <span class="checkbox-icon"></span>
                                                <span class="label-text">전문가용</span>
                                            </label>
                                            <label class="input-label" for="searchCheckProductPurposeWork">
                                                <input type="checkbox"
                                                    id="searchCheckProductPurposeWork"
                                                    name="searchCheck[1][PRODUCT_PURPOSE_WORK]"
                                                    value="1"
                                                    v-model="searchCheckProductPurposeWork"
                                                    @change="onChangeSearchParamPurposeWork"
                                                />
                                                <span class="checkbox-icon"></span>
                                                <span class="label-text">업무용</span>
                                            </label>
                                            <label class="input-label" for="searchCheckProductPurposeStudy">
                                                <input type="checkbox"
                                                    id="searchCheckProductPurposeStudy"
                                                    name="searchCheck[1][PRODUCT_PURPOSE_STUDY]"
                                                    value="1"
                                                    v-model="searchCheckProductPurposeStudy"
                                                    @change="onChangeSearchParamPurposeStudy"
                                                />
                                                <span class="checkbox-icon"></span>
                                                <span class="label-text">학습용</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="search-row">
                                    <div class="search-name">검색어</div>
                                    <div class="w-100">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-5">
                                                <component-form-select
                                                    ref="searchTextWhereRef"
                                                    select-name="searchTextWhere" 
                                                    :select-list="[
                                                        { name: '상품명', value: 'PRODUCT_NAME' },
                                                    ]" 
                                                    select-placeholder="- 구분 -" 
                                                    :select-default="[]" 
                                                    @on-change-select="onHandleSearchParam"
                                                />
                                            </div>
                                            <div class="col-sm-12 col-md-7">
                                                <component-form-text
                                                    ref="searchTextRef"
                                                    text-name="searchText"
                                                    text-placeholder="검색어를 선택해주세요."
                                                    @on-change-text="onHandleSearchParam"
                                                ></component-form-text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            </form>
        </div>
    </div>

    <alert ref="alertRef" :title="'알림'" />
    <modal-product-update ref="modalProductUpdateRef" @onCloseModal="onCloseModalProductUpdate" />
    <modal-product-detail ref="modalProductDetailRef" @onCloseModal="onCloseModalProductDetail" />
    <modal-product-delete ref="modalProductDeleteRef" @onCloseModal="onCloseModalProductDelete" />
    <modal-product-copy ref="modalProductCopyRef" @onCloseModal="onCloseModalProductCopy" />

</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { numberFormat  } from '@/helper/utils/etcUtils.js'
import Alert from '@/modals/common/Alert'

import ModalProductUpdate from '@/modals/admin/product/ModalProductUpdate'
import ModalProductDetail from '@/modals/admin/product/ModalProductDetail'
import ModalProductDelete from '@/modals/admin/product/ModalProductDelete'
import ModalProductCopy from '@/modals/admin/product/ModalProductCopy'

import ComponentFormCheckbox  from "@/components/admin/ComponentFormCheckbox"
import ComponentFormSelect    from "@/components/admin/ComponentFormSelect"
import ComponentFormText      from "@/components/admin/ComponentFormText"

export default defineComponent({
    name       : 'adminProductListPage',
    components : {
        'alert' : Alert,
        'modal-product-update'      : ModalProductUpdate,
        'modal-product-detail'      : ModalProductDetail,
        'modal-product-delete'      : ModalProductDelete,
        'modal-product-copy'        : ModalProductCopy,
        'component-form-checkbox'   : ComponentFormCheckbox,
        'component-form-select'     : ComponentFormSelect,
        'component-form-text'       : ComponentFormText
    },
    setup() {
        const { $axiosConnect, storeState, storeProductPurpose, imgURL } = commonMixin()

        const alertRef              = ref(null)
        const submitButtonDisabled  = ref(true)
        const categoryList          = ref([])

        const list                  = ref([])
        const listCurrentPage       = ref(1);
        const listPageSize          = ref(10);
        const listTotalPages        = ref(0);
        const listAllCount          = ref(0);
        const listTotalCount        = ref(0);
        const listSearchCount       = ref(0);
        const listSearchTarget      = ref([])
        const queryStringParams     = ref({})
        const applySearchParams     = ref({})

        const isAllSelected                         = ref(false)
        const selectedList                          = ref([])
        const expandedRow                           = ref(null)
        const isViewSearch                          = ref(true)

        const modalProductUpdateRef                 = ref(null)
        const modalProductDetailRef                 = ref(null)
        const modalProductDeleteRef                 = ref(null)
        const modalProductCopyRef                   = ref(null)

        const searchCheckProductStateRef            = ref(null)
        const searchTextWhereRef                    = ref(null)
        const searchTextRef                         = ref(null)

        const searchCheckProductSubscribeRentalSale = ref(true)
        const searchCheckProductSubscribe           = ref(true)
        const searchCheckProductRental              = ref(true)
        const searchCheckProductSale                = ref(true)
        
        const searchCheckProductPurpose             = ref(true)
        const searchCheckProductPurposeGaming       = ref(true)
        const searchCheckProductPurposeProfessional = ref(true)
        const searchCheckProductPurposeWork         = ref(true)
        const searchCheckProductPurposeStudy        = ref(true)

        const getList = async () => {
            try {
                let defaultParams = {
                    'page'     : listCurrentPage.value,
                    'pageSize' : listPageSize.value
                };
                let params = Object.assign({}, defaultParams, applySearchParams.value);
                const response = await $axiosConnect('MEMBER', `/admin/product`, 'GET', params);

                list.value            = []
                listAllCount.value    =  listTotalCount.value  = listTotalPages.value = listSearchCount.value = 0
                if(response.body){
                    list.value            = JSON.parse(JSON.stringify(response.body.list))
                    listAllCount.value    = JSON.parse(JSON.stringify(response.body.allTotal))
                    listTotalCount.value  = JSON.parse(JSON.stringify(response.body.total))
                    listTotalPages.value  = listTotalCount.value > 0 ? Math.ceil(listTotalCount.value / listPageSize.value) : 1
                    listSearchCount.value = list.value.length
                }

                if(listSearchCount.value > 0){
                    list.value = list.value.map(list => {
                        return {
                            ...list,
                            convertUid                          : list.uid.toString().padStart(6, '0'),
                            convertRegDate                      : convertDateToStr(list.regDate),
                            convertUpDate                       : convertDateToStr(list.upDate),
                            convertProductState                 : storeState.value[list.productState],
                            convertProductPurpose               : storeProductPurpose.value[list.productPurpose],
                            convertProductSubscribeRegularPrice : numberFormat(list.productSubscribeRegularPrice),
                            convertProductSubscribePrice        : numberFormat(list.productSubscribePrice),
                            convertProductRentalRegularPrice    : numberFormat(list.productRentalRegularPrice),
                            convertProductRentalPrice           : numberFormat(list.productRentalPrice),
                            convertProductRentalMonths          : numberFormat(list.productRentalMonths),
                            convertProductRentalMonthPrice      : numberFormat(list.productRentalPrice/list.productRentalMonths),
                            convertProductSaleRegularPrice      : numberFormat(list.productSaleRegularPrice),
                            convertProductSalePrice             : numberFormat(list.productSalePrice),
                            convertProductViewCount             : numberFormat(list.productViewCount),
                            productImages                       : list.upfiles.filter(file => file.upfilesColumn === 'productImages'),
                            productDescriptionImages            : list.upfiles.filter(file => file.upfilesColumn === 'productDescriptionImages'),
                            convertProductCategory              : list.productCategoryAll ? list.productCategoryAll.split(',').map(item => { const [parentUid, childUid, thirdUid] = item.split('/').map(Number); return { parentUid, childUid, thirdUid }; }).filter(item => item.parentUid || item.childUid || item.thirdUid) : [],
                        }
                    })
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const onChangeListPage = (page) => {
            if(page){
                listCurrentPage.value = page
            }
            getList()
        }
        const onChangeListPreviousPage = () => {
            if (listCurrentPage.value > 1) {
                listCurrentPage.value--
                getList()
            }
        }

        const onChangeListNextPage = () => {
            if (listCurrentPage.value < listTotalPages.value) {
                listCurrentPage.value++
                getList()
            }
        }

        const onChangeListPageSize = () => {
            getList()
        }

        const onClickExpandedRow = (index) => {
            expandedRow.value = expandedRow.value === index ? null : index;
        }

        const onGetSelectListTotalPages = computed(() => {
            const pages = []
            if (listTotalPages.value <= 20) {
                for (let i = 1; i <= listTotalPages.value; i++) {
                    pages.push(i)
                }
            } else {
                for (let i = 1; i <= 20; i++) {
                    pages.push(i)
                }
                for (let i = 30; i <= listTotalPages.value; i += 10) {
                    pages.push(i)
                }
                if (listCurrentPage.value > 20 && !pages.includes(listCurrentPage.value)) {
                    pages.push(listCurrentPage.value)
                }
            }
            return pages
        })

        const onGetSelectListPageSize = computed(() => {
            const pageSize = []

            pageSize.push(10);
            if(listTotalCount.value > 10 && listTotalCount.value <= 50){
                pageSize.push(20);
            }
            if(listTotalCount.value > 50){
                pageSize.push(50);
            }
            if(listTotalCount.value > 100){
                pageSize.push(100);
            }
            if(listTotalCount.value > 10 && (listTotalCount.value !== 10 || listTotalCount.value !== 50 || listTotalCount.value !== 100)){
                pageSize.push(listTotalCount.value);
            }

            return pageSize
        })

        const onClickSearchList = () => {
            applySearchParams.value = { ...queryStringParams.value }
            getList()
        }

        const onHandleSearchParam = (param) => {
            queryStringParams.value[param.name] = 
                param.selecteds.length > 0 ? 
                (param.selecteds.length > 1 ? param.selecteds : param.selecteds[0]) : []
        }

        
        const onChangeSearchParamSubscribeRentalSale = (event) => {
            const { checked } = event.target;
            if (checked) {
                queryStringParams.value["searchCheck[0][PRODUCT_SUBSCRIBE]"] = 1;
                queryStringParams.value["searchCheck[0][PRODUCT_RENTAL]"]    = 1;
                queryStringParams.value["searchCheck[0][PRODUCT_SALE]"]      = 1;
            } else {
                if (queryStringParams.value["searchCheck[0][PRODUCT_SUBSCRIBE]"] === 1) {
                    delete queryStringParams.value["searchCheck[0][PRODUCT_SUBSCRIBE]"];
                }
                if (queryStringParams.value["searchCheck[0][PRODUCT_RENTAL]"] === 1) {
                    delete queryStringParams.value["searchCheck[0][PRODUCT_RENTAL]"];
                }
                if (queryStringParams.value["searchCheck[0][PRODUCT_SALE]"] === 1) {
                    delete queryStringParams.value["searchCheck[0][PRODUCT_SALE]"];
                }
            }
            searchCheckProductSubscribe.value = searchCheckProductRental.value = searchCheckProductSale.value = checked
        }

        const onChangeSearchParamSubscribe = (event) => {
            const { checked } = event.target;
            if (checked) {
                queryStringParams.value["searchCheck[0][PRODUCT_SUBSCRIBE]"] = 1;
                searchCheckProductSubscribe.value = true
            } else {
                if (queryStringParams.value["searchCheck[0][PRODUCT_SUBSCRIBE]"] === 1) {
                    delete queryStringParams.value["searchCheck[0][PRODUCT_SUBSCRIBE]"];
                    searchCheckProductSubscribe.value = false
                }
            }
            searchCheckProductSubscribeRentalSale.value = searchCheckProductSubscribe.value && searchCheckProductRental.value && searchCheckProductSale.value
        }

        const onChangeSearchParamRental = (event) => {
            const { checked } = event.target;
            if (checked) {
                queryStringParams.value["searchCheck[0][PRODUCT_RENTAL]"] = 1;
                searchCheckProductRental.value = true
            } else {
                if (queryStringParams.value["searchCheck[0][PRODUCT_RENTAL]"] === 1) {
                    delete queryStringParams.value["searchCheck[0][PRODUCT_RENTAL]"];
                    searchCheckProductRental.value = false
                }
            }
            searchCheckProductSubscribeRentalSale.value = searchCheckProductSubscribe.value && searchCheckProductRental.value && searchCheckProductSale.value
        }

        const onChangeSearchParamSale = (event) => {
            const { checked } = event.target;
            if (checked) {
                queryStringParams.value["searchCheck[0][PRODUCT_SALE]"] = 1;
                searchCheckProductSale.value = true
            } else {
                if (queryStringParams.value["searchCheck[0][PRODUCT_SALE]"] === 1) {
                    delete queryStringParams.value["searchCheck[0][PRODUCT_SALE]"];
                    searchCheckProductSale.value = false
                }
            }
            searchCheckProductSubscribeRentalSale.value = searchCheckProductSubscribe.value && searchCheckProductRental.value && searchCheckProductSale.value
        }

        const onChangeSearchParamPurpose = (event) => {
            const { checked } = event.target;
            if (checked) {
                queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_GAMING]"]       = 1;
                queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_PROFESSIONAL]"] = 1;
                queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_WORK]"]         = 1;
                queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_STUDY]"]        = 1;
            } else {
                if (queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_GAMING]"] === 1) {
                    delete queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_GAMING]"];
                }
                if (queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_PROFESSIONAL]"] === 1) {
                    delete queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_PROFESSIONAL]"];
                }
                if (queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_WORK]"] === 1) {
                    delete queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_WORK]"];
                }
                if (queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_STUDY]"] === 1) {
                    delete queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_STUDY]"];
                }
            }
            searchCheckProductPurposeGaming.value = searchCheckProductPurposeProfessional.value = searchCheckProductPurposeWork.value = searchCheckProductPurposeStudy.value = checked
        }

        const onChangeSearchParamPurposeGaming = (event) => {
            const { checked } = event.target;
            if (checked) {
                queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_GAMING]"] = 1;
                searchCheckProductPurposeGaming.value = true
            } else {
                if (queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_GAMING]"] === 1) {
                    delete queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_GAMING]"];
                    searchCheckProductPurposeGaming.value = false
                }
            }
            searchCheckProductPurpose.value = searchCheckProductPurposeGaming.value && searchCheckProductPurposeProfessional.value && searchCheckProductPurposeWork.value && searchCheckProductPurposeStudy.value
        }

        const onChangeSearchParamPurposeProfessional = (event) => {
            const { checked } = event.target;
            if (checked) {
                queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_PROFESSIONAL]"] = 1;
                searchCheckProductPurposeProfessional.value = true
            } else {
                if (queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_PROFESSIONAL]"] === 1) {
                    delete queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_PROFESSIONAL]"];
                    searchCheckProductPurposeProfessional.value = false
                }
            }
            searchCheckProductPurpose.value = searchCheckProductPurposeGaming.value && searchCheckProductPurposeProfessional.value && searchCheckProductPurposeWork.value && searchCheckProductPurposeStudy.value
        }

        const onChangeSearchParamPurposeWork = (event) => {
            const { checked } = event.target;
            if (checked) {
                queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_WORK]"] = 1;
                searchCheckProductPurposeWork.value = true
            } else {
                if (queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_WORK]"] === 1) {
                    delete queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_WORK]"];
                    searchCheckProductPurposeWork.value = false
                }
            }
            searchCheckProductPurpose.value = searchCheckProductPurposeGaming.value && searchCheckProductPurposeProfessional.value && searchCheckProductPurposeWork.value && searchCheckProductPurposeStudy.value
        }

        const onChangeSearchParamPurposeStudy = (event) => {
            const { checked } = event.target;
            if (checked) {
                queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_STUDY"] = 1;
                searchCheckProductPurposeStudy.value = true
            } else {
                if (queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_STUDY]"] === 1) {
                    delete queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_STUDY]"];
                    searchCheckProductPurposeStudy.value = false
                }
            }
            searchCheckProductPurpose.value = searchCheckProductPurposeGaming.value && searchCheckProductPurposeProfessional.value && searchCheckProductPurposeWork.value && searchCheckProductPurposeStudy.value
        }

        const onClickRefreshList = () => {
            getList()
        }

        const onClickSearchReset = () => {
            queryStringParams.value = {}
            applySearchParams.value = {}
            searchCheckProductStateRef.value?.onHandleReset()
            searchTextWhereRef.value?.onHandleReset()
            searchTextRef.value?.onHandleReset()

            queryStringParams.value["searchCheck[0][PRODUCT_SUBSCRIBE]"]            = 1
            queryStringParams.value["searchCheck[0][PRODUCT_RENTAL]"]               = 1
            queryStringParams.value["searchCheck[0][PRODUCT_SALE]"]                 = 1
            queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_GAMING]"]       = 1
            queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_PROFESSIONAL]"] = 1
            queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_WORK]"]         = 1
            queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_STUDY]"]        = 1

            getList()
        }

        const onClickAllSelected = () => {
            selectedList.value = []

            if(isAllSelected.value){
                Object.values(list.value).forEach(item => {
                    selectedList.value.push(item)
                })
            }
        }

        const onClickPartSelected = () => {
            isAllSelected.value = (selectedList.value.length === list.value.length)?true:false
        }

        const onClickModalProductDetail = (item) => {
            modalProductDetailRef.value.open({
                'mode' : 'detail',
                'item' : item
            })
        }

        const onCloseModalProductDetail = (payload) => {
            if(payload.isUpdate){
                onClickModalProductUpdate('update', payload.item)
                getList()
            }
        }

        const onClickModalProductUpdate = (mode, item) => {
            modalProductUpdateRef.value.open({
                'mode' : mode,
                'item' : item
            })
        }

        const onCloseModalProductUpdate = (payload) => {
            if(payload.isUpdate){
                onClickModalProductDetail(payload.item)
                getList()
            }
        }

        const onClickModalProductDelete = (item) => {
            if(item || selectedList.value.length > 0){
                modalProductDeleteRef.value.open({
                    'mode' : 'delete',
                    'item' : item ? [item] : selectedList.value
                })
            }else{
                alertRef.value.open({ 'contents' : '선택된 항목이 없습니다.' })
                return
            }
        }

        const onCloseModalProductDelete = (payload) => {
            if(payload.isUpdate){
                selectedList.value = []
                getList()
            }
        }

        const onClickModalProductCopy = (item) => {
            modalProductCopyRef.value.open({
                'mode' : 'copy',
                'item' : item ? item : {}
            })
        }
        
        const onCloseModalProductCopy = (payload) => {
            if(payload.isUpdate){
                getList()
            }
        }

        const onClickIsViewSearch = () => {
            isViewSearch.value = !isViewSearch.value
        }

        const getCategoryList = async () => {
            try {
                let defaultParams = {
                    'page'          : 1,
                    'pageSize'      : 1000,
                    'categoryState' : 1
                }
                const response = await $axiosConnect('MEMBER', `/admin/categoryProduct`, 'GET', defaultParams)

                let list = []
                if(response.body){
                    list = JSON.parse(JSON.stringify(response.body.list))
                    if(list.length > 0){
                        categoryList.value = list.map(({ uid, categoryName, groups, depth }) => {
                            return {
                                categoryUid    : uid,
                                categoryName   : categoryName,
                                categoryGroups : groups,
                                categoryDepth  : depth
                            }
                        })
                    }
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const getCategoryName = (uid) => {
            const category = categoryList.value.find(cat => cat.categoryUid === uid);
            return category ? category.categoryName : '';
        };

        const initialize = async () => {
            queryStringParams.value["searchCheck[0][PRODUCT_SUBSCRIBE]"]            = 1
            queryStringParams.value["searchCheck[0][PRODUCT_RENTAL]"]               = 1
            queryStringParams.value["searchCheck[0][PRODUCT_SALE]"]                 = 1
            queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_GAMING]"]       = 1
            queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_PROFESSIONAL]"] = 1
            queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_WORK]"]         = 1
            queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_STUDY]"]        = 1

            await getList()
            await getCategoryList()
        }
        
        onMounted(async () => {
            await initialize()
        })

        return {
            alertRef,
            submitButtonDisabled,
            list,
            expandedRow,
            modalProductDetailRef,
            modalProductUpdateRef,
            modalProductDeleteRef,
            modalProductCopyRef,

            listCurrentPage,
            listPageSize,
            listTotalPages,
            listAllCount,
            listTotalCount,
            listSearchCount,
            listSearchTarget,
            isAllSelected,
            selectedList,
            isViewSearch,

            searchCheckProductStateRef,
            searchTextWhereRef,
            searchTextRef,
            searchCheckProductSubscribeRentalSale,
            searchCheckProductSubscribe,
            searchCheckProductRental,
            searchCheckProductSale,
            searchCheckProductPurpose,
            searchCheckProductPurposeGaming,
            searchCheckProductPurposeProfessional,
            searchCheckProductPurposeWork,
            searchCheckProductPurposeStudy,

            categoryList,
            imgURL,

            onChangeListPage,
            onChangeListPreviousPage,
            onChangeListNextPage,
            onChangeListPageSize,
            onClickExpandedRow,
            onGetSelectListTotalPages,
            onGetSelectListPageSize,
            onClickSearchList,
            onHandleSearchParam,

            onChangeSearchParamSubscribeRentalSale,
            onChangeSearchParamSubscribe,
            onChangeSearchParamRental,
            onChangeSearchParamSale,
            onChangeSearchParamPurpose,
            onChangeSearchParamPurposeGaming,
            onChangeSearchParamPurposeProfessional,
            onChangeSearchParamPurposeWork,
            onChangeSearchParamPurposeStudy,

            onClickRefreshList,
            onClickSearchReset,
            onClickAllSelected,
            onClickPartSelected,
            onClickModalProductUpdate,
            onCloseModalProductUpdate,
            onClickModalProductDetail,
            onCloseModalProductDetail,
            onClickModalProductDelete,
            onCloseModalProductDelete,
            onClickIsViewSearch,
            getCategoryName,
            onClickModalProductCopy,
            onCloseModalProductCopy
        }

    }
});
</script>