<template>
    <Modal ref="baseModal" :title="`이미지 보기`" :width="`800px`" :isDrag="false" @close="close">
        <template #body>
            <div class="images-view">
                <div class="images-view-button" @click="onClickPrevImage">
                    <i class="fas fa-chevron-left"></i>
                </div>
                <img :src="imgURL + currentImage.upfilesAttachPath + currentImage.upfilesFileName" 
                    alt="이미지" 
                    style="max-width: 100%; height: auto;"
                />
                <div class="images-view-button" @click="onClickNextImage">
                    <i class="fas fa-chevron-right"></i>
                </div>
            </div>
        </template>
        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">닫기</button>
        </template>
	</Modal>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import Modal from '@/modals/Modal'

export default defineComponent({
    name       : 'modalImagesViewerPage',
    components: {
		Modal
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){
        const { imgURL } = commonMixin()

        const baseModal = ref(null)
		const resolvePromise = ref(null)

        const submitButtonDisabled  = ref(false)
        const closeModalData        = ref({})

        const allImagesData = ref({})
        const imagesData    = ref({})
        const currentIndex  = ref(0);
        
        const initialize = async () => {
            //
        }

		const open = async (payload) => {
            closeModalData.value = {
                isUpdate : false,
                item     : ''
            }

            //Open Modal
			baseModal.value.openModal()

            allImagesData.value = JSON.parse(JSON.stringify(payload.allItem))
            imagesData.value = JSON.parse(JSON.stringify(payload.item))
            
            const foundIndex = allImagesData.value.findIndex(image => image.uid === imagesData.value.uid);
            if (foundIndex !== -1) {
                currentIndex.value = foundIndex; // Set currentIndex to the found index
            } else {
                currentIndex.value = 0; // Fallback in case the image is not found
            }

            await initialize()

			return new Promise((resolve) => {
				resolvePromise.value = resolve;
			});
		};

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			baseModal.value.closeModal()
			resolvePromise.value(closeModalData.value)
		};

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onClickSave = async () => {
            //
        }

        const onClickPrevImage = () => {
            if (currentIndex.value > 0) {
                currentIndex.value--;
            }else{
                currentIndex.value = allImagesData.value.length -1
            }
        };

        const onClickNextImage = () => {
            if (currentIndex.value < allImagesData.value.length - 1) {
                currentIndex.value++;
            }else{
                currentIndex.value = 0
            }
        };

        const currentImage = computed(() => {
            return allImagesData.value.length > 0 ? allImagesData.value[currentIndex.value] : {};
        })

        return {
			baseModal, 
			open,
			close,
            onClickSave,
			submitButtonDisabled,
            imgURL,
            currentIndex,
            currentImage,
            onClickPrevImage,
            onClickNextImage,
		}
    }
});
</script>
<style scoped>
.images-view {
    position: relative;
}

.images-view-button {
    cursor: pointer;
    font-size: 34px;
    color: #333;
    padding: 10px;
    transition: color 0.3s;
    position: fixed;
    top: 250px;
    left: 20px;
    right: auto;
}
.images-view-button:last-child {
    left: auto;
    right: 40px;
}

.images-view-button:hover {
    color: #007bff;
}

.images-view-button:disabled {
    cursor: not-allowed;
    color: #ccc;
}
</style>