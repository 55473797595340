<template>
	<label :for="`${selectName}`" class="d-none">선택</label>
	<select :name="`${selectName}`" v-model="selected" @change="onChangeSelect" class="form-control">
		<option v-if="selectPlaceholder !== ''" value="">{{ selectPlaceholder }}</option>
		<option v-for="(item, index) in selectList" :key="index" :value="String(item.value)">{{ item.name }}</option>
	</select>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue'

export default defineComponent({
	name: 'ComponentFormSelect',
	props: {
        selectDefault: {
            type: Array,
            required: false,
            default: () => []
        },
        selectName: {
            type: String,
            required: true,
            default: ''
        },
        selectList: {
            type: Array,
            required: true,
            default: () => []
        },
        selectPlaceholder: {
            type: String,
            required: false,
            default: '- 선택 -'
        }
    },
	emits : ['onChangeSelect'],
	setup(props, {emit}) {
		const selected  = ref('')
        const isInitial = ref(true)

		onMounted(() => {
            onHandleReset()
        })

		const onChangeSelect = () => {
			if (!isInitial.value) {
				emit('onChangeSelect', {
					name     : props.selectName,
					selecteds: selected.value ? [selected.value.toString()] : []
				});
            } else {
                isInitial.value = false
            }
		}

		const onHandleReset = () => {
            selected.value = props.selectDefault && props.selectDefault.length > 0 ? props.selectDefault[0] : ''
        };

		watch(selected, () => {
            onChangeSelect()
        })

		return {
			selected,
			onChangeSelect,
            onHandleReset
		}
	}
})
</script>