<template>
    <Modal ref="baseModal" :title="`구독 취소 신청`" :width="`300px`" :isDrag="false" @close="close">
        <template #body>
            <div>
                해당 상품을 구독 취소 신청하시겠습니까?<br><br>
				구독 취소 시 기 납입한 구독료는 환불되지 않습니다.<br>
                단, 구독 개시일 후 3일 이내 또는 구독료 납부 후 3일 이내에는 환불이 가능합니다.<br><br>
                * 구독 서비스 계약 및 이용은 구독 서비스 약관의 규정에 우선 따릅니다.
            </div>
        </template>
        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">닫기</button>
			<button type="submit" class="btn btn-primary-1" :disabled="submitButtonDisabled" @click="onClickSave">구독 취소 신청</button>
        </template>
	</Modal>
</template>

<script>
import { defineComponent, ref } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import Modal from '@/modals/Modal'

export default defineComponent({
    name       : 'modalOrderCancelPage',
    components: {
		Modal
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){
        const { $axiosConnect } = commonMixin()

        const baseModal = ref(null)
		const resolvePromise = ref(null)

        const submitButtonDisabled  = ref(false)
        const closeModalData        = ref({})

        const productData = ref({})
        
        const initialize = async () => {
        }

		const open = async (payload) => {
            closeModalData.value = {
                isUpdate : false,
                item     : ''
            }

            //Open Modal
			baseModal.value.openModal()

            productData.value = JSON.parse(JSON.stringify(payload.item))
            await initialize()

			return new Promise((resolve) => {
				resolvePromise.value = resolve;
			});
		};

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			baseModal.value.closeModal()
			resolvePromise.value(closeModalData.value)
		};

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onClickSave = async () => {
            productData.value.orderState = '구독취소신청'

            const formData = new FormData();
            for (const key in productData.value) {
                if (productData.value[key] !== '') {
                    formData.set(key, productData.value[key]);
                }
            }

            const response = await $axiosConnect('MEMBER', `/admin/order/${productData.value.uid}`, 'POST', formData)
            if (response.success && response.body && response.body.list.length > 0) {
                closeModalData.value = {
                    isUpdate : true,
                    item     : ''
                }
                close()
            }else{
                alert("상태 변경중 문제가 발생했습니다. 관리자에게 문의하세요.")
            }
        }

        return {
			baseModal, 
			open,
			close,
            onClickSave,
			submitButtonDisabled
		}
    }
});
</script>