<template>
    <Modal ref="baseModal" :title="`주문 ${mode === 'create' ? '추가' : '수정'}`" :width="`95%`">
        <template #body>
			<form ref="orderFormRef" @submit.prevent="onClickSave">
                <div class="row">
                    <div class="col-sm-4 border-right">
                        <div class="form-group row form-required">
                            <label for="productName" class="col-sm-2 col-form-label">상품명</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.productName }}</span>
                                </div>
                            </div>
                        </div>
                        <template v-for="(item, index) in orderFormData.orderItems" :key="index">
                            <div class="form-group row">
                                <label for="productDescription" class="col-sm-2 col-form-label">주문상품 {{ index + 1 }}</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                        <span class="form-control">{{ item.productItemName }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">주문구분</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control" v-if="orderFormData.orderType==='subscribe'">구독</span>
                                    <span class="form-control" v-if="orderFormData.orderType==='rental'">렌탈형 구독</span>
                                    <span class="form-control" v-if="orderFormData.orderType==='sale'">판매</span>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">계약기간</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.convertOrderMonths }}개월</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">총 주문가격</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.convertOrderTotalPrice }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">보증금</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.convertOrderDepositPrice }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">기본가격</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.convertOrderDefaultTotalPrice }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">옵션가격</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.convertOrderOptionTotalPrice }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productDescription" class="col-sm-2 col-form-label">서비스가격</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.convertOrderServiceTotalPrice }}</span>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-sm-4 border-right">
                        <div class="form-group row form-required">
                            <label for="productName" class="col-sm-2 col-form-label">주문자</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.regMemberName }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row form-required">
                            <label for="productName" class="col-sm-2 col-form-label">주문자 ID</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.regMemberId }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row form-required">
                            <label for="productName" class="col-sm-2 col-form-label">주문자 전화번호</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.orderMobile }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row form-required">
                            <label for="productName" class="col-sm-2 col-form-label">주문일</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ orderFormData.convertRegDate }}</span>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div class="form-group row">
                            <label for="productName" class="col-sm-2 col-form-label">수령인</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <input type="text" class="form-control" id="inputMemberName" placeholder="사용자명을 입력해주세요." autocomplete="off" tabindex="1"
                                     
                                    maxlength="50" 
                                    pattern-hint="사용자명은 필수로 입력해야 합니다"
                                    v-model="orderFormData.orderRecipientName"
                                    @input="onInputCheckEvent" 
                                    />
                                </div>
                                <small class="input-hint"></small>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productName" class="col-sm-2 col-form-label">휴대폰 번호</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <input type="text" class="form-control" id="memberMobile" placeholder="수령인의 휴대폰 번호를 입력해주세요." autocomplete="off" tabindex="2"
                                    
                                    maxlength="11" 
                                    pattern="^010\d{8}$"
                                    pattern-hint="휴대폰 번호는 010으로 시작해야하며, 숫자만 입력해야 합니다."
                                    v-model="orderFormData.orderRecipientMobile"
                                    @blur="onInputCheckEvent()" 
                                    />
                                </div>
                                <small class="input-hint"></small>
                            </div>
                        </div>
                        <div class="form-group row mb-3">
                            <label for="memberName" class="col-sm-2 col-form-label">주소</label>
                            <div class="col-sm-10">
                                <div class="row">
                                    <div class="col-md-4 col-sm-6">
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="orderZipcode" placeholder="우편번호" autocomplete="off" tabindex="5"
                                            
                                            maxlength="5" 
                                            pattern="^\d{5}$"
                                            pattern-hint="우편번호를 입력해야 합니다."
                                            v-model="orderFormData.orderZipcode"
                                            @blur="onInputCheckEvent()" 
                                            @click="onClickFindAddress"
                                            readonly
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-sm-6">
                                        <button type="button" class="btn btn-primary btn-block"
                                        @click="onClickFindAddress"
                                        >주소찾기</button>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-12 col-sm-12">
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="orderAddr" placeholder="주소를 입력해주세요." autocomplete="off" tabindex="5"
                                            
                                            pattern-hint="주소를 입력해야 합니다."
                                            v-model="orderFormData.orderAddr"
                                            @blur="onInputCheckEvent()" 
                                            @click="onClickFindAddress"
                                            readonly
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-12 col-sm-12">
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="orderAddrDetail" placeholder="상세 주소를 입력해주세요." autocomplete="off" tabindex="5"
                                            pattern-hint="상세 주소를 입력해야 합니다."
                                            v-model="orderFormData.orderAddrDetail"
                                            @blur="onInputCheckEvent()" 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <div class="form-group row form-required">
                            <label for="productName" class="col-sm-2 col-form-label">배송상태</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <select class="form-control" v-model="orderFormData.orderDeliveryState" @blur="onInputCheckEvent()" >
                                        <option value="대기">대기</option>
                                        <option value="주문확인">주문확인</option>
                                        <option value="배송준비중">배송준비중</option>
                                        <option value="배송중">배송중</option>
                                        <option value="배송완료">배송완료</option>
                                    </select>
                                </div>
                                <small class="input-hint"></small>
                            </div>
                        </div>
                        <div class="form-group row form-required">
                            <label for="productName" class="col-sm-2 col-form-label">구독상태</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <select class="form-control" v-model="orderFormData.orderState" @blur="onInputCheckEvent()" >
                                        <option value="신청">신청</option>
                                        <option value="구독중">구독중</option>
                                        <option value="구독취소신청">구독취소(신청)</option>
                                        <option value="구독취소">구독취소(완료)</option>
                                        <option value="구독종료">구독종료</option>
                                    </select>
                                </div>
                                <small class="input-hint"></small>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="productName" class="col-sm-2 col-form-label">구독시작일</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <VueCtkDateTimePicker
                                        v-model="orderFormData.converOrderContractDate" 
                                        format="YYYY-MM-DD"
                                        outputFormat="YYYY-MM-DD"
                                        :onlyDate="true"
                                        :auto-close="false"
                                        label="구독시작일"
                                        formatted= "ll"
                                        @blur="onInputCheckEvent()" 
                                        @update:modelValue="(value) => onClickOrderUpdate(value)"
                                        />
                                </div>
                                <small class="input-hint"></small>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputContent" class="col-sm-2 col-form-label">비고</label>
                            <div class="col-sm-10">
                                <div class="pt-2 pb-2">
                                    <smarteditor2 
                                    v-model="orderFormData.convertOrderContent"
                                    :editor-id="'editor1'"
                                    ref="editor1"
                                    @blur="onInputCheckEvent()" 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</form>
        </template>
        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">닫기</button>
			<button type="submit" class="btn btn-primary" :disabled="submitButtonDisabled" @mouseenter="onOverSubmitButton" @click="onClickSave()">{{ mode === 'create'?'저장':'수정' }}</button>
        </template>
	</Modal>

	<alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, onMounted, ref, nextTick } from 'vue'
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr, getDateDifference, convertStrToUnixTime } from '@/helper/utils/dateUtils.js'
import { numberFormat, truncateText, unescapeHtmlEntities } from '@/helper/utils/etcUtils.js'

import Modal from '@/modals/Modal'
import Alert from '@/modals/common/Alert'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import ComponentFormSmarteditor2 from "@/components/admin/ComponentFormSmarteditor2"

export default defineComponent({
    name       : 'modalOrderUpdatePage',
    components: {
		Modal,
		'alert' : Alert,
        VueCtkDateTimePicker,
        'smarteditor2' : ComponentFormSmarteditor2,
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){
		const { openModal, closeModal, baseModal, onHandleValidateInput, storeState, $axiosConnect, onHandleIsImage, imgURL } = commonMixin()

        const mode                     = ref('create')
        const closeModalData           = ref({})
        const alertRef                 = ref(null)
        const submitButtonDisabled     = ref(true)

        const orderFormRef           = ref(null)
        const orderFormData          = ref({})

        const editor1 = ref(null)

		const open = (payload) => {
            initialize()

            if (payload.item && payload.mode === 'update') {
                orderFormData.value = JSON.parse(JSON.stringify(payload.item))
                mode.value = payload.mode
                getDeatil()
            }

            //하위등록시
            if(payload.item && payload.item.uid){
                orderFormData.value.parentUid = payload.item.uid
            }

            return openModal()
		}

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			closeModal(closeModalData.value)
		}
        
		/**
         * 입력 값의 유효성을 검사하고, 암호화된 아이디와 비밀번호를 생성하는 메서드
         * 유효성 검사 결과에 따라 submitDisabled 속성을 설정합니다.
         * @method onInputCheckEvent
         * @returns {void}
         */
         const onInputCheckEvent = () => {
            return !(submitButtonDisabled.value = !onHandleValidateInput(orderFormRef.value))
        }

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onOverSubmitButton = () => {
            const formElements = orderFormRef.value.querySelectorAll('input, select, textarea')
            formElements.forEach(input => {
                input.blur()
            })
        }

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
         const onClickSave = async () => {
            if(onInputCheckEvent()){
                //Editor Content
                editor1.value?.syncContent()

                const formData = new FormData()
                for (const key in orderFormData.value) {
                    if (orderFormData.value[key] !== '') {
                        formData.set(key, orderFormData.value[key]);
                    }
                }
                onProcessSave(formData)
            }
        }

        /**
         * 로그인 프로세스를 처리하는 메서드
         * @method onProcessSignIn
         * @param {FormData} formData - 로그인에 필요한 폼 데이터
         * @returns {void}
         */
        const onProcessSave = async (formData) => {
            try {

                let response
                if(mode.value === 'create'){
                    response = await $axiosConnect('MEMBER', `/admin/order`, 'POST', formData)
                }else{
                    const uid = orderFormData.value.uid
                    response = await $axiosConnect('MEMBER', `/admin/order/${uid}`, 'POST', formData)
                }

                if (response.success && response.body && response.body.list.length > 0) {

                    initialize()

                    closeModalData.value = {
                        isUpdate : true,
                        item     : response.body.list[0]
                    }

                    alertRef.value.open({ 
                        'contents' : response.message,
                        'callback' : close
                    })

                }else{
                    alertRef.value.open({ 'contents' : response.message })
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

        const getDeatil = async () => {
            try {
                const uid = orderFormData.value.uid
                const response = await $axiosConnect('MEMBER', `/admin/order/${uid}`, 'GET')

                if (response.success && response.body && response.body.list.length > 0) {

                    const list = JSON.parse(JSON.stringify(response.body.list[0]))
                    orderFormData.value = {
                        ...list,
                        convertUid                      : list.uid.toString().padStart(6, '0'),
                        convertProductName              : list.productName,
                        convertProductDescription       : truncateText(list.productDescription, 50),
                        convertRegDate                  : convertDateToStr(list.regDate),
                        convertUpDate                   : convertDateToStr(list.upDate),
                        convertOrderDepositPrice        : numberFormat(list.orderDepositPrice),
                        convertOrderTotalPrice          : numberFormat(list.orderTotalPrice),
                        convertOrderDefaultTotalPrice   : numberFormat(list.orderDefaultTotalPrice),
                        convertOrderOptionTotalPrice    : numberFormat(list.orderOptionTotalPrice),
                        convertOrderServiceTotalPrice   : numberFormat(list.orderServiceTotalPrice),
                        convertOrderMonths              : numberFormat(list.orderMonths),
                        convertDateDifference           : getDateDifference(parseInt(list.orderContractDate)),
                        converOrderContractDate         : convertDateToStr(list.orderContractDate),
                        convertProductImages            : list.productUpfiles.filter(file => file.upfilesColumn === 'productImages'),
                        convertProductDescriptionImages : list.productUpfiles.filter(file => file.upfilesColumn === 'productDescriptionImages'),
						convertOrderContent             : list.orderContent?unescapeHtmlEntities(list.orderContent):'&nbsp;',
                    }
                }else{
                    alertRef.value.open({ 'contents' : response.message })
                    close()
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const loadDaumPostCodeScript = () => {
            return new Promise((resolve, reject) => {
                if (document.getElementById('daum-postcode-script')) {
                    resolve();
                    return;
                }

                const script = document.createElement('script');
                script.id = 'daum-postcode-script';
                script.src = 'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
                script.onload = resolve;
                script.onerror = reject;
                document.body.appendChild(script);
            });
        };

        const openDaumPostcode = () => {
            new window.daum.Postcode({
                oncomplete: function(data) {
                    // Get the address and postal code and update the form data
                    orderFormData.value.orderZipcode = data.zonecode;
                    orderFormData.value.orderAddr = data.address;
                    // Focus on the detailed address input field after auto-filling
                    nextTick(() => {
                        document.getElementById('orderAddrDetail').focus();
                    });
                },
            }).open();
        };

        const onClickFindAddress = async () => {
            try {
                await loadDaumPostCodeScript();
                openDaumPostcode();
            } catch (error) {
                console.error('Failed to load Daum Postcode script:', error);
            }
        };

        const onClickOrderUpdate = (value) => {
            orderFormData.value.orderContractDate = convertStrToUnixTime(value)
        }

        const initialize = async () => {
            mode.value            = "create"

            orderFormData.value = {}

            closeModalData.value = {
                isUpdate : false,
                item     : ''
            }
        }

        onMounted(async () => {
            await initialize()
        })
       

        return {
			open,
			close,
            mode,
            submitButtonDisabled,

			alertRef,
			orderFormRef,
			orderFormData,

            editor1,

			onInputCheckEvent,
            onOverSubmitButton,
            onHandleIsImage,
            onClickFindAddress,
            onClickOrderUpdate,
            onClickSave,

            //commonMixin
            storeState,
            baseModal, 
            imgURL,
		}
    }
})
</script>