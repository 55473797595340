<template>
    <Modal ref="baseModal" :title="`${mode === 'create' && parentCategoryFormData.uid ? '하위 ' : ''}카테고리 ${mode === 'create' ? '추가' : '수정'}`" :width="`800px`">
        <template #body>
			<form ref="categoryFormRef" @submit.prevent="onClickSave">
                <div class="form-group row form-required" v-if="mode === 'create' && parentCategoryFormData.uid">
					<label for="inputCategoryName" class="col-sm-2 col-form-label">상위 분류명</label>
					<div class="col-sm-10">
                        <div class="input-group">
							<div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
							<span class="form-control">{{ parentCategoryFormData.categoryName }}</span>
						</div>
                        <small class="input-hint">분류명은 필수로 입력해야 합니다.</small>
					</div>
				</div>
				<div class="form-group row form-required">
					<label for="inputCategoryName" class="col-sm-2 col-form-label">분류명</label>
					<div class="col-sm-10">
						<div class="input-group">
							<div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
							<input type="text" class="form-control" id="inputCategoryName" placeholder="분류명을 입력해주세요." autocomplete="off" tabindex="1"
							required 
							maxlength="50" 
                            pattern-hint="분류명은 필수로 입력해야 합니다"
							v-model="categoryFormData.categoryName"
							@input="onInputCheckEvent" 
							/>
						</div>
                        <small class="input-hint"></small>
					</div>
				</div>
                <div class="form-group row form-required">
                    <label for="selectCategoryState" class="col-sm-2 col-form-label">사용여부</label>
                    <div class="col-sm-10">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-check-circle"></i></span>
                            </div>
                            <div class="form-control">
                                <label v-for="(item, key) in storeState" :key="key" class="input-label mr-3" :for="`categoryState_${key}`">
                                    <input type="radio"
                                        :id="`categoryState_${key}`"
                                        name="categoryState"
                                        :value="key"
                                        v-model="categoryFormData.categoryState"
                                        @input="onInputCheckEvent" 
                                        required
                                        pattern-hint="사용여부는 필수로 입력해야 합니다."
                                    />
                                    <span class="radio-icon"></span>
                                    <span class="label-text">{{ item }}</span>
                                </label>
                            </div>
                        </div>
                        <small class="input-hint"></small>
                    </div>
                </div>
                <div class="form-group row">
					<label for="inputContent" class="col-sm-2 col-form-label">비고</label>
					<div class="col-sm-10">
                        <div class="pt-2 pb-2">
                            <smarteditor2 
                            v-model="categoryFormData.categoryNotes"
                            :editor-id="'editor1'"
                            ref="editor1"
                            />
                        </div>
					</div>
				</div>
			</form>
        </template>
        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">닫기</button>
			<button type="submit" class="btn btn-primary" :disabled="submitButtonDisabled" @mouseenter="onOverSubmitButton" @click="onClickSave()">{{ mode === 'create'?'저장':'수정' }}</button>
        </template>
	</Modal>

	<alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import Modal from '@/modals/Modal'
import Alert from '@/modals/common/Alert'
import { unescapeHtmlEntities  } from '@/helper/utils/etcUtils.js'

import ComponentFormSmarteditor2 from "@/components/admin/ComponentFormSmarteditor2"

export default defineComponent({
    name       : 'modalCategoryUpdatePage',
    components: {
		Modal,
		'alert' : Alert,
        'smarteditor2' : ComponentFormSmarteditor2,
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){
		const { openModal, closeModal, baseModal, onHandleValidateInput, $axiosConnect, storeState } = commonMixin()

        const mode                   = ref('create')
		const alertRef               = ref(null)
        const submitButtonDisabled   = ref(true)
        const closeModalData         = ref({})

        const categoryFormRef        = ref(null)
        const categoryFormData       = ref({})
        const parentCategoryFormData = ref({})

        const editor1 = ref(null)
        
        const initialize = async () => {
            mode.value = 'create'

            categoryFormData.value = {
                categoryName   : '',
                categoryNotes  : '',
                categoryState  : 1
            }
            parentCategoryFormData.value = {}

            closeModalData.value = {
                isUpdate : false,
                item     : ''
            }
        }

        onMounted(async () => {
            await initialize()
        })

		const open = async (payload) => {
            initialize()

            if (payload.item && payload.mode === 'update') {
                categoryFormData.value = JSON.parse(JSON.stringify(payload.item))
                mode.value = payload.mode
                await getDeatil()
            }
            
            if(payload.item && payload.item.uid && payload.mode === 'create'){
                parentCategoryFormData.value = JSON.parse(JSON.stringify(payload.item))
                categoryFormData.value.parentUid = parentCategoryFormData.value.uid
            }

            return openModal()
		};

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			closeModal(closeModalData.value)
		};

		/**
         * 입력 값의 유효성을 검사하고, 암호화된 아이디와 비밀번호를 생성하는 메서드
         * 유효성 검사 결과에 따라 submitDisabled 속성을 설정합니다.
         * @method onInputCheckEvent
         * @returns {void}
         */
         const onInputCheckEvent = () => {
            return !(submitButtonDisabled.value = !onHandleValidateInput(categoryFormRef.value))
        }

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onOverSubmitButton = () => {
            const formElements = categoryFormRef.value.querySelectorAll('input, select, textarea')
            formElements.forEach(input => {
                input.blur()
            });
        }

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
        const onClickSave = async () => {
            if(onInputCheckEvent()){
                //Editor Content
                editor1.value?.syncContent()

                const formData = new FormData();
                for (const key in categoryFormData.value) {
                    if (categoryFormData.value[key] !== '') {
                        formData.set(key, categoryFormData.value[key]);
                    }
                }
                onProcessSave(formData)
            }
        }

        /**
         * 로그인 프로세스를 처리하는 메서드
         * @method onProcessSignIn
         * @param {FormData} formData - 로그인에 필요한 폼 데이터
         * @returns {void}
         */
        const onProcessSave = async (formData) => {
            try {

                let response
                if(mode.value === 'create'){
                    response = await $axiosConnect('MEMBER', `/admin/categoryItem`, 'POST', formData)
                }else{
                    const uid = categoryFormData.value.uid
                    response = await $axiosConnect('MEMBER', `/admin/categoryItem/${uid}`, 'PUT', formData)
                }

                if (response.success && response.body && response.body.list.length > 0) {
                    
                    initialize()

                    closeModalData.value = {
                        isUpdate : true,
                        item     : response.body.list[0]
                    }

                    alertRef.value.open({ 
                        'contents' : response.message,
                        'callback' : close
                    })

                }else{
                    alertRef.value.open({ 'contents' : response.message })
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

        const getDeatil = async () => {
            try {
                const uid = categoryFormData.value.uid
                const response = await $axiosConnect('MEMBER', `/admin/categoryItem/${uid}`, 'GET')

                if (response.success && response.body && response.body.list.length > 0) {
                    const list = JSON.parse(JSON.stringify(response.body.list[0]))
                    categoryFormData.value = {
                        ...list,
                        categoryNotes : unescapeHtmlEntities(list.categoryNotes)
                    }

                }else{
                    alertRef.value.open({ 'contents' : response.message })
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        return {
			open,
			close,
            mode,

			alertRef,
			submitButtonDisabled,
			categoryFormRef,
			categoryFormData,
            parentCategoryFormData,
            
            editor1,

			onInputCheckEvent,
            onOverSubmitButton,
            onClickSave,

            //commonMixin
            storeState,
            baseModal, 
		}
    }
});
</script>
<style scoped>
::v-deep .ql-editor {
    height: 200px;
    overflow: scroll;
    overflow-x: hidden;
}
</style>