import axios from 'axios'
//import { decryptNormal } from '@/helper/plugins/security.js'

export const createAxiosInstance = async (token) => {
    const axiosInstance = axios.create({
        timeout: 600 * 1000,
        crossdomain: true,
        headers: {
            'Content-Type': 'multipart/form-data; charset=UTF-8;'
        }
    })
    
    if (token) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }

    return axiosInstance
}

export const responseHandler = (res) => {
    const responseData = res.data
    if (responseData.code === 200 || responseData.code === 201) {
        return responseData
    } else {
        throw new Error(`${responseData.message} [${responseData.code}]`)
    }
}

const httpRequest = {
    install(app) {
        app.config.globalProperties.$axiosConnect = async (connectType='MEMBER', paramUrl, paramMethod, params = {}) => {
            const baseURL = process.env.VUE_APP_BASE_API_URL
            const token   = app.config.globalProperties.$store.getters['member/getAccessToken']

            if(connectType === 'MEMBER'){
                if(!token){
                    //connectType이 MEMBER일 경우에는 token이 꼭 있어야함.
                    throw new Error('토큰이 필요합니다.')
                }
            }

            app.config.globalProperties.$store.dispatch('common/setDataLoading', true)
            
            try {
                const axiosInstance = await createAxiosInstance(token)
                const res = await axiosInstance({
                    url    : `${baseURL}${paramUrl}`,
                    method : paramMethod,
                    params : paramMethod === 'GET' ? params : {},
                    data   : paramMethod !== 'GET' ? params : {}
                })

                console.log("res.data:", res.data)

                if (res.data.code === 401) {
                    //console.log("신규토큰발행요청:", res.data.code)
                    const refreshTokenInstance = await createAxiosInstance(token, 'refreshToken')
                    const refreshRes = await refreshTokenInstance({
                        url    : `${baseURL}/refreshToken`,
                        method : 'POST'
                    })
                    if (refreshRes.data.success && refreshRes.data.code === 200) {
                        //console.log("신규토큰발행됨:", refreshRes.data)
                        app.config.globalProperties.$store.dispatch('member/signIn', refreshRes.data)
                        const newToken = app.config.globalProperties.$store.getters['member/getToken']
                        const newAxiosInstance = await createAxiosInstance(newToken)
                        return responseHandler(await newAxiosInstance({
                            url    : `${baseURL}${paramUrl}`,
                            method : paramMethod,
                            data   : params
                        }))
                    } else {
                        if(token){
                            // 로그아웃 처리
                            app.config.globalProperties.$store.dispatch('member/signOut')
                            alert("로그아웃되었습니다. 다시 로그인 해주세요.")
                            app.config.globalProperties.$router.replace('/')
                            throw new Error("토큰 갱신 실패")
                        }
                    }
                } else {
                    return responseHandler(res)
                }
            } catch (error) {
                console.warn("오류 발생:", error)
                throw error
            } finally {
                app.config.globalProperties.$store.dispatch('common/setDataLoading', false)
            }
        }

        // 파일 다운로드 기능 추가
        app.config.globalProperties.$fileDownload = async (fileName, paramUrl, params = {}) => {
            const baseURL = process.env.VUE_APP_BASE_API_URL;
            const token = app.config.globalProperties.$store.getters['member/getAccessToken'];

            if (!token) {
                throw new Error('토큰이 필요합니다.');
            }
            app.config.globalProperties.$store.dispatch('common/setDataLoading', true);

            try {
                const axiosInstance = await createAxiosInstance(token);
                const res = await axiosInstance({
                    url: `${baseURL}${paramUrl}`,
                    method: 'GET', // 파일 다운로드는 주로 GET 요청으로 수행됩니다.
                    responseType: 'blob', // 이진 데이터를 받기 위해 responseType을 blob으로 설정
                    params
                });

                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); // 파일 이름 설정
                document.body.appendChild(link);
                link.click();

                // 사용된 URL 제거
                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);

            } catch (error) {
                console.warn("파일 다운로드 오류 발생:", error);
                throw error;
            } finally {
                app.config.globalProperties.$store.dispatch('common/setDataLoading', false);
            }
        }
    }
}

export default httpRequest