<template>
	<div class="row">
		<div class="col-sm-12">
			<ul class="product-review-list">
				<template v-for="(item, index) in productReviewList" :key="index">
					<li>
						<div class="product-review-list-info">
							<span class="product-review-list-stars">
								<span v-for="starIndex in 5" :key="starIndex" :class="{ 'active': starIndex <= item.star }">★</span>
							</span>
							<span>{{ item.convertRegDate }}</span>
							<span>{{ item.convertRegMemberName }}</span>
						</div>
						<div class="product-review-list-comments">
							<p class="product-review-list-subject">{{ item.convertSubject }}</p>
							<div class="product-review-list-content"><div v-html="item.convertContent"></div></div>
						</div>
                        <div v-if="item.convertFiles?.length">
                            <div class="review-images">
                                <template v-for="(filesItem, filesIndex) in item.convertFiles" :key="filesIndex">
                                    <div><img 
                                        :src="`${imgURL}${filesItem.upfilesThumnailPath}${filesItem.upfilesFileName}`" 
                                        class="absolute-center"
                                        role="button"
                                        @click="onClickModalImagesViewer(item.convertFiles, filesItem)"
                                        ></div>
                                </template>
                            </div>
                        </div>
					</li>
				</template>
			</ul>

            <!--
			<div class="mt-2" v-if="false">
				<ul class="pagination pagination-sm m-0" style="padding-left: auto">
					<li class="page-item" v-if="productReviewListCurrentPage !== 1">
						<span class="page-link" role="button" @click="onChangeProductReviewListPreviousPage">&laquo;</span>
					</li>
					<li 
						v-for="pageNumber in productReviewListTotalPages" :key="pageNumber"
						:class="['page-item', { active: parseInt(productReviewListCurrentPage) === parseInt(pageNumber) }]"
					>
						<span class="page-link" role="button" @click="onChangeProductReviewListPage(pageNumber)">{{ pageNumber }}</span>
					</li>
					<li class="page-item" v-if="productReviewListCurrentPage < productReviewListTotalPages">
						<span class="page-link" role="button" @click="onChangeProductReviewListNextPage">&raquo;</span>
					</li>
				</ul>
			</div>
            -->
		</div>
	</div>

    <modal-images-viewer ref="modalImagesViewerRef" />

</template>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { truncateText, unescapeHtmlEntities  } from '@/helper/utils/etcUtils.js'

import ModalImagesViewer from '@/modals/web/common/ModalImagesViewer'

export default defineComponent({
	name : 'ComponentProductDetailRecommend',
	props: {
        productFormData: {
            type: Object,
            required: true,
        }
    },
    components: {
        'modal-images-viewer' : ModalImagesViewer,
	},
    setup(props) {
		const { $axiosConnect, imgURL } = commonMixin()

		const productDetailData            = ref({})
		const productReviewList            = ref([])
        const productReviewListCurrentPage = ref(1);
        const productReviewListPageSize    = ref(1000);
        const productReviewListTotalPages  = ref(0);
        const productReviewListAllCount    = ref(0);
        const productReviewListTotalCount  = ref(0);
        const productReviewListSearchCount = ref(0);

        const modalImagesViewerRef = ref(null)

		const getProductReviewList = async () => {
			try {
                let defaultParams = {
                    'page'       : productReviewListCurrentPage.value,
                    'pageSize'   : productReviewListPageSize.value,
					'productUid' : productDetailData.value.uid
                };
                const response = await $axiosConnect('GUEST', `/web/productReview`, 'GET', defaultParams);

                if(response.body){
                    productReviewList.value            = JSON.parse(JSON.stringify(response.body.list))
                    productReviewListAllCount.value    = JSON.parse(JSON.stringify(response.body.allTotal))
                    productReviewListTotalCount.value  = JSON.parse(JSON.stringify(response.body.total))
                    productReviewListTotalPages.value  = productReviewListTotalCount.value > 0 ? Math.ceil(productReviewListTotalCount.value / productReviewListPageSize.value) : 1
                    productReviewListSearchCount.value = productReviewList.value.length
                }

                if(productReviewListSearchCount.value > 0){
                    productReviewList.value = productReviewList.value.map(list => {
                        return {
                            ...list,
                            convertUid           : list.uid.toString().padStart(6, '0'),
                            convertSubject       : truncateText(list.subject, 75),
							convertContent       : unescapeHtmlEntities(list.content),
							convertRegMemberName : truncateText(list.regMemberName, 75),
                            convertRegDate       : convertDateToStr(list.regDate),
                            convertUpDate        : convertDateToStr(list.upDate),
                            convertFiles         : list.upfiles.filter(file => file.upfilesColumn === 'files'),
                        }
                    })
                }

            } catch (error) {
                console.error("error:", error)
            }
		}
		const onChangeProductReviewListPage = (page) => {
            if(page){
                productReviewListCurrentPage.value = page
            }
            getProductReviewList()
        }
        const onChangeProductReviewListPreviousPage = () => {
            if (productReviewListCurrentPage.value > 1) {
                productReviewListCurrentPage.value--
                getProductReviewList()
            }
        }

        const onChangeProductReviewListNextPage = () => {
            if (productReviewListCurrentPage.value < productReviewListTotalPages.value) {
                productReviewListCurrentPage.value++
                getProductReviewList()
            }
        }

        const onClickModalImagesViewer = (allItem, item) => {
            modalImagesViewerRef.value.open({
                'mode'    : 'view',
                'allItem' : allItem,
                'item'    : item
            });
        }

		const initialize = async () => {
            productReviewList.value    = []
			productDetailData.value = props.productFormData;
        }

		onMounted(async () => {
            await initialize()
			await getProductReviewList()
        })

        return {
			imgURL,
			productReviewList,
			productReviewListCurrentPage,
			productReviewListTotalPages,
            modalImagesViewerRef,

			onChangeProductReviewListPreviousPage,
			onChangeProductReviewListPage,
			onChangeProductReviewListNextPage,
            onClickModalImagesViewer
        }
    }
})
</script>
<style scoped>
.review-images {
    display: flex;
    flex-direction: row;
}
.review-images div {
    width: 50px;
    height: 50px;
    overflow: hidden;
    position: relative;
    margin-right: 5px;
}
</style>