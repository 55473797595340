<template>
	<div class="row">
		<template v-for="(item, index) in productRecommendList" :key="index">
			<div class="col-12 col-sm-6 col-lg-4">
				<div class="product-item" data-aos-delay="000" @click="onClickNavigateToProductDetail(item.uid)" role="button">
					<div class="product-inner-item">
						<div class="product-item-type">
							<template v-if="item.productSubscribe === 1"><span class="product-item-subscribe-text">구독</span></template>
							<template v-if="item.productRental === 1"><span class="product-item-rental-text">렌탈형 구독</span></template>
							<template v-if="item.productSale === 1"><span class="product-item-sale-text">판매</span></template>
						</div>
						<picture class="product-image">
							<template v-if="item.convertProductImages && item.convertProductImages.length > 0 ">
								<img class="absolute-center" 
									:src="`${imgURL}${item.convertProductImages[0].upfilesThumnailPath}/${item.convertProductImages[0].upfilesFileName}`" 
									:alt="item.convertProductName"
								/>
							</template>
							<template v-else>
								<img class="absolute-center" 
									src="@/assets/images/common/logo_kor.png"
									:alt="item.convertProductName"
								/>
							</template>
						</picture>
						<div class="product-content">
							<h5 class="product-name">{{ item.convertProductName }}</h5>
							<p class="product-notes">{{ item.convertProductDescription }}</p>
							<div class="product-purpose">
								<template v-if="item.productPurposeGaming === 1"><span class="product-purpose-gaming">전문가용</span></template>
								<template v-if="item.productPurposeProfessional === 1"><span class="product-purpose-professional">게이밍용</span></template>
								<template v-if="item.productPurposeStudy === 1"><span class="product-purpose-study">업무용</span></template>
								<template v-if="item.productPurposeWork === 1"><span class="product-purpose-work">학습용</span></template>
							</div>
							<template v-if="item.productSubscribe === 1">
								<div class="product-price-box">
									<span class="product-price-subscribe-text" v-if="false">구독</span>
									<span class="product-price-before">월 {{ item.convertProductSubscribeRegularPrice }}원</span>
									<span class="product-price-after">월 {{ item.convertProductSubscribePrice }}원</span>
								</div>
							</template>
							<template v-else-if="item.productRental === 1">
								<div class="product-price-box">
									<span class="product-price-rental-text" v-if="false">렌탈형 구독</span>
									<span class="product-price-before">월 {{ item.convertProductRentalRegularPrice }}원</span>
									<span class="product-price-after">월 {{ item.convertProductRentalPrice }}원</span>
								</div>
							</template>
							<template v-else-if="item.productSale === 1">
								<div class="product-price-box">
									<span class="product-price-sale-text" v-if="false">판매</span>
									<span class="product-price-before">월 {{ item.convertProductSaleRegularPrice }}원</span>
									<span class="product-price-after">월 {{ item.convertProductSalePrice }}원</span>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { numberFormat, truncateText  } from '@/helper/utils/etcUtils.js'

export default defineComponent({
	name : 'ComponentProductDetailRecommend',
    setup() {
		const { $axiosConnect, imgURL, router } = commonMixin()

		const productRecommendList         = ref([])

		const getProductRecommendList = async () => {
            try {
                let params = {
                    'page'    : 1,
                    'pageSize': 9,
                    'searchCheck[0][PRODUCT_IS_RECOMMEND]' : 1
                };
                const response = await $axiosConnect('GUEST', `/web/product`, 'GET', params);

                productRecommendList.value = []
                if(response.body){
                    productRecommendList.value = JSON.parse(JSON.stringify(response.body.list))
                }

                productRecommendList.value = productRecommendList.value.map(list => {
                    return {
                        ...list,
                        converUid                           : list.uid.toString().padStart(6, '0'),
						convertProductName                  : truncateText(list.productName, 75),
						convertProductDescription           : truncateText(list.productDescription, 75),
						converRegDate                       : convertDateToStr(list.regDate),
						converUpDate                        : convertDateToStr(list.upDate),
						convertProductSubscribeRegularPrice : numberFormat(list.productSubscribeRegularPrice),
						convertProductSubscribePrice        : numberFormat(list.productSubscribePrice),
						convertProductRentalRegularPrice    : numberFormat(list.productRentalRegularPrice),
						convertProductRentalPrice           : numberFormat(list.productRentalPrice),
						convertProductRentalMonths          : numberFormat(list.productRentalMonths),
						convertProductRentalMonthPrice      : numberFormat(list.productRentalPrice/list.productRentalMonths),
						convertProductSaleRegularPrice      : numberFormat(list.productSaleRegularPrice),
						convertProductSalePrice             : numberFormat(list.productSalePrice),
						convertProductViewCount             : numberFormat(list.productViewCount),
						convertProductImages                : list.upfiles.filter(file => file.upfilesColumn === 'productImages'),
						convertProductDescriptionImages     : list.upfiles.filter(file => file.upfilesColumn === 'productDescriptionImages')
                    }
                })

            } catch (error) {
                console.error("error:", error)
            }
        }

		const onClickNavigateToProductDetail = (uid) => {
            router.push({ name: 'webProductDetailPage', params: { uid } });
        }

		const initialize = async () => {
            productRecommendList.value    = []
        }

		onMounted(async () => {
            await initialize()
			await getProductRecommendList()
        })

        return {
			imgURL,
			productRecommendList,

			onClickNavigateToProductDetail
        }
    }
})
</script>