<template>
    <main>
        <div class="container">
			<section class="content-header">
				<div class="container-fluid">
					<div class="row mb-2">
						<div class="col-sm-12">
							<div class="page-tab-title">
								<ul>
									<li class="active" @click="onClickNavigateToPage('webAgreeServicePage')"><span>이용약관</span></li>
                                    <li @click="onClickNavigateToPage('webAgreePolicyPage')"><span>개인정보 처리방침</span></li>
                                    <li @click="onClickNavigateToPage('webAgreeSharingPage')"><span>개인정보 제공동의</span></li>
                                    <li @click="onClickNavigateToPage('webAgreeReceivingPage')"><span>정보수신 동의</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
            <div class="content">
                <div class="container-fluid">
					<div class="row">
						<div class="col-md-12">

                            <div class="card" style="min-height: 500px">
                                <div class="card-body">
                                    <div v-html="detail.convertInitializeAgreeService"></div>
                                </div>
                            </div>

                        </div>
					</div>
                </div>
            </div>
        </div>
    </main>

</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { unescapeHtmlEntities  } from '@/helper/utils/etcUtils.js'

export default defineComponent({
    name       : 'webAgreeServicePage',
    setup() {
        const { router, $axiosConnect } = commonMixin()
        
        const detail = ref({})

        const onClickNavigateToPage = (pageName) => {
            router.push({ name: pageName });
        }

        const getDeatil = async () => {
            try {
                const response = await $axiosConnect('GUEST', `/web/initialize/1`, 'GET')
                if (response.success && response.body && response.body.list.length > 0) {

                    const list = JSON.parse(JSON.stringify(response.body.list[0]))
                    detail.value = {
                        ...list,
                        convertInitializeAgreeService : unescapeHtmlEntities(list.initializeAgreeService),
						convertInitializeAgreePolicy : unescapeHtmlEntities(list.initializeAgreePolicy),
						convertInitializeAgreeSharing : unescapeHtmlEntities(list.initializeAgreeSharing),
						convertInitializeAgreeReceiving : unescapeHtmlEntities(list.initializeAgreeReceiving),
                    }
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const initialize = async () => {
            await getDeatil()
        }

        onMounted(async () => {
            await initialize()
        })

		return {
            detail,

            onClickNavigateToPage
        }
    }
});
</script>