<template>
    <main>
        <div class="container">
			<section class="content-header">
				<div class="container-fluid">
					<div class="row mb-2">
						<div class="col-sm-12">
							<div class="page-tab-title">
								<ul>
									<li class="active"><span>상품 검색</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
            <div class="content">
                <div class="container-fluid">
					<div class="row">
						<div class="col-md-3">
							<div class="sticky-top mb-3 pt-3">
								<div class="card">
									<div class="card-header">
										<h4 class="card-title">검색</h4>
									</div>
									<div class="card-body">
										<div class="search-row">
											<div class="search-name">분류</div>
											<div class="row">
												<div class="col-xs-12 col-md-6">
													<label class="input-label" for="searchCheckProductSubscribeRentalSale">
														<input type="checkbox"
															id="searchCheckProductSubscribeRentalSale"
															value="1"
															v-model="searchCheckProductSubscribeRentalSale"
															@change="onChangeSearchParamSubscribeRentalSale"
														/>
														<span class="checkbox-icon"></span>
														<span class="label-text">전체</span>
													</label>
												</div>
												<div class="col-xs-12 col-md-6">
													<label class="input-label" for="searchCheckProductSubscribe">
														<input type="checkbox"
															id="searchCheckProductSubscribe"
															name="searchCheck[0][PRODUCT_SUBSCRIBE]"
															value="1"
															v-model="searchCheckProductSubscribe"
															@change="onChangeSearchParamSubscribe"
														/>
														<span class="checkbox-icon"></span>
														<span class="label-text">구독</span>
													</label>
												</div>
												<div class="col-xs-12 col-md-6">
													<label class="input-label" for="searchCheckProductRental">
														<input type="checkbox"
															id="searchCheckProductRental"
															name="searchCheck[0][PRODUCT_RENTAL]"
															value="1"
															v-model="searchCheckProductRental"
															@change="onChangeSearchParamRental"
														/>
														<span class="checkbox-icon"></span>
														<span class="label-text">렌탈형 구독</span>
													</label>
												</div>
												<div class="col-xs-12 col-md-6">
													<label class="input-label" for="searchCheckProductSale">
														<input type="checkbox"
															id="searchCheckProductSale"
															name="searchCheck[0][PRODUCT_Sale]"
															value="1"
															v-model="searchCheckProductSale"
															@change="onChangeSearchParamSale"
														/>
														<span class="checkbox-icon"></span>
														<span class="label-text">판매</span>
													</label>
												</div>
											</div>
										</div>

										<div class="search-row">
											<div class="search-name">사용용도</div>
											<div class="row">
												<div class="col-xs-12 col-md-6">
													<label class="input-label" for="searchCheckProductPurpose">
														<input type="checkbox"
															id="searchCheckProductPurpose"
															value="1"
															v-model="searchCheckProductPurpose"
															@change="onChangeSearchParamPurpose"
														/>
														<span class="checkbox-icon"></span>
														<span class="label-text">전체</span>
													</label>
												</div>
												<div class="col-xs-12 col-md-6">
													<label class="input-label" for="searchCheckProductPurposeGaming">
														<input type="checkbox"
															id="searchCheckProductPurposeGaming"
															name="searchCheck[1][PRODUCT_PURPOSE_GAMING]"
															value="1"
															v-model="searchCheckProductPurposeGaming"
															@change="onChangeSearchParamPurposeGaming"
														/>
														<span class="checkbox-icon"></span>
														<span class="label-text">게이밍용</span>
													</label>
												</div>
												<div class="col-xs-12 col-md-6">
													<label class="input-label" for="searchCheckProductPurposeProfessional">
														<input type="checkbox"
															id="searchCheckProductPurposeProfessional"
															name="searchCheck[1][PRODUCT_PURPOSE_PROFESSIONAL]"
															value="1"
															v-model="searchCheckProductPurposeProfessional"
															@change="onChangeSearchParamPurposeProfessional"
														/>
														<span class="checkbox-icon"></span>
														<span class="label-text">전문가용</span>
													</label>
												</div>
												<div class="col-xs-12 col-md-6">
													<label class="input-label" for="searchCheckProductPurposeWork">
														<input type="checkbox"
															id="searchCheckProductPurposeWork"
															name="searchCheck[1][PRODUCT_PURPOSE_WORK]"
															value="1"
															v-model="searchCheckProductPurposeWork"
															@change="onChangeSearchParamPurposeWork"
														/>
														<span class="checkbox-icon"></span>
														<span class="label-text">업무용</span>
													</label>
												</div>
												<div class="col-xs-12 col-md-6">
													<label class="input-label" for="searchCheckProductPurposeStudy">
														<input type="checkbox"
															id="searchCheckProductPurposeStudy"
															name="searchCheck[1][PRODUCT_PURPOSE_STUDY]"
															value="1"
															v-model="searchCheckProductPurposeStudy"
															@change="onChangeSearchParamPurposeStudy"
														/>
														<span class="checkbox-icon"></span>
														<span class="label-text">학습용</span>
													</label>
												</div>
											</div>
										</div>

										<div class="search-row">
											<div class="search-name">구분</div>
											<div class="row">
												<div class="col-xs-12 col-md-6">
													<label class="input-label" for="searchCheckProductIs">
														<input type="checkbox"
															id="searchCheckProductIs"
															value="1"
															v-model="searchCheckProductIs"
															@change="onChangeSearchParamIs"
														/>
														<span class="checkbox-icon"></span>
														<span class="label-text">전체</span>
													</label>
												</div>
												<div class="col-xs-12 col-md-6">
													<label class="input-label" for="searchCheckProductIsRecommend">
														<input type="checkbox"
															id="searchCheckProductIsRecommend"
															name="searchCheck[2][PRODUCT_IS_RECOMMEND]"
															value="1"
															v-model="searchCheckProductIsRecommend"
															@change="onChangeSearchParamIsRecommend"
														/>
														<span class="checkbox-icon"></span>
														<span class="label-text">추천상품</span>
													</label>
												</div>
												<div class="col-xs-12 col-md-6">
													<label class="input-label" for="searchCheckProductIsSpecialPrice">
														<input type="checkbox"
															id="searchCheckProductIsSpecialPrice"
															name="searchCheck[2][PRODUCT_IS_SPECICAL]"
															value="1"
															v-model="searchCheckProductIsSpecialPrice"
															@change="onChangeSearchParamIsSpecialPrice"
														/>
														<span class="checkbox-icon"></span>
														<span class="label-text">특가상품</span>
													</label>
												</div>
												<div class="col-xs-12 col-md-6">
													<label class="input-label" for="searchCheckProductIsPromotion">
														<input type="checkbox"
															id="searchCheckProductIsPromotion"
															name="searchCheck[2][PRODUCT_IS_PROMOTION]"
															value="1"
															v-model="searchCheckProductIsPromotion"
															@change="onChangeSearchParamIsPromotion"
														/>
														<span class="checkbox-icon"></span>
														<span class="label-text">기획상품</span>
													</label>
												</div>
											</div>
										</div>

										<div class="search-row">
											<div class="search-name">검색어</div>
											<div class="row">
												<div class="col-sm-12">
													<component-form-text
														ref="searchTextRef"
														text-name="searchText"
														:text-default="searchText"
														text-placeholder="검색어를 선택해주세요."
														@on-change-text="onHandleSearchParam"
													></component-form-text>
												</div>
											</div>
										</div>
									</div>
									<div class="card-footer">
										<button type="button" class="btn btn-primary" @click="onClickSearchList()">검색</button>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-9">
							<div class="row">
								<template v-for="(item, index) in list" :key="index">
									<div class="col-12 col-sm-6 col-lg-4">
										<div class="product-item" data-aos-delay="000" @click="onClickNavigateToProductDetail(item.uid)" role="button">
											<div class="product-inner-item">
												<div class="product-item-type">
													<template v-if="item.productSubscribe === 1"><span class="product-item-subscribe-text">구독</span></template>
													<template v-if="item.productRental === 1"><span class="product-item-rental-text">렌탈형 구독</span></template>
													<template v-if="item.productSale === 1"><span class="product-item-sale-text">판매</span></template>
												</div>
												<picture class="product-image">
													<template v-if="item.convertProductImages && item.convertProductImages.length > 0 ">
														<img class="absolute-center" 
															:src="`${imgURL}${item.convertProductImages[0].upfilesThumnailPath}/${item.convertProductImages[0].upfilesFileName}`" 
															:alt="item.convertProductName"
														/>
													</template>
													<template v-else>
														<img class="absolute-center" 
															src="@/assets/images/common/logo_kor.png"
															:alt="item.convertProductName"
														/>
													</template>
												</picture>
												<div class="product-content">
													<h5 class="product-name">{{ item.convertProductName }}</h5>
													<p class="product-notes">{{ item.convertProductDescription }}</p>
													<div class="product-purpose">
														<template v-if="item.productPurposeGaming === 1"><span class="product-purpose-gaming">게이밍용</span></template>
														<template v-if="item.productPurposeProfessional === 1"><span class="product-purpose-professional">전문가용</span></template>
														<template v-if="item.productPurposeStudy === 1"><span class="product-purpose-study">학습용</span></template>
														<template v-if="item.productPurposeWork === 1"><span class="product-purpose-work">업무용</span></template>
													</div>
													<template v-if="item.productSubscribe === 1">
														<div class="product-price-box">
															<span class="product-price-subscribe-text">구독</span>
															<span class="product-price-before">월 {{ item.convertProductSubscribeRegularPrice }}원</span>
															<span class="product-price-after">월 {{ item.convertProductSubscribePrice }}원</span>
														</div>
													</template>
													<template v-else-if="item.productRental === 1">
														<div class="product-price-box">
															<span class="product-price-rental-text">렌탈형 구독</span>
															<span class="product-price-before">월 {{ item.convertProductRentalRegularPrice }}원</span>
															<span class="product-price-after">월 {{ item.convertProductRentalPrice }}원</span>
														</div>
													</template>
													<template v-else-if="item.productSale === 1">
														<div class="product-price-box">
															<span class="product-price-sale-text">판매</span>
															<span class="product-price-before">월 {{ item.convertProductSaleRegularPrice }}원</span>
															<span class="product-price-after">월 {{ item.convertProductSalePrice }}원</span>
														</div>
													</template>
												</div>
											</div>
										</div>
									</div>
								</template>
							</div>

							<div class="row justify-content-center mt-8">
                                <div class="col-12 col-sm-12 col-lg-12">
                                    <div class="product-list-paging">
                                        <template v-if="listSearchCount > 0">
                                            <small>전체 {{ listTotalCount }}개의 상품 중 {{ listSearchCount }}개의 상품을 보고 있습니다.</small>
                                            <div class="progress">
                                                <div class="progress-bar bg-dark" role="progressbar" 
                                                :style="{ width: (listSearchCount / listTotalCount * 100) + '%' }"
                                                ></div>
                                            </div>
                                        </template>
                                        <template v-if="listSearchCount < listTotalCount">
                                            <button class="btn col-sm-2 btn-black-1 mrg-horizon-auto" @click="onClickMoreProductList()" v-if="listSearchCount < listTotalCount">상품 더보기</button>
                                        </template>
										<template v-else>
											<div class="product-request" v-if="Math.ceil(listTotalCount/listPageSize) === listCurrentPage">
												<p class="font-size-xl">찾고자 하는 상품이 못찾으셨나요?</p>
                                                <hr/>
                                                <div class="product-request-group">
                                                    <div>
                                                        <span>A~Z까지 선택해서 상품을 찾아보세요.</span>
                                                        <span role="button" @click="router.push({ name: 'webProductCustomPage' })">상품 찾기</span>
                                                    </div>
                                                    <div>
                                                        <span>1:1 문의로 원하는 상품을 문의해보세요.</span>
                                                        <span role="button" @click="router.push({ name: 'webContactPage' })">1:1 문의</span>
                                                    </div>
                                                    <div>
                                                        <span>담당자와 직접 전화로 문의해서 원하는 상품을 찾을 수 있어요.</span>
                                                        <span role="button" @click="router.push({ name: 'webCallRequestPage' })">전화상담요청</span>
                                                    </div>
                                                </div>
											</div>
										</template>
                                    </div>
                                </div>
                            </div>

						</div>
					</div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { numberFormat, truncateText  } from '@/helper/utils/etcUtils.js'

import ComponentFormText from "@/components/admin/ComponentFormText"

export default defineComponent({
    name       : 'webProductSearchPage',
	components : {
        'component-form-text' : ComponentFormText
    },
    setup() {
		const { $axiosConnect, router, imgURL } = commonMixin()

		const list              = ref([])
		const listCurrentPage   = ref(1);
        const listPageSize      = ref(12);
        const listTotalCount    = ref(0)
        const listSearchCount   = ref(0)
		const queryStringParams = ref({})
        const applySearchParams = ref({})
		
		const searchCheckProductSubscribeRentalSale = ref(true)
        const searchCheckProductSubscribe           = ref(true)
        const searchCheckProductRental              = ref(true)
		const searchCheckProductSale                = ref(true)

		const searchCheckProductPurpose             = ref(true)
		const searchCheckProductPurposeGaming       = ref(true)
		const searchCheckProductPurposeProfessional = ref(true)
		const searchCheckProductPurposeWork         = ref(true)
		const searchCheckProductPurposeStudy        = ref(true)

		const searchCheckProductIs                  = ref(true)
		const searchCheckProductIsRecommend         = ref(true)
		const searchCheckProductIsSpecialPrice      = ref(true)
		const searchCheckProductIsPromotion         = ref(true)

		const parentCompositionList                 = ref([])
        const childCompositionList                  = ref([])
		const searchCommaProductComposition         = ref([])

		const searchText                            = ref('')

		const getList = async () => {
            try {
                let defaultParams = {
                    'page'     : listCurrentPage.value,
                    'pageSize' : listPageSize.value
                };
                let params = Object.assign({}, defaultParams, applySearchParams.value);
                const response = await $axiosConnect('GUEST', `/web/product`, 'GET', params);

                if(response.body){
                    const products = JSON.parse(JSON.stringify(response.body.list))
                    listTotalCount.value  = JSON.parse(JSON.stringify(response.body.total))
                    listSearchCount.value += products.length

                    const processedProducts  = products.map(list => {
                        return {
                            ...list,
                            converUid                           : list.uid.toString().padStart(6, '0'),
							convertProductName                  : truncateText(list.productName, 75),
							convertProductDescription           : truncateText(list.productDescription, 75),
							converRegDate                       : convertDateToStr(list.regDate),
							converUpDate                        : convertDateToStr(list.upDate),
							convertProductSubscribeRegularPrice : numberFormat(list.productSubscribeRegularPrice),
							convertProductSubscribePrice        : numberFormat(list.productSubscribePrice),
							convertProductRentalRegularPrice    : numberFormat(list.productRentalRegularPrice),
							convertProductRentalPrice           : numberFormat(list.productRentalPrice),
							convertProductRentalMonths          : numberFormat(list.productRentalMonths),
							convertProductRentalMonthPrice      : numberFormat(list.productRentalPrice/list.productRentalMonths),
							convertProductSaleRegularPrice      : numberFormat(list.productSaleRegularPrice),
							convertProductSalePrice             : numberFormat(list.productSalePrice),
							convertProductViewCount             : numberFormat(list.productViewCount),
							convertProductImages                : list.upfiles.filter(file => file.upfilesColumn === 'productImages'),
							convertProductDescriptionImages     : list.upfiles.filter(file => file.upfilesColumn === 'productDescriptionImages')
                        }
                    })
                    list.value = [...list.value, ...processedProducts];
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

        const onClickMoreProductList = () => {
			listCurrentPage.value++
            getList()
        }

		const onClickSearchList = () => {
            applySearchParams.value = { ...queryStringParams.value }
			
			list.value            = []
			listTotalCount.value  = listSearchCount.value = 0

            getList()
        }

		const onHandleSearchParam = (param) => {
            queryStringParams.value[param.name] = 
                param.selecteds.length > 0 ? 
                (param.selecteds.length > 1 ? param.selecteds : param.selecteds[0]) : []
        }

		const onChangeSearchParamSubscribeRentalSale = (event) => {
            const { checked } = event.target;
            if (checked) {
                queryStringParams.value["searchCheck[0][PRODUCT_SUBSCRIBE]"] = 1;
                queryStringParams.value["searchCheck[0][PRODUCT_RENTAL]"] = 1;
				queryStringParams.value["searchCheck[0][PRODUCT_SALE]"] = 1;
            } else {
				if (queryStringParams.value["searchCheck[0][PRODUCT_SUBSCRIBE]"] === 1) {
                    delete queryStringParams.value["searchCheck[0][PRODUCT_SUBSCRIBE]"];
                }
                if (queryStringParams.value["searchCheck[0][PRODUCT_RENTAL]"] === 1) {
                    delete queryStringParams.value["searchCheck[0][PRODUCT_RENTAL]"];
                }
                if (queryStringParams.value["searchCheck[0][PRODUCT_SALE]"] === 1) {
                    delete queryStringParams.value["searchCheck[0][PRODUCT_SALE]"];
                }
            }
			searchCheckProductSubscribe.value = searchCheckProductRental.value = searchCheckProductSale.value = checked
        }

        const onChangeSearchParamSubscribe = (event) => {
            const { checked } = event.target;
            if (checked) {
                queryStringParams.value["searchCheck[0][PRODUCT_SUBSCRIBE]"] = 1;
                searchCheckProductSubscribe.value = true
            } else {
                if (queryStringParams.value["searchCheck[0][PRODUCT_SUBSCRIBE]"] === 1) {
                    delete queryStringParams.value["searchCheck[0][PRODUCT_SUBSCRIBE]"];
                    searchCheckProductSubscribe.value = false
                }
            }
            searchCheckProductSubscribeRentalSale.value = searchCheckProductSubscribe.value && searchCheckProductRental.value && searchCheckProductSale.value
        }

        const onChangeSearchParamRental = (event) => {
            const { checked } = event.target;
            if (checked) {
                queryStringParams.value["searchCheck[0][PRODUCT_RENTAL]"] = 1;
                searchCheckProductRental.value = true
            } else {
                if (queryStringParams.value["searchCheck[0][PRODUCT_RENTAL]"] === 1) {
                    delete queryStringParams.value["searchCheck[0][PRODUCT_RENTAL]"];
                    searchCheckProductRental.value = false
                }
            }
            searchCheckProductSubscribeRentalSale.value = searchCheckProductSubscribe.value && searchCheckProductRental.value && searchCheckProductSale.value
        }

		const onChangeSearchParamSale = (event) => {
            const { checked } = event.target;
            if (checked) {
                queryStringParams.value["searchCheck[0][PRODUCT_SALE]"] = 1;
                searchCheckProductRental.value = true
            } else {
                if (queryStringParams.value["searchCheck[0][PRODUCT_SALE]"] === 1) {
                    delete queryStringParams.value["searchCheck[0][PRODUCT_SALE]"];
                    searchCheckProductRental.value = false
                }
            }
            searchCheckProductSubscribeRentalSale.value = searchCheckProductSubscribe.value && searchCheckProductRental.value && searchCheckProductSale.value
        }

		const onChangeSearchParamPurpose = (event) => {
            const { checked } = event.target;
            if (checked) {
                queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_GAMING]"]      = 1;
                queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_PROFESSIONAL]"] = 1;
				queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_WORK]"]        = 1;
				queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_STUDY]"]       = 1;
            } else {
				if (queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_GAMING]"] === 1) {
                    delete queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_GAMING]"];
                }
                if (queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_PROFESSIONAL]"] === 1) {
                    delete queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_PROFESSIONAL]"];
                }
                if (queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_WORK]"] === 1) {
                    delete queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_WORK]"];
                }
				if (queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_STUDY]"] === 1) {
                    delete queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_STUDY]"];
                }
            }

			searchCheckProductPurposeGaming.value = searchCheckProductPurposeProfessional.value = searchCheckProductPurposeWork.value = searchCheckProductPurposeStudy.value = checked
        }

		const onChangeSearchParamPurposeGaming = (event) => {
            const { checked } = event.target;
            if (checked) {
                queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_GAMING]"] = 1;
                searchCheckProductPurposeGaming.value = true
            } else {
                if (queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_GAMING]"] === 1) {
                    delete queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_GAMING]"];
                    searchCheckProductPurposeGaming.value = false
                }
            }
            searchCheckProductPurpose.value = searchCheckProductPurposeGaming.value && searchCheckProductPurposeProfessional.value && searchCheckProductPurposeWork.value && searchCheckProductPurposeStudy.value
        }

		const onChangeSearchParamPurposeProfessional = (event) => {
            const { checked } = event.target;
            if (checked) {
                queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_PROFESSIONAL]"] = 1;
                searchCheckProductPurposeProfessional.value = true
            } else {
                if (queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_PROFESSIONAL]"] === 1) {
                    delete queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_PROFESSIONAL]"];
                    searchCheckProductPurposeProfessional.value = false
                }
            }
            searchCheckProductPurpose.value = searchCheckProductPurposeGaming.value && searchCheckProductPurposeProfessional.value && searchCheckProductPurposeWork.value && searchCheckProductPurposeStudy.value
        }

		const onChangeSearchParamPurposeWork = (event) => {
            const { checked } = event.target;
            if (checked) {
                queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_WORK]"] = 1;
                searchCheckProductPurposeWork.value = true
            } else {
                if (queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_WORK]"] === 1) {
                    delete queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_WORK]"];
                    searchCheckProductPurposeWork.value = false
                }
            }
            searchCheckProductPurpose.value = searchCheckProductPurposeGaming.value && searchCheckProductPurposeProfessional.value && searchCheckProductPurposeWork.value && searchCheckProductPurposeStudy.value
        }

		const onChangeSearchParamPurposeStudy = (event) => {
            const { checked } = event.target;
            if (checked) {
                queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_STUDY]"] = 1;
                searchCheckProductPurposeStudy.value = true
            } else {
                if (queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_STUDY]"] === 1) {
                    delete queryStringParams.value["searchCheck[1][PRODUCT_PURPOSE_STUDY]"];
                    searchCheckProductPurposeStudy.value = false
                }
            }
            searchCheckProductPurpose.value = searchCheckProductPurposeGaming.value && searchCheckProductPurposeProfessional.value && searchCheckProductPurposeWork.value && searchCheckProductPurposeStudy.value
        }

		const onChangeSearchParamIs = (event) => {
			const { checked } = event.target;
			if (checked) {
				queryStringParams.value["searchCheck[2][PRODUCT_IS_RECOMMEND]"]      = 1;
				queryStringParams.value["searchCheck[2][PRODUCT_IS_SPECIAL_PRICE]"] = 1;
				queryStringParams.value["searchCheck[2][PRODUCT_IS_PROMOTION]"]        = 1;
			} else {
				if (queryStringParams.value["searchCheck[2][PRODUCT_IS_RECOMMEND]"] === 1) {
                    delete queryStringParams.value["searchCheck[2][PRODUCT_IS_RECOMMEND]"];
                }
                if (queryStringParams.value["searchCheck[2][PRODUCT_IS_SPECIAL_PRICE]"] === 1) {
                    delete queryStringParams.value["searchCheck[2][PRODUCT_IS_SPECIAL_PRICE]"];
                }
                if (queryStringParams.value["searchCheck[2][PRODUCT_IS_PROMOTION]"] === 1) {
                    delete queryStringParams.value["searchCheck[2][PRODUCT_IS_PROMOTION]"];
                }
			}

			searchCheckProductIsRecommend.value = searchCheckProductIsSpecialPrice.value = searchCheckProductIsPromotion.value = checked
		}

		const onChangeSearchParamIsRecommend = (event) => {
			const { checked } = event.target;
			if (checked) {
				queryStringParams.value["searchCheck[2][PRODUCT_IS_RECOMMEND]"] = 1;
				searchCheckProductIsRecommend.value = true
			} else {
				if (queryStringParams.value["searchCheck[2][PRODUCT_IS_RECOMMEND]"] === 1) {
					delete queryStringParams.value["searchCheck[2][PRODUCT_IS_RECOMMEND]"];
					searchCheckProductIsRecommend.value = false
				}
			}
			searchCheckProductIs.value = searchCheckProductIsRecommend.value && searchCheckProductIsSpecialPrice.value && searchCheckProductIsPromotion.value
		}

		const onChangeSearchParamIsSpecialPrice = (event) => {
			const { checked } = event.target;
			if (checked) {
				queryStringParams.value["searchCheck[2][PRODUCT_IS_SPECIAL_PRICE]"] = 1;
				searchCheckProductIsSpecialPrice.value = true
			} else {
				if (queryStringParams.value["searchCheck[2][PRODUCT_IS_SPECIAL_PRICE]"] === 1) {
					delete queryStringParams.value["searchCheck[2][PRODUCT_IS_SPECIAL_PRICE]"];
					searchCheckProductIsSpecialPrice.value = false
				}
			}
			searchCheckProductIs.value = searchCheckProductIsRecommend.value && searchCheckProductIsSpecialPrice.value && searchCheckProductIsPromotion.value
		}

		const onChangeSearchParamIsPromotion = (event) => {
			const { checked } = event.target;
			if (checked) {
				queryStringParams.value["searchCheck[2][PRODUCT_IS_PROMOTION]"] = 1;
				searchCheckProductIsPromotion.value = true
			} else {
				if (queryStringParams.value["searchCheck[2][PRODUCT_IS_PROMOTION]"] === 1) {
					delete queryStringParams.value["searchCheck[2][PRODUCT_IS_PROMOTION]"];
					searchCheckProductIsPromotion.value = false
				}
			}
			searchCheckProductIs.value = searchCheckProductIsRecommend.value && searchCheckProductIsSpecialPrice.value && searchCheckProductIsPromotion.value
		}

		const getCompositionList = async () => {
            try {
                let defaultParams = {
                    'page'      : 1,
                    'pageSize'  : 1000,
                    'compositionState' : 1
                }
                const response = await $axiosConnect('GUEST', `/web/compositionProduct`, 'GET', defaultParams)

                let list = []
                if(response.body){
                    list = JSON.parse(JSON.stringify(response.body.list))
                    if(list.length > 0){
                        list = list.map(({ uid, compositionName, groups, depth }) => {
                            return {
                                compositionUid    : uid,
                                compositionName   : compositionName,
                                compositionGroups : groups,
                                compositionDepth  : depth
                            }
                        })
                    }
                    parentCompositionList.value = list.filter(composition => composition.compositionDepth === 0)
                    childCompositionList.value  = list.filter(composition => composition.compositionDepth === 1)
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

		const onChangeSearchParamComposition = () => {
			queryStringParams.value["searchComma[PRODUCT_COMPOSITION]"] = searchCommaProductComposition.value.join(',')
		}

		const getCompositionFilteredChildList = (parentComposition) => {
            if (parentComposition.compositionGroups) {
                return childCompositionList.value.filter(child => child.compositionGroups === parentComposition.compositionGroups)
            }
            return []
			
        }

		const isCompositionAllSelected = (parentItem) => {
			const childList = getCompositionFilteredChildList(parentItem);
			return childList.every(child => searchCommaProductComposition.value.includes(child.compositionUid));
		};

		const onToggleCompositionSelectAll = (parentItem) => {
			const childList = getCompositionFilteredChildList(parentItem);
			const allSelected = isCompositionAllSelected(parentItem);

			if (allSelected) {
				searchCommaProductComposition.value = searchCommaProductComposition.value.filter(
					uid => !childList.some(child => child.compositionUid === uid)
				);
			} else {
				childList.forEach(child => {
					if (!searchCommaProductComposition.value.includes(child.compositionUid)) {
						searchCommaProductComposition.value.push(child.compositionUid);
					}
				});
			}
			onChangeSearchParamComposition();
		}

		const onClickNavigateToProductDetail = (uid) => {
            router.push({ name: 'webProductDetailPage', params: { uid } });
        }

		const initialize = async () => {
			
            list.value              = []
            listTotalCount.value    = 0
            listSearchCount.value   = 0
			applySearchParams.value = {}
			queryStringParams.value = {}

            const defaultSearchCheck = {
				"searchCheck[0][PRODUCT_SUBSCRIBE]"            : 1,
				"searchCheck[0][PRODUCT_RENTAL]"               : 1,
				"searchCheck[0][PRODUCT_SALE]"                 : 1,
				"searchCheck[1][PRODUCT_PURPOSE_GAMING]"       : 1,
				"searchCheck[1][PRODUCT_PURPOSE_PROFESSIONAL]" : 1,
				"searchCheck[1][PRODUCT_PURPOSE_WORK]"         : 1,
				"searchCheck[1][PRODUCT_PURPOSE_STUDY]"        : 1,
				"searchCheck[2][PRODUCT_IS_RECOMMEND]"         : 1,
				"searchCheck[2][PRODUCT_IS_SPECIAL_PRICE]"     : 1,
				"searchCheck[2][PRODUCT_IS_PROMOTION]"         : 1,
			};

			queryStringParams.value = { ...defaultSearchCheck };
			queryStringParams.value["searchTextWhere"] = 'PRODUCT_NAME'

			searchCheckProductIs.value             = true
			searchCheckProductIsRecommend.value    = true
			searchCheckProductIsSpecialPrice.value = true
			searchCheckProductIsPromotion.value    = true

			const routeQuery  = router.currentRoute.value.query || {}
			const search      = routeQuery.search || ''
			const searchType  = routeQuery.searchType || ''
			const composition = routeQuery.composition || ''

			searchText.value = search

			if(searchText.value){
				queryStringParams.value["searchText"] = searchText.value
			}

			if(searchType){
				searchCheckProductIs.value = false

				if(searchType === 'recommend'){
					queryStringParams.value["searchCheck[2][PRODUCT_IS_RECOMMEND]"] = 1

					delete queryStringParams.value["searchCheck[2][PRODUCT_IS_SPECIAL_PRICE]"]
					delete queryStringParams.value["searchCheck[2][PRODUCT_IS_PROMOTION]"]
					
					searchCheckProductIsRecommend.value    = true
					searchCheckProductIsSpecialPrice.value = false
					searchCheckProductIsPromotion.value    = false
				}else if(searchType === 'specialPrice'){
					queryStringParams.value["searchCheck[2][PRODUCT_IS_SPECIAL_PRICE]"] = 1

					delete queryStringParams.value["searchCheck[2][PRODUCT_IS_RECOMMEND]"]
					delete queryStringParams.value["searchCheck[2][PRODUCT_IS_PROMOTION]"]

					searchCheckProductIsRecommend.value    = false
					searchCheckProductIsSpecialPrice.value = true
					searchCheckProductIsPromotion.value    = false
				}else if(searchType === 'promotion'){
					queryStringParams.value["searchCheck[2][PRODUCT_IS_PROMOTION]"] = 1

					delete queryStringParams.value["searchCheck[2][PRODUCT_IS_RECOMMEND]"]
					delete queryStringParams.value["searchCheck[2][PRODUCT_IS_SPECIAL_PRICE]"]

					searchCheckProductIsRecommend.value    = false
					searchCheckProductIsSpecialPrice.value = false
					searchCheckProductIsPromotion.value    = true
				}
			}

			if(composition){
				queryStringParams.value["searchComma[PRODUCT_COMPOSITION]"] = composition
				searchCommaProductComposition.value = composition.split(',');
			}else{
				parentCompositionList.value.forEach(parentItem => {
					onToggleCompositionSelectAll(parentItem);
				});
			}

			applySearchParams.value = { ...queryStringParams.value };

			onChangeSearchParamComposition()
			await getCompositionList()
			await getList()

        }

        onMounted(async () => {
            await initialize()
            
        })

		watch(() => router.currentRoute.value.query, async () => {
            await initialize();
        });

		return {
            imgURL,
            router,

            list,
            listCurrentPage,
            listPageSize,
            listTotalCount,
            listSearchCount,

			searchCheckProductSubscribeRentalSale,
			searchCheckProductSale,
			searchCheckProductSubscribe,
			searchCheckProductRental,
			searchCheckProductPurpose,
			searchCheckProductPurposeGaming,
			searchCheckProductPurposeProfessional,
			searchCheckProductPurposeWork,
			searchCheckProductPurposeStudy,
			searchCheckProductIs,
			searchCheckProductIsRecommend,
			searchCheckProductIsSpecialPrice,
			searchCheckProductIsPromotion,
			searchText,
			searchCommaProductComposition,

			parentCompositionList,
			childCompositionList,

			onChangeSearchParamSubscribeRentalSale,
            onChangeSearchParamSubscribe,
            onChangeSearchParamRental,
			onChangeSearchParamSale,

			onChangeSearchParamPurpose,
			onChangeSearchParamPurposeGaming,
			onChangeSearchParamPurposeProfessional,
			onChangeSearchParamPurposeWork,
			onChangeSearchParamPurposeStudy,
			onChangeSearchParamIs,
			onChangeSearchParamIsRecommend,
			onChangeSearchParamIsSpecialPrice,
			onChangeSearchParamIsPromotion,
            onClickMoreProductList,
			onClickSearchList,
			onHandleSearchParam,
			getCompositionFilteredChildList,
			onChangeSearchParamComposition,
			isCompositionAllSelected,
			onToggleCompositionSelectAll,
			onClickNavigateToProductDetail
        }
    }
});
</script>