<template>
    <div class="row">
        <div :class="isViewSearch ? 'col-md-10' : 'col-md-12'">
            <div class="sticky-top mb-3">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">주문 목록</h4>
                        <div class="card-tools">
                            <button type="button" class="btn btn-default btn-sm" @click="onClickRefreshList()"><i class="fas fa-sync-alt"></i></button>&nbsp;
                            <button type="button" class="btn btn-danger btn-sm" @click="onClickModalOrderDelete()">선택 삭제</button>&nbsp;
                            <button type="button" class="btn btn-outline-dark btn-sm" @click="onClickIsViewSearch()">검색{{ isViewSearch?'닫기':'열기' }}</button>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <div class="p-2">
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="d-flex align-items-center h-100">총 {{ listAllCount }}건 중 {{ listTotalCount }}건의 검색되었습니다. 
                                        <span class="ml-2 text-bold">( 현재 {{ listCurrentPage }}페이지의 {{ listSearchCount }}건을 보고 있습니다. )</span>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <select class="form-control float-right w-auto text-sm" v-model="listPageSize" @change="onChangeListPageSize()">
                                        <option v-for="pageSize in onGetSelectListPageSize" :key="pageSize" :value="pageSize">
                                        {{ pageSize===listTotalCount?"전체보기":`${pageSize}줄 보기` }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <!--LIST START-->
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <colgroup>
                                    <col width="5%" />
                                    <col width="6%" />
                                    <col width="5%" />
                                    <col />
                                    <col width="5%" />
                                    <col width="9%" />
                                    <col width="11%" />
                                    <col width="14%" />
                                    <col width="10%" />
                                    <col width="10%" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="input-checkbox">
                                                <label class="input-label" for="isAllSelected">
                                                    <input type="checkbox"
                                                    id="isAllSelected"
                                                    v-model="isAllSelected" 
                                                    @change="onClickAllSelected"
                                                    />
                                                    <span class="checkbox-icon"></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th>번호</th>
                                        <th>판매채널</th>
                                        <th>상품명</th>
                                        <th>가격</th>
                                        <th>배송상태</th>
                                        <th>구독상태</th>
                                        <th>구독시작일</th>
                                        <th>결제</th>
                                        <th>관리</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(item, index) in list" :key="index">
										<tr>
                                            <td>
                                                <div class="input-checkbox">
                                                    <label class="input-label" :for="`checkbox-${index}`">
                                                        <input type="checkbox"
                                                        :id="`checkbox-${index}`"
                                                        :value="item"
                                                        v-model="selectedList"
                                                        @change="onClickPartSelected()"
                                                        />
                                                        <span class="checkbox-icon"></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td role="button" @click="onClickExpandedRow(index)">{{ item.convertUid }}</td>
                                            <td>{{ item.orderDistributor }}</td>
                                            <td>
                                                <div>
                                                    <span class="badge badge-warning ml-1" v-if="item.orderType==='subscribe'">구독</span>
                                                    <span class="badge badge-warning ml-1" v-if="item.orderType==='rental'">렌탈형 구독</span>
                                                    <span class="badge badge-warning ml-1" v-if="item.orderType==='sale'">판매</span>
                                                </div>
                                                <div role="button" @click="onClickModalOrderDetail(item)">{{ item.productName }}</div>
                                            </td>
                                            <td>
                                                <div>{{ item.convertOrderTotalPrice }}원<br>{{ item.convertOrderMonths }}개월</div>
                                            </td>
                                            <td>
                                                <select class="form-control" v-model="item.orderDeliveryState" @change="onClickOrderUpdate('', item)">
                                                    <option value="대기">대기</option>
                                                    <option value="주문확인">주문확인</option>
                                                    <option value="배송준비중">배송준비중</option>
                                                    <option value="배송중">배송중</option>
                                                    <option value="배송완료">배송완료</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select class="form-control" v-model="item.orderState" @change="onClickOrderUpdate('', item)">
                                                    <option value="신청">신청</option>
                                                    <option value="구독중">구독중</option>
                                                    <option value="구독취소신청">구독취소(신청)</option>
                                                    <option value="구독취소">구독취소(완료)</option>
                                                    <option value="구독종료">구독종료</option>
                                                </select>
                                            </td>
                                            <td>
                                                <VueCtkDateTimePicker
                                                v-model="item.converOrderContractDate" 
                                                format="YYYY-MM-DD"
                                                outputFormat="YYYY-MM-DD"
                                                :onlyDate="true"
                                                :auto-close="false"
                                                label="구독시작일"
                                                formatted= "ll"
                                                @update:modelValue="(value) => onClickOrderUpdate(value, item)"
                                                />
                                                
                                            </td>
                                            <td>
                                                <div>{{ item.converLastPayment?.paymentRound }}회 (결제주기 : {{ new Date(item.converLastPayment?.regDate * 1000).getDate() }}일)</div>
                                                <button type="button" class="btn btn-info btn-block btn-xs" @click="onClickModalPaymentDetail(item)">결제현황</button>
                                                <button type="button" class="btn btn-dark btn-block btn-xs" @click="onClickModalPaymentNextMonth(item)"><!-- v-if="isPaymentDueWithinRange(item.converLastPayment?.regDate)"-->
                                                    {{ (item.converLastPayment?.paymentRound) + 1 }}회 결제하기
                                                </button>
                                            </td>
                                            <td>
                                                <button type="button" class="btn btn-primary btn-xs" @click="onClickModalOrderUpdate('update', item)">수정</button>
                                                &nbsp;
                                                <button type="button" class="btn btn-danger btn-xs" @click="onClickModalOrderDelete(item)">삭제</button>
                                            </td>
                                        </tr>
                                        <tr v-show="expandedRow === index">
                                            <td colspan="9">
                                                
                                                <div class="table-inner-box">
                                                    <div class="row">
                                                        <div class="col-sm-1"><span class="inner-label">제품 합계</span></div>
                                                        <div class="col-sm-2"><span>{{ item.convertOrderDefaultTotalPrice }}</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">옵션 합계</span></div>
                                                        <div class="col-sm-2"><span>{{ item.convertOrderOptionTotalPrice }}</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">서비스 합계</span></div>
                                                        <div class="col-sm-2"><span>{{ item.convertOrderServiceTotalPrice }}</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">보증금</span></div>
                                                        <div class="col-sm-2"><span>{{ item.convertOrderDepositPrice }}</span></div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-1"><span class="inner-label">등록정보</span></div>
                                                        <div class="col-sm-2"><span>{{ item.regMemberName }} ({{ item.regMemberId }})</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">등록일</span></div>
                                                        <div class="col-sm-2"><span>{{ item.convertRegDate }}</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">등록IP</span></div>
                                                        <div class="col-sm-2"><span>{{ item.regIp }}</span></div>
                                                        <div class="col-sm-1"></div>
                                                        <div class="col-sm-2"></div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-1"><span class="inner-label">수정정보</span></div>
                                                        <div class="col-sm-2"><span>{{ item.upMemberName }} ({{ item.upMemberId }})</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">수정일</span></div>
                                                        <div class="col-sm-2"><span>{{ item.convertUpDate }}</span></div>
                                                        <div class="col-sm-1"><span class="inner-label">수정IP</span></div>
                                                        <div class="col-sm-2"><span>{{ item.upIp }}</span></div>
                                                        <div class="col-sm-1"></div>
                                                        <div class="col-sm-2"></div>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <!--LIST END-->
                    </div>
                    <div class="card-footer clearfix">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <span class="mr-1 ml-1">전체 {{ listTotalPages }} 페이지 중</span>
                                <select class="form-control paging-select" v-model="listCurrentPage" @change="onChangeListPage()">
                                    <option v-for="pageNumber in onGetSelectListTotalPages" :key="pageNumber" :value="pageNumber">
                                        {{ pageNumber }}
                                    </option>
                                </select>
                                <span>페이지를 보고 있습니다</span>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <ul class="pagination pagination-sm m-0 float-right">
                                    <li class="page-item" v-if="listCurrentPage !== 1">
                                        <span class="page-link" @click="onChangeListPreviousPage">&laquo;</span>
                                    </li>
                                    <li class="page-item" v-for="pageNumber in listTotalPages" :key="pageNumber" :class="{ 'active': pageNumber === listCurrentPage }">
                                        <span class="page-link" @click="onChangeListPage(pageNumber)">{{ pageNumber }}</span>
                                    </li>
                                    <li class="page-item" v-if="listCurrentPage < listTotalPages">
                                        <span class="page-link" @click="onChangeListNextPage">&raquo;</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-2" v-if="isViewSearch">
            <form @submit.prevent="onClickSearchList">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">검색</h3>
                    <div class="card-tools">
                        <button type="button" class="btn btn-success btn-sm" @click="onClickSearchReset()">초기화</button>&nbsp;
                        <button type="button" class="btn btn-primary btn-sm" @click="onClickSearchList()">검색</button>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="search-wrap">
                        <ul class="flex-column">
                            <li>
                                <div class="search-row">
                                    <div class="search-name">상태</div>
                                    <div>
                                        <component-form-checkbox
                                            ref="searchCheckProductStateRef"
                                            checkbox-name="searchCheck[3][PRODUCT_STATE]" 
                                            :checkbox-list="[
                                                { name: '사용', value: 1 },
                                                { name: '정지', value: 0 }
                                            ]" 
                                            :checkbox-default="[1, 0]" 
                                            @on-change-checkbox="onHandleSearchParam"
                                        />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="search-row">
                                    <div class="search-name">검색어</div>
                                    <div class="w-100">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-5">
                                                <component-form-select
                                                    ref="searchTextWhereRef"
                                                    select-name="searchTextWhere" 
                                                    :select-list="[
                                                        { name: '상품명', value: 'PRODUCT_NAME' },
                                                    ]" 
                                                    select-placeholder="- 구분 -" 
                                                    :select-default="[]" 
                                                    @on-change-select="onHandleSearchParam"
                                                />
                                            </div>
                                            <div class="col-sm-12 col-md-7">
                                                <component-form-text
                                                    ref="searchTextRef"
                                                    text-name="searchText"
                                                    text-placeholder="검색어를 선택해주세요."
                                                    @on-change-text="onHandleSearchParam"
                                                ></component-form-text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            </form>
        </div>
    </div>

    <alert ref="alertRef" :title="'알림'" />
    <modal-order-update ref="modalOrderUpdateRef" @onCloseModal="onCloseModalOrderUpdate" />
    <modal-order-detail ref="modalOrderDetailRef" @onCloseModal="onCloseModalOrderDetail" />
    <modal-order-delete ref="modalOrderDeleteRef" @onCloseModal="onCloseModalOrderDelete" />

    <modal-payment-detail ref="modalPaymentDetailRef" @onCloseModal="onCloseModalPaymentDetail" />
    <modal-payment-next-month ref="modalPaymentNextMonthRef" @onCloseModal="onCloseModalPaymentNextMonth" />

</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr, convertStrToUnixTime, getDateDifference } from '@/helper/utils/dateUtils.js'
import { numberFormat, truncateText } from '@/helper/utils/etcUtils.js'

import Alert from '@/modals/common/Alert'

import ModalOrderUpdate from '@/modals/admin/order/ModalOrderUpdate'
import ModalOrderDetail from '@/modals/admin/order/ModalOrderDetail'
import ModalOrderDelete from '@/modals/admin/order/ModalOrderDelete'

import ModalPaymentDetail from '@/modals/admin/order/ModalPaymentDetail'
import ModalPaymentNextMonth from '@/modals/admin/order/ModalPaymentNextMonth'

import ComponentFormCheckbox  from "@/components/admin/ComponentFormCheckbox"
import ComponentFormSelect    from "@/components/admin/ComponentFormSelect"
import ComponentFormText      from "@/components/admin/ComponentFormText"

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

export default defineComponent({
    name       : 'adminOrderListPage',
    components : {
        'alert' : Alert,
        'modal-order-update'       : ModalOrderUpdate,
        'modal-order-detail'       : ModalOrderDetail,
        'modal-order-delete'       : ModalOrderDelete,
        'modal-payment-detail'     : ModalPaymentDetail,
        'modal-payment-next-month' : ModalPaymentNextMonth,
        'component-form-checkbox'  : ComponentFormCheckbox,
        'component-form-select'    : ComponentFormSelect,
        'component-form-text'      : ComponentFormText,
        VueCtkDateTimePicker,
    },
    setup() {
        const { $axiosConnect, imgURL } = commonMixin()

        const alertRef              = ref(null)
        const submitButtonDisabled  = ref(true)
        const categoryList          = ref([])

        const list                  = ref([])
        const listCurrentPage       = ref(1);
        const listPageSize          = ref(10);
        const listTotalPages        = ref(0);
        const listAllCount          = ref(0);
        const listTotalCount        = ref(0);
        const listSearchCount       = ref(0);
        const listSearchTarget      = ref([])
        const queryStringParams     = ref({})
        const applySearchParams     = ref({})

        const isAllSelected                         = ref(false)
        const selectedList                          = ref([])
        const expandedRow                           = ref(null)
        const isViewSearch                          = ref(true)

        const modalOrderUpdateRef                   = ref(null)
        const modalOrderDetailRef                   = ref(null)
        const modalOrderDeleteRef                   = ref(null)
        const modalPaymentDetailRef                 = ref(null)
        const modalPaymentNextMonthRef              = ref(null)

        const searchCheckProductStateRef            = ref(null)
        const searchTextWhereRef                    = ref(null)
        const searchTextRef                         = ref(null)

        const orderContractDate = ref(null)

        const getList = async () => {
            try {
                let defaultParams = {
                    'page'     : listCurrentPage.value,
                    'pageSize' : listPageSize.value
                };
                let params = Object.assign({}, defaultParams, applySearchParams.value);

                const response = await $axiosConnect('MEMBER', `/admin/order`, 'GET', params);

                list.value            = []
                listAllCount.value    =  listTotalCount.value  = listTotalPages.value = listSearchCount.value = 0
                if(response.body){
                    list.value            = JSON.parse(JSON.stringify(response.body.list))
                    listAllCount.value    = JSON.parse(JSON.stringify(response.body.allTotal))
                    listTotalCount.value  = JSON.parse(JSON.stringify(response.body.total))
                    listTotalPages.value  = listTotalCount.value > 0 ? Math.ceil(listTotalCount.value / listPageSize.value) : 1
                    listSearchCount.value = list.value.length
                }

                if(listSearchCount.value > 0){
                    list.value = list.value.map(list => {
                        return {
                            ...list,
                            convertUid                    : list.uid.toString().padStart(6, '0'),
                            convertProductName            : list.productName,
                            convertProductDescription     : truncateText(list.productDescription, 50),
                            convertRegDate                : convertDateToStr(list.regDate),
                            convertUpDate                 : convertDateToStr(list.upDate),
                            convertOrderDepositPrice      : numberFormat(list.orderDepositPrice),
                            convertOrderTotalPrice        : numberFormat(list.orderTotalPrice),
                            convertOrderDefaultTotalPrice : numberFormat(list.orderDefaultTotalPrice),
                            convertOrderOptionTotalPrice  : numberFormat(list.orderOptionTotalPrice),
                            convertOrderServiceTotalPrice : numberFormat(list.orderServiceTotalPrice),
                            convertOrderMonths            : numberFormat(list.orderMonths),
                            convertDateDifference         : getDateDifference(parseInt(list.orderContractDate)),
							converOrderContractDate       : convertDateToStr(list.orderContractDate),
                            converLastPayment             : list.payment[list.payment.length-1]
                        }
                    })
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const onChangeListPage = (page) => {
            if(page){
                listCurrentPage.value = page
            }
            getList()
        }
        const onChangeListPreviousPage = () => {
            if (listCurrentPage.value > 1) {
                listCurrentPage.value--
                getList()
            }
        }

        const onChangeListNextPage = () => {
            if (listCurrentPage.value < listTotalPages.value) {
                listCurrentPage.value++
                getList()
            }
        }

        const onChangeListPageSize = () => {
            getList()
        }

        const onClickExpandedRow = (index) => {
            expandedRow.value = expandedRow.value === index ? null : index;
        }

        const onGetSelectListTotalPages = computed(() => {
            const pages = []
            if (listTotalPages.value <= 20) {
                for (let i = 1; i <= listTotalPages.value; i++) {
                    pages.push(i)
                }
            } else {
                for (let i = 1; i <= 20; i++) {
                    pages.push(i)
                }
                for (let i = 30; i <= listTotalPages.value; i += 10) {
                    pages.push(i)
                }
                if (listCurrentPage.value > 20 && !pages.includes(listCurrentPage.value)) {
                    pages.push(listCurrentPage.value)
                }
            }
            return pages
        })

        const onGetSelectListPageSize = computed(() => {
            const pageSize = []

            pageSize.push(10);
            if(listTotalCount.value > 10 && listTotalCount.value <= 50){
                pageSize.push(20);
            }
            if(listTotalCount.value > 50){
                pageSize.push(50);
            }
            if(listTotalCount.value > 100){
                pageSize.push(100);
            }
            if(listTotalCount.value > 10 && (listTotalCount.value !== 10 || listTotalCount.value !== 50 || listTotalCount.value !== 100)){
                pageSize.push(listTotalCount.value);
            }

            return pageSize
        })

        const onClickSearchList = () => {
            applySearchParams.value = { ...queryStringParams.value }
            getList()
        }

        const onHandleSearchParam = (param) => {
            queryStringParams.value[param.name] = 
                param.selecteds.length > 0 ? 
                (param.selecteds.length > 1 ? param.selecteds : param.selecteds[0]) : []
        }

        const onClickRefreshList = () => {
            getList()
        }

        const onClickSearchReset = () => {
            queryStringParams.value = {}
            applySearchParams.value = {}
            searchCheckProductStateRef.value?.onHandleReset()
            searchTextWhereRef.value?.onHandleReset()
            searchTextRef.value?.onHandleReset()

            getList()
        }

        const onClickAllSelected = () => {
            selectedList.value = []

            if(isAllSelected.value){
                Object.values(list.value).forEach(item => {
                    selectedList.value.push(item)
                })
            }
        }

        const onClickPartSelected = () => {
            isAllSelected.value = (selectedList.value.length === list.value.length)?true:false
        }

        const onClickModalOrderDetail = (item) => {
            modalOrderDetailRef.value.open({
                'mode' : 'detail',
                'item' : item
            })
        }

        const onCloseModalOrderDetail = (payload) => {
            if(payload.isUpdate){
                onClickModalOrderUpdate('update', payload.item)
                getList()
            }
        }

        const onClickModalOrderUpdate = (mode, item) => {
            modalOrderUpdateRef.value.open({
                'mode' : mode,
                'item' : item
            })
        }

        const onCloseModalOrderUpdate = (payload) => {
            if(payload.isUpdate){
                //onClickModalOrderDetail(payload.item)
                getList()
            }
        }

        const onClickModalOrderDelete = (item) => {
            if(item || selectedList.value.length > 0){
                modalOrderDeleteRef.value.open({
                    'mode' : 'delete',
                    'item' : item ? [item] : selectedList.value
                })
            }else{
                alertRef.value.open({ 'contents' : '선택된 항목이 없습니다.' })
                return
            }
        }

        const onCloseModalOrderDelete = (payload) => {
            if(payload.isUpdate){
                selectedList.value = []
                getList()
            }
        }

        const onClickIsViewSearch = () => {
            isViewSearch.value = !isViewSearch.value
        }

        const onClickOrderUpdate = async (value, item) => {
            const formData = new FormData();
            for (const key in item) {
                if (item[key] !== '') {
                    if(key === 'orderContractDate' && value){
                        item[key] = convertStrToUnixTime(value)
                    }
                    formData.set(key, item[key]);
                }
            }

            const response = await $axiosConnect('MEMBER', `/admin/order/${item.uid}`, 'POST', formData)
            if (response.success && response.body && response.body.list.length > 0) {
                getList()
            }

        }

        const onClickModalPaymentDetail = (item) => {
            modalPaymentDetailRef.value.open({
                'mode' : 'detail',
                'item' : item
            })
        }

        const onCloseModalPaymentDetail = (payload) => {
            if(payload.isUpdate){
                selectedList.value = []
                getList()
            }
        }

        const isPaymentDueWithinRange = (lastPaymentRegDate) => {
            if (!lastPaymentRegDate) return false;

            const lastPaymentDate = new Date(lastPaymentRegDate * 1000); // 유닉스 타임스탬프 변환
            const nextMonth = new Date(lastPaymentDate); // 복사해서 새로운 날짜 객체 생성
            nextMonth.setMonth(nextMonth.getMonth() + 1); // 다음 달로 설정

            const paymentDay = lastPaymentDate.getDate(); // 결제한 날
            const currentDate = new Date(); // 현재 날짜

            // 다음 달의 결제일 기준으로 앞 3일, 뒤 3일의 범위
            const startDate = new Date(nextMonth.setDate(paymentDay - 3)); // 다음 달 결제일 - 3일
            const endDate = new Date(nextMonth.setDate(paymentDay + 6)); // 다음 달 결제일 + 3일 (+6은 -3한 후 다시 기준일로 복귀 후 3일 추가)

            // 현재 날짜가 startDate와 endDate 사이인지 확인
            return currentDate >= startDate && currentDate <= endDate;
        };

        const onClickModalPaymentNextMonth = (item) => {
            if(confirm('결제를 진행하시겠습니까?')){
                modalPaymentNextMonthRef.value.open({
                    'mode' : 'payment',
                    'item' : item
                })
            }
        }

        const onCloseModalPaymentNextMonth = (payload) => {
            if(payload.isUpdate){
                getList()
            }
        }

        const initialize = async () => {
            await getList()
        }
        
        onMounted(async () => {
            await initialize()
        })

        return {
            alertRef,
            submitButtonDisabled,
            list,
            expandedRow,
            modalOrderDetailRef,
            modalOrderUpdateRef,
            modalOrderDeleteRef,
            modalPaymentDetailRef,
            modalPaymentNextMonthRef,

            listCurrentPage,
            listPageSize,
            listTotalPages,
            listAllCount,
            listTotalCount,
            listSearchCount,
            listSearchTarget,
            isAllSelected,
            selectedList,
            isViewSearch,

            searchCheckProductStateRef,
            searchTextWhereRef,
            searchTextRef,

            categoryList,
            imgURL,
            orderContractDate,

            onChangeListPage,
            onChangeListPreviousPage,
            onChangeListNextPage,
            onChangeListPageSize,
            onClickExpandedRow,
            onGetSelectListTotalPages,
            onGetSelectListPageSize,
            onClickSearchList,
            onHandleSearchParam,

            onClickRefreshList,
            onClickSearchReset,
            onClickAllSelected,
            onClickPartSelected,
            onClickModalOrderUpdate,
            onCloseModalOrderUpdate,
            onClickModalOrderDetail,
            onCloseModalOrderDetail,
            onClickModalOrderDelete,
            onCloseModalOrderDelete,
            onClickIsViewSearch,
            onClickOrderUpdate,
            onClickModalPaymentDetail,
            onCloseModalPaymentDetail,
            isPaymentDueWithinRange,
            onClickModalPaymentNextMonth,
            onCloseModalPaymentNextMonth

        }

    }
});
</script>
<style scoped>
.date-time-picker {
    position: inherit !important;
}
::v-deep .visible.datetimepicker.flex {
    top: 0 !important;
}
.field.has-value.field-label{
    font-size: 10px;
}
</style>