<template>
    <main>
        <div class="container">
            <div class="content">
                <div class="container-fluid">

                    <div class="main-top">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="main-top-img-group">
                                    <div class="main-top-img" role="button" @click="onClickNavigateToProduct('gaming')">
                                        <picture>
                                            <img class="absolute-center" 
                                                src="@/assets/images/web/main/main_top_img_1.png" 
                                            />
                                        </picture>
                                        <p>난, 게이밍 전문가!</p>
                                        <h6>게이밍용</h6>
                                    </div>
                                    <div class="main-top-img" role="button" @click="onClickNavigateToProduct('professional')">
                                        <picture>
                                            <img class="absolute-center" 
                                            src="@/assets/images/web/main/main_top_img_2.png" 
                                            />
                                        </picture>
                                        <p>난, 그래픽, 동영상 전문가!</p>
                                        <h6>전문가용</h6>
                                    </div>
                                    <div class="main-top-img" role="button" @click="onClickNavigateToProduct('work')">
                                        <picture>
                                            <img class="absolute-center" 
                                            src="@/assets/images/web/main/main_top_img_3.png" 
                                            />
                                        </picture>
                                        <p>일반 사무, 업무용에는!</p>
                                        <h6>업무용</h6>
                                    </div>
                                    <div class="main-top-img" role="button" @click="onClickNavigateToProduct('study')">
                                        <picture>
                                            <img class="absolute-center" 
                                            src="@/assets/images/web/main/main_top_img_4.png" 
                                            />
                                        </picture>
                                        <p>이번 시험은 왠지 100점!</p>
                                        <h6>학습용</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="main-top-slogan-group">
                                    <div class="main-top-slogan-1">
                                        <div class="main-top-slogan-1-text">
                                            <img src="@/assets/images/web/main/main_top_tip.png">
                                            <div class="main-top-slogan-1-over-button">
                                                <button class="btn btn-sm btn-primary-1" @click="router.push({ name: 'webContactPage' })">1:1 문의</button>
                                                <button class="btn btn-sm btn-sub-1" @click="router.push({ name: 'webCallRequestPage' })">전화상담요청</button>
                                                <button class="btn btn-sm btn-special-1" @click="router.push({ name: 'webHelpPage' })">구독 도움말</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="main-top-slogan-2">
                                        <span @click="onClickNavigateToProductCustom()" role="button">A~Z까지 내가 선택한다!<i class="fas fa-computer ml-2"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="main-banner">
                        <picture class="banner-image" role="button" @click="onClickEventDetail(2)">
                            <img src="@/assets/images/web/main/main_banner_1.png">
                        </picture>
                    </div>

                    <!--추천상품-->
                    <div class="main-products" v-show="productRecommendList.length > 0">
                        <div class="main-product-header">
                            <h4 class="">추천상품</h4>
                            <div class="plus-button" @click="onClickNavigateToProductSearch('recommend')"><i class="fas fa-plus"></i></div>
                        </div>
                        <div class="mt-1">
                            <component-product-swiper
                                selectCheck="recommend"
                                @on-swiper-list="onSwiperList"
                            />
                        </div>
                    </div>

                    <!--특가상품-->
                    <div class="main-products" v-show="productSpecialPriceList.length > 0">
                        <div class="main-product-header">
                            <h4 class="">특가상품</h4>
                            <div class="plus-button" @click="onClickNavigateToProductSearch('specialPrice')"><i class="fas fa-plus"></i></div>
                        </div>
                        <div class="mt-1">
                            <component-product-swiper
                                selectCheck="specialPrice"
                                @on-swiper-list="onSwiperList"
                            />
                        </div>
                    </div>

                    <!--기획상품-->
                    <div class="main-products" v-show="productPromotionList.length > 0">
                        <div class="main-product-header">
                            <h4 class="">기획상품</h4>
                            <div class="plus-button" @click="onClickNavigateToProductSearch('promotion')"><i class="fas fa-plus"></i></div>
                        </div>
                        <div class="mt-1">
                            <component-product-swiper
                                selectCheck="promotion"
                                @on-swiper-list="onSwiperList"
                            />
                        </div>
                    </div>

                    <div class="main-banner">
                        <picture class="banner-image" role="button" @click="onClickNavigateToProduct('gaming')">
                            <img src="@/assets/images/web/main/main_banner_2.png">
                        </picture>
                    </div>

                    <div class="main-banner">
                        <picture class="banner-image" role="button" @click="onClickEventDetail(2)">
                            <img src="@/assets/images/web/main/main_banner_3.png">
                        </picture>
                    </div>

                    <div class="main-foot mt-5 mb-5">
                        <div class="row">
                            <div class="col-12">
                                <div class="main-foot-buttons">
                                    <button class="btn btn-special-reverse-2" @click="router.push({ name: 'webNoticeListPage' })">고객센터</button>
                                    <button class="btn btn-special-reverse-2" @click="router.push({ name: 'webHelpPage' })">구독 도움말</button>
                                    <button class="btn btn-special-reverse-2" @click="router.push({ name: 'webSignInPage' })">로그인</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'

import ComponentProductSwiper from '@/components/web/ComponentProductSwiper'

export default defineComponent({
    name       : 'webMainPage',
    components : {
        'component-product-swiper': ComponentProductSwiper
    },
    setup() {
        const { router } = commonMixin()

        const productRecommendList    = ref([])
        const productSpecialPriceList = ref([])
        const productPromotionList    = ref([])

        const onSwiperList = (payload) => {
            const type = payload.type
            if (type === 'recommend') {
                productRecommendList.value = payload.list
            } else if (type === 'specialPrice') {
                productSpecialPriceList.value = payload.list
            } else if (type === 'promotion') {
                productPromotionList.value = payload.list
            }
        }

        const onClickNavigateToProductSearch = (text) => {
            router.push({ name: 'webProductSearchPage', query: { searchType: text } });
        }

        const onClickNavigateToProductDetail = (uid) => {
            router.push({ name: 'webProductDetailPage', params: { uid } });
        }

        const onClickNavigateToProductCustom = () => {
            router.push({ name: 'webProductCustomPage' });
        }

        const onClickNavigateToProduct = (searchPurpose) => {
            //console.log("Navigating to product page with searchPurpose:", searchPurpose);
            router.push({ name: 'webProductListPage', params: { searchPurpose: searchPurpose } });
        }

		const onClickEventDetail = (uid) => {
            router.push({ name: 'webEventDetailPage', params: { uid: uid } });
        }
		

        const initialize = async () => {
            productRecommendList.value    = []
            productSpecialPriceList.value = []
            productPromotionList.value    = []
        }

        onMounted(async () => {
            await initialize()
        })
        
        return {
            productRecommendList,
            productSpecialPriceList,
            productPromotionList,
            router,

            onClickNavigateToProductSearch,
            onClickNavigateToProductDetail,
            onClickNavigateToProductCustom,
            onClickNavigateToProduct,
            onSwiperList,
			onClickEventDetail
        }

    }
});
</script>