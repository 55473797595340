<template>
    <Loading />
    <router-view />
    <notifications group="top-center"    position="top center"   :speed="400" :max="3" />
    <notifications group="top-left"      position="top left"     :speed="400" :max="3" />
    <notifications group="top-right"     position="top right"    :speed="400" :max="3" />
    <notifications group="bottom-left"   position="bottom left"  :speed="400" :max="3" />
    <notifications group="bottom-right"  position="bottom right" :speed="400" :max="3" />
</template>

<script>
import Loading from '@/components/common/ComponentLoading';

export default {
    name: 'App',
    components: {
        Loading
    },
    setup() {
    }
}
</script>