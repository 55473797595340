<template>
    <Modal ref="baseModal" :title="`리뷰 ${mode === 'create' ? '추가' : '수정'}`" :width="`600px`" :isDrag="false" @close="close">
        <template #body>
            <form ref="reviewFormRef" @submit.prevent="onClickSave">
				<div class="form-group row form-required">
					<label for="inputSubject" class="col-sm-2 col-form-label">제목</label>
					<div class="col-sm-10">
						<div class="input-group">
							<div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
							<input type="text" class="form-control" id="inputSubject" placeholder="제목을 입력해주세요." autocomplete="off" tabindex="1"
							required 
							maxlength="255" 
                            pattern-hint="제목은 필수로 입력해야 합니다"
							v-model="reviewFormData.subject"
							@blur="onInputCheckEvent" 
							/>
						</div>
                        <small class="input-hint"></small>
					</div>
				</div>
				<div class="form-group row form-required">
					<label for="inputSubject" class="col-sm-2 col-form-label">별점</label>
					<div class="col-sm-10">
						<div class="product-review-list-stars">
							<span 
                                v-for="star in 5" 
                                :key="star" 
                                @click="onClickRating(star)"
                                :class="{ 'active': star <= reviewFormData.star }"
								role="button"
                            >
                                ★
                            </span>
						</div>
						<small class="input-hint-block"
						:class="{ 'active': starHint }"
						>{{ starHint }}</small>
					</div>
				</div>
                <div class="form-group row form-required">
					<label for="inputContent" class="col-sm-2 col-form-label">내용</label>
					<div class="col-sm-10">
                        <div class="pt-2 pb-2">
                            <smarteditor2 
                            v-model="reviewFormData.content"
                            :editor-id="'editor1'"
                            ref="editor1"
                            />
                        </div>
						<small class="input-hint-block"
						:class="{ 'active': contentHint }"
						>{{ contentHint }}</small>
					</div>
				</div>
                <div class="form-group row">
					<label for="selectNoticeState" class="col-sm-2 col-form-label">파일</label>
					<div class="col-sm-10">
						<div class="input-group">
							<component-form-file-upload 
                                ref="fileUploadRef"
                                :maxSize="10240"
                                :initialFiles = reviewFormData.upfiles
                                @on-click-attach-file="onClickAttachFile"
                                accept="image/*"
                            />
						</div>
					</div>
				</div>
			</form>
        </template>
        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">취소</button>
			<button type="submit" class="btn btn-primary-1" :disabled="submitButtonDisabled" @click="onClickSave">저장</button>
        </template>
	</Modal>

	<alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, ref } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import Modal from '@/modals/Modal'
import Alert from '@/modals/common/Alert'

import ComponentFormSmarteditor2 from "@/components/web/ComponentFormSmarteditor2"
import ComponentFormFileUpload from "@/components/admin/ComponentFormFileUpload"

export default defineComponent({
    name       : 'modalProductReviewPage',
    components: {
		Modal,
		'smarteditor2' : ComponentFormSmarteditor2,
		'alert' : Alert,
		'component-form-file-upload' : ComponentFormFileUpload,
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){
		const { onHandleValidateInput, $axiosConnect } = commonMixin()

        const baseModal = ref(null)
		const resolvePromise = ref(null)

        const submitButtonDisabled  = ref(true)
        const closeModalData        = ref({})
		const mode                  = ref('create')
		const alertRef              = ref(null)

		const reviewFormRef         = ref(null)
		const reviewFormData        = ref({
            subject    : '',
			content    : '',
			productUid : 0,
            orderUid   : 0,
			star       : '',
			upfiles    : []
        })

		const editor1 = ref(null)
		const contentHint            = ref('')
		const starHint               = ref('')

		const uploadedFiles = ref([])

		/**
         * 입력 값의 유효성을 검사하고, 암호화된 아이디와 비밀번호를 생성하는 메서드
         * 유효성 검사 결과에 따라 submitDisabled 속성을 설정합니다.
         * @method onInputCheckEvent
         * @returns {void}
         */
         const onInputCheckEvent = () => {
			if (reviewFormData.value.star) {
                starHint.value = ''
            } else {
				starHint.value = '별점은 필수로 입력해야 합니다'
            }
            return !(submitButtonDisabled.value = !onHandleValidateInput(reviewFormRef.value) || !!starHint.value)
        }

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onOverSubmitButton = () => {
            const formElements = reviewFormRef.value.querySelectorAll('input, select, textarea')
            formElements.forEach(input => {
                input.blur()
            });
        }

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
        const onClickSave = async () => {
            if(onInputCheckEvent()){
                //Editor Content
                editor1.value?.syncContent()

                if (reviewFormData.value.content.replace(/(<([^>]+)>)/ig, "")) {
					contentHint.value = ''

                    const formData = new FormData();
                    for (const key in reviewFormData.value) {
                        if (reviewFormData.value[key] !== '') {
                            formData.set(key, reviewFormData.value[key]);
                        }
                    }

                    // 파일들을 FormData에 추가
                    uploadedFiles.value.forEach((file, index) => {
                        formData.append(`files[${index}]`, file)
                    })

                    onProcessSave(formData)

                } else {
					contentHint.value = '내용은 필수로 입력해야 합니다'
				}
            }
        }

        /**
         * 로그인 프로세스를 처리하는 메서드
         * @method onProcessSignIn
         * @param {FormData} formData - 로그인에 필요한 폼 데이터
         * @returns {void}
         */
        const onProcessSave = async (formData) => {
            try {

                const response = await $axiosConnect('MEMBER', `/web/review`, 'POST', formData)
                if (response.success && response.body && response.body.list.length > 0) {
                    
                    editor1.value?.cleanContent()

                    reviewFormData.value = {
                        subject    : '',
                        content    : '',
						productUid : 0,
                        orderUid   : 0,
						star       : '',
                        upfiles    : []
                    }

                    closeModalData.value = {
                        isUpdate : true,
                        item     : response.body.list[0]
                    }

                    alertRef.value.open({ 
                        'contents' : response.message,
                        'callback' : close
                    })

                }else{
                    alertRef.value.open({ 'contents' : response.message })
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

		const onClickAttachFile = (filesInfo) => {
            uploadedFiles.value = filesInfo.map(fileInfo => fileInfo.file)

            reviewFormData.value.uploadedFilesOrder = []
            filesInfo.forEach(fileInfo => {
                reviewFormData.value.uploadedFilesOrder.push({
                    "name"  : fileInfo.upfilesOriginalName?fileInfo.upfilesOriginalName:fileInfo.name,
                    "order" : fileInfo.order
                })
            })
            reviewFormData.value.uploadedFilesOrder = JSON.stringify(reviewFormData.value.uploadedFilesOrder)

            onInputCheckEvent()
        }

		const onEditorChange = (event) => {
            reviewFormData.value.content = event.html
        }

		const onClickRating = (rating) => {
            reviewFormData.value.star = rating
			onInputCheckEvent()
        }
        
        const initialize = async () => {

        }

		const open = async (payload) => {
			mode.value = 'create'
            //submitButtonDisabled.value = false

			reviewFormData.value = {
                subject    : '',
				content    : '',
				productUid : 0,
                orderUid   : 0,
				star       : '',
				upfiles    : []
            }

            if (payload.item && payload.mode === 'update') {
                reviewFormData.value = JSON.parse(JSON.stringify(payload.item))
                mode.value = payload.mode

            }else{
                if(payload.item && payload.item.uid){
                    reviewFormData.value.productUid = payload.item.productUid
                    reviewFormData.value.orderUid   = payload.item.uid
                }
            }

            closeModalData.value = {
                isUpdate : false
            }

            //Open Modal
			baseModal.value.openModal()
            await initialize()
			return new Promise((resolve) => {
				resolvePromise.value = resolve;
			});
		};

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			baseModal.value.closeModal()
			resolvePromise.value(closeModalData.value)
		};

        return {
			baseModal, 
			open,
			close,
            submitButtonDisabled,
            mode,
			alertRef,

			reviewFormRef,
			reviewFormData,
			editor1,
			contentHint,
			starHint,

			onClickSave,
			onClickAttachFile,
			onEditorChange,
			onInputCheckEvent,
			onOverSubmitButton,
			onClickRating

		}
    }
});
</script>
<style scoped>
::v-deep .ql-editor {
    height: 200px;
    overflow: scroll;
    overflow-x: hidden;
}
::v-deep .date-time-picker button {
    padding: 0;
}
</style>