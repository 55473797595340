import { createRouter, createWebHistory } from 'vue-router'
import adminRoutes from './modules/admin'
import webRoutes from './modules/web'
import store from '../store'

const routes = [
    ...adminRoutes,
    ...webRoutes
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 }
    }
});

router.beforeEach(async (to, from, next) => {
    //직접접근
    if (to.path.startsWith('/API') || to.path.startsWith('/uploads')) {
        next()
    }
    
    //store.dispatch('common/setPageLoading', true)
    await import('@/assets/plugins/fontawesome-free/css/all.min.css')
    await import('@/assets/style/common/common.css')
    await import('@/assets/style/common/default.css')
    await import('@/assets/style/admin/adminlte.css')
    await import('@/assets/style/web/scrollbar.css')
    await import('@/assets/style/common/mobile.css')

    try {
        const isAdminLayout = to.matched.some(record => record.meta.layout === 'admin')
        if (isAdminLayout) {
            await import('@/assets/style/admin/admin.css')
        } else {
            await import('@/assets/style/web/web.css')
            
        }
    } catch (error) {
        console.error("Failed to load CSS", error)
    }    
    
    store.dispatch('common/setPreviousPage', from.name)

    const requiresAuth  = to.matched.some(record => record.meta.requiresAuth)
    if (requiresAuth) {
        const accessToken = store.getters["member/getAccessToken"]
        const memberInfo  = store.getters["member/getMemberInfo"]
        if (accessToken && memberInfo) {
            return next()
        } else {

            if (to.path.startsWith('/admin')){
                return next({
                    path  : '/admin/adminSignIn',
                    query : { redirect: to.fullPath }
                });
            }else{
                return next({
                    name  : 'webSignInPage',
                    query : { redirect: to.fullPath }
                });
            }
        }
    }

    next()  
})

router.afterEach(() => {
    //store.dispatch('common/setPageLoading', false)
})

export default router;