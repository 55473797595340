<template>
    <main>
        <div class="container">
			<section class="content-header">
				<div class="container-fluid">
					<div class="row mb-2">
						<div class="col-sm-12">
							<div class="page-tab-title">
								<ul>
                                    <li @click="router.push({ name: 'webMyPageOrderStatusPage' })"><span>MY 페이지</span></li>
									<li class="active"><span>내 정보</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
            <div class="content">
                <div class="container-fluid">
					<div class="row">
						<div class="col-sm-12">

                            <div class="card">
                                <div class="card-body join-card-body">
                                    <form ref="myInfoFormRef">
                                        <div class="row justify-content-center">
                                            <div class="col-md-12 col-sm-12">
                                                <div class="form-group row form-required mb-3">
                                                    <label for="memberName" class="col-sm-3 col-form-label">가입유형</label>
                                                    <div class="col-sm-9">
                                                        <span class="form-control">{{ storeAuthority[myInfoFormData.memberAuthority] }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-sm-12">
                                                <div class="form-group row form-required mb-3">
                                                    <label for="memberName" class="col-sm-3 col-form-label">이름</label>
                                                    <div class="col-sm-9">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" id="memberName" placeholder="이름을 입력해주세요." autocomplete="off" tabindex="1"
                                                            required 
                                                            maxlength="50" 
                                                            pattern="^[a-zA-Z가-힣]+$"
                                                            pattern-hint="이름은 한글/영문으로 실명을 입력해야 합니다."
                                                            v-model="myInfoFormData.memberName"
                                                            @blur="onInputCheckEvent()" 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-sm-12">
                                                <div class="form-group row form-required mb-3">
                                                    <label for="memberName" class="col-sm-3 col-form-label">아이디</label>
                                                    <div class="col-sm-9">
                                                        <span class="form-control">{{ myInfoFormData.memberId }}</span>
                                                    </div>
                                                    <small class="input-hint with-label-3"></small>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-sm-12">
                                                <div class="form-group row mb-3">
                                                    <label for="memberName" class="col-sm-3 col-form-label">비밀번호 변경</label>
                                                    <div class="col-sm-5">
                                                        <button type="button" class="btn btn-primary-1 btn-block"
                                                        @click="onClickModalPasswordChange"
                                                        >현재의 비밀번호를 변경하시려면 클릭하세요.</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-sm-12">
                                                <div class="form-group row form-required mb-3">
                                                    <label for="memberName" class="col-sm-3 col-form-label">이메일</label>
                                                    <div class="col-sm-9">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" id="memberEmail" placeholder="이메일을 입력해주세요." autocomplete="off" tabindex="5"
                                                            required
                                                            maxlength="100" 
                                                            pattern="^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$"
                                                            pattern-hint="이메일을 형식에 맞게 정확히 입력해야 합니다."
                                                            v-model="myInfoFormData.memberEmail"
                                                            @blur="onInputCheckEvent()" 
                                                            />
                                                        </div>
                                                    </div>
                                                    <small class="input-hint with-label-3"></small>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-sm-12">
                                                <div class="form-group row form-required mb-3">
                                                    <label for="memberName" class="col-sm-3 col-form-label">휴대폰</label>
                                                    <div class="col-sm-9">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" id="memberMobile" placeholder="수령인의 휴대폰 번호를 입력해주세요." autocomplete="off" tabindex="2"
                                                            required
                                                            maxlength="11" 
                                                            pattern="^010\d{8}$"
                                                            pattern-hint="휴대폰 번호는 010으로 시작해야하며, 숫자만 입력해야 합니다."
                                                            v-model="myInfoFormData.memberMobile"
                                                            @blur="onInputCheckEvent()" 
                                                            />
                                                        </div>
                                                    </div>
                                                    <small class="input-hint with-label-3"></small>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-sm-12">
                                                <div class="form-group row form-required mb-3">
                                                    <label for="memberName" class="col-sm-3 col-form-label">주소</label>
                                                    <div class="col-sm-9">
                                                        <div class="row">
                                                            <div class="col-md-4 col-sm-6">
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control" id="orderZipcode" placeholder="우편번호" autocomplete="off" tabindex="5"
                                                                    required
                                                                    maxlength="5" 
                                                                    pattern="^\d{5}$"
                                                                    pattern-hint="우편번호를 입력해야 합니다."
                                                                    v-model="myInfoFormData.memberZipcode"
                                                                    @blur="onInputCheckEvent()" 
                                                                    @click="onClickFindAddress"
                                                                    readonly
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 col-sm-6">
                                                                <button type="button" class="btn btn-primary-reverse-2 btn-block"
                                                                @click="onClickFindAddress"
                                                                >주소찾기</button>
                                                            </div>
                                                        </div>
                                                        <div class="row mt-2">
                                                            <div class="col-md-12 col-sm-12">
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control" id="orderAddr" placeholder="주소를 입력해주세요." autocomplete="off" tabindex="5"
                                                                    required
                                                                    pattern-hint="주소를 입력해야 합니다."
                                                                    v-model="myInfoFormData.memberAddr"
                                                                    @blur="onInputCheckEvent()" 
                                                                    @click="onClickFindAddress"
                                                                    readonly
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mt-2">
                                                            <div class="col-md-12 col-sm-12">
                                                                <div class="input-group">
                                                                    <input type="text" class="form-control" id="orderAddrDetail" placeholder="상세 주소를 입력해주세요." autocomplete="off" tabindex="5"
                                                                    pattern-hint="상세 주소를 입력해야 합니다."
                                                                    v-model="myInfoFormData.memberAddrDetail"
                                                                    @blur="onInputCheckEvent()" 
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-md-3 col-sm-12">
                                                <div class="mt-3 mb-3">
                                                    <button type="button" class="btn btn-block btn-special-2"
                                                    :disabled="submitButtonDisabled" @mouseenter="onOverSubmitButton"
                                                    @click="onClickMyInfo"
                                                    >수정</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>

						</div>
					</div>
                </div>
            </div>
        </div>

        <modal-password-change ref="modalPasswordChangeRef" @onCloseModal="onCloseModalPasswordChange" />
        <modal-password-check ref="modalPasswordCheckRef" @onCloseModal="onCloseModalPasswordCheck" />
		<alert ref="alertRef" :title="'알림'" />

    </main>
</template>

<script>
import { defineComponent, ref, onMounted, nextTick } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { decryptNormal } from '@/helper/plugins/security.js'
import Alert from '@/modals/common/Alert'

import ModalPasswordChange from '@/modals/web/mypage/ModalPasswordChange'
import ModalPasswordCheck from '@/modals/web/mypage/ModalPasswordCheck'

export default defineComponent({
    name       : 'webMyInfoPage',
    components: {
		'alert' : Alert,
        'modal-password-change' : ModalPasswordChange,
        'modal-password-check' : ModalPasswordCheck,
	},
    setup() {
		const { onHandleValidateInput, $axiosConnect, router, storeAuthority, notify, store } = commonMixin()

        const submitButtonDisabled = ref(true)
        const myInfoFormRef        = ref(null)
        const myInfoFormData       = ref({})

        const alertRef = ref(null)
        const modalPasswordChangeRef = ref(null)
        const modalPasswordCheckRef = ref(null)

        const getMyInfo = async () => {
            try {
                const response = await $axiosConnect('MEMBER', `/web/myInfo`, 'GET')
                if (response.success) {
                    myInfoFormData.value = convertKeysToCamelCase(JSON.parse(decryptNormal('ddokdok', response.body.memberInfo)));
                }else{
                    alertRef.value.open({ 'contents' : response.message })
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        /**
         * 입력 값의 유효성을 검사하고, 암호화된 아이디와 비밀번호를 생성하는 메서드
         * 유효성 검사 결과에 따라 submitDisabled 속성을 설정합니다.
         * @method onInputCheckEvent
         * @returns {void}
         */
         const onInputCheckEvent = async () => {
            return !(submitButtonDisabled.value = !onHandleValidateInput(myInfoFormRef.value))
        }

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onOverSubmitButton = () => {
            const formElements = myInfoFormRef.value.querySelectorAll('input, select, textarea')
            formElements.forEach(input => {
                input.blur()
            });
        }

        const onClickMyInfo = async () => {
            modalPasswordCheckRef.value.open({
                'mode' : 'password',
                'item' : ''
            })
        }

        const onCloseModalPasswordCheck = (payload) => {
            if(payload.isUpdate){
                let newMyInfoFormData = { ...myInfoFormData.value };
                if(!newMyInfoFormData.memberPassword){
                    delete newMyInfoFormData.memberPassword
                    delete newMyInfoFormData.memberPasswordEncrypted
                }
                if(onInputCheckEvent()){
                    const formData = new FormData();
                    for (const key in newMyInfoFormData) {
                        if (newMyInfoFormData[key] !== '') {
                            formData.set(key, newMyInfoFormData[key]);
                        }
                    }
                    onProcessSave(formData)
                }
            }
        }

        /**
         * 로그인 프로세스를 처리하는 메서드
         * @method onProcessSave
         * @param {FormData} formData - 로그인에 필요한 폼 데이터
         * @returns {void}
         */
         const onProcessSave = async (formData) => {
            try {
                const response = await $axiosConnect('MEMBER', '/web/myInfo', 'POST', formData)
                if (response.success) {
                    store.dispatch('member/signIn', response.body);
                    alertRef.value.open({ 'contents' : response.message })
                    initialize()
                }else{
                    notify({
                        group : "top-center",
						title : "내정보 수정 실패",
						text  : response.message,
                        type  : 'error'
					});
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const toCamelCase = (str) => {
            return str
              .toLowerCase()
              .replace(/_([a-z])/g, (match, p1) => p1.toUpperCase());
        }

        const convertKeysToCamelCase = (obj) => {
            const newObject = {};
            for (const key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    const newKey = toCamelCase(key);
                    newObject[newKey] = obj[key];
                }
            }
            return newObject;
        }

        const loadDaumPostCodeScript = () => {
            return new Promise((resolve, reject) => {
                if (document.getElementById('daum-postcode-script')) {
                    resolve();
                    return;
                }

                const script = document.createElement('script');
                script.id = 'daum-postcode-script';
                script.src = 'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
                script.onload = resolve;
                script.onerror = reject;
                document.body.appendChild(script);
            });
        };

        const openDaumPostcode = () => {
            new window.daum.Postcode({
                oncomplete: function(data) {
                    // Get the address and postal code and update the form data
                    myInfoFormData.value.memberZipcode = data.zonecode;
                    myInfoFormData.value.memberAddr = data.address;
                    // Focus on the detailed address input field after auto-filling
                    nextTick(() => {
                        document.getElementById('memberAddrDetail').focus();
                    });
                },
            }).open();
        };

        const onClickFindAddress = async () => {
            try {
                await loadDaumPostCodeScript();
                openDaumPostcode();
            } catch (error) {
                console.error('Failed to load Daum Postcode script:', error);
            }
        };

        const onClickModalPasswordChange = () => {
            modalPasswordChangeRef.value.open({
                'mode' : 'change',
                'item' : ''
            });
        }

        const onCloseModalPasswordChange = (payload) => {
            if (payload.isUpdate) {
                initialize()
            }
        }

        const initialize = async () => {
            myInfoFormData.value = {}
            submitButtonDisabled.value = true
            await getMyInfo()
        }

        onMounted(async () => {
            await initialize()
        })

		return {
			router,
            alertRef,
            submitButtonDisabled,
            myInfoFormRef,
            myInfoFormData,
            storeAuthority,
            modalPasswordChangeRef,
            modalPasswordCheckRef,

            onHandleValidateInput,
            onClickMyInfo,
            onOverSubmitButton,
            onInputCheckEvent,
            onClickFindAddress,
            onClickModalPasswordChange,
            onCloseModalPasswordChange,
            onCloseModalPasswordCheck
        }
    }
});
</script>