import { decryptNormal } from '@/helper/plugins/security.js'

const state = {
    accessToken : null,
    memberInfo  : null,
    authority   : {
        10  : '개인회원',
        11  : '기업회원(개인)',
        12  : '기업회원(법인)',
        90  : '매니저',
        99  : '관리자',
        999 : '최고관리자',
    },
    signUpFormData : {
        memberAuthority         : '0',
        memberAgreeService      : false,
        memberAgreePolicy       : false,
        memberAgreeSharing      : false,
        memberAgreeReceiving    : false,
        memberName              : '',
        memberBirth             : '',
        memberBirthYear         : new Date().getFullYear() - 20,
        memberBirthMonth        : 1,
        memberBirthDay          : 1,
        memberGender            : '',
        memberMobile            : '',
        memberMobileCarrier     : '',
        memberAuthNumber        : '',
        memberId                : '',
        memberPassword          : '',
        memberPasswordEncrypted : '',
        memberRePassword        : '',
    }
}
//https://minu0807.tistory.com/64
const mutations = {
	/*
     * SET_accessToken 뮤테이션은 state의 accessTokens 값을 설정합니다.
     *
     * @param {Object} state - Vuex 상태 객체
     * @param {string} accessToken - 설정할 토큰 값
     * @returns {void}
     */
    SET_ACCESS_TOKEN(state, accessToken) {
        state.accessToken = accessToken
    },

    /*
     * CLEAR_accessToken 뮤테이션은 state의 accessToken 값을 초기화합니다.
     *
     * @param {Object} state - Vuex 상태 객체
     * @returns {void}
     */
    CLEAR_ACCESS_TOKEN(state) {
        state.accessToken = null
    },

    /*
     * SET_accessToken 뮤테이션은 state의 accessTokens 값을 설정합니다.
     *
     * @param {Object} state - Vuex 상태 객체
     * @param {string} accessToken - 설정할 토큰 값
     * @returns {void}
     */
    SET_MEMBER_INFO(state, memberInfo) {
        state.memberInfo = memberInfo
    },

    /*
     * CLEAR_accessToken 뮤테이션은 state의 accessToken 값을 초기화합니다.
     *
     * @param {Object} state - Vuex 상태 객체
     * @returns {void}
     */
    CLEAR_MEMBER_INFO(state) {
        state.memberInfo = null
    },

    /*
     * SET_accessToken 뮤테이션은 state의 accessTokens 값을 설정합니다.
     *
     * @param {Object} state - Vuex 상태 객체
     * @param {string} accessToken - 설정할 토큰 값
     * @returns {void}
     */
    SET_SIGN_UP_FORM_DATA(state, signUpFormData) {
        state.signUpFormData = { ...signUpFormData }
    },
}

const actions = {
	/*
     * signIn 액션은 credentials에서 받아온 토큰을 설정합니다.
     *
     * @param {Object} context - Vuex 액션 컨텍스트 객체
     * @param {Object} payload - 로그인 시 받아온 자격 증명
     * @returns {void}
     */
    signIn({ commit }, payload) {
        commit('SET_ACCESS_TOKEN', payload.accessToken)
        commit('SET_MEMBER_INFO',  payload.memberInfo)
    },

    /*
     * signInIdSave 액션은 로그인 ID를 저장합니다.
     *
     * @param {Object} context - Vuex 액션 컨텍스트 객체
     * @param {Object} payload - 로그인 ID 저장에 필요한 데이터
     * @returns {void}
     */
    signInIdSaveState(_, payload) {
        const { memberSignInIdSaveState, memberId } = payload
        if (memberSignInIdSaveState) {
            const items = {
                value  : memberId,
                expire : Date.now() + (60 * 60 * 24 * 30 * 1000)
            }
            window.localStorage.setItem("signInIdSaveState", JSON.stringify(items))
        } else {
            window.localStorage.removeItem('signInIdSaveState')
        }
    },

    /*
     * signOut 액션은 토큰을 초기화합니다.
     *
     * @param {Object} context - Vuex 액션 컨텍스트 객체
     * @returns {void}
     */
    signOut({ commit }) {
        commit('CLEAR_ACCESS_TOKEN')
        commit('CLEAR_MEMBER_INFO')
    },

    /*
     * signOut 액션은 토큰을 초기화합니다.
     *
     * @param {Object} context - Vuex 액션 컨텍스트 객체
     * @returns {void}
     */
    setSignUpStep({ commit }, payload) {
        commit('SET_SIGN_UP_FORM_DATA', JSON.parse(JSON.stringify(payload)));
    }
}

const getters = {

    /*
     * 토큰 반환 - accessaccessToken, refalseaccessToken은 풀어서 사용
     * getaccessToken
     *
     * @param {Object} state - Vuex 상태 객체
     * @returns {boolean}
     */
    getAccessToken: state => {
        return state.accessToken
    },

    /*
     * 토큰 반환 - accessaccessToken, refalseaccessToken은 풀어서 사용
     * getaccessToken
     *
     * @param {Object} state - Vuex 상태 객체
     * @returns {boolean}
     */
    getMemberInfo: state => {
        if (state.memberInfo) {
            try {
                return JSON.parse(decryptNormal('ddokdok', state.memberInfo))
            } catch (error) {
                console.error('Error parsing accessToken:', error)
                return null
            }
        }
        return null
    },

    /*
     * 로그인 ID 저장 상태를 반환합니다.
     * @method getSignInIdSaveState
     * @returns {boolean | string} - 로그인 ID 저장 상태 (저장된 ID가 없거나 기간이 만료된 경우 false, 그렇지 않으면 저장된 ID)
     */
    getSignInIdSaveState: () => {
        const items = window.localStorage.getItem('signInIdSaveState')
        if(!items) {
            return false
        }
        const item = JSON.parse(items)
        if(Date.now() > item.expire) {    
            window.localStorage.removeItem('signInIdSaveState')
            return false
        }
        return item.value
    },

    /*
     * 토큰 반환 - accessaccessToken, refalseaccessToken은 풀어서 사용
     * getaccessToken
     *
     * @param {Object} state - Vuex 상태 객체
     * @returns {boolean}
     */
    getAuthority: state => {
        return state.authority
    },

    /*
     * 토큰 반환 - accessaccessToken, refalseaccessToken은 풀어서 사용
     * getaccessToken
     *
     * @param {Object} state - Vuex 상태 객체
     * @returns {boolean}
     */
    getSignUpFormData: state => {
        return JSON.parse(JSON.stringify(state.signUpFormData))
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}