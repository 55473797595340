export const adminMenus = [
	{
		seq      : 0,
		title    : '대시보드',
		icon     : 'fas fa-home',
		badge    : '0',
		isOpen   : false,
		path     : '/admin',
		group    : "HOME",
		children : []
	},
	{
		seq      : 1,
		title    : '주문관리',
		icon     : 'fas fa-store',
		badge    : '0',
		isOpen   : false,
		path     : '#',
		group    : "SALES",
		children : [
			{
				seq      : 0,
				title    : '주문',
				icon     : 'fas fa-cart-shopping',
				path     : '/admin/order',
				badge    : '0',
				isOpen   : false,
				group    : "",
				children : []
			},
		]
	},
	{
		seq      : 2,
		title    : '상품관리',
		icon     : 'fas fa-boxes-packing',
		badge    : '0',
		isOpen   : false,
		path     : '#',
		group    : "",
		children : [
			{
				seq      : 0,
				title    : '상품',
				icon     : 'fas fa-box-archive',
				path     : '/admin/product',
				badge    : '0',
				isOpen   : false,
				group    : "",
				children : []
			},
			{
				seq      : 1,
				title    : '상품 구성',
				icon     : 'fas fa-boxes-stacked',
				path     : '/admin/product/composition',
				badge    : '0',
				isOpen   : false,
				group    : "",
				children : []
			},
			{
				seq      : 2,
				title    : '상품 카테고리',
				icon     : 'fas fa-folder-tree',
				path     : '/admin/product/category',
				badge    : '0',
				isOpen   : false,
				group    : "",
				children : []
			},
		]
	},
	{
		seq      : 3,
		title    : '제품관리',
		icon     : 'fas fa-cubes',
		badge    : '0',
		isOpen   : false,
		path     : '#',
		group    : "ITEMS",
		children : [
			{
				seq      : 0,
				title    : '제품',
				icon     : 'fas fa-cube',
				path     : '/admin/item',
				badge    : '0',
				isOpen   : false,
				group    : "",
				children : []
			},
			{
				seq      : 1,
				title    : '제품 카테고리',
				icon     : 'fas fa-folder-tree',
				path     : '/admin/item/category',
				badge    : '0',
				isOpen   : false,
				group    : "",
				children : []
			}
		]
	},
	{
		seq      : 4,
		title    : '회원관리',
		icon     : 'fas fa-user-group',
		badge    : '0',
		isOpen   : false,
		path     : '#',
		group    : "MEMBER",
		children : [
			{
				seq      : 0,
				title    : '웹회원',
				icon     : 'fas fa-user',
				path     : '/admin/member',
				badge    : '0',
				isOpen   : false,
				group    : "",
				children : []
			},
		]
	},
	{
		seq      : 5,
		title    : '웹관리',
		icon     : 'fas fa-desktop',
		badge    : '0',
		isOpen   : false,
		path     : '#',
		group    : "WEBSITE",
		children : [
			{
				seq      : 0,
				title    : '1:1문의',
				icon     : 'fas fa-person-circle-question',
				path     : '/admin/contact',
				badge    : '0',
				isOpen   : false,
				group    : "",
				children : []
			},
			{
				seq      : 1,
				title    : '전화상담요청',
				icon     : 'fas fa-headset',
				path     : '/admin/callRequest',
				badge    : '0',
				isOpen   : false,
				group    : "",
				children : []
			},
			{
				seq      : 2,
				title    : '공지사항',
				icon     : 'fas fa-users-between-lines',
				path     : '/admin/notice',
				badge    : '0',
				isOpen   : false,
				group    : "",
				children : []
			},
			{
				seq      : 3,
				title    : 'FAQ',
				icon     : 'fas fa-circle-question',
				path     : '/admin/faq',
				badge    : '0',
				isOpen   : false,
				group    : "",
				children : []
			},
			{
				seq      : 4,
				title    : '이벤트',
				icon     : 'fas fa-calendar-days',
				path     : '/admin/event',
				badge    : '0',
				isOpen   : false,
				group    : "",
				children : []
			},
			/*
			{
				seq      : 5,
				title    : '이벤트 당첨자',
				icon     : 'fas fa-trophy',
				path     : '/admin/eventResult',
				badge    : '0',
				isOpen   : false,
				group    : "",
				children : []
			},
			*/
			{
				seq      : 6,
				title    : '제휴문의',
				icon     : 'fas fa-handshake-simple',
				path     : '/admin/businessRequest',
				badge    : '0',
				isOpen   : false,
				group    : "",
				children : []
			}
			
		]
	},
	{
		seq      : 6,
		title    : '설정관리',
		icon     : 'fas fa-sliders-h',
		badge    : '0',
		isOpen   : false,
		path     : '#',
		group    : "ADMIN",
		children : [
			{
				seq      : 0,
				title    : '관리자',
				icon     : 'fas fa-user-cog',
				path     : '/admin/administrator',
				badge    : '0',
				isOpen   : false,
				group    : "",
				children : []
			},
			{
				seq      : 1,
				title    : '운영정보',
				icon     : 'fas fa-file-shield',
				path     : '/admin/websiteInfo',
				badge    : '0',
				isOpen   : false,
				group    : "",
				children : []
			},			
		]
	},
]