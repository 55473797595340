<template>
    <Modal ref="baseModal" :title="`상품 상세보기`" :width="`95%`">
        <template #body>
            <div class="row">
                <div class="col-sm-4 border-right">
                    <div class="form-group row form-required">
                        <label for="productName" class="col-sm-2 col-form-label">상품명</label>
                        <div class="col-sm-10">
                            <div class="input-group">
                                <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                <span class="form-control">{{ productFormData.productName }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="productDescription" class="col-sm-2 col-form-label">간략 소개글</label>
                        <div class="col-sm-10">
                            <div class="input-group">
                                <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                <span class="form-control">{{ productFormData.productDescription }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row form-required">
                        <label for="productState" class="col-sm-2 col-form-label">사용여부</label>
                        <div class="col-sm-10">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-check-circle"></i></span>
                                </div>
                                <div class="form-control">
                                    <label v-for="(item, key) in storeState" :key="key" class="input-label mr-3" :for="`state_${key}`">
                                        <input type="radio"
                                            :id="`state_${key}`"
                                            name="productState"
                                            :value="key"
                                            v-model="productFormData.productState"
                                            disabled
                                        />
                                        <span class="radio-icon"></span>
                                        <span class="label-text">{{ item }}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="convertProductImages" class="col-sm-2 col-form-label">상품이미지</label>
                        <div class="col-sm-10">
                            <template v-if="Array.isArray(productFormData.convertProductImages) && productFormData.convertProductImages.length > 0">
                                <div class="file-upload">
                                    <div class="file-preview">
                                        <div v-for="(file, index) in productFormData.convertProductImages" :key="index" class="file-preview-item">
                                            <div class="file-preview-image-box">
                                                <template v-if="onHandleIsImage(file.upfilesExt)">
                                                    <img :src="`${imgURL}${file.upfilesThumnailPath}/${file.upfilesFileName}`" :alt="file.name" class="file-preview-image" />
                                                </template>
                                                <template v-else>
                                                    <div class="file-preview-file"><i class="fas fa-file-alt"></i></div>
                                                </template>
                                            </div>
                                            <p class="mb-0 file-name">{{ file.upfilesOriginalName }}.{{ file.upfilesExt }}</p>
                                            <p class="mb-0">({{ file.upfilesSize }} KB)</p>
                                            <button type="submit" class="btn btn-primary btn-xs" @click="onClickUpfileDownload(file)">다운로드</button>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-file-upload"></i></span>
                                    </div>
                                    <span class="form-control">첨부된 파일이 없습니다.</span>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="convertProductDescriptionImages" class="col-sm-2 col-form-label">상세정보</label>
                        <div class="col-sm-10">
                            <template v-if="Array.isArray(productFormData.convertProductDescriptionImages) && productFormData.convertProductDescriptionImages.length > 0">
                                <div class="file-upload">
                                    <div class="file-preview">
                                        <div v-for="(file, index) in productFormData.convertProductDescriptionImages" :key="index" class="file-preview-item">
                                            <div class="file-preview-image-box">
                                                <template v-if="onHandleIsImage(file.upfilesExt)">
                                                    <img :src="`${imgURL}${file.upfilesThumnailPath}/${file.upfilesFileName}`" :alt="file.name" class="file-preview-image" />
                                                </template>
                                                <template v-else>
                                                    <div class="file-preview-file"><i class="fas fa-file-alt"></i></div>
                                                </template>
                                            </div>
                                            <p class="mb-0 file-name">{{ file.upfilesOriginalName }}.{{ file.upfilesExt }}</p>
                                            <p class="mb-0">({{ file.upfilesSize }} KB)</p>
                                            <button type="submit" class="btn btn-primary btn-xs" @click="onClickUpfileDownload(file)">다운로드</button>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-file-upload"></i></span>
                                    </div>
                                    <span class="form-control">첨부된 파일이 없습니다.</span>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="convertProductViewCount" class="col-sm-2 col-form-label">노출수</label>
                        <div class="col-sm-10">
                            <div class="input-group">
                                <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-desktop"></i></span></div>
                                <span class="form-control">{{ productFormData.convertProductViewCount }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row form-required">
                        <label for="regMemberName" class="col-sm-2 col-form-label">등록정보</label>
                        <div class="col-sm-10">
                            <div class="input-group">
                                <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-user-pen"></i></span></div>
                                <span class="form-control justify-content-between">
                                    <span>{{ productFormData.regMemberName }} ({{ productFormData.regMemberId }})</span>
                                    <span>IP : {{ productFormData.regIp }}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row form-required">
                        <label for="convertRegDate" class="col-sm-2 col-form-label">등록일</label>
                        <div class="col-sm-10">
                            <div class="input-group">
                                <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-calendar-day"></i></span></div>
                                <span class="form-control">{{ productFormData.convertRegDate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row form-required" v-if="productFormData.upMemberName">
                        <label for="upMemberName" class="col-sm-2 col-form-label">수정정보</label>
                        <div class="col-sm-10">
                            <div class="input-group">
                                <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-user-pen"></i></span></div>
                                <span class="form-control justify-content-between">
                                    <span>{{ productFormData.upMemberName }} ({{ productFormData.upMemberId }})</span>
                                    <span>IP : {{ productFormData.upIp }}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row form-required" v-if="productFormData.convertUpDate">
                        <label for="convertUpDate" class="col-sm-2 col-form-label">수정일</label>
                        <div class="col-sm-10">
                            <div class="input-group">
                                <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-calendar-day"></i></span></div>
                                <span class="form-control">{{ productFormData.convertUpDate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 border-right">
                    <div class="form-group row form-required">
                        <label for="inputProductSale" class="col-sm-2 col-form-label">판매분류</label>
                        <div class="col-sm-10">
                            <div class="input-group">
                                <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-check-square"></i></span></div>
                                <span class="form-control">
                                    <label class="input-label mr-3" for="productSubscribe">
                                        <input type="checkbox"
                                            id="productSubscribe"
                                            value="1"
                                            :checked="productFormData.productSubscribe === 1"
                                            disabled
                                        />
                                        <span class="checkbox-icon"></span>
                                        <span class="label-text">구독</span>
                                    </label>
                                    <label class="input-label mr-3" for="productRental">
                                        <input type="checkbox"
                                            id="productRental"
                                            value="1"
                                            :checked="productFormData.productRental === 1"
                                            disabled
                                        />
                                        <span class="checkbox-icon"></span>
                                        <span class="label-text">렌탈</span>
                                    </label>
                                    <label class="input-label mr-3" for="productSale">
                                        <input type="checkbox"
                                            id="productSale"
                                            value="1"
                                            :checked="productFormData.productSale === 1"
                                            disabled
                                        />
                                        <span class="checkbox-icon"></span>
                                        <span class="label-text">판매</span>
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <template v-if="productFormData.productSubscribe === 1">
                        <div class="form-group row form-required">
                            <label for="inputProductSubscribeRegularPrice" class="col-sm-2 col-form-label">구독 정상가</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-1"></i></span></div>
                                    <span class="form-control">{{ productFormData.convertProductSubscribeRegularPrice }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row form-required">
                            <label for="inputProductSubscribePrice" class="col-sm-2 col-form-label">구독 판매가</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-1"></i></span></div>
                                    <span class="form-control">{{ productFormData.convertProductSubscribePrice }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row form-required">
                            <label for="inputProductSubscribeDeposit" class="col-sm-2 col-form-label">구독 보증금</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-1"></i></span></div>
                                    <span class="form-control">{{ productFormData.convertProductSubscribeDeposit }}</span>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-if="productFormData.productRental === 1">
                        <div class="form-group row form-required">
                            <label for="inputProductRentalRegularPrice" class="col-sm-2 col-form-label">렌탈형 구독 정상가(월)</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-1"></i></span></div>
                                    <span class="form-control">{{ productFormData.convertProductRentalRegularPrice }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row form-required">
                            <label for="inputProductRentalPrice" class="col-sm-2 col-form-label">렌탈형 구독 판매가(월)</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-1"></i></span></div>
                                    <span class="form-control">
                                        {{ productFormData.convertProductRentalPrice }}
                                        <span v-if="productFormData.convertProductRentalMonths > 0" class="ml-2">(1개월)</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row form-required">
                            <label for="inputProductRentalPrice" class="col-sm-2 col-form-label">렌탈형 구독 개월</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-1"></i></span></div>
                                    <span class="form-control">{{ productFormData.convertProductRentalMonths }}개월 
                                        <span v-if="productFormData.convertProductRentalMonths > 0" class="ml-2"> - {{ productFormData.convertProductRentalMonthPrice }}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row form-required">
                            <label for="inputProductRentalDeposit" class="col-sm-2 col-form-label">렌탈형 구독 보증금</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-1"></i></span></div>
                                    <span class="form-control">{{ productFormData.convertProductRentalDeposit }}</span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="productFormData.productSale === 1">
                        <div class="form-group row form-required">
                            <label for="inputProductSaleRegularPrice" class="col-sm-2 col-form-label">판매 정상가</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-1"></i></span></div>
                                    <span class="form-control">{{ productFormData.convertProductSaleRegularPrice }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row form-required">
                            <label for="inputProductSalePrice" class="col-sm-2 col-form-label">판매가</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-1"></i></span></div>
                                    <span class="form-control">{{ productFormData.convertProductSalePrice }}</span>
                                </div>
                            </div>
                        </div>
                    </template>

                    <hr />

                    <template v-for="(item, index) in productFormData.productDefaultItems" :key="index">
                        <div class="form-group row form-required mb-1">
                            <label for="inputProductSale" class="col-sm-2 col-form-label">기본사양({{ index + 1 }})</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-list-check"></i></span></div>
                                    <span class="form-control">{{ productFormData.productDefaultItemsFilter[index].itemName }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row" v-if="false">
                            <label for="inputProductSale" class="col-sm-2 col-form-label">&nbsp;</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                    <span class="form-control">{{ item.productItemName }}</span>
                                </div>
                            </div>
                        </div>
                    </template>

                    
                    <template v-for="(item, index) in productFormData.productOptionItems" :key="index">
                        <template v-if="productFormData.productOptionItems && productFormData.productOptionItems.length > 0 && item.productItemName">
                            <div class="form-group row mb-1">
                                <label for="inputProductSale" class="col-sm-2 col-form-label">
                                    추가옵션({{ index + 1 }})
                                </label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-list-check"></i></span>
                                        </div>
                                        <span class="form-control">{{ productFormData.productOptionItemsFilter[index].itemName }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1" v-if="false">
                                <label class="col-sm-2 col-form-label">&nbsp;</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                        <span class="form-control">{{ item.productItemName }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1" v-if="productFormData.productSubscribe === 1">
                                <label class="col-sm-2 col-form-label">&nbsp;</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-1"></i></span></div>
                                        <span class="form-control">{{ item.productItemSubscribePrice }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row" v-if="productFormData.productRental === 1">
                                <label class="col-sm-2 col-form-label">&nbsp;</label>
                                <div class="col-sm-5">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-1"></i></span></div>
                                        <span class="form-control">{{ item.productItemRentalPrice }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1" v-if="productFormData.productSale === 1">
                                <label class="col-sm-2 col-form-label">&nbsp;</label>
                                <div class="col-sm-5">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-1"></i></span></div>
                                        <span class="form-control">{{ item.productItemSalePrice }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>

                    <template v-for="(item, index) in productFormData.productServiceItems" :key="index">
                        <template v-if="productFormData.productServiceItems && productFormData.productServiceItems.length > 0 && item.productItemName">
                            <div class="form-group row mb-1">
                                <label for="inputProductSale" class="col-sm-2 col-form-label">
                                    서비스({{ index + 1 }})
                                </label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-list-check"></i></span>
                                        </div>
                                        <span class="form-control">{{ productFormData.productServiceItemsFilter[index].itemName }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1" v-if="false">
                                <label class="col-sm-2 col-form-label">&nbsp;</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
                                        <span class="form-control">{{ item.productItemName }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1" v-if="productFormData.productSubscribe === 1">
                                <label class="col-sm-2 col-form-label">&nbsp;</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-1"></i></span></div>
                                        <span class="form-control">{{ item.productItemSubscribePrice }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1" v-if="productFormData.productRental === 1">
                                <label class="col-sm-2 col-form-label">&nbsp;</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-1"></i></span></div>
                                        <span class="form-control">{{ item.productItemRentalPrice }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-1" v-if="productFormData.productSale === 1">
                                <label class="col-sm-2 col-form-label">&nbsp;</label>
                                <div class="col-sm-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-1"></i></span></div>
                                        <span class="form-control">{{ item.productItemSalePrice }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>

                </div>

                <div class="col-sm-4">
                    <div class="form-group row form-required">
                        <label for="inputProductPurpose" class="col-sm-2 col-form-label">상품용도</label>
                        <div class="col-sm-10">
                            <div class="input-group">
                                <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-check-square"></i></span></div>
                                <div class="form-control">
                                    <label class="input-label mr-3" for="productPurposeGaming">
                                        <input type="checkbox"
                                            id="productPurposeGaming"
                                            value="1"
                                            :checked="productFormData.productPurposeGaming === 1"
                                            disabled
                                        />
                                        <span class="checkbox-icon"></span>
                                        <span class="label-text">게이밍용</span>
                                    </label>
                                    <label class="input-label mr-3" for="productPurposeProfessional">
                                        <input type="checkbox"
                                            id="productPurposeProfessional"
                                            value="1"
                                            :checked="productFormData.productPurposeProfessional === 1"
                                            disabled
                                        />
                                        <span class="checkbox-icon"></span>
                                        <span class="label-text">전문가용</span>
                                    </label>
                                    <label class="input-label mr-3" for="productPurposeWork">
                                        <input type="checkbox"
                                            id="productPurposeWork"
                                            value="1"
                                            :checked="productFormData.productPurposeWork === 1"
                                            disabled
                                        />
                                        <span class="checkbox-icon"></span>
                                        <span class="label-text">업무용</span>
                                    </label>
                                    <label class="input-label mr-3" for="productPurposeStudy">
                                        <input type="checkbox"
                                            id="productPurposeStudy"
                                            value="1"
                                            :checked="productFormData.productPurposeStudy === 1"
                                            disabled
                                        />
                                        <span class="checkbox-icon"></span>
                                        <span class="label-text">학습용</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputProductIs" class="col-sm-2 col-form-label">상품표기</label>
                        <div class="col-sm-10">
                            <div class="input-group">
                                <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-check-square"></i></span></div>
                                <div class="form-control">
                                    <label class="input-label mr-3" for="productIsRecommend">
                                        <input type="checkbox"
                                            id="productIsRecommend"
                                            value="1"
                                            :checked="productFormData.productIsRecommend === 1"
                                            disabled
                                        />
                                        <span class="checkbox-icon"></span>
                                        <span class="label-text">추천상품</span>
                                    </label>
                                    <label class="input-label mr-3" for="productIsSpecialPrice">
                                        <input type="checkbox"
                                            id="productIsSpecialPrice"
                                            value="1"
                                            :checked="productFormData.productIsSpecialPrice === 1"
                                            disabled
                                        />
                                        <span class="checkbox-icon"></span>
                                        <span class="label-text">특가상품</span>
                                    </label>
                                    <label class="input-label mr-3" for="productIsPromotion">
                                        <input type="checkbox"
                                            id="productIsPromotion"
                                            value="1"
                                            :checked="productFormData.productIsPromotion === 1"
                                            disabled
                                        />
                                        <span class="checkbox-icon"></span>
                                        <span class="label-text">기획상품</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <template v-for="(item, index) in productFormData.convertProductCategory" :key="index">
                        <div class="form-group row">
                            <label for="inputProductCategory" class="col-sm-2 col-form-label">
                                카테고리({{ index + 1 }}) 
                            </label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-list-check"></i></span>
                                    </div>
                                    <span class="form-control">
                                        {{ getCategoryName(item.parentUid) }}
                                        {{ item.childUid ? ' > ' + getCategoryName(item.childUid) : '' }}
                                        {{ item.thirdUid ? ' > ' + getCategoryName(item.thirdUid) : '' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-for="(item, index) in productFormData.convertProductComposition" :key="index">
                        <div class="form-group row">
                            <label for="inputProductComposition" class="col-sm-2 col-form-label">
                                상품구성({{ index + 1 }}) 
                            </label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-list-check"></i></span>
                                    </div>
                                    <span class="form-control">
                                        {{ getCompositionName(item.parentUid) }}
                                        {{ item.childUid ? ' > ' + getCompositionName(item.childUid) : '' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div class="form-group row">
                        <label for="inputContent" class="col-sm-2 col-form-label">비고</label>
                        <div class="col-sm-10">
                            <div class="input-content text-sm" v-html="productFormData.convertProductNotes"></div>
                        </div>
                    </div>

                </div>
            </div>
        </template>

        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">닫기</button>
			<button type="submit" class="btn btn-primary" @click="onClickMovementUpdate">수정</button>
        </template>
	</Modal>

	<alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { numberFormat  } from '@/helper/utils/etcUtils.js'
import { unescapeHtmlEntities  } from '@/helper/utils/etcUtils.js'
import Modal from '@/modals/Modal'
import Alert from '@/modals/common/Alert'

export default defineComponent({
    name       : 'modalAdministratorDetailPage',
    components: {
		Modal,
		'alert' : Alert
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){
		const { openModal, closeModal, baseModal, $axiosConnect, $fileDownload, imgURL, storeState, storeProductPurpose,  onHandleIsImage } = commonMixin()

        const mode            = ref('detail')
        const closeModalData  = ref({})
        const alertRef        = ref(null)

        const productFormData = ref({})
        const compositionList = ref([])
        const categoryList    = ref([])

		const open = (payload) => {
            initialize()

            if (payload.item && payload.mode === 'detail') {
                productFormData.value = JSON.parse(JSON.stringify(payload.item))
                mode.value = payload.mode
                getDeatil()
            }
            return openModal()
		}

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			closeModal(closeModalData.value)
		}

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
        const onClickMovementUpdate = () => {
            closeModalData.value = {
                isUpdate : true,
                item     : productFormData.value
            }
            close()
        }

        const getDeatil = async () => {
            try {
                const uid = productFormData.value.uid
                const response = await $axiosConnect('MEMBER', `/admin/product/${uid}`, 'GET')

                if (response.success && response.body && response.body.list.length > 0) {

                    const list = JSON.parse(JSON.stringify(response.body.list[0]))
                    productFormData.value = {
                        ...list,
                        converUid                           : list.uid.toString().padStart(8, '0'),
                        convertRegDate                      : convertDateToStr(list.regDate, '', 'Hi'),
                        convertUpDate                       : convertDateToStr(list.upDate, '', 'Hi'),
                        convertProductSubscribeRegularPrice : numberFormat(list.productSubscribeRegularPrice),
                        convertProductSubscribePrice        : numberFormat(list.productSubscribePrice),
                        convertProductSubscribeDeposit      : numberFormat(list.productSubscribeDeposit),
                        convertProductRentalRegularPrice    : numberFormat(list.productRentalRegularPrice),
                        convertProductRentalPrice           : numberFormat(list.productRentalPrice),
                        convertProductRentalMonths          : numberFormat(list.productRentalMonths),
                        convertProductRentalMonthPrice      : numberFormat(list.productRentalPrice*list.productRentalMonths),
                        convertProductRentalDeposit         : numberFormat(list.productRentalDeposit),
                        convertProductSaleRegularPrice      : numberFormat(list.productSaleRegularPrice),
                        convertProductSalePrice             : numberFormat(list.productSalePrice),
                        convertProductViewCount             : numberFormat(list.productViewCount),
                        convertProductImages                : list.upfiles.filter(file => file.upfilesColumn === 'productImages'),
                        convertProductDescriptionImages     : list.upfiles.filter(file => file.upfilesColumn === 'productDescriptionImages'),
                        productDefaultItems                 : list.productItems.filter(item => item.itemType === 1).length ? list.productItems.filter(item => item.itemType === 1) : [{}],
                        productOptionItems                  : list.productItems.filter(item => item.itemType === 2).length ? list.productItems.filter(item => item.itemType === 2) : [{}],
                        productServiceItems                 : list.productItems.filter(item => item.itemType === 3).length ? list.productItems.filter(item => item.itemType === 3) : [{}],
                        productDefaultItemsFilter           : list.productItems.filter(item => item.itemType === 1).length ? list.productItems.filter(item => item.itemType === 1).map(({ itemName, itemType, itemUid }) => ({ itemName, itemType, itemUid })) : [{}],
                        productOptionItemsFilter            : list.productItems.filter(item => item.itemType === 2).length ? list.productItems.filter(item => item.itemType === 2).map(({ itemName, itemType, itemUid }) => ({ itemName, itemType, itemUid })) : [{}],
                        productServiceItemsFilter           : list.productItems.filter(item => item.itemType === 3).length ? list.productItems.filter(item => item.itemType === 3).map(({ itemName, itemType, itemUid }) => ({ itemName, itemType, itemUid })) : [{}],
                        convertProductNotes                 : list.productNotes?unescapeHtmlEntities(list.productNotes):'&nbsp;',
                        convertProductComposition           : list.productCompositionAll ? list.productCompositionAll.split(',').map(item => { const [parentUid, childUid] = item.split('/').map(Number); return { parentUid, childUid }; }).filter(item => item.parentUid || item.childUid) : [],
                        convertProductCategory              : list.productCategoryAll ? list.productCategoryAll.split(',').map(item => { const [parentUid, childUid, thirdUid] = item.split('/').map(Number); return { parentUid, childUid, thirdUid }; }).filter(item => item.parentUid || item.childUid || item.thirdUid) : [],
                    }

                }else{
                    alertRef.value.open({ 'contents' : response.message })
                    close()
                }
            } catch (error) {
                console.error("error:", error)
            }

        }

        const onClickUpfileDownload = async (upfile) => {
            try {
                const uid = upfile.uid
                await $fileDownload(upfile.upfilesOriginalName + '.' + upfile.upfilesExt, `/common/uploads/download/${uid}`)
            } catch (error) {
                console.error("error:", error)
            }
        }

        const getCompositionList = async () => {
            try {
                let defaultParams = {
                    'page'      : 1,
                    'pageSize'  : 1000,
                    'compositionState' : 1
                }
                const response = await $axiosConnect('MEMBER', `/admin/compositionProduct`, 'GET', defaultParams)

                let list = []
                if(response.body){
                    list = JSON.parse(JSON.stringify(response.body.list))
                    if(list.length > 0){
                        compositionList.value = list.map(({ uid, compositionName, groups, depth }) => {
                            return {
                                compositionUid    : uid,
                                compositionName   : compositionName,
                                compositionGroups : groups,
                                compositionDepth  : depth
                            }
                        })
                    }
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const getCompositionName = (uid) => {
            const composition = compositionList.value.find(composition => composition.compositionUid === uid)
            if(composition){
                return composition.compositionName
            }
            return ''
        }

        const getCategoryList = async () => {
            try {
                let defaultParams = {
                    'page'      : 1,
                    'pageSize'  : 1000,
                    'compositionState' : 1
                }
                const response = await $axiosConnect('MEMBER', `/admin/categoryProduct`, 'GET', defaultParams)

                let list = []
                if(response.body){
                    list = JSON.parse(JSON.stringify(response.body.list))
                    if(list.length > 0){
                        categoryList.value = list.map(({ uid, categoryName, groups, depth }) => {
                            return {
                                categoryUid    : uid,
                                categoryName   : categoryName,
                                categoryGroups : groups,
                                categoryDepth  : depth
                            }
                        })
                    }
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const getCategoryName = (uid) => {
            const category = categoryList.value.find(category => category.categoryUid === uid)
            if(category){
                return category.categoryName
            }
            return ''
        }

        const initialize = async () => {
            productFormData.value = {
                productName                : '',
                productDescription         : '',
                productNotes               : '',
                productState               : 1,
                productImages              : [],
                productDescriptionImages   : [],
                productIsRecommend         : 0,
                productIsSpecialPrice      : 0,
                productIsPromotion         : 0,
                productPurposeGaming       : 1,
                productPurposeProfessional : 0,
                productPurposeWork         : 0,
                productPurposeStudy        : 0,

                convertProductNotes        : '',
            }

            closeModalData.value = {
                isUpdate : false,
                item     : ''
            }

            await getCompositionList()
            await getCategoryList()
        }

        onMounted(async () => {
            await initialize()
        })

        return {
			open,
			close,
            mode,

			alertRef,
			productFormData,
            compositionList,
            categoryList,

            onClickMovementUpdate,
            onClickUpfileDownload,
            getCompositionName,
            getCategoryName,

            //commonMixin
            baseModal, 
            imgURL,
            storeState,
            storeProductPurpose,
            onHandleIsImage
		}
    }
})
</script>