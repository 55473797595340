<template>
    <Modal ref="baseModal" :title="`로그인 이동`" :width="`300px`" :isDrag="false" @close="close">
        <template #body>
            <div>
                로그인이 필요한 서비스입니다.<br>
				로그인 페이지로 이동하시겠습니까?
            </div>
        </template>
        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">닫기</button>
			<button type="submit" class="btn btn-primary-1" :disabled="submitButtonDisabled" @click="onClickSave">로그인</button>
        </template>
	</Modal>
</template>

<script>
import { defineComponent, ref } from 'vue';
import Modal from '@/modals/Modal'

export default defineComponent({
    name       : 'modalSignInConfirmPage',
    components: {
		Modal
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){

        const baseModal = ref(null)
		const resolvePromise = ref(null)

        const submitButtonDisabled  = ref(false)
        const closeModalData        = ref({})
        
        const initialize = async () => {
			//
        }

		const open = async () => {
            closeModalData.value = {
                isUpdate : false,
                item     : ''
            }

            //Open Modal
			baseModal.value.openModal()
            await initialize()
			return new Promise((resolve) => {
				resolvePromise.value = resolve;
			});
		};

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			baseModal.value.closeModal()
			resolvePromise.value(closeModalData.value)
		};

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onClickSave = () => {
            closeModalData.value = {
                isUpdate : true,
                item     : ''
            }
            close()
        }

        return {
			baseModal, 
			open,
			close,
            onClickSave,
			submitButtonDisabled
		}
    }
});
</script>