<template>
    <div class="preloader" v-if="isDataLoading">
        <div class="preload-section">
            <div class="preload-container">
                <div class="preload-card">
                    <figure class="preload-card-rotate"><img src="@/assets/images/common/logo_eng.png"/></figure>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
    setup() {
        const store = useStore();
        const isPageLoading = computed(() => store.getters["common/getIsPageLoading"]);
        const isDataLoading = computed(() => store.getters["common/getIsDataLoading"]);

        return {
            isPageLoading,
            isDataLoading
        };
    }
})
</script>

<style>
/*
 * preLoader
 */
.preloader {
    /*background-color: rgba(0,0,0,0.6);*/
    background-color: transparent;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    resize: both;
    overflow: auto;
    z-index: 19999;
    position: fixed;
    top: 0;
    left: 0;
}
.preload-section {
    position:fixed;
    top: 50%;
    margin-top: -50px;
    width: 100%;
    resize: both;
}
.preload-container {
    width: auto;
    position: relative;
    perspective: 400px;
    margin: 0 auto;
    text-align:center;
}
.preload-card {
    transform-style: preserve-3d;
    transition: transform 1s;
    transition: -webkit-transform 1s;
}

/* WebKit and Opera browsers */
@-webkit-keyframes card {
    from { -webkit-transform: rotateY(0deg);    }
    to   { -webkit-transform: rotateY(-360deg); }
}

/* all other browsers */
@keyframes card {
    from {
        -moz-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    to {
        -moz-transform: rotateY(-360deg);
        -ms-transform: rotateY(-360deg);
        transform: rotateY(-360deg);
    }
}

.preload-card-rotate {
    -webkit-animation-name: card;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 3s;

    animation-name: card;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 3s;

    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;

    white-space: nowrap;
    font-size: 30px;
    color: #FFF;
}
.preload-card-rotate img {
    max-width: 150px;
    padding: 10px;
    background-color: transparent;
}
</style>
