/**
 * 주어진 초 단위의 시간을 MM:SS 형식으로 변환합니다.
 * @param {number} count - 변환할 초 단위의 시간
 * @returns {string} - 변환된 시간 문자열 (MM:SS 형식)
 */
export const convertTimeToStr = (count) => {
    try {
        const minutes = Math.floor(count / 60)
        const seconds = Math.floor(count % 60)
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds
        return `${minutes < 10 ? '0' : ''}${minutes}:${formattedSeconds}`
    } catch (e) {
        console.error(e)
        return count
    }
}

/**
 * Unix 타임스탬프를 지정된 형식의 날짜 문자열로 변환합니다.
 * @param {number} timestamp - Unix 타임스탬프 (초 단위)
 * @param {string} [dateType=''] - 날짜 형식 ('YYYY-MM-DD' 또는 빈 문자열)
 * @param {string} [timeType=''] - 시간 형식 ('HH:MM' 또는 빈 문자열)
 * @returns {string} - 변환된 날짜 및 시간 문자열
 */
export const convertDateToStr = (timestamp, dateType = '', timeType = '') => {
    try {
        if (typeof timestamp !== 'number'){
            timestamp = parseInt(timestamp)
        }
        if (timestamp <= 0) {
            return
        }
        const isMilliseconds = timestamp > 1000000000000
        const date = new Date(isMilliseconds ? timestamp : timestamp * 1000)

        const year    = date.getFullYear()
        const month   = date.getMonth() + 1
        const day     = date.getDate()
        const hours   = date.getHours()
        const minutes = date.getMinutes()

        const formattedMonth   = month < 10 ? `0${month}` : month
        const formattedDay     = day < 10 ? `0${day}` : day
        const formattedHours   = hours < 10 ? `0${hours}` : hours
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes

        // 기본 날짜 형식
        let dateStr = dateType 
            ? `${year}-${formattedMonth}-${formattedDay}`
            : `${year}년 ${formattedMonth}월 ${formattedDay}일`

        // 시간 추가 여부
        if (timeType) {
            const timeStr = timeType 
                ? `${formattedHours}:${formattedMinutes}` 
                : `${formattedHours}시 ${formattedMinutes}분`
            dateStr += ` ${timeStr}`
        }

        return dateStr
    } catch (e) {
        console.error(e)
        return
    }
}

/**
 * 날짜 문자열을 Unix 타임스탬프로 변환합니다.
 * @param {string} dateStr - 변환할 날짜 문자열 (형식: 'YYYY-MM-DDTHH:MM', 'YYYY-MM-DD HH:MM', 'YYYY-MM-DD')
 * @returns {number|null} - 변환된 Unix 타임스탬프 (초 단위) 또는 오류 발생 시 null
 */
export const convertStrToUnixTime = (dateStr) => {
    try {
        let [datePart, timePart] = dateStr.includes('T') ? dateStr.split('T') : dateStr.split(' ');

        // YYYY-MM-DD 형식의 경우 시간 부분을 기본값으로 설정
        if (!timePart) {
            timePart = '00:01'; // 기본 시간을 00:01으로 설정
        }

        const [year, month, day] = datePart.split('-').map(Number)
        const [hours, minutes] = timePart.split(':').map(Number)
        const date = new Date(year, month - 1, day, hours, minutes)
        return Math.floor(date.getTime() / 1000)
    } catch (e) {
        console.error(e)
        return null
    }
}

/**
 * Unix 타임스탬프를 지정된 형식의 날짜 문자열로 변환합니다.
 * @param {number} timestamp - Unix 타임스탬프 (초 단위)
 * @param {string} [format='iso'] - 날짜 형식 ('iso' 또는 빈 문자열)
 * @returns {string|null} - 변환된 날짜 및 시간 문자열 (형식: 'YYYY-MM-DDTHH:MM' 또는 'YYYY-MM-DD HH:MM')
 */
export const convertUnixTimeToStr = (timestamp, format = 'iso') => {
    try {
        const date    = new Date(timestamp * 1000)
        const year    = date.getFullYear()
        const month   = String(date.getMonth() + 1).padStart(2, '0')
        const day     = String(date.getDate()).padStart(2, '0')
        const hours   = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')

        return `${year}-${month}-${day}${format === 'iso' ? 'T' : ' '}${hours}:${minutes}`
    } catch (e) {
        console.error(e)
        return null
    }
}

/**
 * 현재 로컬 날짜와 시간을 'YYYY-MM-DDTHH:MM' 형식으로 반환합니다.
 * @param {string} [format='iso'] - 날짜 형식 ('iso' 또는 빈 문자열)
 * @returns {string} - 현재 날짜 및 시간 문자열
 */
export const getCurrentLocalDateTimeISO = (format = 'iso') => {
    const now     = new Date()
    const year    = now.getFullYear()
    const month   = String(now.getMonth() + 1).padStart(2, '0')
    const day     = String(now.getDate()).padStart(2, '0')
    const hours   = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')
    //const seconds = String(now.getSeconds()).padStart(2, '0')
    //const milliseconds = String(now.getMilliseconds()).padStart(3, '0')

    return `${year}-${month}-${day}${format === 'iso' ? 'T' : ' '}${hours}:${minutes}`
}

/**
 * 두 Unix 타임스탬프 간의 차이를 년, 개월, 일 단위로 반환합니다.
 * @param {number} startTimestamp - 시작 Unix 타임스탬프 (초 단위)
 * @param {number} endTimestamp - 종료 Unix 타임스탬프 (초 단위, 기본값: 현재 시간)
 * @returns {Object} - { years, months, days } 차이 객체
 */
export const getDateDifference = (startTimestamp, endTimestamp = Math.floor(Date.now() / 1000)) => {
    try {
        if (typeof startTimestamp !== 'number' || typeof endTimestamp !== 'number') {
            throw new Error('타임스탬프는 숫자여야 합니다.')
        }

        const startDate = new Date(startTimestamp * 1000)
        const endDate   = new Date(endTimestamp * 1000)

        let years  = endDate.getFullYear() - startDate.getFullYear()
        let months = endDate.getMonth() - startDate.getMonth()
        let days   = endDate.getDate() - startDate.getDate()

        // 일 단위 차이가 음수면 한 달 차이 조정
        if (days < 0) {
            months -= 1
            const previousMonthEnd = new Date(endDate.getFullYear(), endDate.getMonth(), 0)
            days += previousMonthEnd.getDate()
        }

        // 월 단위 차이가 음수면 년 단위 차이 조정
        if (months < 0) {
            years -= 1
            months += 12
        }

        let text = years > 0 ? years + '년 ' : ''
        text += months > 0 ? months + '개월 ' : ''
        text += days > 0 ? days + '일' : ''

        if(startDate > endDate){
            text = ''
        }

        return text
    } catch (e) {
        console.error(e)
        return null
    }
}

export const getDateDifferenceInSeconds = (startTimestamp, endTimestamp = Math.floor(Date.now() / 1000)) => {
    try {
        if (typeof startTimestamp !== 'number' || typeof endTimestamp !== 'number') {
            throw new Error('타임스탬프는 숫자여야 합니다.');
        }

        // 만약 startTimestamp가 0인 경우, 특별히 처리하고 싶다면 여기서 처리
        if (startTimestamp === 0) {
            return 0;
        }

        // startTimestamp와 endTimestamp 간의 차이를 초 단위로 계산
        const differenceInSeconds = endTimestamp - startTimestamp;

        // 차이를 초 단위로 반환 (양수면 과거, 음수면 미래)
        return differenceInSeconds;
    } catch (e) {
        console.error(e);
        return null;
    }
}