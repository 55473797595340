<template>
    <Modal ref="baseModal" :title="`관리자 ${mode === 'create' ? '추가' : '수정'}`" :width="`800px`">
        <template #body>
			<form ref="singUpFormRef" @submit.prevent="onClickSave">
				<div class="form-group row form-required">
					<label for="inputAdministratorName" class="col-sm-2 col-form-label">사용자명</label>
					<div class="col-sm-10">
						<div class="input-group">
							<div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
							<input type="text" class="form-control" id="inputAdministratorName" placeholder="사용자명을 입력해주세요." autocomplete="off" tabindex="1"
							required 
							maxlength="50" 
                            pattern-hint="사용자명은 필수로 입력해야 합니다"
							v-model="singUpFormData.memberName"
							@input="onInputCheckEvent" 
							/>
						</div>
                        <small class="input-hint"></small>
					</div>
				</div>
                <div class="form-group row form-required">
					<label for="inputAdministratorName" class="col-sm-2 col-form-label">아이디</label>
					<div class="col-sm-10">
						<div class="input-group">
							<div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
							<input type="text" class="form-control" id="inputAdministratorName" placeholder="아이디를 입력해주세요." autocomplete="off" tabindex="2"
							required 
                            maxlength="15" 
                            pattern="^[a-z][a-z0-9]{5,15}$" 
                            pattern-hint="아이디는 영문 소문자로 시작하고 영문 소문자와 숫자를 합친 6~15자 입니다."
							v-model="singUpFormData.memberId"
							@input="onInputCheckEvent" 
							/>
						</div>
                        <small class="input-hint"></small>
					</div>
				</div>
                <div class="form-group row form-required">
					<label for="inputAdministratorName" class="col-sm-2 col-form-label">비밀번호</label>
					<div class="col-sm-10">
						<div class="input-group">
							<div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-keyboard"></i></span></div>
							<input type="text" class="form-control" id="inputAdministratorName" placeholder="비밀번호를 입력해주세요." autocomplete="off" tabindex="3"
							:required="mode==='create'"
                            maxlength="20" 
                            pattern="^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{8,20}$" 
                            pattern-hint="비밀번호는 최소 1개의 숫자 또는 특수문자를 포함한 영문자 8~20자 입니다."
                            v-model="singUpFormData.memberPassword" 
							@input="onInputCheckEvent" 
							/>
						</div>
                        <small class="input-hint-block" v-if="mode==='update'">비밀번호 수정일 필요할때만 입력하세요.</small>
                        <small class="input-hint"></small>
					</div>
				</div>
                
                <div class="form-group row form-required">
                    <label for="inputMemberAuthority" class="col-sm-2 col-form-label">권한</label>
                    <div class="col-sm-10">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-check-circle"></i></span>
                            </div>
                            <div class="form-control">
                                <template v-for="(item, key) in storeAuthority" :key="key">
                                    <label class="input-label mr-3" :for="`memberAuthority_${key}`" v-if="key>=90">
                                        <input type="radio"
                                            :id="`memberAuthority_${key}`"
                                            name="memberAuthority"
                                            :value="key"
                                            v-model="singUpFormData.memberAuthority"
                                            @input="onInputCheckEvent" 
                                            required
                                            pattern-hint="권한은 필수로 선택해야 합니다"
                                        />
                                        <span class="radio-icon"></span>
                                        <span class="label-text">{{ item }}</span>
                                    </label>
                                </template>
                            </div>
                        </div>
                        <small class="input-hint"></small>
                    </div>
                </div>

                <div class="form-group row form-required">
                    <label for="inputMemberState" class="col-sm-2 col-form-label">사용여부</label>
                    <div class="col-sm-10">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-check-circle"></i></span>
                            </div>
                            <div class="form-control">
                                <label v-for="(item, key) in storeState" :key="key" class="input-label mr-3" :for="`memberState_${key}`">
                                    <input type="radio"
                                        :id="`memberState_${key}`"
                                        name="memberState"
                                        :value="key"
                                        v-model="singUpFormData.memberState"
                                        @input="onInputCheckEvent" 
                                        required
                                        pattern-hint="사용여부는 필수로 선택해야 합니다"
                                    />
                                    <span class="radio-icon"></span>
                                    <span class="label-text">{{ item }}</span>
                                </label>
                            </div>
                        </div>
                        <small class="input-hint"></small>
                    </div>
                </div>

                <div class="form-group row">
					<label for="selectAdministratorState" class="col-sm-2 col-form-label">파일</label>
					<div class="col-sm-10">
						<div class="input-group">
							<component-form-file-upload 
                                ref="fileUploadRef"
                                :maxSize="10240"
                                accept="png"
                                :initialFiles = singUpFormData.upfiles
                                @on-click-attach-file="onClickAttachFile"
                            />
						</div>
					</div>
				</div>
			</form>
        </template>
        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">닫기</button>
			<button type="submit" class="btn btn-primary" :disabled="submitButtonDisabled" @mouseenter="onOverSubmitButton" @click="onClickSave()">{{ mode === 'create'?'저장':'수정' }}</button>
        </template>
	</Modal>

	<alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { encryptPassword } from '@/helper/plugins/security.js'
import Modal from '@/modals/Modal'
import Alert from '@/modals/common/Alert'

import ComponentFormFileUpload from "@/components/admin/ComponentFormFileUpload"

export default defineComponent({
    name       : 'modalAdministratorUpdatePage',
    components: {
		Modal,
		'alert' : Alert,
        'component-form-file-upload' : ComponentFormFileUpload,
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){
		const { onHandleValidateInput, $axiosConnect, store } = commonMixin()

        const baseModal            = ref(null)
		const resolvePromise       = ref(null)

        const submitButtonDisabled = ref(true)
        const mode                 = ref('create')
        const closeModalData       = ref({})
        const alertRef             = ref(null)

        const storeAuthority       = ref({})
        const storeState           = ref({})

        const singUpFormRef        = ref(null)
        const singUpFormData       = ref({
            memberName              : '',
            memberId                : '',
            memberPassword          : '',
            memberPasswordEncrypted : '',
            memberAuthority         : 90,
            memberPasswordState     : 1,
            memberState             : 1,
            upfiles                 : []
        })

        const uploadedFiles = ref([])

        onMounted(() => {
            storeAuthority.value = store.getters["member/getAuthority"]
            storeState.value     = store.getters["common/getState"]
        })

		const open = (payload) => {

            mode.value = 'create'
            singUpFormData.value = {
                memberName              : '',
                memberId                : '',
                memberPassword          : '',
                memberPasswordEncrypted : '',
                memberAuthority         : 90,
                memberPasswordState     : 1,
                memberState             : 1,
                upfiles                 : []
            }

            if (payload.item && payload.mode === 'update') {
                singUpFormData.value = JSON.parse(JSON.stringify(payload.item))
                mode.value = payload.mode
                getDeatil()
            }else{
                if(payload.item && payload.item.uid){
                    singUpFormData.value.parentUid = payload.item.uid
                }
            }

            closeModalData.value = {
                isUpdate : false,
                item     : ''
            }

            //Open Modal
			baseModal.value.openModal()
			return new Promise((resolve) => {
				resolvePromise.value = resolve;
			});
		};

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			baseModal.value.closeModal()
			resolvePromise.value(closeModalData.value)
		};
        
		/**
         * 입력 값의 유효성을 검사하고, 암호화된 아이디와 비밀번호를 생성하는 메서드
         * 유효성 검사 결과에 따라 submitDisabled 속성을 설정합니다.
         * @method onInputCheckEvent
         * @returns {void}
         */
         const onInputCheckEvent = () => {
            onHandlePasswordEncrypt()
            return !(submitButtonDisabled.value = !onHandleValidateInput(singUpFormRef.value))
        }

        /**
         * 입력된 비밀번호를 암호화하는 메서드
         * @method onHandleEncrypt
         * @returns {void}
         */
         const onHandlePasswordEncrypt = async () => {
            singUpFormData.value.memberPasswordEncrypted = await encryptPassword(singUpFormData.value.memberPassword)
        }

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onOverSubmitButton = () => {
            const formElements = singUpFormRef.value.querySelectorAll('input, select, textarea')
            formElements.forEach(input => {
                input.blur()
            });
        }

        /**
         * 한글 입력을 방지하고자 하는 텍스트 필드에서 호출되는 메서드입니다.
         * 입력된 값 중 한글이 감지되면 해당 한글 문자를 제거하고, 제거한 값으로 텍스트 필드를 업데이트합니다.
         * @method onHandValidateKorean
         * @param {Event} event - 발생한 이벤트 객체
         * @returns {void}
         */
         const onClickSave = async () => {
            let newSingUpFormData = { ...singUpFormData.value };
            if(!newSingUpFormData.memberPassword){
                delete newSingUpFormData.memberPassword
                delete newSingUpFormData.memberPasswordEncrypted
            }
            if(onInputCheckEvent()){
                const formData = new FormData();
                for (const key in newSingUpFormData) {
                    if (newSingUpFormData[key] !== '') {
                        formData.set(key, newSingUpFormData[key]);
                    }
                }

                // 파일들을 FormData에 추가
                uploadedFiles.value.forEach((file, index) => {
                    formData.append(`files[${index}]`, file)
                })

                onProcessSave(formData)
            }
        }

        /**
         * 로그인 프로세스를 처리하는 메서드
         * @method onProcessSignIn
         * @param {FormData} formData - 로그인에 필요한 폼 데이터
         * @returns {void}
         */
        const onProcessSave = async (formData) => {
            try {

                let response
                if(mode.value === 'create'){
                    response = await $axiosConnect('MEMBER', `/admin/administrator`, 'POST', formData)
                }else{
                    const uid = singUpFormData.value.uid
                    response = await $axiosConnect('MEMBER', `/admin/administrator/${uid}`, 'POST', formData)
                }

                if (response.success && response.body && response.body.list.length > 0) {
                    
                    singUpFormData.value = {
                        memberName              : '',
                        memberId                : '',
                        memberPassword          : '',
                        memberPasswordEncrypted : '',
                        memberAuthority         : 90,
                        memberPasswordState     : 1,
                        memberState             : 1,
                        upfiles                 : []
                    }

                    closeModalData.value = {
                        isUpdate : true,
                        item     : response.body.list[0]
                    }

                    alertRef.value.open({ 
                        'contents' : response.message,
                        'callback' : close
                    })

                }else{
                    alertRef.value.open({ 'contents' : response.message })
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

        const getDeatil = async () => {
            try {
                const uid = singUpFormData.value.uid
                const response = await $axiosConnect('MEMBER', `/admin/administrator/${uid}`, 'GET')

                if (response.success && response.body && response.body.list.length > 0) {

                    const detail = JSON.parse(JSON.stringify(response.body.list[0]))
                    singUpFormData.value = {
                        ...detail,
                    }

                    if(mode.value === 'update'){
                        singUpFormData.value.memberPassword = ''
                    }
                }else{
                    alertRef.value.open({ 'contents' : response.message })
                    close()
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const onClickAttachFile = (filesInfo) => {
            uploadedFiles.value = filesInfo.map(fileInfo => fileInfo.file)

            singUpFormData.value.uploadedFilesOrder = []
            filesInfo.forEach(fileInfo => {
                singUpFormData.value.uploadedFilesOrder.push({
                    "name"  : fileInfo.upfilesOriginalName?fileInfo.upfilesOriginalName:fileInfo.name,
                    "order" : fileInfo.order
                })
            })
            singUpFormData.value.uploadedFilesOrder = JSON.stringify(singUpFormData.value.uploadedFilesOrder)

            onInputCheckEvent()
        }

        return {
			baseModal, 
			open,
			close,
            mode,
            submitButtonDisabled,

			alertRef,
			singUpFormRef,
			singUpFormData,
            storeAuthority,
            storeState,

			onInputCheckEvent,
            onOverSubmitButton,
            onClickSave,
            onClickAttachFile
		}
    }
});
</script>