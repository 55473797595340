<template>
    <main>
        <div class="container">
            <div class="content mt-5">
                <div class="container-fluid">
					<div class="row">
						<div class="col-12">
							<h3 class="d-inline-block font-weight-bold text-lg">{{ productFormData.convertProductName }}</h3>
							<p class="text-sm mb-1">{{ productFormData.productDescription }}</p>
							<div>
								<template v-if="productFormData.productSubscribe === 1"><span class="product-item-subscribe-text">구독</span></template>
								<template v-if="productFormData.productRental === 1"><span class="product-item-rental-text">렌탈형구독</span></template>
								<template v-if="productFormData.productSale === 1"><span class="product-item-sale-text">판매</span></template>
								<template v-if="productFormData.productPurposeGaming === 1"><span class="product-purpose-gaming">게이밍용</span></template>
								<template v-if="productFormData.productPurposeProfessional === 1"><span class="product-purpose-professional">전문가용</span></template>
								<template v-if="productFormData.productPurposeWork === 1"><span class="product-purpose-work">업무용</span></template>
								<template v-if="productFormData.productPurposeStudy === 1"><span class="product-purpose-study">학습용</span></template>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-sm-8">
							<div class="row">
								<div class="col-12 p-0">
									<div class="product-detail-image-content">
										<swiper
											ref="swiperRef"
											:spaceBetween   = "20"
											:slidesPerView  = "1"
											:autoplay="{
												delay: 8000,
												disableOnInteraction: false
											}"
											:loop = swiperLoop
											:loopedSlides = "1"
											:modules="modules"
											:pagination="{ clickable: true }"
											@swiper="onSwiper"
										>
										<template v-if="productFormData.convertProductImages && productFormData.convertProductImages.length > 0 ">
												<template v-for="(item, index) in productFormData.convertProductImages" :key="index">
													<swiper-slide>
														<div class="product-detail-image-item" data-aos-delay="100" >
															<picture class="product-detail-image">
																<img class="absolute-center" 
																	:src="`${imgURL}${item.upfilesThumnailPath}/${item.upfilesFileName}`" 
																	:alt="item.name"
																/>
															</picture>
														</div>
													</swiper-slide>
												</template>
											</template>
											<template v-else>
												<swiper-slide>
													<div class="product-detail-image-item" data-aos-delay="100" >
														<picture class="product-detail-image">
															<img class="absolute-center" 
																src="@/assets/images/common/logo_kor.png"
																:alt="productFormData.convertProductName"
															/>
														</picture>
													</div>
												</swiper-slide>
											</template>
										</swiper>
									</div>
								</div>
							</div>

							<!--content-->
							<div class="row mobile-hide">
								<div class="col-12">
									<div class="row mt-2 mb-2">
										<div class="col-sm-12 pl-0 pr-0">
											<nav>
												<div class="nav nav-tabs" id="product-tab" role="tablist">
													<a
														class="nav-item nav-link"
														:class="{ active: activeTab === 'description' }"
														@click="onSetActiveTab('description')"
														role="button"
														aria-selected="true"
													>상세정보</a>
													<a
														class="nav-item nav-link"
														:class="{ active: activeTab === 'note' }"
														@click="onSetActiveTab('note')"
														role="button"
														aria-selected="false"
													>유의사항</a>
													<a
														class="nav-item nav-link"
														:class="{ active: activeTab === 'review' }"
														@click="onSetActiveTab('review')"
														role="button"
														aria-selected="false"
													>사용자 리뷰</a>
													<a
														class="nav-item nav-link"
														:class="{ active: activeTab === 'recommend' }"
														@click="onSetActiveTab('recommend')"
														role="button"
														aria-selected="false"
													>추천상품</a>
												</div>
											</nav>
										</div>
									</div>
									<div class="row justify-content-center pb-6 mb-5">
										<div class="col-sm-12 pl-0 pr-0">
											<div class="tab-content pt-2 pb-2">
												<div
													v-if="activeTab === 'description'"
													class="tab-pane fade p-2"
													:class="{ 'show active': activeTab === 'description' }"
													role="tabpanel"
												>
													<!--상세정보-->
													<template v-for="(item, index) in productFormData.convertProductDescriptionImages" :key="index">
														<img :src="`${imgURL}${item.upfilesThumnailPath}/${item.upfilesFileName}`" :alt="item.name" style="width: 100%;">
													</template>
												</div>
												<div
													v-if="activeTab === 'review'"
													class="tab-pane fade p-2"
													:class="{ 'show active': activeTab === 'review' }"
													role="tabpanel"
												>
													<!--사용자 리뷰-->
													<component-product-detail-review
													:productFormData = "productFormData"
													/>
												</div>
												<div
													v-if="activeTab === 'note'"
													class="tab-pane fade p-2"
													:class="{ 'show active': activeTab === 'note' }"
													role="tabpanel"
												>
													<!--유의사항-->
													<component-product-detail-note />
												</div>
												<div
													v-if="activeTab === 'recommend'"
													class="tab-pane fade p-2"
													:class="{ 'show active': activeTab === 'recommend' }"
													role="tabpanel"
												>
													<!--추천상품-->
													<component-product-detail-recommend />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>

						<div class="col-12 col-sm-4">
							<div class="product-detail-calc">
								<div class="product-detail-select-content">
									<div>
										<div class="form-group row mt-3 mb-3">
											<label for="productDefaultItems" class="col-sm-12 col-form-label">기본사항</label>
											<template v-for="(item, index) in productFormData.productDefaultItems" :key="index">
												<div class="col-sm-12">
													<input
														type="checkbox"
														:id="'default-item-' + index"
														v-model="productSelectedItem.productDefaultItems"
														:value="item"
														class="btn-web-product-checked"
														autocomplete="off"
														disabled
													/>
													<label
														:for="'default-item-' + index"
														class="btn btn-block btn-web-product text-left"
													>
														<span class="btn-web-product-checked-status selected"><i class="fa-solid fa-circle-check"></i></span>
														<span class="btn-web-product-checked-status unselected"><i class="fa-regular fa-circle-xmark"></i></span>
														{{ item.productItemName }}
													</label>
												</div>
											</template>
										</div>
										<div class="form-group row" v-if="productFormData.productOptionItems && productFormData.productOptionItems.length > 0">
											<label for="productOptionItems" class="col-sm-12 col-form-label">추가옵션</label>
											<template v-for="(item, index) in productFormData.productOptionItems" :key="index">
												<div class="col-sm-12">
													<input
														type="checkbox"
														:id="'option-item-' + index"
														v-model="productSelectedItem.productOptionItems"
														:value="item"
														class="btn-web-product-checked"
														autocomplete="off"
														@change="onChangeOrderTotalCalculate()"
													/>
													<label
														:for="'option-item-' + index"
														class="btn btn-block btn-web-product text-left"
													>
														<span class="btn-web-product-checked-status selected"><i class="fa-solid fa-circle-check"></i></span>
														<span class="btn-web-product-checked-status unselected"><i class="fa-regular fa-circle-xmark"></i></span>
														{{ item.productItemName }}
														<template  v-if="storeSignInState">
															&nbsp;
															<span class="text-sm" v-if="orderType === 'subscribe'">+{{ numberFormat(item.productItemSubscribePrice) }}원</span>
															<span class="text-sm" v-if="orderType === 'rental'">+{{ numberFormat(item.productItemRentalPrice) }}원</span>
															<span class="text-sm" v-if="orderType === 'sale'">+{{ numberFormat(item.productItemSalePrice)`원` }}원</span>
														</template>
													</label>
												</div>
											</template>
										</div>
										<div class="form-group row mt-3" v-if="productFormData.productServiceItems && productFormData.productServiceItems.length > 0">
											<label for="productServiceItems" class="col-sm-12 col-form-label">서비스</label>
											<template v-for="(item, index) in productFormData.productServiceItems" :key="index">
												<div class="col-sm-12">
													<input
														type="checkbox"
														:id="'service-item-' + index"
														v-model="productSelectedItem.productServiceItems"
														:value="item"
														class="btn-web-product-checked"
														autocomplete="off"
														disabled
													/>
													<label
														:for="'service-item-' + index"
														class="btn btn-block btn-web-product text-left"
													>
														<span class="btn-web-product-checked-status selected"><i class="fa-solid fa-circle-check"></i></span>
														<span class="btn-web-product-checked-status unselected"><i class="fa-regular fa-circle-xmark"></i></span>
														{{ item.productItemName }}
													</label>
												</div>
											</template>
										</div>

										<div class="form-group row mt-3 mb-3" v-show="false">
											<label for="productOrderType" class="col-sm-12 col-form-label">구매방법</label>
											<div class="col-sm-3" v-if="productFormData.productSubscribe === 1 && productFormData.productSubscribePrice > 0">
												<input
													type="radio"
													id="orderSubscribe"
													name="orderType"
													v-model="orderType"
													value="subscribe"
													class="btn-web-product-checked"
													autocomplete="off"
													@change="onChangeOrderTotalCalculate()"
												/>
												<label for="orderSubscribe" class="btn btn-block btn-web-product">구독</label>
											</div>
											<div class="col-sm-3" v-if="productFormData.productRental === 1 && productFormData.productRentalPrice > 0">
												<input
													type="radio"
													id="orderRental"
													name="orderType"
													v-model="orderType"
													value="rental"
													class="btn-web-product-checked"
													autocomplete="off"
													@change="onChangeOrderTotalCalculate()"
												/>
												<label for="orderRental" class="btn btn-block btn-web-product">렌탈형구독</label>
											</div>
											<div class="col-sm-3" v-if="productFormData.productSale === 1 && productFormData.productSalePrice > 0">
												<input
													type="radio"
													id="orderSale"
													name="orderType"
													v-model="orderType"
													value="sale"
													class="btn-web-product-checked"
													autocomplete="off"
													@change="onChangeOrderTotalCalculate()"
												/>
												<label for="orderSale" class="btn btn-block btn-web-product">구매</label>
											</div>
										</div>
									</div>
									<div>
										<div class="form-group row mt-3">
											<div class="col-sm-12">
												<span class="btn btn-primary btn-block btn-special-1" @click="onClickOrder()"><span v-html="orderButtonText" style="font-size: 90%"></span></span>
											</div>
										</div>

										<div class="form-group row mt-1">
											<div class="col-sm-12">
												<button class="btn btn-sub-2 btn-block" @click="onClickCart()">장바구니 담기</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<!--content MOBILE-->
					<div class="row mobile-show">
						<div class="col-12">
							<div class="row mt-2 mb-2">
								<div class="col-sm-12 pl-0 pr-0">
									<nav>
										<div class="nav nav-tabs" id="product-tab" role="tablist">
											<a
												class="nav-item nav-link"
												:class="{ active: activeTab === 'description' }"
												@click="onSetActiveTab('description')"
												role="button"
												aria-selected="true"
											>상세정보</a>
											<a
												class="nav-item nav-link"
												:class="{ active: activeTab === 'note' }"
												@click="onSetActiveTab('note')"
												role="button"
												aria-selected="false"
											>유의사항</a>
											<a
												class="nav-item nav-link"
												:class="{ active: activeTab === 'review' }"
												@click="onSetActiveTab('review')"
												role="button"
												aria-selected="false"
											>사용자 리뷰</a>
											<a
												class="nav-item nav-link"
												:class="{ active: activeTab === 'recommend' }"
												@click="onSetActiveTab('recommend')"
												role="button"
												aria-selected="false"
											>추천상품</a>
										</div>
									</nav>
								</div>
							</div>
							<div class="row justify-content-center pb-6 mb-5">
								<div class="col-sm-12 pl-0 pr-0">
									<div class="tab-content pt-2 pb-2">
										<div
											v-if="activeTab === 'description'"
											class="tab-pane fade p-2"
											:class="{ 'show active': activeTab === 'description' }"
											role="tabpanel"
										>
											<!--상세정보-->
											<template v-for="(item, index) in productFormData.convertProductDescriptionImages" :key="index">
												<img :src="`${imgURL}${item.upfilesThumnailPath}/${item.upfilesFileName}`" :alt="item.name" style="width: 100%;">
											</template>
										</div>
										<div
											v-if="activeTab === 'review'"
											class="tab-pane fade p-2"
											:class="{ 'show active': activeTab === 'review' }"
											role="tabpanel"
										>
											<!--사용자 리뷰-->
											<component-product-detail-review
											:productFormData = "productFormData"
											/>
										</div>
										<div
											v-if="activeTab === 'note'"
											class="tab-pane fade p-2"
											:class="{ 'show active': activeTab === 'note' }"
											role="tabpanel"
										>
											<!--유의사항-->
											<component-product-detail-note />
										</div>
										<div
											v-if="activeTab === 'recommend'"
											class="tab-pane fade p-2"
											:class="{ 'show active': activeTab === 'recommend' }"
											role="tabpanel"
										>
											<!--추천상품-->
											<component-product-detail-recommend />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
                </div>
            </div>
        </div>
    </main>
	
	<alert ref="alertRef" :title="'알림'" />
	<modal-sign-in-confirm ref="modalSignInConfirmRef" @onCloseModal="onCloseModalSignInConfirm" />
	<modal-product-cart ref="modalProductCartRef" @onCloseModal="onCloseProductCart" />

</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue'
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { unescapeHtmlEntities  } from '@/helper/utils/etcUtils.js'
import { numberFormat, truncateText  } from '@/helper/utils/etcUtils.js'

import Alert from '@/modals/common/Alert'

import ModalSignInConfirm from '@/modals/web/account/ModalSignInConfirm'
import ModalProductCart from '@/modals/web/product/ModalProductCart'

import ComponentProductDetailNote from '@/components/web/ComponentProductDetailNote'
import ComponentProductDetailReview from '@/components/web/ComponentProductDetailReview'
import ComponentProductDetailRecommend from '@/components/web/ComponentProductDetailRecommend'

import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination, Autoplay, Parallax } from 'swiper/modules'
import 'swiper/swiper-bundle.css'

export default defineComponent({
    name       : 'webProductDetailPage',
	components: {
		Swiper,
        SwiperSlide,
		'alert' : Alert,
		'modal-sign-in-confirm' : ModalSignInConfirm,
		'modal-product-cart' : ModalProductCart,
		'component-product-detail-note': ComponentProductDetailNote,
		'component-product-detail-review': ComponentProductDetailReview,
		'component-product-detail-recommend': ComponentProductDetailRecommend
	},
    props: {
        uid: {
            type: String,
            required: true
        }
    },
    setup(props) {
		const modules = [Navigation, Pagination, Autoplay, Parallax]

		const { $axiosConnect, imgURL, router, store, storeSignInState } = commonMixin()
        const alertRef                     = ref(null)

		const swiperRef                    = ref(null)
        const swiperLoop                   = ref(false)

		const productFormData              = ref({})
		const selectedImage                = ref(null)
		const activeTab                    = ref("description")

		const productSelectedItem = ref({
			productDefaultItems : [],
			productOptionItems  : [],
			productServiceItems : [],
			productOrder        : [],
			productFormData     : [],
			productCart         : []
		})
		const orderTotalPrice              = ref(0)
		const orderDefaultTotalPrice       = ref(0)
		const orderOptionTotalPrice        = ref(0)
		const orderServiceTotalPrice       = ref(0)

		const orderType                    = ref('subscribe')
		const orderButtonText              = ref("")

		const modalSignInConfirmRef        = ref(null)
		const modalProductCartRef          = ref(null)

		const getDeatil = async () => {
            try {
                const uid = props.uid
                const response = await $axiosConnect('GUEST', `/web/product/${uid}`, 'GET')

                if (response.success && response.body && response.body.list.length > 0) {

                    const list = JSON.parse(JSON.stringify(response.body.list[0]))
                    productFormData.value = {
                        ...list,
						converUid                           : list.uid.toString().padStart(6, '0'),
						convertProductName                  : list.productName,
						convertProductDescription           : truncateText(list.productDescription, 50),
						converRegDate                       : convertDateToStr(list.regDate),
						converUpDate                        : convertDateToStr(list.upDate),
						convertContent                      : unescapeHtmlEntities(list.content),

						convertProductSubscribeRegularPrice : numberFormat(list.productSubscribeRegularPrice),
                        convertProductSubscribePrice        : numberFormat(list.productSubscribePrice),
                        convertProductRentalRegularPrice    : numberFormat(list.productRentalRegularPrice),
                        convertProductRentalPrice           : numberFormat(list.productRentalPrice),
                        convertProductRentalMonths          : numberFormat(list.productRentalMonths),
                        convertProductRentalMonthPrice      : numberFormat(list.productRentalPrice*list.productRentalMonths),
                        convertProductSaleRegularPrice      : numberFormat(list.productSaleRegularPrice),
                        convertProductSalePrice             : numberFormat(list.productSalePrice),

						convertProductViewCount             : numberFormat(list.productViewCount),
						convertProductImages                : list.upfiles.filter(file => file.upfilesColumn === 'productImages'),
						convertProductDescriptionImages     : list.upfiles.filter(file => file.upfilesColumn === 'productDescriptionImages'),

						productDefaultItems                 : list.productItems.filter(item => item.itemType === 1).length ? list.productItems.filter(item => item.itemType === 1) : [],
                        productOptionItems                  : list.productItems.filter(item => item.itemType === 2).length ? list.productItems.filter(item => item.itemType === 2) : [],
                        productServiceItems                 : list.productItems.filter(item => item.itemType === 3).length ? list.productItems.filter(item => item.itemType === 3) : [],
						productDefaultItemsFilter           : list.productItems.filter(item => item.itemType === 1).length ? list.productItems.filter(item => item.itemType === 1).map(({ itemName, itemType, itemUid }) => ({ itemName, itemType, itemUid })) : [],
                        productOptionItemsFilter            : list.productItems.filter(item => item.itemType === 2).length ? list.productItems.filter(item => item.itemType === 2).map(({ itemName, itemType, itemUid }) => ({ itemName, itemType, itemUid })) : [],
                        productServiceItemsFilter           : list.productItems.filter(item => item.itemType === 3).length ? list.productItems.filter(item => item.itemType === 3).map(({ itemName, itemType, itemUid }) => ({ itemName, itemType, itemUid })) : [],
                    }

					if (productFormData.value.convertProductImages.length > 0) {
                        selectedImage.value = `${imgURL.value}/${productFormData.value.convertProductImages[0].upfilesThumnailPath}/${productFormData.value.convertProductImages[0].upfilesFileName}`
                    }

					if(productFormData.value.productSubscribe === 1){
						orderType.value = 'subscribe'
					}else if(productFormData.value.productRental === 1){
						orderType.value = 'rental'
					}else if(productFormData.value.productSale === 1){
						orderType.value = 'sale'
					}

					productSelectedItem.value.productDefaultItems.push(productFormData.value.productDefaultItems[0])

					productSelectedItem.value.productServiceItems.push(...productFormData.value.productServiceItems);


					orderTotalPrice.value = productFormData.value.productSubscribePrice

                }else{
                    alertRef.value.open({ 'contents' : response.message })
                    close()
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

		const onSelectImage = (index) => {
            const selected = productFormData.value.convertProductImages[index]
            if (selected) {
                selectedImage.value = `${imgURL.value}/${selected.upfilesThumnailPath}/${selected.upfilesFileName}`
            }
        }

		const onSwiper = (swiper) => {
            swiperRef.value = swiper
        }

		const onSetActiveTab = (tab) => {
            activeTab.value = tab
        }

		const onChangeOrderTotalCalculate = async () => {

			orderDefaultTotalPrice.value = productFormData.value.productSubscribePrice || 0
			orderOptionTotalPrice.value  = productSelectedItem.value.productOptionItems.reduce((acc, item) => acc + (item.productItemSubscribePrice || 0), 0)
			orderServiceTotalPrice.value = productSelectedItem.value.productServiceItems.reduce((acc, item) => acc + (item.productItemSubscribePrice || 0), 0)
			let orderText = "월 구독 요금 "
			
			if(orderType.value === 'rental'){
				orderDefaultTotalPrice.value = productFormData.value.productRentalPrice || 0
				orderOptionTotalPrice.value  = productSelectedItem.value.productOptionItems.reduce((acc, item) => acc + (item.productItemRentalPrice || 0), 0)
				orderServiceTotalPrice.value = productSelectedItem.value.productServiceItems.reduce((acc, item) => acc + (item.productItemRentalPrice || 0), 0)
				orderText = "월 렌탈형 구독 요금 "
			}else if(orderType.value === 'sale'){
				orderDefaultTotalPrice.value = productFormData.value.productSalePrice || 0
				orderOptionTotalPrice.value  = productSelectedItem.value.productOptionItems.reduce((acc, item) => acc + (item.productItemSalePrice || 0), 0)
				orderServiceTotalPrice.value = productSelectedItem.value.productServiceItems.reduce((acc, item) => acc + (item.productItemSalePrice || 0), 0)
				orderText = ""
			}
			orderTotalPrice.value = orderDefaultTotalPrice.value + orderOptionTotalPrice.value + orderServiceTotalPrice.value
			orderText += '<span class="font-weight-semi-bold font-size-xl">' + numberFormat(orderTotalPrice.value) + '원</span>'

			if(orderType.value === 'rental'){
				orderText += ' / ' + productFormData.value.convertProductRentalMonths + '개월'
			}

			if(productFormData.value.productDistributor === 'ddokdok'){
				orderButtonText.value = orderText + ' <span class="font-weight-semi-bold font-size-xl">결제</span>'
			}else{
				orderButtonText.value = orderText + ' <span class="font-weight-semi-bold font-size-xl">신청</span>'
			}
		}

		const onClickOrder = () => {
			if(storeSignInState.value){

				let productOrder = productSelectedItem.value
				const productOrderData = {
					orderTotalPrice   : orderTotalPrice.value,
					defaultTotalPrice : orderDefaultTotalPrice.value,
					optionTotalPrice  : orderOptionTotalPrice.value,
					serviceTotalPrice : orderServiceTotalPrice.value,
					orderType         : orderType.value,
					orderDepositPrice : orderType.value==='subscribe'?productFormData.value.productSubscribeDeposit:(orderType.value==='rental'?productFormData.value.productRentalDeposit:0),
					orderMonths       : orderType.value==='subscribe'?productFormData.value.productSubscribeMonths:(orderType.value==='rental'?productFormData.value.productRentalMonths:0),
				}
				productOrder.productOrder.push(productOrderData)
				productOrder.productFormData.push(productFormData.value)

				store.dispatch('common/setProductOrderData', productOrder)
				router.push({ name: 'webOrderCheckPage' })
			}else{
				onClickModalSignInConfirm()
			}
		}

		const onClickCart = () => {
			if(storeSignInState.value){
				let productOrder = productSelectedItem.value
				const productOrderData = {
					orderTotalPrice   : orderTotalPrice.value,
					defaultTotalPrice : orderDefaultTotalPrice.value,
					optionTotalPrice  : orderOptionTotalPrice.value,
					serviceTotalPrice : orderServiceTotalPrice.value,
					orderType         : orderType.value
				}
				productOrder.productOrder.push(productOrderData)
				productOrder.productFormData.push(productFormData.value)

				const formData = new FormData()
				for (const key in productOrder) {
					if (productOrder[key] !== '') {
						formData.set(key, JSON.stringify(productOrder[key]))
					}
				}
				onProcessSave(formData)
				modalProductCartRef.value.open();

			}else{
				onClickModalSignInConfirm()
			}
		}

		const onCloseProductCart = (payload) => {
			if (payload.isUpdate) {
				router.push({ name : 'webMyPageCartPage' })
            }
		}

		/**
         * 로그인 프로세스를 처리하는 메서드
         * @method onProcessSignIn
         * @param {FormData} formData - 로그인에 필요한 폼 데이터
         * @returns {void}
         */
         const onProcessSave = async (formData) => {
            try {

                const response = await $axiosConnect('GUEST', `/web/cart`, 'POST', formData)
                if (response.success && response.body && response.body.list.length > 0) {
					initialize()
                }else{
                    //alertRef.value.open({ 'contents' : response.message })
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

		const onClickModalSignInConfirm = () => {
            modalSignInConfirmRef.value.open();
        }

        const onCloseModalSignInConfirm = (payload) => {
            if (payload.isUpdate) {
				const redirectPath = router.currentRoute.value.fullPath
				router.push({
					name : 'webSignInPage',
					query: { redirect: redirectPath }
				})
            }
        }

        const initialize = async () => {
            productFormData.value = []
			productSelectedItem.value = {
				productDefaultItems : [],
				productOptionItems  : [],
				productServiceItems : [],
				productOrder        : [],
				productFormData     : []
			}

			activeTab.value = "description"

			await getDeatil()
			await onChangeOrderTotalCalculate()

			store.dispatch('common/cleanProductOrderData')
        }

        onMounted(async () => {
            await initialize()
        })

        watch(() => props.uid, async () => {
            await initialize()
        })

		return {
			modules,
			imgURL,
			selectedImage,
            productFormData,
			swiperRef,
            swiperLoop,
			activeTab,
			productSelectedItem,
			orderTotalPrice,
			orderType,
			orderButtonText,
			
			storeSignInState,
			modalSignInConfirmRef,
			modalProductCartRef,
			
			onSelectImage,
			onSwiper,
			onSetActiveTab,
			numberFormat,
			onChangeOrderTotalCalculate,
			onClickOrder,
			onClickCart,
			onCloseModalSignInConfirm,
			onCloseProductCart
        }
    }
})
</script>
<style scoped>
.btn-check {
    display: none;
	background-color: #FFF;
    border-color: var(--color-black-2);
}

.btn-check:checked + label {
    background-color: var(--color-sub-2);
    color: white;
    border-color: var(--color-sub-2);
}

.tab-content {
	width: 100%;
	background-color: #FFF;
	min-height: 500px;
	border-radius: 0.5rem;
}

.pagination {
	padding-left: auto;
}

.nav-tabs {
	border: 0;
	justify-content: space-between;
}

.nav-tabs .nav-link {
	border: 1px solid var(--color-special-2);
	background-color: #FFF;
	color: var(--color-special-2);
	border-radius: 0;
	width: calc(25% - 2px);
	margin-right: 2px;
	text-align: center;
	flex-grow: 1;
}

.nav-tabs .nav-link:last-child {
	margin-right: 0;
}

.nav-tabs .nav-link.active, 
.nav-tabs .nav-item.show .nav-link {
	border: 1px solid var(--color-special-2);
	background-color: var(--color-special-2);
	color: #FFF;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border: 1px solid var(--color-special-2);
	background-color: var(--color-special-2);
	color: #FFF;
}
</style>