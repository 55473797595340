<template>
    ///////
</template>

<script>
import axios from 'axios'
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
    name       : 'testPage',
    components : {
    },
    setup() {
        
		const getCreditCardReceipt = async () => {
			try {
				const response = await axios.get(`https://pgapi.payletter.com/v1.0/receipt/info/mobinssys-202206089150320/?client_id=mobinssys`, {
					headers: {
						Authorization: 'PLKEY OTlFNzQzRTM5QkEzRjdCRjY5MDMzMUNDQUEwMzQyRDQ='
					}
				})
				const jsonData = response.data;
				console.log("jsonData:", jsonData)
				
			} catch (error) {
				console.error('Error during API request:', error);
			}
		}

        const initialize = async () => {
			getCreditCardReceipt()
        }

        onMounted(async () => {
            await initialize()
        })
        
        return {
        }

    }
});
</script>