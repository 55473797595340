<template>
	<label :for="`${dateName}-start`" class="d-none">날짜검색</label>
	<input
		type="date"
		:id="`${dateName}-start`"
		v-model="startDate"
		v-on:input="onDateChange"
		class="form-control"
	/>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue'

export default defineComponent({
	name: 'ComponentFormDate',
	props: {
        dateDefault: {
            type: Array,
            required: false,
            default: () => []
        },
        dateName: {
            type: String,
            required: true,
            default: ''
        }
    },
	emits : ['onChangeDate'],
	setup(props, {emit}) {
		const startDate = ref('');

		onMounted(() => {
			onHandleReset()
        })

		watch(startDate, () => {
            onChangeDate();
        });

		const onChangeDate = () => {
			emit('onChangeDate', {
                name     : props.dateName,
                selecteds: startDate.value
            });
		}

		const onHandleReset = () => {
            if (props.dateDefault.length > 0) {
                startDate.value = props.dateDefault.toString()
            }else{
				startDate.value = new Date().toISOString().substr(0, 10)
			}
        };

		return {
			startDate,
            onChangeDate,
            onHandleReset
		}
	}
})
</script>