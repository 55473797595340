<template>
    <main>
        <div class="container">
			<section class="content-header">
				<div class="container-fluid">
					<div class="row mb-2">
						<div class="col-sm-12">
							<div class="page-tab-title">
								<ul>
                                    <li class="active"><span>MY 페이지</span></li>
									<li @click="router.push({ name: 'webMyInfoPage' })"><span>내 정보</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
						<div class="col-sm-12">
                            <ul class="tab-list">
								<li @click="router.push({ name: 'webMyPageOrderStatusPage' })"><span>구독현황</span></li>
								<li @click="router.push({ name: 'webMyPageCartPage' })"><span>장바구니</span></li>
								<li class="active"><span>나의 리뷰</span></li>
								<li @click="router.push({ name: 'webMyPageContactPage' })"><span>나의 1:1 문의</span></li>
								<li @click="router.push({ name: 'webMyPageCallRequestPage' })"><span>나의 전화상담요청</span></li>
								<!--<li @click="router.push({ name: 'webMyPageCouponPage' })"><span>쿠폰</span></li>-->
							</ul>
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-md-12">

                            <div class="card" style="min-height: 500px">
                                <div class="card-body">
                                    <div class="review-product">
                                        
                                        <div class="product-inner-item" @click="onClickNavigateToProductDetail(detail)" role="button">
                                            <picture class="product-image">
                                                <template v-if="detail.convertProductImages && detail.convertProductImages.length > 0 ">
                                                    <img class="absolute-center" 
                                                        :src="`${imgURL}${detail.convertProductImages[0].upfilesThumnailPath}/${detail.convertProductImages[0].upfilesFileName}`" 
                                                        :alt="detail.convertProduct?.convertProductName"
                                                    />
                                                </template>
                                                <template v-else>
                                                    <img class="absolute-center" 
                                                        src="@/assets/images/common/logo_kor.png"
                                                        :alt="detail.convertProduct?.convertProductName"
                                                    />
                                                </template>
                                            </picture>
                                            <div class="product-content">
                                                <h5 class="product-name">{{ detail.convertProduct?.productName }}</h5>
                                                <p class="product-notes">{{ detail.convertProduct?.productDescription }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="font-weight-bold text-lg">{{ detail.subject }}</div>
                                    <div class="mt-1 text-xs">{{ detail.convertRegDate }}</div>
                                    <hr />
                                    <div>
                                        <span class="product-review-list-stars">
                                            <span v-for="starIndex in 5" :key="starIndex" :class="{ 'active': starIndex <= detail.star }">★</span>
                                        </span>
                                    </div>
                                    <hr />
                                    <div class="mt-3" v-html="detail.convertContent"></div>
                                    <div class="review-images">
                                        <template v-for="(filesItem, filesIndex) in detail.convertFiles" :key="filesIndex">
                                            <div><img :src="`${imgURL}${filesItem.upfilesThumnailPath}${filesItem.upfilesFileName}`"></div>
                                        </template>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12">
                                            <button class="btn btn-sub-2 float-right" @click="onClickList()">목록</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

						</div>
					</div>
                </div>
            </div>
        </div>
    </main>

    <alert ref="alertRef" :title="'알림'" />

</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'
import { convertDateToStr  } from '@/helper/utils/dateUtils.js'
import { unescapeHtmlEntities  } from '@/helper/utils/etcUtils.js'

import Alert from '@/modals/common/Alert'

export default defineComponent({
    name       : 'webMyPageReviewDetailPage',
    components: {
		'alert' : Alert,
	},
    props: {
        uid: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const { $axiosConnect, router, imgURL } = commonMixin()
        const alertRef       = ref(null)

		const detail = ref({})
        
        const getDeatil = async () => {
            try {
                const uid = props.uid
                const response = await $axiosConnect('MEMBER', `/web/review/${uid}`, 'GET')

                if (response.success && response.body && response.body.list.length > 0) {

                    const list = JSON.parse(JSON.stringify(response.body.list[0]))
                    detail.value = {
                        ...list,
                        convertRegDate       : convertDateToStr(list.regDate),
                        convertUpDate        : convertDateToStr(list.upDate),
                        convertContent       : unescapeHtmlEntities(list.content),
                        convertProduct       : list.product[0],
                        convertProductImages : list.product[0]?.upfiles.filter(file => file.upfilesColumn === 'productImages') || [],
                        convertFiles         : list.upfiles.filter(file => file.upfilesColumn === 'files'),
                    }
                }else{
                    alertRef.value.open({ 'contents' : response.message })
                    close()
                }
            } catch (error) {
                console.error("error:", error)
            }
        }

        const initialize = async () => {
            detail.value = []
        }

        const onClickList = () => {

            const routeQuery = router.currentRoute.value.query || {}
			const page = routeQuery.page || ''

            router.push({ name: 'webMyPageReviewPage', query: { page: page } });
        }

        const onClickNavigateToProductDetail = (item) => {
            const uid = item.convertProduct.uid
            router.push({ name: 'webProductDetailPage', params: { uid } });
		}

        onMounted(async () => {
            await initialize()
            await getDeatil()
        })

        watch(() => props.uid, async () => {
            await initialize();
            await getDeatil();
        });

		return {
            router,
            detail,
            imgURL,

            onClickList,
            onClickNavigateToProductDetail
        }
    }
});
</script>
<style scoped>
.review-images {
    display: block;
}
</style>