<template>
    <div class="row">
        <div class="col-md-4">
            <GChart
            type="ColumnChart"
            :data="chartDataOrder"
            :options="chartOptionsOrder"
            style="width: 100%; height: 300px"
            />
        </div>
        <div class="col-md-4">
            <GChart
            type="ColumnChart"
            :data="chartDataOrderTotal"
            :options="chartOptionsOrderTotal"
            style="width: 100%; height: 300px"
            />
        </div>
        <div class="col-md-4">
            <GChart
                type="ColumnChart"
                :data="chartDataMembers"
                :options="chartOptionsMembers"
                style="width: 100%; height: 300px"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import commonMixin from '@/helper/mixins/commonMixin.js'
import { GChart } from 'vue-google-charts';

export default defineComponent({
    name : 'adminMainPage',
    components: {
        GChart
    },
    setup() {
        const { $axiosConnect } = commonMixin()

        // 구독 수 차트 옵션
        const chartOptionsOrder = ref({
            title: '최근 구독수',
            //hAxis: { title: '최근 일주일' },
            vAxis: { 
                title: '구독수',
                minValue: 0,
                stepSize: 1,
            },
            stepSize: 1,
            colors: ['#FF9900'],
            legend: { position: 'none' }
        })

        // 구독 금액 차트 옵션
        const chartOptionsOrderTotal = ref({
            title: '최근 구독 금액',
            //hAxis: { title: '최근 일주일' },
            vAxis: { 
                title: '구독금액',
                minValue: 0,
                stepSize: 1,
            },
            stepSize: 1,
            colors: ['#FF9900'],
            legend: { position: 'none' }
        })

        // 가입한 회원 수 차트 옵션
        const chartOptionsMembers = ref({
            title: '최근 가입 회원',
            //hAxis: { title: '최근 일주일' },
            vAxis: { 
                title: '가입수',
                minValue: 0,
                stepSize: 1,
            },
            colors: ['#4285F4'],
            legend: { position: 'none' }
        })

        // 판매 수 데이터 (일주일 동안)
        const chartDataOrder = ref([
            ['일자', '구독수']
        ]);

        // 판매 수 데이터 (일주일 동안)
        const chartDataOrderTotal = ref([
            ['일자', '구독금액']
        ]);

        // 가입한 회원 수 데이터 (일주일 동안)
        const chartDataMembers = ref([
            ['일자', '가입수']
        ]);

        const getOrderDashboard = async () => {
            try {
                const response = await $axiosConnect('MEMBER', `/admin/orderDashboard`, 'GET')
                if (response.success && response.body.list) {
                    updateChartDataOrder(response.body.list);
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

        const getOrderTotalDashboard = async () => {
            try {
                const response = await $axiosConnect('MEMBER', `/admin/orderTotalDashboard`, 'GET')
                if (response.success && response.body.list) {
                    updateChartDataOrderTotal(response.body.list);
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

        const getMemberDashboard = async () => {
            try {
                const response = await $axiosConnect('MEMBER', `/admin/memberDashboard`, 'GET')
                if (response.success && response.body.list) {
                    updateChartDataMembers(response.body.list);
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

        const getLast7Days = () => {
            const last7Days = [];
            const today = new Date();
            
            for (let i = 6; i >= 0; i--) {
                const pastDate = new Date(today);
                pastDate.setDate(today.getDate() - i); // i일 전의 날짜 계산
                const formattedDate = pastDate.toISOString().split('T')[0]; // YYYY-MM-DD 포맷으로 변환
                last7Days.push(formattedDate);
            }
            return last7Days;
        }

        const updateChartDataMembers = (data) => {
            const last7Days = getLast7Days();
            const dataMap = new Map(data.map(item => [item.regDate, item.memberCount]));

            last7Days.forEach(date => {
                const memberCount = dataMap.get(date) || 0; // 데이터가 없으면 0으로 채움
                chartDataMembers.value.push([date, memberCount]);
            });
        }

        const updateChartDataOrder = (data) => {
            const last7Days = getLast7Days();
            const dataMap = new Map(data.map(item => [item.regDate, item.orderCount]));

            last7Days.forEach(date => {
                const orderCount = dataMap.get(date) || 0; // 데이터가 없으면 0으로 채움
                chartDataOrder.value.push([date, orderCount]);
            });
        }

        const updateChartDataOrderTotal = (data) => {
            const last7Days = getLast7Days();
            const dataMap = new Map(data.map(item => [item.regDate, item.orderTotal]));

            last7Days.forEach(date => {
                const orderTotal = dataMap.get(date) || 0; // 데이터가 없으면 0으로 채움
                chartDataOrderTotal.value.push([date, orderTotal]);
            });
        }

        onMounted(() => {
            getOrderDashboard()
            getOrderTotalDashboard()
            getMemberDashboard()
        })

        return {
            chartDataMembers,
            chartOptionsMembers,
            chartDataOrder,
            chartOptionsOrder,
            chartDataOrderTotal,
            chartOptionsOrderTotal
        };
    }
})
</script>