<template>
    <div class="input-radio-group">
        <template v-for="(item, index) in radioList" :key="index">
            <label class="input-label" :for="`${radioName}-${index}`">
                <input type="radio"
                    :id="`${radioName}-${index}`"
                    :name="radioName"
                    :value="item.value"
                    v-model="selected"
                    @change="onChangeRadio"
                />
                <span class="radio-icon"></span>
                <span class="label-text">{{ item.name }}</span>
            </label>
        </template>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted, watch } from 'vue'

export default defineComponent({
    name: 'ComponentFormRadio',
    props: {
        radioDefault: {
            type: Array,
            required: false,
            default: () => []
        },
        radioName: {
            type: String,
            required: true,
            default: ''
        },
        radioList: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    emits: ['onChangeRadio'],
    setup(props, { emit }) {
        const selected = ref('')

        onMounted(() => {
            onHandleReset()
        })

        const onChangeRadio = () => {
            emit('onChangeRadio', {
                name     : props.radioName,
                selecteds: selected.value ? [selected.value.toString()] : []
            });
        }

        const onHandleReset = () => {
            selected.value = props.radioDefault[0] || (props.radioList.length > 0 ? props.radioList[0].value : '')
            onChangeRadio()
        }

        watch(selected, () => {
            onChangeRadio()
        })

        return {
            selected,
            onChangeRadio,
            onHandleReset
        };
    }
});
</script>