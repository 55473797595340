<template>
    <Modal ref="baseModal" :title="agreeTitle" :width="`800px`" :isDrag="false" @close="close">
        <template #body>
            <div>
                <div class="modal-agree-text"
                @scroll="onScrollContent"
                ref="signUpAgreeContentRef"
                v-html="signUpAgreeContent"
                >
                </div>
            </div>
            <div class="modal-agree-scroll">
                <template v-if="submitButtonDisabled">
                    <span>내용을 다 읽고 동의 버튼을 클릭해주세요.</span>
                </template>
                <template v-else>
                    <span>내용을 다 읽으셨나요? 동의 버튼을 클릭해주세요.</span>
                </template>
            </div>
        </template>
        <template #footer>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close()">닫기</button>
			<button type="submit" class="btn btn-primary-1" :disabled="submitButtonDisabled" @click="onClickSave">동의</button>
        </template>
	</Modal>
</template>

<script>
import { defineComponent, ref, nextTick } from 'vue';
import Modal from '@/modals/Modal'
import commonMixin from '@/helper/mixins/commonMixin.js'
import { unescapeHtmlEntities  } from '@/helper/utils/etcUtils.js'

export default defineComponent({
    name       : 'modalSignUpAgreePage',
    components: {
		Modal
	},
    emits: ['onCloseModal'],
	setup(_, {emit}){

        const { $axiosConnect } = commonMixin()

        const baseModal = ref(null)
		const resolvePromise = ref(null)

        const submitButtonDisabled  = ref(false)
        const mode                  = ref('memberAgreeService')
        const closeModalData        = ref({})
        const signUpContentType     = ref(null)
        const signUpAgreeContent    = ref(null)
        const signUpAgreeContentRef = ref(null)
        const detail                = ref({})
        const agreeTitle            = ref('사용자 동의')
        
        const initialize = async () => {
            await getDeatil()
            await nextTick();
            onScrollContent()
        }

        const getDeatil = async () => {
            try {
                const response = await $axiosConnect('GUEST', `/web/initialize/1`, 'GET')
                if (response.success && response.body && response.body.list.length > 0) {

                    const list = JSON.parse(JSON.stringify(response.body.list[0]))
                    detail.value = {
                        ...list,
                        convertInitializeAgreeService : unescapeHtmlEntities(list.initializeAgreeService),
						convertInitializeAgreePolicy : unescapeHtmlEntities(list.initializeAgreePolicy),
						convertInitializeAgreeSharing : unescapeHtmlEntities(list.initializeAgreeSharing),
						convertInitializeAgreeReceiving : unescapeHtmlEntities(list.initializeAgreeReceiving),
                    }
                }

                if(signUpContentType.value === 'memberAgreeService'){
                    signUpAgreeContent.value=detail.value.convertInitializeAgreeService
                    agreeTitle.value = '이용약관 동의';
                }else if(signUpContentType.value === 'memberAgreePolicy'){
                    signUpAgreeContent.value=detail.value.convertInitializeAgreePolicy
                    agreeTitle.value = '개인정보처리방침 동의';
                }else if(signUpContentType.value === 'memberAgreeSharing'){
                    signUpAgreeContent.value=detail.value.convertInitializeAgreeSharing
                    agreeTitle.value = '개인정보 제공 동의';
                }else if(signUpContentType.value === 'memberAgreeReceiving'){
                    signUpAgreeContent.value=detail.value.convertInitializeAgreeReceiving
                    agreeTitle.value = '정보수신 동의';
                }

            } catch (error) {
                console.error("error:", error)
            }
        }

		const open = async (payload) => {
            mode.value              = payload.mode
            signUpContentType.value = payload.item
            submitButtonDisabled.value = false

            closeModalData.value = {
                isUpdate : false,
                item     : signUpContentType.value
            }

            //Open Modal
			baseModal.value.openModal()
            await initialize()
			return new Promise((resolve) => {
				resolvePromise.value = resolve;
			});
		};

		const close = () => {
            emit('onCloseModal', closeModalData.value)
			baseModal.value.closeModal()
			resolvePromise.value(closeModalData.value)
		};

        /**
         * 버튼에 마우스가 올라오면 버튼을 클릭할 상태가 되도록 하는 메서드
         * @method onOverSubmitButton
         * @returns {void}
         */
         const onClickSave = () => {
            closeModalData.value = {
                isUpdate : true,
                item     : signUpContentType.value
            }
            close()
        }

        const onScrollContent = () => {
            /*
            const content = signUpAgreeContentRef.value;
            if (content.scrollHeight <= content.clientHeight || content.scrollHeight - content.scrollTop === content.clientHeight) {
                submitButtonDisabled.value = false;
            }
            */
        };

        return {
			baseModal, 
			open,
			close,

            submitButtonDisabled,
            signUpAgreeContentRef,
            signUpAgreeContent,
            agreeTitle,

            onClickSave,
            onScrollContent
		}
    }
});
</script>