<template>
	<li class="nav-item" :class="{ 'menu-is-opening menu-open': item.isOpen }">
		<span :class="['nav-link', isActive ? 'active' : '']" @click="onClickMenu">
			<i :class="['nav-icon', item.icon]"></i>
			<p>
				{{ item.title }}
				<i v-if="item.children && item.children.length > 0" class="right fas fa-angle-left"></i>
				<span v-if="item.badge !== '0'" class="right badge badge-danger">{{ item.badge }}</span>
			</p>
		</span>
		<ul v-if="item.children && item.children.length > 0" class="nav nav-treeview">
			<ComponentSideBarMenuItem
				v-for="child in item.children"
				:key="child.seq"
				:item="child"
				@toggle="onToggleMenu"
			/>
		</ul>
	</li>
</template>

<script>
import { defineComponent, computed } from 'vue'
import commonMixin from '@/helper/mixins/commonMixin'

export default defineComponent({
	name: 'ComponentSideBarMenuItem',
	props: {
		item: {
			type: Object,
			required: true
		}
	},
	setup(props, { emit }) {
		const { router } = commonMixin()

		const isActive = computed(() => {
			return props.item.path === router.currentRoute.value.path
		});

		const onToggleMenu = () => {
			emit('toggle', props.item)
		}

		const onClickMenu = (event) => {
            if (props.item.children && props.item.children.length > 0) {
                event.preventDefault();
                onToggleMenu();
            } else {
                router.push(props.item.path);
            }
        }

		return {
			isActive,
			onToggleMenu,
			onClickMenu
		}
	}
})
</script>