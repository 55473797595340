<template>
    <footer class="main-footer">
        <div class="container">
            <div class="footer-wrapper" style="position: relative;">
                <ul class="footer-row">
                    <li class="footer-item"><span role="button" @click="onClickNavigateToPage('webAgreeServicePage')">이용약관</span></li>
                    <li class="footer-item"><span role="button" @click="onClickNavigateToPage('webAgreePolicyPage')">개인정보처리방침</span></li>
                    <li class="footer-item"><span role="button" @click="onClickNavigateToPage('webDixlabCompanyPage')">회사소개</span></li>
                    <li class="footer-item"><span role="button" @click="onClickModalBusinessRequest()">제휴문의</span></li>
                </ul>
                <div class="footer-row2 row">
                    <div class="col-sm-11">
                        <ul class="footer-row mt-3 mb-3 footer-info">
                            <li class="footer-item"><span role="button">(주)딕스랩</span></li>
                            <li class="footer-item"><span>인천광역시 남동구 남동대로 215번길 30, 인천종합비즈니스센터 7층 711호</span></li>
                            <li class="footer-item"><span>대표 김범준</span></li>
                            <li class="footer-item"><span>사업자등록번호 587-24-01502</span></li>
                            <li class="footer-item"><span>통신판매신고 제 2024-인천남동구-2325 호</span></li>
                            <li class="footer-item"><span role="button" @click="onClickBusinessNumberCheck()">[사업자정보확인]</span></li>
                            <li class="footer-item"><span>T. 010-6465-3695</span></li>
                            <li class="footer-item"><span>dixlab@nate.com</span></li>                            
                        </ul>
                    </div>
                    <div class="col-sm-1">
                        <div class="escrow-img"><img src="@/assets/images/common/mark-escrow-sm.png" /></div>
                    </div>
                </div>
                <div class="coryight">Copyright ⓒ DIXLAP. All Rights Reserved.</div>
            </div>
        </div>
    </footer>

    <modal-business-request ref="modalBusinessRequestRef" @onCloseModal="onCloseModalBusinessRequest" />

</template>

<script>
import { defineComponent, ref } from 'vue';
import commonMixin from '@/helper/mixins/commonMixin.js'

import ModalBusinessRequest from '@/modals/web/common/ModalBusinessRequest'

export default defineComponent({
    name : 'defaultFooterPage',
    components: {
		'modal-business-request' : ModalBusinessRequest,
	},
    setup() {
        const { router } = commonMixin()

        const modalBusinessRequestRef = ref(null)

		const onClickNavigateToPage = (pageName) => {
                router.push({ name: pageName });
        }

        const onClickModalBusinessRequest = () => {
			modalBusinessRequestRef.value.open({
                'mode' : 'create',
                'item' : ''
            });
		}

		const onCloseModalBusinessRequest = () => {
            //
		}

        const onClickBusinessNumberCheck = () => {
            const url = "http://www.ftc.go.kr/bizCommPop.do?wrkr_no=5872401502";
            window.open(url, "bizCommPop", "width=750, height=700;");
        }

        return {
            modalBusinessRequestRef,

            onClickNavigateToPage,
            onClickModalBusinessRequest,
            onCloseModalBusinessRequest,
            onClickBusinessNumberCheck
        }
    }
});
</script>