import DefaultLayoutPage              from '@/layouts/web/DefaultLayout';
 
import WebMainPage                    from '@/views/web/Main';

import WebProductCustomPage           from '@/views/web/product/ProductCustom';
import WebProductCustomSearchPage     from '@/views/web/product/ProductCustomSearch';
import WebProductSearchPage           from '@/views/web/product/ProductSearch';

import WebProductListPage             from '@/views/web/product/ProductList';
import WebProductDetailPage           from '@/views/web/product/ProductDetail';

import WebOrderCheckPage              from '@/views/web/order/OrderCheck';
import WebOrderPaymentPage            from '@/views/web/order/OrderPayment';
import WebOrderComplatePage           from '@/views/web/order/OrderComplete';

import WebEventListPage               from '@/views/web/event/EventList';
import WebEventDetailPage             from '@/views/web/event/EventDetail';
import WebEventResultListPage         from '@/views/web/event/EventResultList';

import WebNoticeListPage              from '@/views/web/cs/NoticeList';
import WebNoticeDetailPage            from '@/views/web/cs/NoticeDetail';
import WebFaqListPage                 from '@/views/web/cs/FaqList';
import WebContactPage                 from '@/views/web/cs/Contact';
import WebCallRequestPage             from '@/views/web/cs/CallRequest';
import WebHelpPage                    from '@/views/web/cs/Help';

import WebSignInPage                  from '@/views/web/account/SignIn';
import WebIdFindPage                  from '@/views/web/account/IdFind';
import WebPasswordFindPage            from '@/views/web/account/PasswordFind';

import WebSignUpStep1Page             from '@/views/web/account/SignUpStep1';
import WebSignUpStep2Page             from '@/views/web/account/SignUpStep2';
import WebSignUpStep3Page             from '@/views/web/account/SignUpStep3';
import WebSignUpStep4Page             from '@/views/web/account/SignUpStep4';
import WebSignUpStepComplatePage      from '@/views/web/account/SignUpStepComplate';

import WebMyPageOrderStatusPage       from '@/views/web/mypage/OrderStatus';
import WebMyPageCartPage              from '@/views/web/mypage/Cart';
import WebMyPageReviewPage            from '@/views/web/mypage/Review';
import WebMyPageReviewDetailPage      from '@/views/web/mypage/ReviewDetail';
import WebMyPageContactPage           from '@/views/web/mypage/Contact';
import WebMyPageContactDetailPage     from '@/views/web/mypage/ContactDetail';
import WebMyPageCallRequestPage       from '@/views/web/mypage/CallRequest';
import WebMyPageCallRequestDetailPage from '@/views/web/mypage/CallRequestDetail';
import WebMyPageCouponPage            from '@/views/web/mypage/Coupon';

import WebMyInfoPage                  from '@/views/web/mypage/MyInfo';

import WebAgreeServicePage            from '@/views/web/agree/Service';
import WebAgreePolicyPage             from '@/views/web/agree/Policy';
import WebAgreeSharingPage            from '@/views/web/agree/Sharing';
import WebAgreeReceivingPage          from '@/views/web/agree/Receiving';

import WebDixlabCompanyPage           from '@/views/web/dixlab/Company';

import TestPage                       from '@/views/web/Test';

const webRoutes = [
    {
        path: '/',
        name: 'webLayoutPage',
        components: {
            default: DefaultLayoutPage
        },
        children: [
            {
                path: '',
                name: 'webMainPage',
                components: {
                    default: WebMainPage
                }
            },

            //상품            
            {
                path: 'products/:searchPurpose',
                name: 'webProductListPage',
                components: {
                    default: WebProductListPage
                },
                props: true
            },
            {
                path: 'product/:uid',
                name: 'webProductDetailPage',
                components: {
                    default: WebProductDetailPage
                },
                props: true
            },

            //검색
            {
                path: 'search',
                name: 'webProductSearchPage',
                components: {
                    default: WebProductSearchPage
                }
            },
            //상품만들기
            {
                path: 'custom',
                name: 'webProductCustomPage',
                components: {
                    default: WebProductCustomPage
                }
            },
            {
                path: 'customSearch',
                name: 'webProductCustomSearchPage',
                components: {
                    default: WebProductCustomSearchPage
                }
            },

            //구매
            {
                path: 'order/check',
                name: 'webOrderCheckPage',
                components: {
                    default: WebOrderCheckPage
                },
                meta: { 
                    requiresAuth: true,
                    layout: 'web'
                },
            },
            {
                path: 'order/payment',
                name: 'webOrderPaymentPage',
                components: {
                    default: WebOrderPaymentPage
                },
                meta: { 
                    requiresAuth: true,
                    layout: 'web'
                },
            },
            {
                path: 'order/complate/:orderUid',
                name: 'webOrderComplatePage',
                components: {
                    default: WebOrderComplatePage
                },
                meta: { 
                    requiresAuth: true,
                    layout: 'web'
                },
                props: true
            },

            //이벤트
            {
                path: 'event',
                name: 'webEventListPage',
                components: {
                    default: WebEventListPage
                }
            },
            {
                path: 'event/:uid',
                name: 'webEventDetailPage',
                components: {
                    default: WebEventDetailPage
                },
                props: true
            },
            {
                path: 'eventResult',
                name: 'webEventResultListPage',
                components: {
                    default: WebEventResultListPage
                }
            },

            //고객센터
            {
                path: 'notice',
                name: 'webNoticeListPage',
                components: {
                    default: WebNoticeListPage
                }
            },
            {
                path: 'notice/:uid',
                name: 'webNoticeDetailPage',
                components: {
                    default: WebNoticeDetailPage
                },
                props: true
            },
            {
                path: 'faq',
                name: 'webFaqListPage',
                components: {
                    default: WebFaqListPage
                }
            },
            {
                path: 'contact',
                name: 'webContactPage',
                components: {
                    default: WebContactPage
                },
                meta: { 
                    requiresAuth: true,
                    layout: 'web'
                },
            },
            {
                path: 'callRequest',
                name: 'webCallRequestPage',
                components: {
                    default: WebCallRequestPage
                },
                meta: { 
                    requiresAuth: true,
                    layout: 'web'
                },
            },
            {
                path: 'help',
                name: 'webHelpPage',
                components: {
                    default: WebHelpPage
                }
            },

            //로그인
            {
                path: 'signIn',
                name: 'webSignInPage',
                components: {
                    default: WebSignInPage
                }
            },

            //아이디 찾기
            {
                path: 'idFind',
                name: 'webIdFindPage',
                components: {
                    default: WebIdFindPage
                }
            },

            //비밀번호 찾기
            {
                path: 'passwordFind',
                name: 'webPasswordFindPage',
                components: {
                    default: WebPasswordFindPage
                }
            },

            //회원가입
            {
                path: 'signUpStep1',
                name: 'webSignUpStep1Page',
                components: {
                    default: WebSignUpStep1Page
                }
            },
            {
                path: 'signUpStep2',
                name: 'webSignUpStep2Page',
                components: {
                    default: WebSignUpStep2Page
                }
            },
            {
                path: 'signUpStep3',
                name: 'webSignUpStep3Page',
                components: {
                    default: WebSignUpStep3Page
                }
            },
            {
                path: 'signUpStep4',
                name: 'webSignUpStep4Page',
                components: {
                    default: WebSignUpStep4Page
                }
            },
            {
                path: 'signUpStepComplate',
                name: 'webSignUpStepComplatePage',
                components: {
                    default: WebSignUpStepComplatePage
                }
            },

            //MY 페이지 - orderStatus/cart/review/contact/coupon
            {
                path: 'myPage/orderStatus',
                name: 'webMyPageOrderStatusPage',
                components: {
                    default: WebMyPageOrderStatusPage
                },
                meta: { 
                    requiresAuth: true,
                    layout: 'web'
                },
            },
            {
                path: 'myPage/cart',
                name: 'webMyPageCartPage',
                components: {
                    default: WebMyPageCartPage
                },
                meta: { 
                    requiresAuth: true,
                    layout: 'web'
                },
            },
            {
                path: 'myPage/review',
                name: 'webMyPageReviewPage',
                components: {
                    default: WebMyPageReviewPage
                },
                meta: { 
                    requiresAuth: true,
                    layout: 'web'
                },
            },
            {
                path: 'myPage/review/:uid',
                name: 'webMyPageReviewDetailPage',
                components: {
                    default: WebMyPageReviewDetailPage
                },
                meta: { 
                    requiresAuth: true,
                    layout: 'web'
                },
                props: true
            },
            {
                path: 'myPage/contact',
                name: 'webMyPageContactPage',
                components: {
                    default: WebMyPageContactPage
                },
                meta: { 
                    requiresAuth: true,
                    layout: 'web'
                },
            },
            {
                path: 'myPage/contact/:uid',
                name: 'webMyPageContactDetailPage',
                components: {
                    default: WebMyPageContactDetailPage
                },
                meta: { 
                    requiresAuth: true,
                    layout: 'web'
                },
                props: true
            },
            {
                path: 'myPage/callRequest',
                name: 'webMyPageCallRequestPage',
                components: {
                    default: WebMyPageCallRequestPage
                },
                meta: { 
                    requiresAuth: true,
                    layout: 'web'
                },
            },
            {
                path: 'myPage/callRequest/:uid',
                name: 'webMyPageCallRequestDetailPage',
                components: {
                    default: WebMyPageCallRequestDetailPage
                },
                meta: { 
                    requiresAuth: true,
                    layout: 'web'
                },
                props: true
            },
            {
                path: 'myPage/coupon',
                name: 'webMyPageCouponPage',
                components: {
                    default: WebMyPageCouponPage
                },
                meta: { 
                    requiresAuth: true,
                    layout: 'web'
                },
            },

            {
                path: 'myInfo',
                name: 'webMyInfoPage',
                components: {
                    default: WebMyInfoPage
                },
                meta: { 
                    requiresAuth: true,
                    layout: 'web'
                },
            },

            {
                path: 'agree/service',
                name: 'webAgreeServicePage',
                components: {
                    default: WebAgreeServicePage
                }
            },
            {
                path: 'agree/policy',
                name: 'webAgreePolicyPage',
                components: {
                    default: WebAgreePolicyPage
                }
            },
            {
                path: 'agree/sharing',
                name: 'webAgreeSharingPage',
                components: {
                    default: WebAgreeSharingPage
                }
            },
            {
                path: 'agree/receiving',
                name: 'webAgreeReceivingPage',
                components: {
                    default: WebAgreeReceivingPage
                }
            },

            {
                path: 'dixlab/company',
                name: 'webDixlabCompanyPage',
                components: {
                    default: WebDixlabCompanyPage
                }
            },

			{
                path: 'test',
                name: 'testPage',
                components: {
                    default: TestPage
                }
            },
            
        ]
    }
];

export default webRoutes;